import { CircularProgress, FormControlLabel, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import { useForm } from 'hooks/useForm';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createProject, updateProject } from 'services/projectService';
import styled from 'styled-components';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useProjectEffect } from './hooks';

const schemaProject = {
  name: yup.string().required().label('Name'),
  holidays: yup.bool().required().label('Holidays'),
  vacations: yup.bool().required().label('Vacations'),
  isActive: yup.bool().required().label('isActive'),
  allowTimsheetImport: yup.bool().required().label('allowTimsheetImport'),

  clientSince: yup.string().nullable().optional().typeError('Invalid date'),
  techStack: yup.string().nullable().optional().label('Tech stack'),
  category: yup.string().nullable().optional().label('Category'),
  partner: yup.string().nullable().optional().label('Partner'),
};

type Props = {};

const ProjectForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();
  const { project, loading, isReadOnly } = useProjectEffect(id);
  const history = useHistory();
  const [savingProject, setSavingProject] = useState(false);
  const { useInput, useDateInput, useSwitch, values, isValid } = useForm(project, schemaProject as any);
  const subTitle = id ? 'Update the project' : 'Create a new project';
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';

  const handleSaveProjectItem = async () => {
    if (!isValid) {
      notifier.error('Please, fix the errors on the form before submitting');
      return;
    }

    setSavingProject(true);

    if (id) {
      const response = await updateProject(values, id);

      if (response) notifier.success('Project updated successfully.');
      setSavingProject(false);
      return;
    }

    const newProject = await createProject(values);

    if (newProject) {
      notifier.success('Project created successfully.');
      history.push(`/projects/${newProject._id}`);
    }
    setSavingProject(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Project: ${!initialLoading ? values?.name : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Name</BodyTitle>
          <TextBody>
            <TextField
              required
              fullWidth
              inputProps={{
                maxLength: 32,
              }}
              id={'projectName'}
              label="Name"
              variant="outlined"
              {...useInput('name')}
              disabled={isReadOnly}
            />
          </TextBody>

          <BodyTitle>Timesheet Settings</BodyTitle>

          <FormControlLabelOptionContainer
            control={<Switch id={'holidaysSwitch'} name="holidays" {...useSwitch('holidays')} />}
            label="Does client Pay Holiday? "
            disabled={isReadOnly}
          />
          <FormControlLabelOptionContainer
            control={<Switch id={'vacationsSwitch'} name="vacations" {...useSwitch('vacations')} />}
            label="Does client Pay Vacations?"
            disabled={isReadOnly}
          />
          <FormControlLabelOptionContainer
            control={
              <Switch id={'allowTimsheetImport'} name="allowTimsheetImport" {...useSwitch('allowTimsheetImport')} />
            }
            label="Allows Timesheet Import?"
            disabled={isReadOnly}
          />

          <br />
          <br />
          <BodyTitle>Details</BodyTitle>

          <DetailsLine>
            <TextField
              id={'category'}
              inputProps={{
                maxLength: 32,
              }}
              label="Category"
              variant="outlined"
              disabled={isReadOnly}
              {...useInput('category')}
            />
            <TextOptions
              id={'partner'}
              inputProps={{
                maxLength: 32,
              }}
              label="Partner"
              variant="outlined"
              disabled={isReadOnly}
              {...useInput('partner')}
            />

            <SelectContainerWide>
              <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                <DateInputPickerContainer
                  id={'clientSince'}
                  label="Client Since"
                  format={dateFormatUsed}
                  inputVariant="outlined"
                  disabled={isReadOnly}
                  {...useDateInput('clientSince')}
                  value={values.clientSince}
                  InputLabelProps={{
                    shrink: values.clientSince ? true : false,
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  clearable
                />
              </Tooltip>
            </SelectContainerWide>
          </DetailsLine>
          <DetailsLine>
            <ExpandTextField
              multiline
              fullWidth
              minRows={4}
              maxRows={Infinity}
              inputProps={{ maxLength: 5000 }}
              id="techStack"
              label="Tech Stack"
              variant="outlined"
              name="techStack"
              disabled={isReadOnly}
              {...useInput('techStack')}
            />
          </DetailsLine>
          <br />
          <FormControlLabelOptionContainer
            control={<Switch id={'isActiveSwitch'} name="isActive" {...useSwitch('isActive')} />}
            label="Active"
            disabled={isReadOnly}
          />

          <ButtonContainer>
            {savingProject && <Spinner size={30} />}
            <Submit
              id={'saveProjectButton'}
              disabled={savingProject || loading || !isValid || isReadOnly}
              onClick={handleSaveProjectItem}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const TextBody = styled.div`
  margin: 20px;
`;

const TextOptions = styled(TextField)`
  margin-left: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const FormControlLabelOptionContainer = styled(FormControlLabel)`
  margin-left: 10px;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  margin-top: 5px;
  margin: 20px;
`;

const SelectContainerWide = styled.div`
  min-width: 160px;
  width: 280px;
  display: flex;
  float: left;
  margin-left: 20px;
`;

const DateInputPickerContainer = styled(KeyboardDatePicker)`
  width: 200px;
  .MuiButtonBase-root {
    margin-right: -14px;
  }
`;

const ExpandTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: auto !important;
  }
`;

export default ProjectForm;
