import { unstable_createMuiStrictModeTheme } from '@material-ui/core';

import { BLACK, BLUE, CINNABAR, DIM_GRAY, NOBEL, PIZAZZ, SIDE_BAR_BACKGROUND, WHITE } from '../utilities/colors';

export const LIGHT_THEME = unstable_createMuiStrictModeTheme({
  palette: {
    primary: { main: BLUE },
    success: { main: BLUE },
    background: { default: WHITE, paper: '#eee' },
    error: { main: CINNABAR },
    text: { primary: BLACK, secondary: DIM_GRAY },
    common: { black: BLACK, white: WHITE },
    secondary: { main: PIZAZZ },
    divider: NOBEL,
    type: 'light',
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: SIDE_BAR_BACKGROUND,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});
