import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import {
  EmployeeWithReferFilter,
  EmployeeWithReferResource,
  getAllEmployeesWithReferBy,
} from 'services/employeeService';
import { getStatusesReferral } from 'services/statusReferral';
import { Item } from 'shared/types';
import { collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [statusesFilter, setStatusesFilter] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newStatuses = await getStatusesReferral();

      setStatusesFilter(collectionToItem(newStatuses));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, statusesFilter };
};

export const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

export const useReferralEffect = (filter: Query & EmployeeWithReferFilter) => {
  const [referral, setReferral] = useState<PageResource<EmployeeWithReferResource>>({
    totalItems: 0,
    items: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchReferral = useCallback(async () => {
    setLoading(true);

    const data = await getAllEmployeesWithReferBy(filter);

    setLoading(false);
    setReferral(data);
  }, [filter]);

  useEffect(() => {
    fetchReferral();
  }, [fetchReferral, filter]);

  return { referral, loading, setLoading, fetchReferral };
};
