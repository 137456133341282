import React from 'react';

import { ContainerDiv } from './styles';

interface Props {
  children: any;
}

const Container = (props: Props) => {
  const { children } = props;

  return <ContainerDiv>{children}</ContainerDiv>;
};

export default Container;
