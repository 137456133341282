import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import {
  ExitProcedureProcessFilter,
  ExitProcedureProcessResource,
  getAllExitProcedureProcess,
} from 'services/exitProcedureProcessService';
import { getStatusesFilter } from 'services/statusFilterService';
import { Item } from 'shared/types';
import { collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [statusesFilter, setStatusesFilter] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newStatuses = await getStatusesFilter();

      setStatusesFilter(collectionToItem(newStatuses));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, statusesFilter };
};

export const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

export const useExitProcedureProcessEffect = (filter: Query & ExitProcedureProcessFilter) => {
  const [exitProcedureProcess, setExitProcedureProcess] = useState<PageResource<ExitProcedureProcessResource>>({
    totalItems: 0,
    items: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchExitProcedureProcess = useCallback(async () => {
    setLoading(true);

    const data = await getAllExitProcedureProcess(filter);

    setLoading(false);
    setExitProcedureProcess(data);
  }, [filter]);

  useEffect(() => {
    fetchExitProcedureProcess();
  }, [fetchExitProcedureProcess, filter]);

  return { exitProcedureProcess, loading, setLoading, fetchExitProcedureProcess };
};
