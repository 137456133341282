import { useEffect, useState } from 'react';
import { getAllEmployees } from 'services/employeeService';
import { CreateExitProcedureProcessResource } from 'services/exitProcedureProcessService';
import { getExitProcedureTemplates } from 'services/exitProcedureTemplate';
import { Item } from 'shared/types';
import { collectionEmployeeToItem } from 'utils/mappers/item';

const defaultCreateEpResource = {
  employee: '',
  template: '',
  descriptions: [],
};

export const useFetchEpEffect = () => {
  const [ep] = useState<CreateExitProcedureProcessResource>(defaultCreateEpResource);

  return { ep };
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [templates, setTemplates] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [employeesResult, templatesResult] = await Promise.all([getAllEmployees(), getExitProcedureTemplates()]);

      const employeesItems = collectionEmployeeToItem(employeesResult);
      const templateItems: Item[] = templatesResult.map((template) => ({ id: template._id, label: template.title }));

      setEmployees(employeesItems);
      setLoading(false);

      setTemplates(templateItems);
    }

    fetchCatalogs();
  }, []);

  return { templates, employees, loading };
};
