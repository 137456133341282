import DateInputPicker from 'components/date-input-picker';
import ExportButton from 'components/export-button';
import MultiSelectBase from 'components/multi-select';
import SingleSelectBase from 'components/single-select';
import { Query } from 'hooks/usePaging';
import React, { useCallback } from 'react';
import { ActionItemFilter } from 'services/actionItemService';
import { loadCsvFileAllInfo } from 'services/actionItemService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  employees: Item[];
  leaders: Item[];
  statuses: Item[];
  priorities: Item[];
  filter: ActionItemFilter & Query;
  setFilter(value: ActionItemFilter & Query): void;
  totalItems: number;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_actionItem';

const Filter: React.FC<Props> = (props) => {
  const { filter, employees, leaders, statuses, priorities, setFilter, totalItems } = props;

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setFilter({ ...filter, [name!]: value, page: 1 });
    },
    [setFilter, filter],
  );

  return (
    <Container>
      <MultiSelect
        id={'employeesSelect'}
        name="employees"
        options={employees}
        currentValues={filter.employees || []}
        label={'Employees'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'leadersSelect'}
        name="leaderAssigned"
        options={leaders}
        currentValues={filter.leaderAssigned || []}
        label={'Assigned to'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'statusSelect'}
        name="status"
        options={statuses}
        currentValues={filter.status || []}
        label={'Status'}
        onChange={handleOnChange}
      />
      <SingleSelect
        id={'prioritySelect'}
        name="priority"
        options={priorities}
        label={'Priorities'}
        onChange={handleOnChange}
        value={filter.priority}
      />
      <DateInputContainer
        views={['year', 'month']}
        label={'Start date'}
        minDate={new Date('2020-01-01')}
        name={'startDate'}
        value={filter.startDate ? filter.startDate?.toString() : ''}
        onChange={handleOnChange}
      />
      <DateInputContainer
        views={['year', 'month']}
        label={'End date'}
        minDate={new Date('2020-02-01')}
        name={'endDate'}
        value={filter.endDate ? filter.endDate?.toString() : ''}
        onChange={handleOnChange}
      />
      {totalItems > 0 ? (
        <ExportButton
          filter={filter}
          filename={'ActionItems.csv'}
          totalRows={totalItems}
          csvDataLoader={loadCsvFileAllInfo}
        />
      ) : undefined}
    </Container>
  );
};

const DateInputContainer = styled(DateInputPicker)`
  max-width: 175px;
  margin-right: 10px;
`;
const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
`;

const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 0;
`;

export default Filter;
