/* eslint-disable react/jsx-no-bind */

import CopyButton from 'components/copy-button-onboarding-template';
import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import {
  copyOnboardingProcessTemplate,
  deleteOnboardingProcessTemplate,
  OnboardingProcessResource,
} from 'services/onboardingProcessTemplate';
import styled from 'styled-components';

const fixedColumns: Column<OnboardingProcessResource>[] = [
  {
    label: 'Title',
    sortKey: 'title',
    path: 'title',
  },
];

const variableColumns = (fetchOnboardingProcessTemplates: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <CopyButtonContainer>
          <CopyButton id={item._id} fetchItems={fetchOnboardingProcessTemplates} copyItem={copyOnboardingProcessTemplate} />
        </CopyButtonContainer>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchOnboardingProcessTemplates}
          deleteItem={deleteOnboardingProcessTemplate}
          setLoading={setLoading}
          url={'onboarding-process-templates'}
          name={'Onboarding process template'}
          deleteTitle={'Delete Onboarding Process Template'}
          deleteMessage={'Do you want to delete this onboarding process template?'}
        />
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const CopyButtonContainer = styled.div`
  float: left;
`;

const columns = (fetchOnboardingProcessTemplates: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchOnboardingProcessTemplates, setLoading)];
};

export default columns;
