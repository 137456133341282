import { InputAdornment } from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ExportButton from 'components/export-button';
import MultiSelectBase from 'components/multi-select';
import SingleSelectBase from 'components/single-select';
import { Query } from 'hooks/usePaging';
import { countryValues, monthValues } from 'pages/hr/commonValues';
import React, { useCallback, useEffect, useRef } from 'react';
import { EmployeeFilter, loadCsvFileAllInfo } from 'services/employeeService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  employees: Item[];
  leaders: Item[];
  projects: Item[];
  jobTitles: Item[];
  onlyLeaders: boolean;
  filter: EmployeeFilter & Query;
  setFilter(value: EmployeeFilter & Query): void;
  search: string;
  defaultFilter: EmployeeFilter;
  setDefaultFilter: any;
  totalItems: number;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_employees';

const Filter: React.FC<Props> = (props) => {
  const {
    filter,
    leaders,
    projects,
    jobTitles,
    setFilter,
    defaultFilter,
    setDefaultFilter,
    totalItems,
  } = props;

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setFilter({ ...filter, ...defaultFilter, page: 1 }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter.search]);

  useEffect(() => {
    setFilter({ ...filter, ...defaultFilter, page: 1 });
  }, [
    defaultFilter.leaders,
    defaultFilter.projects,
    defaultFilter.jobTitles,
    defaultFilter.salaryPeriodMonth,
    defaultFilter.clientSalaryPeriodMonth,
    defaultFilter.country,
    defaultFilter.isActive
  ]);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: value, page: 1 });
    },
    [setDefaultFilter, defaultFilter],
  );

  const handleOnCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;

      saveInfoInLocalStorage(name, checked.toString(), localStoragePrefix);
      setDefaultFilter({ defaultFilter, [name!]: checked, page: 1 });
    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByNameInput'}
        inputProps={{
          style: { fontSize: 13 },
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={'Search by Name'}
        variant="outlined"
        name="search"
        value={defaultFilter.search}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'leaderSelect'}
        name="leaders"
        options={leaders}
        currentValues={filter.leaders || []}
        label={'Leaders'}
        onChange={handleOnChange}
        inputProps={{ style: { fontSize: 13 } }}
      />
      <MultiSelect
        id={'projectSelect'}
        name="projects"
        options={projects}
        label={'Projects'}
        onChange={handleOnChange}
        currentValues={filter.projects || []}
        inputProps={{ style: { fontSize: 13 } }}
      />
      <SingleSelect
        id={'jobTitleSelect'}
        name="jobTitles"
        options={jobTitles}
        label={'Job Titles'}
        onChange={handleOnChange}
        value={filter.jobTitles}
        InputProps={{ style: { fontSize: 13 } }}
      />
      <SingleSelect
        id={'salaryPeriodMonthSelect'}
        name="salaryPeriodMonth"
        options={monthValues}
        label={'Period'}
        onChange={handleOnChange}
        value={filter.salaryPeriodMonth}
        InputProps={{ style: { fontSize: 13 } }}
      />
      <SingleSelect
        id={'clientSalaryPeriodMonthSelect'}
        name="clientSalaryPeriodMonth"
        options={monthValues}
        label={'Client Period'}
        onChange={handleOnChange}
        value={filter.clientSalaryPeriodMonth}
        InputProps={{ style: { fontSize: 13 } }}
      />
      <SingleSelect
        id={'countrySelect'}
        name="country"
        options={countryValues}
        label={'Country'}
        onChange={handleOnChange}
        value={filter.country}
        InputProps={{ style: { fontSize: 13 } }}
      />
      <LabelContainer>
        <FormControlLabel
          id={'isActiveCheckbox'}
          control={
            <Checkbox
              value={filter.isActive}
              checked={filter.isActive}
              onChange={handleOnCheckboxChange}
              name="isActive"
            />
          }
          label="Active"
        />
      </LabelContainer>

      {totalItems > 0 ? (
        <ExportButton
          filter={filter}
          filename={'EmployeesInformation.csv'}
          totalRows={totalItems}
          csvDataLoader={loadCsvFileAllInfo}
        />
      ) : undefined}
    </Container>
  );
};

const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
  font-size: 13px !important;
`;

const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 0;
`;

const LabelContainer = styled.div`
  display: inline-flex;
`;

const TextFieldWrapper = styled(TextField)`
  min-width: 180px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export default Filter;
