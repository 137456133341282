import 'react-quill/dist/quill.snow.css';

import React, { ComponentProps, useCallback } from 'react';
import ReactQuillBase from 'react-quill'; // Doc: https://github.com/zenoamaro/react-quill
import styled from 'styled-components';

type Props = {
  value: any;
  onChange: (data: any) => void;
  height?: string;
  readOnly?: boolean;
} & ComponentProps<typeof TextAreaQuill>;

const RichTextEditor: React.FC<Props> = (props) => {
  const { value = '', onChange, height = '170px', readOnly = false, ...rest } = props;

  const handleOnChange = useCallback(
    (value: string) => {
      const event = { target: { value } };

      onChange(event);
    },
    [onChange],
  );

  return <TextAreaQuill height={height} value={value} onChange={handleOnChange} readOnly={readOnly} {...rest} />;
};

const TextAreaQuill = styled(ReactQuillBase)<{ height: string }>`
  border: none;

  .ql-editor {
    height: ${(p) => p.height};
  }
`;

export default RichTextEditor;
