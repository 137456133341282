// eslint-disable-next-line simple-import-sort/sort
import axios from 'axios';
import config from 'utils/config';

const http = axios.create({ baseURL: config.baseUrl });

const toQueryString = (obj: { [key: string]: any }) => {
  const parts: string[] = [];

  for (const property in obj) {
    if (obj.hasOwnProperty(property) && property !== null && property !== undefined) {
      const value = obj[property] as any;

      if (value !== null && value !== undefined && value !== '')
        parts.push(`${encodeURIComponent(property)}=${encodeURIComponent(value)}`);
    }
  }
  return '?' + parts.join('&');
};

export default {
  post: http.post,
  get: http.get,
  put: http.put,
  delete: http.delete,
  request: http.request,
  interceptors: http.interceptors,
  toQueryString,
};
