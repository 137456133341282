import { FormControl, IconButton, InputAdornment, TextField } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CurrencyInput from 'components/currencyInput';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  hasPermission: boolean;
  id: string | undefined;
  lastValue: string;
  setLastValue: Function;
  onPushNavigationRoute: string;
  fieldType: 'salary' | 'rate';
}

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: string;
}>;

const MutableInputTextOrHidden = (props: Props) => {
  const { hasPermission, id, lastValue, setLastValue, onPushNavigationRoute, fieldType } = props;
  const [showValue, setShowValue] = useState(false);
  const history = useHistory();
  const capitalizedType = fieldType.charAt(0).toUpperCase() + fieldType.slice(1);

  const handleUpdateValue = async () => {
    history.push(onPushNavigationRoute);
  };

  const handleMouseDownValue = () => {
    setShowValue(true);
  };

  const handleMouseUpValue = () => {
    setShowValue(false);
  };

  const handleValueOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { value } = event.target;

      setLastValue(value);
    },
    [setLastValue],
  );

  if (hasPermission && id) {
    return (
      <TextBody style={{ width: '185px' }}>
        <FormControl variant="outlined" disabled={!lastValue}>
          <TextField
            id={`employee${capitalizedType}Field`}
            label={`${capitalizedType}`}
            variant="outlined"
            type={showValue ? 'text' : 'password'}
            defaultValue={lastValue}
            value={lastValue}
            style={{ paddingRight: '5px' }}
            InputProps={{
              inputComponent: CurrencyInputContainer as any,
              endAdornment: (
                <InputAdornment position="end" style={{ display: lastValue ? '' : 'none' }}>
                  <IconButton
                    edge="end"
                    onMouseDown={handleMouseDownValue}
                    onMouseUp={handleMouseUpValue}
                    onMouseOut={handleMouseUpValue}
                  >
                    {showValue ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <SubmitButton
          id={`submit${capitalizedType}`}
          onClick={handleUpdateValue}
          aria-label={`Update ${fieldType}`}
          size="small"
        >
          <MonetizationOnIcon />
        </SubmitButton>
      </TextBody>
    );
  } else {
    return (
      <TextBodyShort>
        <TextField
          required={!id}
          label={`${capitalizedType}`}
          variant="outlined"
          name={fieldType}
          id={fieldType}
          value={lastValue.replace('$', '')}
          onChange={handleValueOnChange}
          InputProps={{
            inputComponent: CurrencyInputContainer as any,
          }}
        />
      </TextBodyShort>
    );
  }
};

const SubmitButton = styled(IconButton)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
`;

const TextBody = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 280px;
  float: left;
  display: flex;
  align-items: center;
`;

const TextBodyShort = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 180px;
  float: left;
  display: flex;
  align-items: center;
`;

const CurrencyInputContainer = styled(CurrencyInput)`
  margin-right: 20px;
  width: 233px;
  float: left;
`;

export default MutableInputTextOrHidden;
