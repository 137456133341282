import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import DateInputPicker from 'components/date-input-picker';
import ExportButton from 'components/export-button';
import MultiSelectBase from 'components/multi-select';
import { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useRef } from 'react';
import { CareerDevelopmentPlanFilter, loadAllCdpsFile } from 'services/careerDevelopmentPlanService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  search: string;
  leaders: Item[];
  cdpStatuses: Item[];
  filter: CareerDevelopmentPlanFilter & Query;
  defaultFilter: CareerDevelopmentPlanFilter;
  setDefaultFilter: any;
  setFilter(value: CareerDevelopmentPlanFilter & Query): void;
  totalItems: number;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_cdp';

const Filter: React.FC<Props> = (props) => {
  const { filter, leaders, cdpStatuses, setFilter, defaultFilter, setDefaultFilter, totalItems } = props;

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setFilter({ ...filter, ...defaultFilter, page: 1 }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter]);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event?.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: value, page: 1 });
    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByNameInput'}
        inputProps={{
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={'Search by Name'}
        variant="outlined"
        name="search"
        value={defaultFilter.search}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'leaderSelect'}
        name="leaders"
        options={leaders}
        currentValues={defaultFilter.leaders || []}
        label={'Leaders'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'leaderAssignedSelect'}
        name="leaderAssigned"
        options={leaders}
        currentValues={defaultFilter.leaderAssigned || []}
        label={'Assigned to'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'cdpStatusSelect'}
        name="cdpStatus"
        options={cdpStatuses}
        currentValues={defaultFilter.cdpStatus || []}
        label={'Status'}
        onChange={handleOnChange}
      />
      <DateInputContainer
        id={'dateInputSelect'}
        views={['year', 'month']}
        label={'Year and Month'}
        minDate={new Date('2020-01-01')}
        name={'date'}
        value={defaultFilter.date?.toString() || null}
        onChange={handleOnChange}
      />
      {totalItems > 0 ? (
        <ExportButton
          filter={filter}
          filename={'Cdp_Report.csv'}
          totalRows={totalItems}
          csvDataLoader={loadAllCdpsFile}
        />
      ) : undefined}
    </Container>
  );
};

const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
  max-width: 175px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 0;
`;

const DateInputContainer = styled(DateInputPicker)`
  max-width: 175px;
`;

const TextFieldWrapper = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export default Filter;
