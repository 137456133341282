/* eslint-disable react/jsx-no-bind */

import CopyButton from 'components/copy-button';
import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import {
  copyDevelopmentPlanTemplate,
  deleteDevelopmentPlanTemplate,
  DevelopmentPlanResource,
} from 'services/developmentPlanTemplate';
import styled from 'styled-components';

const fixedColumns: Column<DevelopmentPlanResource>[] = [
  {
    label: 'Title',
    sortKey: 'title',
    path: 'title',
  },
];

const variableColumns = (fetchDevelopmentPlanTemplates: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <CopyButtonContainer>
          <CopyButton id={item._id} fetchItems={fetchDevelopmentPlanTemplates} copyItem={copyDevelopmentPlanTemplate} />
        </CopyButtonContainer>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchDevelopmentPlanTemplates}
          deleteItem={deleteDevelopmentPlanTemplate}
          setLoading={setLoading}
          url={'development-plan-templates'}
          name={'development plan template'}
          deleteTitle={'Delete Development Plan Template'}
          deleteMessage={'Do you want to delete this development plan template?'}
        />
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const CopyButtonContainer = styled.div`
  float: left;
`;

const columns = (fetchDevelopmentPlanTemplates: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchDevelopmentPlanTemplates, setLoading)];
};

export default columns;
