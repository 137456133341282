import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useEffect, useState } from 'react';
import { ProcessFilter } from 'services/processService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import Filter from './filter';
import { useCatalogFilter, useProcessesEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

const ProcessesList: React.FC = () => {
  const localEmployeesFilter = localStorage.getItem('employees_processes');
  const localProcessType = localStorage.getItem('processType_processes');
  const localLeadersFilter = localStorage.getItem('leaders_processes');
  const localSearchFilter = localStorage.getItem('search_processes');
  const localUnassignedFilter = localStorage.getItem('unassigned_processes');

  const DEFAULT_QUERY: Query & ProcessFilter = {
    employees: localEmployeesFilter?.split(',') || [],
    processType: localProcessType || '',
    leaders: localLeadersFilter?.split(',') || [],
    search: localSearchFilter || '',
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'employee.name',
    unassigned: localUnassignedFilter ? Boolean(localUnassignedFilter) : false,
  };

  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const { processes, loading: processLoading, setLoading, fetchProcesses } = useProcessesEffect(query);
  const { processTypes, leaders, loading: catalogLoading } = useCatalogFilter();
  const initialLoading = useInitialLoading(processLoading || catalogLoading);
  const isLoading = processLoading || catalogLoading;
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_QUERY);

  return (
    <Container>
      <Card>
        <CardHeader title="Process Assignment" loading={isLoading} newPageRedirectionLink={'/process-assignment/new'} />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter
              processTypes={processTypes}
              leaders={leaders}
              setFilter={setQuery}
              filter={query}
              defaultFilter={defaultFilter}
              setDefaultFilter={setDefaultFilter}
              totalItems={processes.totalItems}
            />
            {processes?.items?.length ? (
              <StandardTable
                keyColumn="_id"
                data={processes.items}
                totalItems={processes.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchProcesses, setLoading)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default ProcessesList;
