import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import { monthName } from 'pages/hr/commonValues';
import React from 'react';
import { deleteMaxHoursPerMonth, HoursPerMonth } from 'services/timesheetService';

const fixedColumns: Column<HoursPerMonth>[] = [
  {
    label: 'Year',
    path: 'year',
    sortKey: 'year',
  },
  {
    label: 'Month',
    path: 'month',
    sortKey: 'month',
    render: (item) => `${monthName(item.month)}`,
  },
  {
    label: 'Total hours',
    path: 'totalHours',
    sortKey: 'totalHours',
  },
];

const variableColumns = (fetchHoursPerMonth: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        deleteEnable
        fetchItems={fetchHoursPerMonth}
        setLoading={setLoading}
        url={'hourspermonth'}
        name={'Hour per month'}
        deleteItem={deleteMaxHoursPerMonth}
        deleteTitle={'Delete Hours'}
        deleteMessage={'Do you want to delete this hours record?'}
      />
    ),
  };
};

const columns = (fetchHoursPerMonth: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchHoursPerMonth, setLoading)];
};

export default columns;
