import { ACCESS_TOKEN_KEY } from 'services/authService';
import { ACCESS_TOKEN_KEY_EMPLOYEE } from 'services/authServiceEmployee';

export const saveInfoInLocalStorage = (name: string | undefined, value: string, localStorageSufix: string) => {
  if (name) {
    localStorage.setItem(name + localStorageSufix, value);

    if (!value || value.length < 1) {
      localStorage.removeItem(name + localStorageSufix);
    }
  }
};

export const clearLocalStorage = () => {
  var accessTokenKey = localStorage.getItem(ACCESS_TOKEN_KEY);
  var accessTokenEmployeKey = localStorage.getItem(ACCESS_TOKEN_KEY_EMPLOYEE);
  var oneOnOneModeKey = localStorage.getItem('oneOnOneMode');
  var cdpModeKey = localStorage.getItem('cdpMode');

  localStorage.clear();
  if (accessTokenKey) localStorage.setItem(ACCESS_TOKEN_KEY, accessTokenKey);
  if (accessTokenEmployeKey) localStorage.setItem(ACCESS_TOKEN_KEY_EMPLOYEE, accessTokenEmployeKey);
  if (oneOnOneModeKey) localStorage.setItem('oneOnOneMode', oneOnOneModeKey);
  if (cdpModeKey) localStorage.setItem('cdpMode', cdpModeKey);
};
