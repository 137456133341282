import { CatalogResource } from 'shared/types';

import http from './httpService';

const MARITAL_STATUS_URL = '/api/maritalStatuses';

export async function getMaritalStatuses() {
  const { data } = await http.get<CatalogResource[]>(MARITAL_STATUS_URL);

  return data;
}