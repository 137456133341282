import { PageResource, Query } from 'hooks/usePaging';

import http from './httpService';

const PROJECT_URL = '/api/projects';

export type ProjectResource = {
  _id: string;
  name: string;
  holidays: boolean;
  vacations: boolean;
  isActive: boolean;
  allowTimsheetImport: boolean;

  clientSince?: string | null;
  techStack?: string;
  category?: string;
  partner?: string;
};

export type SaveProjectResource = {
  name: string;
  holidays: boolean;
  vacations: boolean;
  isActive: boolean;
  allowTimsheetImport: boolean;

  clientSince?: string | null;
  techStack?: string;
  category?: string;
  partner?: string;
};

export async function getProjects() {
  const { data } = await http.get<ProjectResource[]>(PROJECT_URL);

  return data;
}
export async function getProjectsForTimesheets() {
  const { data } = await http.get<ProjectResource[]>(`${PROJECT_URL}/timesheets`);

  return data;
}
export async function getProjectsQuery(query: Query) {
  const { data } = await http.get<PageResource<ProjectResource>>(`${PROJECT_URL}/query` + http.toQueryString(query));

  return data;
}

export async function getProjectById(id: string) {
  const { data } = await http.get<ProjectResource>(`${PROJECT_URL}/single/${id}`);

  return data;
}

export async function deleteProject(id: string) {
  const { data } = await http.delete<ProjectResource[]>(`${PROJECT_URL}/${id}`);

  return data;
}

export async function updateProject(Project: SaveProjectResource, id: string) {
  const { data } = await http.put<ProjectResource>(`${PROJECT_URL}/${id}`, Project);

  return data;
}

export async function createProject(Project: SaveProjectResource) {
  const { data } = await http.post<ProjectResource>(`${PROJECT_URL}`, Project);

  return data;
}
