import { useEffect, useState } from 'react';
import { CreateCareerDevelopmentPlanResource } from 'services/careerDevelopmentPlanService';
import { getCdpStatus } from 'services/cdpStatusService';
import { getDevelopmentPlanTemplates } from 'services/developmentPlanTemplate';
import { getAllEmployees, getAllLeaders } from 'services/employeeService';
import { EmployeeAndLeader, Item } from 'shared/types';
import { collectionEmployeeToItem, collectionEmployeeWithLeader, collectionToItem } from 'utils/mappers/item';

const defaultCreateCDPResource = {
  employee: '',
  leaderAssigned: '',
  cdpStatus: '',
  template: '',
  period: new Date().toString(),
};

export const useFetchCDPEffect = () => {
  const [cdp] = useState<CreateCareerDevelopmentPlanResource>(defaultCreateCDPResource);

  return { cdp };
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [cdpStatuses, setCdpStatuses] = useState<Item[]>([]);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [templates, setTemplates] = useState<Item[]>([]);
  const [employeesWithLeaders, setEmployeesWithLeaders] = useState<EmployeeAndLeader[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [employeesResult, leadersResult, statusResult, templatesResult] = await Promise.all([
        getAllEmployees(),
        getAllLeaders(),
        getCdpStatus(),
        getDevelopmentPlanTemplates(),
      ]);

      const leaderItems = collectionEmployeeToItem(leadersResult);
      const statusItems = collectionToItem(statusResult);
      const employeesItems = collectionEmployeeToItem(employeesResult);
      const employeesWithLeaders = collectionEmployeeWithLeader(employeesResult);
      const templateItems: Item[] = templatesResult.map((template) => ({ id: template._id, label: template.title }));

      setCdpStatuses(statusItems);
      setEmployees(employeesItems);
      setLeaders(leaderItems);
      setLoading(false);
      setEmployeesWithLeaders(employeesWithLeaders);
      setTemplates(templateItems);
    }

    fetchCatalogs();
  }, []);

  return { templates, leaders, cdpStatuses, employees, employeesWithLeaders, loading };
};
