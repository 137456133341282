import SingleSelect from 'components/single-select';
import React, { useCallback } from 'react';
import { Item } from 'shared/types';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import SearchBar from '../../../../components/searchbar/index';
import { Container, DateInputContainer, MultiSelect } from './Filter.styles';

type Props = {
  employees: Item[];
  leaders: Item[];
  statuses: Item[];
  reasons: Item[];
  categories: Item[];
  filter: any;
  setFilter: Function;
  localStoragePrefix: string;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const Filter = (props: Props) => {
  const { employees, leaders, statuses, reasons, filter, setFilter, localStoragePrefix, categories } = props;
  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setFilter({ ...filter, [name!]: value, page: 1 });
    },
    [localStoragePrefix, setFilter, filter],
  );

  return (
    <Container>
      <MultiSelect
        id={'categoriesSelect'}
        name="categories"
        options={categories}
        currentValues={filter.categories || []}
        label={'Categories'}
        onChange={handleOnChange}
      />
      <SearchBar
        id={'searchByNameInput'}
        name="searchText"
        label={'Objective'}
        value={filter.searchText}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'employeesSelect'}
        name="employees"
        options={employees}
        currentValues={filter.employees || []}
        label={'Employees'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'reasonSelect'}
        name="reasons"
        options={reasons}
        currentValues={filter.reasons || []}
        label={'Reasons'}
        onChange={handleOnChange}
      />
      <DateInputContainer
        id={'startDateInputSelect'}
        label={'Start Date'}
        name={'startDate'}
        value={filter.startDate?.toString() || ''}
        maxDate={filter.endDate || undefined}
        defaultValue=""
        clearable
        onChange={handleOnChange}
      />
      <DateInputContainer
        id={'endDateInputSelect'}
        label={'End Date'}
        name={'endDate'}
        value={filter.endDate?.toString() || ''}
        minDate={filter.startDate || undefined}
        defaultValue=""
        clearable
        onChange={handleOnChange}
      />
      <SingleSelect
        id={'statusSelect'}
        name="status"
        options={statuses}
        value={filter.status || ''}
        label={'Status'}
        onChange={handleOnChange}
        style={{ marginRight: 10 }}
      />
      <MultiSelect
        id={'createdBySelect'}
        name="leaders"
        options={leaders}
        currentValues={filter.leaders || []}
        label={'Created by'}
        onChange={handleOnChange}
      />
    </Container>
  );
};

export default Filter;
