import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import { isPermissionGranted, ROLE_ADMIN, ROLE_HR, ROLE_IT } from 'helpers/roleHelper';
import { useForm } from 'hooks/useForm';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { updateEp } from 'services/exitProcedureProcessService';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import ItemList from './exit-procedure-item';
import { useCatalogs, useExitProcedureProcessEffect, useReadOnlyEffect } from './hooks';

const schema = {
  descriptions: yup.array(yup.string().optional()),
};

saveInfoInLocalStorage('inputValue', '', '_form');
type Props = {};

const ScreenExitProcedureForm: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const variable = id.split(',');
  const idExitProcedure = variable[0];

  const { descriptions, epStatuses, loading: catalogLoading } = useCatalogs(idExitProcedure);

  const {
    exitProcedureProcess,
    loading,
    saveExitProcedureProcess,
    setExitProcedureProcess,
  } = useExitProcedureProcessEffect(idExitProcedure);
  const [savingExitProcedure, setSavingExitProcedure] = useState(false);
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';

  const { isValid } = useForm(saveExitProcedureProcess, schema as any, loading || catalogLoading);

  const handleSaveExitProcedureProcess = async () => {
    setSavingExitProcedure(true);

    if (!isValid) {
      notifier.error(`Please, fix the errors on the form before submitting `);
      setSavingExitProcedure(false);
      return;
    }

    const saveEpObject = {
      descriptions: exitProcedureProcess!.descriptions,
    };

    if (variable[1] === 'create') {
      const response = await updateEp(saveEpObject, idExitProcedure);

      if (response) {
        notifier.success('Exit  Procedure updated successfully.');
        saveInfoInLocalStorage('inputValue', '', '_form');
      }
    } else {
      const response = await updateEp(saveEpObject, idExitProcedure);

      if (response) {
        notifier.success('Exit  Procedure updated successfully.');
        saveInfoInLocalStorage('inputValue', '', '_form');
      }
    }
    setSavingExitProcedure(false);
    return;
  };

  const user = useCurrentUser();
  const userHasAdminPermission = () => {
    return isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_IT], user);
  };
  const localReadOnly = localStorage.getItem('epMode');
  const { isReadOnly } = useReadOnlyEffect(
    localReadOnly,
    !localStorage.getItem('inputValue_form') ||
      (localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') !== 'changed') ||
      false,
    userHasAdminPermission(),
  );

  return (
    <Container>
      <Card>
        <Prompt
          when={
            localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') === 'changed'
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <CardHeader
          loading={initialLoading}
          title={`Exit  Procedure: ${
            !initialLoading
              ? `${exitProcedureProcess?.employee.name} ${exitProcedureProcess?.employee.lastName}`
              : 'Loading'
          }`}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <Descriptions>
            {catalogLoading && (
              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            )}
            <BodyTitle>Exit Procedure Check List:</BodyTitle>
            <DetailsLine>
              {!catalogLoading && (
                <DescriptionsContainer id={'descriptionsContainer'}>
                  {descriptions.map((description) => (
                    <ItemList
                      description={description}
                      epStatuses={epStatuses}
                      setSaveExitProcedure={setExitProcedureProcess}
                      saveExitProcedure={exitProcedureProcess}
                    />
                  ))}
                </DescriptionsContainer>
              )}
            </DetailsLine>
          </Descriptions>

          <ButtonContainer>
            {savingExitProcedure && <Spinner size={30} />}
            <Submit
              disabled={savingExitProcedure || loading || isReadOnly || !isValid}
              onClick={handleSaveExitProcedureProcess}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const DescriptionsContainer = styled.div`
  width: 100%;
  margin: 0 auto 50px auto;
  padding: 50px;
  background: white;
  position: relative;
  /*box-shadow*/
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  /*border-radius*/
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
`;
const Descriptions = styled.div``;
const Container = styled.div`
  margin: 0 15px;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
  float: right;
`;

const DetailsLine = styled.div`
  display: block;
  padding-left: 30px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300px;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

export default ScreenExitProcedureForm;
