import http from './httpService';

const ONBOARDING_PROCESS_STATUS_URL = '/api/opstatus';

export type OpStatusResource = {
  _id: string;
  order: number;
  name: string;
  description: string;
};

export async function getOpStatus() {
  const { data } = await http.get<OpStatusResource[]>(ONBOARDING_PROCESS_STATUS_URL);

  return data;
}
