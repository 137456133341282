import { Chip } from '@material-ui/core';
import camelcase from 'camelcase';
import React from 'react';
import styled from 'styled-components';
import constants from 'utils/constants';

import {
  BLACK,
  DIM_GRAY,
  FRUIT_SALAD,
  HOTPINK,
  LIGHTBLUE,
  NOBEL,
  PIZAZZ,
  PURPLE,
  RED,
  TEAL_BLUE,
  WHITE,
  YELLOW,
} from '../../shared/style/utilities/colors';

type Props = {
  icon?: any;
  name: string;
};

type ownColor = { background: string; text: string };

const COLORS = {
  default: { background: DIM_GRAY, text: WHITE },
  open: { background: LIGHTBLUE, text: WHITE },
  inProgress: { background: PURPLE, text: WHITE },
  complete: { background: FRUIT_SALAD, text: WHITE },
  low: { background: FRUIT_SALAD, text: WHITE },
  mid: { background: YELLOW, text: BLACK },
  high: { background: RED, text: WHITE },
  onHold: { background: NOBEL, text: WHITE },
  completed: { background: FRUIT_SALAD, text: WHITE },
  uncompleted: { background: PURPLE, text: WHITE },
  communicate: { background: HOTPINK, text: WHITE },
  unsuccessful: { background: RED, text: WHITE },
  missingInfo: { background: RED, text: WHITE },
  [camelcase(constants.REWARD_PENDING_APPROVAL_STATUS_NAME)]: { background: YELLOW, text: BLACK },
  [camelcase(constants.REWARD_REJECTED_STATUS_NAME)]: { background: RED, text: WHITE },
  [camelcase(constants.REWARD_PENDING_REFUND_STATUS_NAME)]: { background: TEAL_BLUE, text: WHITE },
  [camelcase(constants.REWARD_APPROVED_STATUS_NAME)]: { background: PIZAZZ, text: WHITE },
  [camelcase(constants.REWARD_REFUNDED_STATUS_NAME)]: { background: FRUIT_SALAD, text: WHITE },
};

const StatusHighlighter: React.FC<Props> = (props) => {
  let { icon, name } = props;

  const getNameReal = name;
  var regex = /(\d+)/g;
  const getNumbers = name.match(regex);

  if (getNumbers !== undefined && getNumbers !== null) {
    if (getNumbers[0] === getNumbers[1]) {
      name = 'complete';
    } else if (getNumbers[0] < getNumbers[1]) {
      name = 'high';
    }
  }
  const dateAndStatus = getNameReal.split(',');
  const nameSeparation = getNameReal.split(' ');

  if (dateAndStatus[1] === 'Pending') {
    name = 'high';
  }
  if (dateAndStatus[1] === 'Paid') {
    name = 'default';
  }

  if (nameSeparation[0] === 'Completed') {
    name = 'complete';
  }
  if (nameSeparation[0] === 'Incomplete') {
    name = 'default';
  }
  if (nameSeparation[0] === 'Uncompleted') {
    name = 'uncompleted';
  }
  if (nameSeparation[0] === 'Unsuccessful') {
    name = 'unsuccessful';
  }

  const color = chooseColorForName(camelcase(name));

  if (dateAndStatus.length > 1) {
    return <StyledChip size="small" icon={icon} label={dateAndStatus[0]} owncolor={color} />;
  }

  return <StyledChip size="small" icon={icon} label={getNameReal} owncolor={color} />;
};

const chooseColorForName = (name: string) => {  
  if (COLORS[name]) return COLORS[name];
  return COLORS.default;
};

const StyledChip = styled(Chip)<{ owncolor: ownColor }>`
  background-color: ${(p) => p.owncolor.background} !important;
  color: ${(p) => p.owncolor.text} !important;
  font-weight: bold;
`;

export default StatusHighlighter;
