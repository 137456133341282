import { ReasonResource } from 'shared/types';

import http from './httpService';

const REWARD_REASON_URL = '/api/rewardsReason';

export async function getAllRewardReason() {
  const { data } = await http.get<ReasonResource[]>(REWARD_REASON_URL);

  return data;
}
