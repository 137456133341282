import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { ComponentProps, useCallback } from 'react';
import { Item } from 'shared/types';
import styled from 'styled-components';

type CustomSelect = Omit<ComponentProps<typeof Select>, 'value'>;

type Props = {
  options: Item[];
  currentValues: string[];
  customWidth?: string;
  rigthSpacing?: boolean;
  leftSpacing?: boolean;
} & CustomSelect;

const MultiSelect: React.FC<Props> = (props) => {
  const {
    options,
    currentValues,
    label,
    customWidth = '185px',
    leftSpacing = false,
    rigthSpacing = false,
    ...rest
  } = props;

  const renderValue = useCallback(
    (values: any) => {
      const selectedValues = values as string[];
      const selectedOptions = options.filter((x) => selectedValues.includes(x.id.toString()));

      return selectedOptions.map((x) => x.label).join(', ');
    },
    [options],
  );

  return (
    <Container variant="outlined" width={customWidth} leftspacing={leftSpacing} rightspacing={rigthSpacing}>
      <InputLabel>{label}</InputLabel>
      <Select renderValue={renderValue} value={currentValues} label={label} {...rest} multiple displayEmpty>
        {options.map((value) => (
          <MenuItem key={value.id} value={value.id} data-tes data-testid="select-test-id">
            <Checkbox checked={currentValues.includes(value.id.toString())} />
            <ListItemText primary={value.label} />
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};

const Container = styled(FormControl)<{ width: string; leftspacing: boolean; rightspacing: boolean }>`
  width: ${(props) => props.width};
  margin-right: ${(props) => (props.rightspacing ? '15px' : '0px')};
  margin-left: ${(props) => (props.rightspacing ? '15px' : '0px')};
`;

export default MultiSelect;
