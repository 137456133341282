import Button from '@material-ui/core/Button';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useCountDownEffect } from './hooks';

type Props = {};

const TokenExpired: React.FC<Props> = () => {
  const history = useHistory();

  const handleGoToLogin = async () => {
    history.push(`/login`);
  };

  const { seconds } = useCountDownEffect(7, handleGoToLogin);

  return (
    <Container>
      <Card>
        <CardHeader title={'Token has expired'} />
        <CardBody>
          <BodyTitle>{`You'll be redirected to login screen in ${seconds} seconds...`}</BodyTitle>
          <ButtonContainer>
            <Submit onClick={handleGoToLogin}>Go to Login screen</Submit>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 30px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
  text-align: center;
`;

export default TokenExpired;
