import { Backdrop, Box } from '@material-ui/core';
import styled from 'styled-components';

export const StyledBackdrop = styled(Backdrop)`
  z-index: ${(p) => p.theme.zIndex.drawer + 1};
  color: #fff;
  margin-top: -100px;
`;

export const StyledBox = styled(Box)`
  text-align: center;
`;
