import { PageResource, Query } from 'hooks/usePaging';

import { EmployeeResource } from './employeeService';
import http from './httpService';

const REWARDS_URL = '/api/rewards';

export type RewardFilter = {
  reasons: string[];
  status: string[];
  startDate: string | null;
  endDate: string | null;
  search: string;
};
export type ReasonService = {
  _id: string;
  order: number;
  reason: string;
};
export type StatusService = {
  _id: string;
  order: number;
  statusName: string;
};
export type RewardResource = {
  _id: string;
  employee: EmployeeResource;
  reasonID: ReasonService;
  realAmount: string;
  estimatedAmount: string;
  rewStatusID: StatusService;
  requestDate: string;
  updateDate: string;
  requestDescription: string;
};

export type SaveRewardResource = {
  reasonID: string;
  requestDescription: string;
  estimatedAmount: string;
};

export type UserAuthorzation = {
  isAuthorized: boolean;
};

export async function getAllRewards(filter: RewardFilter & Query) {
  const { data } = await http.get<PageResource<RewardResource>>(REWARDS_URL + http.toQueryString(filter));

  return data;
}
export async function deleteReward(id: string) {
  const { data } = await http.delete<RewardResource>(`${REWARDS_URL}/${id}`);

  return data;
}
export async function getRewardById(id: string) {
  const { data } = await http.get<RewardResource>(`${REWARDS_URL}/${id}`);

  return data;
}

export async function createReward(reward: SaveRewardResource) {
  const saveRewardObject = { ...reward };

  const { data } = await http.post<RewardResource>(`${REWARDS_URL}`, saveRewardObject);

  return data;
}
