import { FormControl, MenuItem, TextField } from '@material-ui/core';
import React, { ComponentProps } from 'react';
import { Item } from 'shared/types';
import styled from 'styled-components';

type Props = {
  empty?: boolean;
  options: Item[];
  label: string;
} & ComponentProps<typeof TextField>;

const SingleSelectNoValue: React.FC<Props> = (props) => {
  const { options, label, ...rest } = props;

  return (
    <Container variant="outlined">
      <TextField variant="outlined" select label={label} {...rest} inputProps={{ 'data-testid': 'single-select-test' }}>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Container>
  );
};

const Container = styled(FormControl)`
  width: 185px;
`;

export default SingleSelectNoValue;
