import { CircularProgress } from '@material-ui/core';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import columns from './columns';
import { useCatalogs, useSalariesEffect } from './hooks';

const DEFAULT_QUERY: Query = {
  pageSize: 20,
  page: 1,
  isSortAscending: 0,
  sortByColumn: 'date',
};

const SalaryList: React.FC = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const { handlePageChange, handleColumnSorting, query } = usePaging(DEFAULT_QUERY);
  const { employee, loadingCatalogs } = useCatalogs(employeeId!);
  const { salaries, fetchSalaries, setLoading } = useSalariesEffect(employee!, query);

  const display = loadingCatalogs ? 'none' : 'block';

  return (
    <Container>
      <Card>
        <CardHeader
          loading={loadingCatalogs}
          title={`Employee: ${!loadingCatalogs ? employee?.name + ' ' + employee?.lastName : 'Loading'}`}
          subTitle="Salary change log"
          newPageRedirectionLink={'salaries/new'}
        />
        {loadingCatalogs && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Salary history</BodyTitle>
          <CardBody>
            {salaries?.items.length ? (
              <StandardTable
                keyColumn="_id"
                data={salaries.items}
                totalItems={salaries.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchSalaries, setLoading, employeeId)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display. </NoRecords>
            )}
          </CardBody>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default SalaryList;
