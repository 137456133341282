import { useCallback, useEffect, useState } from 'react';
import { getAllRewardReason } from 'services/rewardsReasonService';
import { getRewardById, RewardResource, SaveRewardResource } from 'services/rewardsService';
import { Item } from 'shared/types';
import { collectionToReasonId } from 'utils/mappers/item';

const defaultRewardResource = {
  reasonID: '',
  requestDescription: '',
  estimatedAmount: '',
};

export const useRewardEffect = (id: string) => {
  const [saveReward, setSaveReward] = useState<SaveRewardResource>(defaultRewardResource);
  const [reward, setReward] = useState<RewardResource>();
  const [loading, setLoading] = useState(false);
  const [rewardCreationDate, setRewardCreationDate] = useState(new Date().toISOString());
  const [estimatedAmount, setEstimatedAmount] = useState('');
  const [employeeSelected, setEmployeeSelected] = useState<string>('');
  const [rewStatusNameSelected, setRewStatusNameSelected] = useState<string>('');

  const fetchReward = useCallback(async () => {
    setLoading(true);
    const rewardResult = await getRewardById(id);

    if (rewardResult) {
      setSaveReward(mapRewardResponse(rewardResult));
      setRewardCreationDate(rewardResult.requestDate);
      setEstimatedAmount(rewardResult.estimatedAmount || '');
      setEmployeeSelected(rewardResult.employee.email);
      setRewStatusNameSelected(rewardResult.rewStatusID.statusName);
      setReward(rewardResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchReward();
  }, [id, fetchReward]);

  return {
    reward,
    setReward,
    loading,
    saveReward,
    setSaveReward,
    setLoading,
    fetchReward,
    rewardCreationDate,
    estimatedAmount,
    setEstimatedAmount,
    employeeSelected,
    setEmployeeSelected,
    rewStatusNameSelected,
  };
};

const mapRewardResponse = (reward: RewardResource) => {
  return {
    reasonID: reward.reasonID._id || '',
    estimatedAmount: reward.estimatedAmount || '',
    requestDescription: reward.requestDescription || '',
    requestDate: reward.requestDate || '',
    employeeEmail: reward.employee.email,
  } as SaveRewardResource;
};

export const useCatalogs = (id: string) => {
  const [loading, setLoading] = useState(true);

  const [reasonsReward, setReasonsReward] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [reasonResult] = await Promise.all([getAllRewardReason()]);

      setReasonsReward(collectionToReasonId(reasonResult));

      setLoading(false);
    }
    fetchCatalogs();
  }, [id]);

  return { reasonsReward, loading };
};

export const useReadOnlyEffect = (
  localStorageValue: string | null,
  rewarddFinished: boolean,
  adminPermission: boolean,
) => {
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (adminPermission) {
      setReadOnly(false);
      return;
    }

    if (localStorageValue && localStorageValue === 'readOnly') {
      setReadOnly(true);
    } else if (rewarddFinished) {
      setReadOnly(true);
    } else setReadOnly(false);
  }, [adminPermission, rewarddFinished, localStorageValue]);

  return { isReadOnly, setReadOnly };
};
