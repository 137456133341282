import { useCallback, useEffect, useState } from 'react';
import { DevelopmentPlanResource, getDevelopmentPlanTemplates } from 'services/developmentPlanTemplate';
import { getAllEmployees } from 'services/employeeService';
import { getProcessById, ProcessResource, SaveProcessResource } from 'services/processService';
import { getAllProcessTypes } from 'services/processTypeService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem, collectionToItemWithTitle } from 'utils/mappers/item';

const currentDate = new Date();

const defaultProcessResource = {
  employee: '',
  periodicity: 1,
  isActive: true,
  nextPeriodStartDate: new Date(currentDate.setMonth(currentDate.getMonth() + 1)).toString(),
  processType: '',
  careerDevelopmentPlanTemplate: '',
};

export const useProcessEffect = (id?: string, employeeId?: string, processTypeId?: string) => {
  const [saveProcess, setSaveProcess] = useState<SaveProcessResource>(defaultProcessResource);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState<ProcessResource>();

  const fetchProcess = useCallback(async () => {
    setLoading(true);
    const processResult = await getProcessById(id!);

    if (processResult) {
      setSaveProcess(mapProcessResponse(processResult));
      setProcess(processResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchProcess();
    else if (employeeId && employeeId !== undefined) {
      defaultProcessResource.employee = employeeId;
      defaultProcessResource.processType = processTypeId || '';
      defaultProcessResource.careerDevelopmentPlanTemplate = '';
      setSaveProcess(defaultProcessResource);
    }
  }, [id, fetchProcess, employeeId, processTypeId]);

  return { saveProcess, loading, process, setSaveProcess };
};

const mapProcessResponse = (process: ProcessResource) => {
  return {
    employee: process.employee._id,
    periodicity: process.periodicity,
    isActive: process.isActive,
    nextPeriodStartDate: process.nextPeriodStartDate,
    processType: process.processType?._id || '',
    careerDevelopmentPlanTemplate: process.careerDevelopmentPlanTemplate
      ? process.careerDevelopmentPlanTemplate._id || ''
      : '',
  } as SaveProcessResource;
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [processTypes, setProcessTypes] = useState<Item[]>([]);
  const [periodicityOptions, setPeriodicityOptions] = useState<Item[]>([]);
  const [cdpTemplates, setCdpTemplates] = useState<Item[]>([]);
  const [cdpTemplatesAllInfo, setCdpTemplatesAllInfo] = useState<DevelopmentPlanResource[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [employeesResult, processTypesResult, periodicityItems, cdpTemplatesResult] = await Promise.all([
        getAllEmployees(),
        getAllProcessTypes(),
        getPeriodicityDefaultItems(),
        getDevelopmentPlanTemplates(),
      ]);

      const employeeItems = collectionEmployeeToItem(employeesResult);
      const processTypeItems = collectionToItem(processTypesResult);
      const cdpTemplatesItems = collectionToItemWithTitle(cdpTemplatesResult);

      setEmployees(employeeItems);
      setProcessTypes(processTypeItems);
      setPeriodicityOptions(periodicityItems);
      setCdpTemplates(cdpTemplatesItems);
      setCdpTemplatesAllInfo(cdpTemplatesResult);
      setLoading(false);
    }

    fetchCatalogs();
  }, []);

  function getPeriodicityDefaultItems() {
    return [
      { id: '1', label: '1' },
      { id: '2', label: '2' },
      { id: '3', label: '3' },
      { id: '4', label: '4' },
      { id: '5', label: '5' },
      { id: '6', label: '6' },
      { id: '7', label: '7' },
      { id: '8', label: '8' },
      { id: '9', label: '9' },
      { id: '10', label: '10' },
      { id: '11', label: '11' },
      { id: '12', label: '12' },
    ];
  }

  return { employees, processTypes, cdpTemplates, cdpTemplatesAllInfo, periodicityOptions, loading };
};

export const useProcessType = (selectedProcessType: string, setShowCDP: any, selectedProcessTypeName: string) => {
  const [processType, setProcessType] = useState<string>('');

  const loadProcessType = useCallback(() => {
    if (selectedProcessType) {
      setProcessType(selectedProcessType);
      if (selectedProcessTypeName === 'Career Development Plan') setShowCDP(true);
    }
  }, [selectedProcessType, selectedProcessTypeName, setShowCDP]);

  useEffect(() => {
    loadProcessType();
  }, [loadProcessType]);

  return { processType, setProcessType };
};
