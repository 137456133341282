import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource, CatalogResourceTitle } from 'shared/types';

import { EmployeeResource, EmployeeResourceCompact } from './employeeService';
import http from './httpService';

const PROCESS_URL = '/api/processes';

export type ProcessFilter = {
  employees: string[];
  processType: string;
  leaders: string[];
  search: string;
  unassigned: boolean;
};

export type ProcessResource = {
  _id: string;
  employee: EmployeeResource;
  leader: EmployeeResourceCompact;
  periodicity: number;
  nextPeriodStartDate: string;
  isActive: boolean;
  processType: CatalogResource;
  careerDevelopmentPlanTemplate: CatalogResourceTitle;
};

export type SaveProcessResource = {
  employee: string;
  periodicity: number;
  nextPeriodStartDate: string;
  isActive: boolean;
  processType: string;
  careerDevelopmentPlanTemplate: string | undefined;
};

export async function getAllProcesses(filter: ProcessFilter & Query) {
  const { data } = await http.get<PageResource<ProcessResource>>(PROCESS_URL + http.toQueryString(filter));

  return data;
}

export async function getProcessById(id: string) {
  const { data } = await http.get<ProcessResource>(`${PROCESS_URL}/${id}`);

  return data;
}

export async function updateProcess(process: SaveProcessResource, id: string) {
  const response = await http.put<ProcessResource>(`${PROCESS_URL}/${id}`, process);

  return response;
}

export async function createProcess(process: SaveProcessResource) {
  const { data } = await http.post<ProcessResource>(`${PROCESS_URL}/`, process);

  return data;
}

export async function deleteProcess(id: string) {
  const { data } = await http.delete<ProcessResource>(`${PROCESS_URL}/${id}`);

  return data;
}

export async function loadCsvFileProcessAssigned(query: Query) {
  const { data } = await http.get<any[]>(`${PROCESS_URL}/queryAllProcessAssignements.csv${http.toQueryString(query)}`);

  return data;
}
