import { toast, ToastOptions } from 'react-toastify';

const success = (message: string, options?: ToastOptions) => {
  toast.success(message, options);
};

const warning = (message: string, options?: ToastOptions) => {
  toast.warning(message, options);
};

const error = (message: string, options?: ToastOptions) => {
  toast.error(message, options);
};

const info = (message: string, options?: ToastOptions) => {
  toast.info(message, options);
};

export default { success, warning, error, info };
