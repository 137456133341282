/* eslint-disable react/jsx-no-bind */
import { Box, Chip, IconButton, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AttachIcon from '@material-ui/icons/AttachFile';
import DownloadIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import AlertDialog from 'components/alert-dialog';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import DayRow from 'components/day-row';
import SingleSelect from 'components/single-select';
import UploadXlsxFile from 'components/upload-xlsx-file';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  createTimesheet,
  deleteDay,
  getTimesheetById,
  loadPdfTimeSheetFileInfo,
  TimesheetMonth,
  updateDayDescription,
  updateDayHours,
  updateDaySubType,
  updateDayType,
  updateTimesheet,
} from 'services/timesheetService';
import styled from 'styled-components';
import config from 'utils/config';
import constants from 'utils/constants';
import {
  calculateDaysLeft,
  getLastBussinessDay,
  stringDateToHumanFormatMonthName,
  stringDateToMonthYearFormat,
} from 'utils/formatters/date';
import { sortDateAsc } from 'utils/general/generalMethods';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';

import { useEmployeeCatalogFilter, useTimesheetEmployeeEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const TimesheetEmployee: React.FC = (props: any) => {
  const { id } = useParams<{ id: string }>();
  let query = useQuery();
  const timesheetId = query.get('timesheetId');
  const user = useCurrentUser();
  const { projects, dayTypes, daySubTypes, availablePeriods, loading, employeeName } = useEmployeeCatalogFilter(
    id ? id : user!._id,
  );
  const {
    timesheetMonth,
    setTimesheetMonth,
    dateSelected,
    registeredHours,
    setRegisteredHours,
    remainingHours,
    setRemainingHours,
    overtimeHours,
    setOvertimeHours,
    otherTimeHours,
    setOtherTimeHours,
    holidayHours,
    setHolidayHours,
    setDateSelected,
    loadingTimesheet,
    deadLineDate,
    daysLeft,
    setDeadLineDate,
    setDaysLeft,
    projectId,
    employeeId,
    projectName,
    setProjectName,
    projectAllowsHolidays,
    projectAllowsVacations,
    setProjectId,
    pubicURL,
    setPublicURL,
    handleUploadFile,
    fileName,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    openUploadFileConfirmationDialog,
    setOpenUploadFileConfirmationDialog,
    openDeleteFileConfirmationDialog,
    setOpenDeleteFileConfirmationDialog,
    uploadFileConfirmation,
    deleteFileConfirmation,
    handleDownloadFile,
    handleDeleteFile,
    loadingFile,
    handleOnCancelUpload,
    timesheetClosed,
    handleConvertUploadedFile,
    handleOnUploadFileClose,
    displayUploadFileOption,
  } = useTimesheetEmployeeEffect(id, user!, timesheetId || '');
  const [savingTimesheet, setSavingTimesheet] = useState(false);
  const [loadingPDF, setLoadingfPDF] = useState(false);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { value } = event.target;

      switch (event.target.name) {
        case 'date':
          if (value) setDateSelected(value as string);
          else {
            setDateSelected('');
          }
          break;
        case 'projects':
          if (value) {
            const selectedProjectName = projects.find((project) => project.id === (value as string))?.label || '';

            setProjectId(value as string);
            setProjectName(selectedProjectName);
            setPublicURL(
              `${config.baseUrl}/api/timesheets/pdf-download?client=${selectedProjectName}&id=${employeeId}`,
            );
          } else {
            setProjectId('');
          }
          break;
      }
    },
    [employeeId, projects, setDateSelected, setProjectId, setProjectName, setPublicURL],
  );

  const handleDescriptonOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { value } = event.target;
      const name = event.target.name?.replace('description', '');

      setTimesheetMonth((currentTimesheetMonth) => {
        const recordIndex = currentTimesheetMonth.days.findIndex((day) => day.date.toString() === name)!;

        const days = [...currentTimesheetMonth.days];

        days[recordIndex] = {
          ...days[recordIndex],
          taskDescription: value as string,
        };

        return { ...currentTimesheetMonth, days };
      });
    },
    [setTimesheetMonth],
  );

  const handleDayRemove = async (dayId: string) => {
    const result = await deleteDay(timesheetMonth._id.toString(), dayId);

    if (result) refreshTimesheet(result);
  };

  const handleTimeSpentOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { value } = event.target;
      const name = event.target.name?.replace('hours', '');

      setTimesheetMonth((currentTimesheetMonth) => {
        const recordIndex = currentTimesheetMonth.days.findIndex((day) => day.date.toString() === name)!;

        const days = [...currentTimesheetMonth.days];

        days[recordIndex] = {
          ...days[recordIndex],
          timeSpent: value as number,
        };

        return { ...currentTimesheetMonth, days };
      });
    },
    [setTimesheetMonth],
  );

  const handleOnHourBlur = async (id: string, hours: string, date: Date) => {
    if (!hours) hours = '0';
    if (parseFloat(hours) % 1 !== 0) {
      const decimalValue = hours.split('.')[1];

      if (decimalValue !== '5') {
        notifier.error('Invalid time value. You can only add half an hour');
        return;
      }
    }

    const hourInfo = {
      timesheetId: timesheetMonth._id.toString(),
      dayId: id,
      hours: hours,
      date: date,
    };

    const dayRecord = timesheetMonth.days.find((element) => element._id === id);

    if (dayRecord) dayRecord.timeSpent = parseFloat(hours);

    const savedTimesheet = await updateDayHours(hourInfo);

    if (savedTimesheet) {
      refreshTimesheet(savedTimesheet);
    } else {
      const selectedDate = moment.utc(dayRecord?.date);
      const isWeekend = selectedDate.day() === 6 || selectedDate.day() === 0;

      if (dayRecord?.taskDescription === constants.HOLIDAY_DAY_TYPE) {
        if (projectAllowsHolidays && !isWeekend) dayRecord.timeSpent = 8;
        else dayRecord.timeSpent = 0;
      } else if (dayRecord?.taskDescription === 'Vacation') {
        if (projectAllowsVacations)
          dayRecord.timeSpent = dayRecord.type.name === constants.HALF_DAY_VACATION_DAY_TYPE ? 4 : 8;
        else dayRecord.timeSpent = 0;
      } else if (
        dayRecord?.taskDescription === 'License Benefit' ||
        dayRecord?.taskDescription === 'Medical Leave' ||
        dayRecord?.taskDescription === 'Sick'
      ) {
        dayRecord.timeSpent = 0;
      }
      setTimesheetMonth(timesheetMonth);
    }
  };

  const handleOnDescriptionBlur = async (id: string, description: string) => {
    if (!description) description = '';
    const descriptionInfo = {
      timesheetId: timesheetMonth._id.toString(),
      dayId: id,
      description: description,
    };

    const dayRecord = timesheetMonth.days.find((element) => element._id === id);

    if (dayRecord) dayRecord.taskDescription = description;

    const savedTimesheet = await updateDayDescription(descriptionInfo);

    if (savedTimesheet) {
      refreshTimesheet(savedTimesheet, false);
    } else {
      const timeSheetResponse = await getTimesheetById(timesheetMonth._id.toString());

      setTimesheetMonth(timeSheetResponse);
    }
  };

  const handleOnDayTypeChange = async (dayId: string, dayTypeId: string) => {
    const dayTypeInfo = {
      timesheetId: timesheetMonth._id.toString(),
      dayId: dayId,
      value: dayTypeId,
    };
    const result = await updateDayType(dayTypeInfo);

    if (result) {
      refreshTimesheet(result);
    }
  };

  const handleOnDaySubTypeChange = async (dayId: string, daySubTypeId: string) => {
    const daySubTypeInfo = {
      timesheetId: timesheetMonth._id.toString(),
      dayId: dayId,
      value: daySubTypeId,
    };
    const result = await updateDaySubType(daySubTypeInfo);

    if (result) {
      refreshTimesheet(result);
    }
  };

  const handleOnSubmitReport = async () => {
    setOpenConfirmationDialog(false);
    setSavingTimesheet(true);
    const result = await updateTimesheet(timesheetMonth);

    if (result) {
      refreshTimesheet(result);

      notifier.success('Timesheet saved successfully.');
    }
    setSavingTimesheet(false);
  };

  const refreshTimesheet = (result: TimesheetMonth, shouldSetTimesheetMonth = true) => {
    result.days?.sort(sortDateAsc);
    if (shouldSetTimesheetMonth) setTimesheetMonth(result);
    setRegisteredHours(result.timeSpent.toString() + 'Hrs');
    setRemainingHours(result.timeRemaining.toString() + 'Hrs');
    setOvertimeHours(result.overtime.toString() + 'Hrs');
    setOtherTimeHours(result.otherTime.toString() + 'Hrs');
    setHolidayHours(result.holidayTime.toString() + 'Hrs');
  };

  const showConfirmationModal = () => {
    setOpenConfirmationDialog(true);
  };

  const handleInitializeTimesheet = async () => {
    setSavingTimesheet(true);

    const savedTimesheet = await createTimesheet(employeeId, dateSelected, projectId);

    if (savedTimesheet) {
      refreshTimesheet(savedTimesheet);

      const deadLine = getLastBussinessDay(moment.utc(savedTimesheet.period).endOf('month'));
      const daysLeft = calculateDaysLeft(deadLine, moment.utc());

      setDeadLineDate(stringDateToHumanFormatMonthName(deadLine));
      setDaysLeft(daysLeft + ' days left');
    }
    setSavingTimesheet(false);
  };

  const downloadTimesheetPdf = async () => {
    setLoadingfPDF(true);
    const data = await loadPdfTimeSheetFileInfo(timesheetMonth._id);

    if (data) {
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      const pdfName = `${projectName} - ${employeeName} - ${stringDateToMonthYearFormat(dateSelected)}.pdf`;

      saveAs(pdfBlob, pdfName);
    } else {
      notifier.error('Error generating PDF file, please try again...');
    }

    setLoadingfPDF(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader title="Timesheet" subTitle={employeeName} loading={loading} />
        {loading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!loading && (
          <CardBody>
            <OptionsBox>
              <FilterWrapper>
                <InputWrapper>
                  <SingleSelect
                    id={'projectSelect'}
                    name="projects"
                    options={projects}
                    label={'Projects'}
                    onChange={handleOnChange}
                    empty={false}
                    value={projectId}
                    disabled={!!timesheetId}
                  />
                </InputWrapper>
                <InputWrapper>
                  <SingleSelect
                    id={'dateSelect'}
                    name={'date'}
                    options={availablePeriods}
                    label={'Year and Month'}
                    onChange={handleOnChange}
                    empty={false}
                    value={dateSelected}
                    disabled={!!timesheetId}
                  />
                </InputWrapper>
                <Tooltip title={constants.DOWNLOAD_TIMESHEET_TOOLTIP}>
                  <ExportButtonContainer>
                    {!loadingPDF ? (
                      <IconButton
                        id="btn_timesheet_download"
                        aria-label="download"
                        disabled={!timesheetMonth}
                        onClick={downloadTimesheetPdf}
                      >
                        <DownloadIcon />
                      </IconButton>
                    ) : (
                      <SpinnerMedium />
                    )}
                  </ExportButtonContainer>
                </Tooltip>

                <input
                  accept=".xlsx,.xls,image/*,.doc, .docx,.pdf"
                  style={{ display: 'none' }}
                  id="upload-file-button"
                  multiple
                  type="file"
                  onChange={handleUploadFile}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={(event: any) => {
                    event.target.value = null;
                  }}
                />
                {!fileName && !loadingFile && (
                  <Tooltip title={constants.ATTACH_SUPPORT_TIMESHEET_TOOLTIP}>
                    <label htmlFor="upload-file-button">
                      <IconButton component="span">
                        <AttachIcon />
                      </IconButton>
                    </label>
                  </Tooltip>
                )}

                {fileName && !loadingFile && (
                  <Tooltip title={fileName}>
                    <Chip
                      label={`${fileName.substring(0, 20)}${fileName.length >= 20 ? '...' : ''}`}
                      variant="outlined"
                      onClick={handleDownloadFile}
                      onDelete={handleDeleteFile}
                    />
                  </Tooltip>
                )}
                {loadingFile && <CircularProgress />}
              </FilterWrapper>

              {displayUploadFileOption && !timesheetClosed && timesheetMonth && timesheetMonth.days.length > 0 && (
                <UploadXlsxFile
                  handleConvertUploadedFile={handleConvertUploadedFile}
                  handleOnUploadFileClose={handleOnUploadFileClose}
                  xlsxTemplate={null}
                />
              )}
            </OptionsBox>
            <TextsDiv>
              <TextContainer>
                <SummaryText>
                  Registered: {loadingTimesheet || (savingTimesheet && <SpinnerSmall />)}
                  {!loadingTimesheet && !savingTimesheet && (
                    <TextWithColor id="txt_timesheet_registered_hours" textcolor={'#5bcaff'}>
                      {registeredHours}
                    </TextWithColor>
                  )}{' '}
                </SummaryText>
              </TextContainer>
              <TextContainer>
                <SummaryText>
                  Remaining: {loadingTimesheet || (savingTimesheet && <SpinnerSmall />)}
                  {!loadingTimesheet && !savingTimesheet && (
                    <TextWithColor
                      id="txt_timesheet_remaining_hours"
                      textcolor={parseInt(remainingHours) > 0 ? 'red' : '#5bcaff'}
                    >
                      {remainingHours}
                    </TextWithColor>
                  )}{' '}
                </SummaryText>
              </TextContainer>
              <TextContainer>
                <SummaryText>
                  Holiday: {loadingTimesheet || (savingTimesheet && <SpinnerSmall />)}
                  {!loadingTimesheet && !savingTimesheet && (
                    <TextWithColor id="txt_timesheet_holida_hours" textcolor={'#5bcaff'}>
                      {holidayHours}
                    </TextWithColor>
                  )}{' '}
                </SummaryText>
              </TextContainer>
              <TextContainer>
                <SummaryText>
                  Others: {loadingTimesheet || (savingTimesheet && <SpinnerSmall />)}
                  {!loadingTimesheet && !savingTimesheet && (
                    <TextWithColor id="txt_timesheet_holida_hours" textcolor={'#5bcaff'}>
                      {otherTimeHours}
                    </TextWithColor>
                  )}{' '}
                </SummaryText>
              </TextContainer>
              <TextContainer>
                <b>
                  OT: {loadingTimesheet || (savingTimesheet && <SpinnerSmall />)}
                  {!loadingTimesheet && !savingTimesheet && (
                    <TextWithColor id="txt_timesheet_overtime_hours" textcolor={'#5bcaff'}>
                      {overtimeHours}
                    </TextWithColor>
                  )}{' '}
                </b>
              </TextContainer>
            </TextsDiv>

            {(loadingTimesheet || savingTimesheet) && (
              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            )}

            <p style={{ textAlign: 'center' }}>
              (Deadline to submit report: {deadLineDate} - <b>{daysLeft}</b>)
            </p>

            {timesheetClosed && (
              <p style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>
                Timesheet is closed, changes can no longer be made. Please contact your DM/Lead if adjustments are
                required.
              </p>
            )}
            {!loadingTimesheet && !savingTimesheet && timesheetMonth && timesheetMonth.days.length > 0 ? (
              <div>
                <div>
                  <Row>
                    <DateHeaderContainer>
                      <HeaderText id="txt_timesheet_day_header">Day</HeaderText>
                    </DateHeaderContainer>
                    <TrackingTypeContainer>
                      <HeaderText id="txt_timesheet_tracking_type_header">Tracking type</HeaderText>
                    </TrackingTypeContainer>
                    <DescriptionContainer>
                      <HeaderText id="txt_timesheet_description_header">Task Description</HeaderText>
                    </DescriptionContainer>
                    <HoursContainer>
                      <HeaderText id="txt_timesheet_time_spent_header">Time Spent</HeaderText>
                    </HoursContainer>
                  </Row>
                </div>
                <div>
                  {timesheetMonth.days.map((day) => (
                    <DayRow
                      key={day.date.toString()}
                      dayId={day._id}
                      dayDate={day.date}
                      dayTypes={dayTypes}
                      daySubTypes={daySubTypes}
                      dayType={day.type}
                      dayDescription={day.taskDescription}
                      dayTimeSpent={day.timeSpent}
                      handleOnHourBlur={handleOnHourBlur}
                      handleDescriptonOnChange={handleDescriptonOnChange}
                      handleOnDayTypeChange={handleOnDayTypeChange}
                      handleOnDaySubTypeChange={handleOnDaySubTypeChange}
                      handleOnDescriptionBlur={handleOnDescriptionBlur}
                      handleOnHourChange={handleTimeSpentOnChange}
                      paidHolidays={timesheetMonth.project?.holidays || false}
                      paidVacations={timesheetMonth.project?.vacations || false}
                      handleOnDayRemoveRow={handleDayRemove}
                      isDisabled={timesheetClosed}
                      daySubType={day.subType}
                    />
                  ))}
                </div>

                <LinkContainer>
                  public timesheet link: &nbsp;
                  <a href={pubicURL} rel="noopener noreferrer" target="_blank">
                    {pubicURL}{' '}
                  </a>
                </LinkContainer>

                <ButtonContainer hidden={!!timesheetId}>
                  <Submit id="submit_timesheet_button" onClick={showConfirmationModal} disabled={timesheetClosed}>
                    <PublishIcon></PublishIcon>
                    Submit Report
                  </Submit>
                </ButtonContainer>
              </div>
            ) : (
              <NoRecords hidden={loadingTimesheet || savingTimesheet}>
                <SingleTextWrapper>No timesheet initialized for this month</SingleTextWrapper>
                <ButtonContainer>
                  <Submit onClick={handleInitializeTimesheet}>Initialize</Submit>
                </ButtonContainer>
              </NoRecords>
            )}
          </CardBody>
        )}
      </Card>

      <AlertDialog
        title={'Submit report'}
        message={'Are you sure that you want to submit this report?'}
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        acceptConfirmation={handleOnSubmitReport}
      />

      <AlertDialog
        title={'Upload file'}
        message={constants.MSG_UPLOAD_FILE_CONFIRMATION + fileName}
        open={openUploadFileConfirmationDialog}
        setOpen={setOpenUploadFileConfirmationDialog}
        acceptConfirmation={uploadFileConfirmation}
        onCancel={handleOnCancelUpload}
      />

      <AlertDialog
        title={'Delete file'}
        message={constants.MSG_DELETE_FILE_CONFIRMATION}
        open={openDeleteFileConfirmationDialog}
        setOpen={setOpenDeleteFileConfirmationDialog}
        acceptConfirmation={deleteFileConfirmation}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    text-align: right;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;
const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const SpinnerSmall = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
  height: 13px !important;
  width: 13px !important;
  margin-left: 5px;
`;

const SpinnerMedium = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
  height: 23px !important;
  width: 23px !important;
  margin: 10px;
  margin-left: 15px;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const SingleTextWrapper = styled.div`
  margin-top: 50px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

const FilterWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const OptionsBox = styled(Box)`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

const TextsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const InputWrapper = styled.div`
  float: left;
  margin-right: 15px;
  padding-top: 5px;
`;

const TextContainer = styled.div`
  display: block;
  padding: 10px 5px;
  float: left;
`;

const ExportButtonContainer = styled.div`
  margin-left: -6px;
  margin-right: 5px;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const DateHeaderContainer = styled.div`
  width: 7%;
  display: block;
`;

const TrackingTypeContainer = styled.div`
  width: 10%;
  display: flex !important;
  padding-left: 15px;
`;

const DescriptionContainer = styled.div`
  width: 74%;
  margin: 0 5px;
  display: block;
`;

const HoursContainer = styled.div`
  width: 8%;
  display: block;
  h4 {
    text-align: start;
  }
`;

const HeaderText = styled.h4`
  text-align: center;
  font-size: 20;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  color: ${(p) => p.theme.palette.primary.light};
`;

const TextWithColor = styled.span<{ textcolor: string }>`
  color: ${(props) => (props.textcolor ? props.textcolor : 'none')};
`;

const ButtonContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const LinkContainer = styled.div`
  padding-top: 20px;
  text-align: center;
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-top: 5px;
`;

const SummaryText = styled.div`
  font-weight: bold;
  text-align: center;
`;

export default TimesheetEmployee;
