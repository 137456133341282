import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

import { Column } from '../types';

type Props = {
  data: any[];
  columns: Column[];
  keyColumn?: string;
};

const TableContent: React.FC<Props> = (props) => {
  const { data, columns, keyColumn = '_id' } = props;

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item[keyColumn]} data-testid="table-row-test-id">
          {columns.map((column) => (
            <TableCell data-testid="table-cell-test-id" key={`${item[keyColumn]}_${column.label}`}>
              {renderCell(item, column)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

function renderCell(item: any, column: Column) {
  if (column.render) return column.render(item);

  return getValue(item, column.path!);
}

function getValue(value: any, path: string) {
  return path.split('.').reduce((prev, current) => prev[current], value);
}

export default TableContent;
