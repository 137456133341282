import usePaging, { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeResource, getEmployeeById } from 'services/employeeService';
import { getAllGenericNameValuesByParent } from 'services/genericNameValueTupleService';
import { getJobTitles } from 'services/jobTitleService';
import {
  getLastSalaryByEmployeeId,
  getSalariesByEmployeeId,
  getSalaryById,
  SalaryResource,
  SaveSalaryResource,
} from 'services/salaryService';
import { Item } from 'shared/types';
import { decryptText } from 'utils/encryptionMethods/encryption';
import { collectionToItem } from 'utils/mappers/item';

export const defaultSalaryResource = {
  employee: '',
  salary: '',
  increase: '',
  jobTitle: '',
  increaseType: '',
  comments: '',
  date: '',
};
const DEFAULT_QUERY: Query = {
  pageSize: 20,
  page: 1,
  isSortAscending: 0,
  sortByColumn: 'date',
};

export const useSalariesEffect = (salaryId: string, employeeId: string) => {
  const [salary, setSalary] = useState<SaveSalaryResource>(defaultSalaryResource);
  const [lastIncrease, setLastIncrease] = useState('0');
  const [loadingSalary, setLoadingSalary] = useState(true);
  const [salaryResultObject, setSalaryResultObject] = useState<SalaryResource>();
  const [lastSalary, setLastSalary] = useState('');
  const [isLastSalary, setIsLastSalary] = useState(true);
  const [increaseTypes, setIncreaseTypes] = useState<Item[]>([]);
  const [employeeJobTitle, setEmployeeJobTitle] = useState('0');
  const [jobTitleChangeId, setJobTitleChangeId] = useState('');

  const fetchSalary = useCallback(async () => {
    setLoadingSalary(true);
    const [lastSalaryResult, increaseTypesResult, employeeResult] = await Promise.all([
      getLastSalaryByEmployeeId(employeeId!),
      getAllGenericNameValuesByParent('increaseType'),
      getEmployeeById(employeeId),
    ]);

    setLastSalary(decryptText(lastSalaryResult.salary).replaceAll(/\s/g, '').replace('$', ''));

    setEmployeeJobTitle(employeeResult.jobTitle._id || '');
    const increaseTypesItems = collectionToItem(increaseTypesResult);

    setIncreaseTypes(increaseTypesItems);

    setJobTitleChangeId(
      increaseTypesItems.find((increaseType) => increaseType.label === 'Job Title Change')?.id.toString() || '',
    );

    if (salaryId !== 'new') {
      const salaryResult = await getSalaryById(salaryId!);

      if (salaryResult) {
        const checkLastSalary =
          decryptText(lastSalaryResult.salary).replaceAll(/\s/g, '').replace('$', '') ===
          decryptText(salaryResult.salary).replaceAll(/\s/g, '').replace('$', '');

        setIsLastSalary(checkLastSalary);

        if (!checkLastSalary) {
          setLastSalary(decryptText(salaryResult.salary).replaceAll(/\s/g, '').replace('$', ''));
        }

        setSalary(mapSalaryResponse(salaryResult));

        setSalaryResultObject(salaryResult);
        setLastIncrease(decryptText(salaryResult.increase).replaceAll(/\s/g, '').replace('$', ''));
      }
    }
    setLoadingSalary(false);
  }, [employeeId, salaryId]);

  useEffect(() => {
    fetchSalary();
  }, [fetchSalary]);

  const handleOnSalaryResult = (salaryResult: SalaryResource) => {
    setSalary(mapSalaryResponse(salaryResult));

    setSalaryResultObject(salaryResult);
  };

  return {
    salary,
    lastIncrease,
    setSalary,
    isLastSalary,
    lastSalary,
    loadingSalary,
    setLoadingSalary,
    salaryResultObject,
    fetchSalary,
    handleOnSalaryResult,
    increaseTypes,
    employeeJobTitle,
    jobTitleChangeId,
  };
};

export const useCatalogs = (id: string) => {
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [jobTitles, setJobTitles] = useState<Item[]>([]);
  const [employee, setEmployee] = useState<EmployeeResource>();

  const defaultResponse: PageResource<SalaryResource> = { totalItems: 0, items: [] };
  const [salaries, setSalaries] = useState(defaultResponse);
  const { query } = usePaging(DEFAULT_QUERY);

  const [dateFirst, setDateFirst] = useState('');
  let firstDate = '';

  useEffect(() => {
    async function fetchCatalogs() {
      setLoadingCatalogs(true);

      const [jobTitlesResult, employeeResult] = await Promise.all([getJobTitles(), getEmployeeById(id!)]);
      const jobTitlesItems = collectionToItem(jobTitlesResult);

      if (employeeResult) {
        setEmployee(employeeResult);
      }

      setJobTitles(jobTitlesItems);

      const salariesResult = await getSalariesByEmployeeId(id, query);

      setSalaries(salariesResult);

      if (salariesResult.items[0] !== undefined) {
        firstDate = salariesResult.items[0].date;

        for (let i = 0; i < salariesResult.items.length; i++) {
          if (salariesResult.items[i].date < firstDate) {
            firstDate = salariesResult.items[i].date;
          }
        }
      }

      setDateFirst(firstDate);
      setLoadingCatalogs(false);
    }

    fetchCatalogs();
  }, [id, query, firstDate]);

  return {
    employee,
    jobTitles,
    loadingCatalogs,
    dateFirst,
  };
};

const mapSalaryResponse = (salary: SalaryResource) => {
  return {
    employee: salary.employee,
    salary: decryptText(salary.salary).replace('$', ''),
    increase: decryptText(salary.increase).replace('$', ''),
    jobTitle: salary.jobTitle._id,
    comments: salary.comments,
    increaseType: salary.increaseType?._id || '',
    date: salary.date,
  } as SaveSalaryResource;
};
