/* eslint-disable react-hooks/rules-of-hooks */
import { CircularProgress, IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import { useOldOneOnOnesEffect } from './hooks';

type Props = {
  showModal: boolean;
  setShowModal: any;
  seletedEmployeeId: string;
  displayInNewTab?: boolean;
};

const DEFAULT_QUERY: Query = {
  pageSize: 6,
  page: 1,
  isSortAscending: 0,
  sortByColumn: 'creationDate',
};

const HistoryModal: React.FC<Props> = (props) => {
  const { showModal, setShowModal, seletedEmployeeId, displayInNewTab = false } = props;
  const history = useHistory();

  if (!seletedEmployeeId || !showModal) return <div></div>;

  const handleClose = () => {
    setShowModal(false);
  };

  const { query, handlePageChange } = usePaging(DEFAULT_QUERY);
  const { oneOnOnes, loading } = useOldOneOnOnesEffect(query, seletedEmployeeId);
  const onViewPressed = (oneOnOnId: string) => {
    setShowModal(false);
    saveInfoInLocalStorage('oneOnOneMode', 'readOnly', '');
    if (displayInNewTab) window.open(`/one-on-ones/${oneOnOnId}`, '_blank');
    else history.push(`/one-on-ones/${oneOnOnId}`);
  };

  return (
    <div>
      <ModalContainer
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <ContentContainer>
            <h2 style={{ marginLeft: '125px' }}>
              Recent One on Ones History
              <IconButton style={{ marginLeft: '50px', display: 'inline' }} onClick={handleClose}>
                <CloseIconShape />
              </IconButton>
            </h2>
            <h3>
              {oneOnOnes?.items?.length
                ? oneOnOnes.items[0].employee.name + ' ' + oneOnOnes.items[0].employee.lastName
                : ''}
            </h3>
            {loading && (
              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            )}
            {!loading && (
              <div>
                {oneOnOnes?.items?.length ? (
                  <StandardTable
                    keyColumn="_id"
                    data={oneOnOnes.items}
                    totalItems={oneOnOnes.totalItems}
                    page={query.page}
                    pageSize={query.pageSize}
                    isSortAscending={Boolean(query.isSortAscending)}
                    sortByColumn={query.sortByColumn}
                    onChangePage={handlePageChange}
                    columns={columns(onViewPressed)}
                  />
                ) : (
                  <NoRecords>There are no records to display. </NoRecords>
                )}
              </div>
            )}
          </ContentContainer>
        </Fade>
      </ModalContainer>
    </div>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CloseIconShape = styled(CancelOutlinedIcon)`
  color: ${(p) => p.theme.palette.primary.main};
`;
const mystyle = styled.div`
  margin-left: 20px;
`;

const ContentContainer = styled.div`
  background-color: ${(p) => p.theme.palette.background.paper};
  border: 2px solid #000;
  box-shadow: ${(p) => p.theme.shadows[5]};
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  min-width: 450px;
  min-height: 540px;
  text-align: center;
`;

const NoRecords = styled.div`
  padding: 15px;
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

export default HistoryModal;
