import { PageResource, Query } from 'hooks/usePaging';

import http from './httpService';

const CATEGORIES_URL = '/api/categories';

export type CategoryResource = {
  _id: string;
  name: string;
};
export type SaveCategoryResource = {
  name: string;
};

export async function getCategories() {
  const { data } = await http.get<CategoryResource[]>(`${CATEGORIES_URL}/categories`);

  return data;
}
export async function getCategoriesQuery(query: Query) {
  const { data } = await http.get<PageResource<CategoryResource>>(
    `${CATEGORIES_URL}/categories/query` + http.toQueryString(query),
  );

  return data;
}

export async function getCategoryById(id: string) {
  const { data } = await http.get<CategoryResource>(`${CATEGORIES_URL}/categories/${id}`);

  return data;
}

export async function deleteCategory(id: string) {
  const { data } = await http.delete<CategoryResource[]>(`${CATEGORIES_URL}/categories/${id}`);

  return data;
}

export async function updateCategory(category: SaveCategoryResource, id: string) {
  const { data } = await http.put<CategoryResource>(`${CATEGORIES_URL}/categories/${id}`, category);

  return data;
}

export async function createCategory(category: SaveCategoryResource) {
  const { data } = await http.post<CategoryResource>(`${CATEGORIES_URL}/categories`, category);

  return data;
}
