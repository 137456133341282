import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useEffect, useState } from 'react';
import { HitgoalTemplateFilter } from 'services/hitgoalsService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import Filter from './filter';
import { useHitgoalTemplateEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

const HitgoalTemplatesList: React.FC = () => {
  const localOwnersFilter = localStorage.getItem('owners_hitgoalTemplates');
  const localCategoriesFilter = localStorage.getItem('categories_hitgoalTemplates');
  const localSearchFilter = localStorage.getItem('searchText_hitgoalTemplates');
  const localStatusFilter = localStorage.getItem('status_hitgoalTemplates');
  const localOfficialFilter = localStorage.getItem('official_hitgoalTemplates');

  const DEFAULT_QUERY: Query & HitgoalTemplateFilter = {
    owners: localOwnersFilter?.split(',') || [],
    categories: localCategoriesFilter?.split(',') || [],
    searchText: localSearchFilter || '',
    status: Boolean(localStatusFilter ? localStatusFilter === 'true' : 'true'),
    official: Boolean(localOfficialFilter === 'true'),
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'objective',
  };

  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const {
    hitgoalTemplates,
    ownerList,
    categoryList,
    loading,
    setLoading,
    fetchTemplates,
    onCopyTemplate,
  } = useHitgoalTemplateEffect(query);
  const initialLoading = useInitialLoading(loading);

  return (
    <Container>
      <Card>
        <CardHeader title="Templates" subTitle="" loading={loading} newPageRedirectionLink={'/hitGoal-templates/new'} />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter owners={ownerList} categories={categoryList} setFilter={setQuery} filter={query} />
            {hitgoalTemplates?.items.length ? (
              <StandardTable
                keyColumn="_id"
                data={hitgoalTemplates.items}
                totalItems={hitgoalTemplates.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchTemplates, setLoading, onCopyTemplate)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    text-align: right;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;
const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default HitgoalTemplatesList;
