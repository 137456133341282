import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import Filter from './filter';
import { useActionItemsEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

const ActionItemsList: React.FC = () => {
  const {
    actionItems,
    loading,
    setLoading,
    fetchActionItems,
    handlePageChange,
    handleColumnSorting,
    query,
    setQuery,
    employees,
    leaders,
    statuses,
    priorities,
    loadingCatalogs,
  } = useActionItemsEffect();
  const initialLoading = useInitialLoading(loading || loadingCatalogs);

  return (
    <Container>
      <Card>
        <CardHeader
          title="Action Items"
          subTitle="All the action items information"
          newPageRedirectionLink="/action-items/new"
          loading={loading}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter
              employees={employees}
              leaders={leaders}
              statuses={statuses}
              priorities={priorities}
              setFilter={setQuery}
              filter={query}
              totalItems={actionItems.totalItems}
            />
            {actionItems?.items?.length ? (
              <StandardTable
                keyColumn="_id"
                data={actionItems.items}
                totalItems={actionItems.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchActionItems, setLoading)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default ActionItemsList;
