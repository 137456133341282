import { useCallback, useEffect, useState } from 'react';
import { CategoryResource, getCategoryById, SaveCategoryResource } from 'services/categoriesService';

const defaultCategoryResource = {
  name: '',
};

export const useCategoryEffect = (id?: string) => {
  const [category, setCategory] = useState<SaveCategoryResource>(defaultCategoryResource);
  const [loading, setLoading] = useState(false);

  const fetchCategory = useCallback(async () => {
    setLoading(true);
    const categoryResult = await getCategoryById(id!);

    if (categoryResult) {
      setCategory(mapCategoryResponse(categoryResult));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchCategory();
  }, [id, fetchCategory]);

  return { category, loading };
};

const mapCategoryResponse = (category: CategoryResource) => {
  return {
    name: category.name,
  } as SaveCategoryResource;
};
