import { createStyles, CssBaseline, makeStyles, Theme } from '@material-ui/core';
import Navbar from 'components/nav-bar';
import SidebarTimesheet from 'components/side-bar/side-bar-timesheet';
import { ROLE_EMPLOYEE } from 'helpers/roleHelper';
import LoginTimesheet from 'pages/loginTimeseet';
import TimesheetEmployee from 'pages/timesheets/employee';
import TokenExpired from 'pages/token-expired';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch, spring } from 'react-router-transition';
import styled, { createGlobalStyle } from 'styled-components';

import { privateRoutesNavBar } from '../side-bar/routes';
import { useWindowSize } from './hooks';
import PrivateRoute from './privateRoute';

const getDrawerWidth = (currentWidth: number) => {
  let panelWidth = currentWidth * 0.2;

  //Set mobile size.
  if (currentWidth < 600) panelWidth = 200;
  //Max bar size
  else if (panelWidth > 310) panelWidth = 310;

  return panelWidth as number;
};

const RoutingTimsheet: React.FC = () => {
  document.title = "Fusionhit Timesheet";
  const user = useCurrentUser();
  const navBarRoutes = Boolean(user) ? privateRoutesNavBar : [];
  const [mobileOpen, setMobileOpen] = useState(false);
  const [windowWidth] = useWindowSize();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${getDrawerWidth(windowWidth)}px)`,
          marginLeft: getDrawerWidth(windowWidth),
        },
      },
    }),
  );

  const classes = useStyles();

  return (
    <RouterPanel>
      <GlobalStyles />
      <CssBaseline />
      <div>
        <SidebarTimesheet
          logoText="Timesheet"
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          drawerWidth={getDrawerWidth(windowWidth)}
        />
      </div>
      <MainPanel className={classes.content}>
        <Navbar userInfo={user} routes={navBarRoutes} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <AnimatedSwitch {...animation} runOnMount>
          <Route path="/login" component={LoginTimesheet} />

          <PrivateRoute exact path="/" roles={[ROLE_EMPLOYEE]} component={TimesheetEmployee} />
          <PrivateRoute exact path="/timesheets-summary" roles={[ROLE_EMPLOYEE]} component={TimesheetEmployee} />

          <Route path="/token-expired" component={TokenExpired} />
          <Route component={NotFound} />
        </AnimatedSwitch>
      </MainPanel>
    </RouterPanel>
  );
};

const popConfig = { stiffness: 360, damping: 25 };

const animation = {
  atEnter: {
    transitionIndex: 0,
    scale: 0.8,
    opacity: 0,
  },
  atLeave: {
    transitionIndex: 2,
    scale: spring(0.8, popConfig),
    opacity: spring(0, popConfig),
  },
  atActive: {
    scale: spring(1, popConfig),
    opacity: 1,
    transitionIndex: 1,
  },
  mapStyles: (styles) => ({
    position: styles.transitionIndex <= 1 ? 'relative' : 'absolute',
    width: '100%',
    height: '100%',
    transform: `scale(${styles.scale})`,
    opacity: styles.opacity,
  }),
};

const RouterPanel = styled(Router)`
  display: 'flex';
`;

//TODO: Move later and create
const NotFound: React.FC = () => {
  return <p>Not Found</p>;
};

const MainPanel = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  max-height: 100%;
`;

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${(p) => p.theme.palette.background.paper};
  }

  body, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 300;
    line-height: 1.5em;

  }

  .Toastify__toast-container {
    width: 320px;
    }

  .Toastify__toast--default {
      background: #fff;
      color: #aaa;
  }

  .Toastify__toast--success {
    background: ${(p) => p.theme.palette.success.main};
  };
`;

export default RoutingTimsheet;
