import { Description } from 'services/exitProcedureProcessService';

export const statusObjectToString = (descriptions: Description[]) => {
  let countCompleted = 0;
  let countInCompleted = 0;
  let message = '';

  for (let i = 0; i < descriptions.length; i++) {
    if (descriptions[i].epStatus === 'Completed' || descriptions[i].epStatus === 'N/A') {
      countCompleted++;
    } else {
      countInCompleted++;
    }
  }
  if (countCompleted !== 0) {
    message = `Completed (${countCompleted}/${descriptions.length})`;
  }
  if (countInCompleted !== 0) {
    message = `Incomplete (${countCompleted}/${descriptions.length})`;
  }
  return message;
};
