import { InputAdornment, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import { useJobTitlesEffect } from './hooks';

export type SearchFilter = {
  searchText: string;
};

clearLocalStorage(); //Clear local storage on refresh

const localStoragePrefix = '_jobTitles';

const DEFAULT_QUERY: Query & SearchFilter = {
  pageSize: 20,
  page: 1,
  isSortAscending: 1,
  sortByColumn: 'order',
  searchText: '',
};

const DEFAULT_FILTER: SearchFilter = {
  searchText: localStorage.getItem(localStoragePrefix) || '',
}

const JobTitleList: React.FC = () => {
  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_FILTER);
  const {
    jobTitles,
    loading: jobTitlesLoading,
    setLoading,
    fetchJobTitles,
  } = useJobTitlesEffect(query);
  const initialLoading = useInitialLoading(jobTitlesLoading);
  const isLoading = jobTitlesLoading;

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setQuery({ ...query, ...defaultFilter }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter]);

  const handleOnChange = useCallback(
    (event: any) => {
      const { name, value } = event?.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: value});

    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <Card>
        <CardHeader title="JobTitles" subTitle="" loading={isLoading} newPageRedirectionLink={'/jobtitles/new'} />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <SearchBar>
                  <TextField
                    id="standard-search"
                    label="Search"
                    type="search"
                    name="searchText"
                    value={defaultFilter.searchText}
                    onChange={handleOnChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </SearchBar>
              </Grid>
            </Grid>
            {jobTitles?.items.length ? (
              <StandardTable
                keyColumn="_id"
                data={jobTitles.items}
                totalItems={jobTitles.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchJobTitles, setLoading)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    text-align: right;
  }
`;
const SearchBar = styled.div`
  margin: 10px 0px 20px 15px;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;
const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default JobTitleList;
