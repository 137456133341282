import { Checkbox, FormControlLabel } from '@material-ui/core';
import SingleSelectNoValue from 'components/single-select-no-value';
import React, { useState } from 'react';
import { ExitProcedureProcessResource } from 'services/exitProcedureProcessService';
import { ExitItem, Item } from 'shared/types';
import styled from 'styled-components';

type Props = {
  description: ExitItem;
  epStatuses: Item[];
  setSaveExitProcedure: any;
  saveExitProcedure: ExitProcedureProcessResource | undefined;
};

const ItemList: React.FC<Props> = (props) => {
  const { description, epStatuses, setSaveExitProcedure, saveExitProcedure } = props;

  const [valueSelect, setValueSelect] = useState<string>(description.epStatus);
  const [valueCheck, setValueCheck] = useState<boolean>(
    description.epStatus === 'Completed' || description.epStatus === 'N/A',
  );

  const onChange = (event: any) => {
    if (!saveExitProcedure) return;

    const { value } = event.target;

    setValueSelect(value);
    if (value === 'Completed') {
      setValueCheck(value === 'Completed');
    } else if (value === 'N/A') {
      setValueCheck(value === 'N/A');
    } else {
      setValueCheck(value === '');
    }

    findDescription(saveExitProcedure, value);
  };

  const onCheck = (event: any) => {
    const { checked } = event.target;

    if (!saveExitProcedure) return;

    if (!checked) {
      setValueSelect('Pending');
      setValueCheck(checked);

      findDescription(saveExitProcedure, 'Pending');
    } else {
      setValueSelect('Completed');
      setValueCheck(checked);

      findDescription(saveExitProcedure, 'Completed');
    }
  };

  const findDescription = (saveExitProcedure: ExitProcedureProcessResource, value: string) => {
    const childDescription = saveExitProcedure.descriptions.find(
      (itemDescription) => itemDescription._id === description.id,
    );

    if (!childDescription) return;

    childDescription.epStatus = value;

    setSaveExitProcedure({ ...saveExitProcedure, childDescription });
  };

  return (
    <OptionsContainer>
      <CheckboxLabel
        key={description.id}
        control={<Checkbox key={`check-${description.id}`} checked={valueCheck} onChange={onCheck} color="secondary" />}
        label={description.description}
      />
      <SelectContainer>
        <SingleSelectNoValue
          label={'Status'}
          options={epStatuses}
          value={valueSelect}
          onChange={onChange}
          name={'description'}
        />
      </SelectContainer>
    </OptionsContainer>
  );
};

const SelectContainer = styled.div`
  display: flex;
  min-width: 25%;
  margin-right: 3%;

  margin-top: 1%;
`;
const CheckboxLabel = styled(FormControlLabel)`
  display: flex;
  min-width: 75%;
  margin-right: 3%;
  overflow: hidden;
`;
const OptionsContainer = styled.div`
  display: flex;

  border: 1px solid #dddddd;
  border-radius: 3px;
`;

export default ItemList;
