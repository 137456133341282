import { Item } from 'shared/types';

export const monthValues: Item[] = [
  { id: '01', label: 'January' },
  { id: '02', label: 'February' },
  { id: '03', label: 'March' },
  { id: '04', label: 'April' },
  { id: '05', label: 'May' },
  { id: '06', label: 'June' },
  { id: '07', label: 'July' },
  { id: '08', label: 'August' },
  { id: '09', label: 'September' },
  { id: '10', label: 'October' },
  { id: '11', label: 'November' },
  { id: '12', label: 'December' },
];

export const monthValuesSalaryPeriod: Item[] = [
  { id: '01', label: 'January' },
  { id: '05', label: 'May' },
  { id: '09', label: 'September' },
];

export const countryValues: Item[] = [
  { id: 'Costa Rica', label: 'Costa Rica' },
  { id: 'United States', label: 'United States' },
  { id: 'El Salvador', label: 'El Salvador' },
  { id: 'Colombia', label: 'Colombia' },
  { id: 'Bolivia', label: 'Bolivia' },
];

export const monthName = (number: number) => {
  let monthToGet = '';

  if (number.toString().length === 1) {
    monthToGet = '0' + number.toString();
  } else monthToGet = number.toString();

  return monthValues.filter((e) => e.id === monthToGet)[0].label;
};
