import { isPermissionGranted, ROLE_ADMIN } from 'helpers/roleHelper';
import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { getProjectById, ProjectResource, SaveProjectResource } from 'services/projectService';

const defaultProjectResource = {
  name: '',
  holidays: false,
  vacations: false,
  isActive: true,
  allowTimsheetImport: false,

  clientSince: null,
  techStack: '',
  category: '',
  partner: '',
};

export const useProjectEffect = (id?: string) => {
  const [project, setProject] = useState<SaveProjectResource>(defaultProjectResource);
  const [loading, setLoading] = useState(false);
  const [isReadOnly, setReadOnly] = useState<boolean>(false);
  const user = useCurrentUser();

  const fetchProject = useCallback(async () => {
    setLoading(true);
    const projectResult = await getProjectById(id!);

    if (projectResult) {
      setProject(mapProjectResponse(projectResult));
    }

    setReadOnly(!isPermissionGranted([ROLE_ADMIN], user));
    setLoading(false);
  }, [id, user]);

  useEffect(() => {
    if (id) fetchProject();
  }, [id, fetchProject]);

  return { project, loading, isReadOnly };
};

const mapProjectResponse = (project: ProjectResource) => {
  return {
    name: project.name,
    holidays: project.holidays,
    vacations: project.vacations,
    isActive: project.isActive,
    allowTimsheetImport: project.allowTimsheetImport,

    clientSince: project.clientSince || null,
    techStack: project.techStack || '',
    category: project.category,
    partner: project.partner,
  } as SaveProjectResource;
};
