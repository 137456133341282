type Config = {
  baseUrl: string;
  googleId: string;
  encryptionKey: string;
  microsoftId: string;
  microsoftTenantUrl: string;
};

type EnvironmentConfig = {
  default: Config;
  test: Partial<Config>;
  production: Partial<Config>;
};

const config: EnvironmentConfig = {
  default: require('./default.json'),
  test: require('./test.json'),
  production: require('./production.json'),
};

const ENV: string = process.env.REACT_APP_ENVIRONMENT || 'development';

function getConfig() {
  if (ENV === 'development') return config.default;
  if (ENV === 'test') return { ...config.default, ...config.test };
  if (ENV === 'production') return { ...config.default, ...config.production };

  return config.default;
}

export default getConfig();
