import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const TextFieldWrapper = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
  .MuiInputBase-input {
    font-size: 13px;
    padding-right:5px;
  }
`;
