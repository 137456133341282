import Icon from '@material-ui/core/Icon';
import BaseListItem from '@material-ui/core/ListItem';
import BaseListItemText from '@material-ui/core/ListItemText';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import React, { useCallback, useState } from 'react';
import { NavLink as BaseNavLink } from 'react-router-dom';
import styled from 'styled-components';

import { RouteItem } from '../routes';

type Props = {
  to?: string;
  icon: string;
  name: string;
  parent?: string;
  onClick?(): void;
  iconStyle?: any;
  level: number;
  visibility: boolean;
  enabled?: boolean;
  children?: boolean;
  routes: RouteItem[];
  setRoutes(obj: RouteItem[]): void;
};

const activeClassName = 'active';

const NavItem: React.FC<Props> = (props) => {
  let { to, icon, enabled, name, onClick, iconStyle, level, visibility, children, routes, setRoutes } = props;
  const [visibilityState, setVisibilityState] = useState(visibility);
  const [enabledState, setEnabledState] = useState(enabled);
  const nameId = name.replace(/\s/g, '');

  const handleIsActiveCheck = useCallback(
    (match, location) => {
      if (match) return true;
      if (to === '/') return false;
      return location.pathname.includes(to);
    },
    [to],
  );

  const renderLink = () => {
    const link = to ? to : '';

    return (
      <NavLink
        id={nameId + '-sidebar-id'}
        level={level}
        enabled={enabled}
        to={link}
        exact
        activeClassName={activeClassName}
        isActive={handleIsActiveCheck}
        visibility={visibility.toString()}
      >
        {renderItem()}
      </NavLink>
    );
  };

  const handleClick = () => {
    const newRoutes = routes.map((route) =>
      route.parent === name ? { ...route, visibility: !route.visibility, enabled: !route.enabled } : route,
    );

    setRoutes(newRoutes);

    setEnabledState(!enabledState);

    setVisibilityState(!visibilityState);

    if (onClick) onClick();
  };

  const renderItem = () => {
    const child = children ? 1 : 0;

    const splitName = name.split(' ');
    let flag = false;

    if (name.includes('In Process')) {
      flag = true;
    }

    return (
      <ListItem
        id={nameId + '-sidebar-id'}
        button
        onClick={handleClick}
        enabled={enabled}
        visibility={visibility.toString()}
      >
        <IconContainer>
          {icon === 'howToReg' && <HowToRegIcon />}
          {icon !== 'howToReg' && <Icon style={iconStyle}>{icon}</Icon>}
        </IconContainer>
        <DropIconContainer displayArrow={child}>
          {!iconStyle && (visibilityState === false ? <ExpandMoreIcon /> : <ExpandLessIcon />)}
        </DropIconContainer>

        {flag === true && (
          <div>
            {splitName.length === 1 && <ListItemText primary={splitName[0] + ' '} disableTypography={true} />}
            {splitName.length === 3 && <ListItemText primary={splitName[0] + ' '} disableTypography={true} />}
            {splitName.length > 3 && (
              <ListItemText primary={splitName[0] + ' ' + splitName[1] + ' '} disableTypography={true} />
            )}
            {splitName[1] === 'In' && splitName[2] === 'Process' && (
              <ListItemInProcess primary={splitName[1] + ' ' + splitName[2]} disableTypography={true} />
            )}
            {splitName[2] === 'In' && splitName[3] === 'Process' && (
              <ListItemInProcess primary={' ' + splitName[2] + ' ' + splitName[3]} disableTypography={true} />
            )}
          </div>
        )}
        {flag === false && <ListItemText primary={name} disableTypography={true} />}
      </ListItem>
    );
  };

  return to ? renderLink() : renderItem();
};

const NavLink = styled(BaseNavLink)<{ level: number; visibility: string; enabled: boolean | undefined }>`
  margin-left: ${(p) => (p.level * 15).toString()}px;
  position: relative;
  display: ${(p) => (p.enabled === true ? 'block' : 'none')};
  text-decoration: none;

  &:hover,
  &:focus,
  &:visited,
  & {
    color: ${(p) => p.theme.palette.common.white};
  }
  &.${activeClassName} > div {
    background-color: ${(p) => p.theme.palette.primary.main};
  }
`;

const ListItem = styled(BaseListItem)<{ visibility: string; enabled: boolean | undefined }>`
  width: auto;
  margin: 10px 15px 0;
  display: ${(p) => (p.enabled === true ? 'block' : 'none')};
  padding: 10px 15px;
  position: relative;
  transition: all 300ms linear;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  border-radius: 3px;
  background-color: transparent;
  &:hover,
  &:focus,
  &:visited,
  & {
    color: ${(p) => p.theme.palette.common.white};
  }
`;

const ListItemText = styled(BaseListItemText)`
  margin: 0;
  display: inline;
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  line-height: 30px;
  color: ${(p) => p.theme.palette.common.white};
  a {
    text-decoration: none;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ListItemInProcess = styled(BaseListItemText)`
  margin: 0;
  font-size: 14px;
  display: inline;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  line-height: 30px;
  background-color: yellow;
  color: ${(p) => p.theme.palette.common.black};
  a {
    text-decoration: none;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
  float: left;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
`;

export const DropIconContainer = styled.div<{ displayArrow: number }>`
  display: ${(p) => (p.displayArrow === 1 ? 'block' : 'none')};
  float: right;
`;

export default NavItem;
