import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getOldOneOnOnesById, HistoryOneOnOneResource } from 'services/oneOnOneService';

export const useDisplayModalEffect = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return { showModal, setShowModal };
};

export const useSeletedEmployeeOneOnOneEffect = (seletedEmployee: string) => {
  const [selectedEmployee, setSelectedEmployee] = useState('');

  useEffect(() => {
    setSelectedEmployee(seletedEmployee);
  }, [seletedEmployee]);

  return { selectedEmployee, setSelectedEmployee };
};

export const useOldOneOnOnesEffect = (query: Query, selectedOneOnOne: string) => {
  const [oneOnOnes, setOnOnOnes] = useState<PageResource<HistoryOneOnOneResource>>({ totalItems: 0, items: [] });
  const [loading, setLoading] = useState(true);

  const fetchOneOnOnes = useCallback(async () => {
    setLoading(true);
    const data = await getOldOneOnOnesById(query, selectedOneOnOne);

    setLoading(false);
    setOnOnOnes(data);
  }, [query, selectedOneOnOne]);

  useEffect(() => {
    fetchOneOnOnes();
  }, [fetchOneOnOnes, query]);

  return { oneOnOnes, loading };
};
