import { CircularProgress, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import { useForm } from 'hooks/useForm';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { updateRewardHistorical } from 'services/rewardsHistoricalService';
import getBoxBackground from 'shared/style/utilities/getBoxBackground';
import getBoxShadow from 'shared/style/utilities/getBoxShadow';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import Constants from 'utils/constants';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useRewardEffect } from './hookts';

saveInfoInLocalStorage('inputValue', '', '_form');
type Props = {
  rewardId: string;
  statusInTheMainScreen: string;
};

const schemaRewardValidation = {
  rewStatusID: yup.string().default('').required().label('Validation'),
  requestComment: yup.string().required().label('Comment'),
};

const RewardValidation: React.FC<Props> = (props) => {
  const { rewardId, statusInTheMainScreen } = props;
  const user = useCurrentUser();

  const { saveReward, loading, hasPermission } = useRewardEffect(rewardId, user!);

  let { useInput, values, isValid } = useForm(saveReward, schemaRewardValidation as any, loading);
  const { isSubmiting, handleUpdateReward: handleUpdateRewardHistorical } = useSubmitForm(values, true, rewardId);

  return (
    <Container>
      <Card>
        <CommentsHeader loading={loading} title={`Reward Validation`} />
        <CardBody>
          <FormContainer>
            <DetailsLineWithRadioButtons>
              <RadioGroupAdminContainer row {...useInput('rewStatusID')}>
                <FormControlLabel
                  value={'yes'}
                  control={<Radio />}
                  label={'Acepted'}
                  disabled={!hasPermission || saveReward.requestComment !== '' ? true : false}
                />
                <FormControlLabel
                  value={'no'}
                  control={<Radio />}
                  label={'Rejected'}
                  disabled={!hasPermission || saveReward.requestComment !== '' ? true : false}
                />
                <RequiredForm>*</RequiredForm>
              </RadioGroupAdminContainer>
            </DetailsLineWithRadioButtons>

            <TextBody>
              <TextField
                required
                multiline
                fullWidth
                rows={4}
                id="requestComment"
                label="Comment"
                variant="outlined"
                inputProps={{ maxLength: 5000 }}
                disabled={!hasPermission || saveReward.requestComment !== '' ? true : false}
                {...useInput('requestComment')}
              />
            </TextBody>
            <ButtonContainer>
              {isSubmiting && <Spinner size={30} />}
              {statusInTheMainScreen === Constants.REWARD_PENDING_APPROVAL_STATUS_NAME ? (
                <Submit disabled={isSubmiting || loading || !isValid} onClick={handleUpdateRewardHistorical}>
                  Save
                </Submit>
              ) : (
                ''
              )}
            </ButtonContainer>
          </FormContainer>
        </CardBody>
      </Card>
    </Container>
  );
};
const useSubmitForm = (values: any, isValid: boolean, rewardId: string) => {
  const history = useHistory();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const handleUpdateRewardHistorical = useCallback(async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    setIsSubmitting(true);
    if (rewardId) {
      try {
        const newReward = await updateRewardHistorical(values, rewardId);

        if (newReward) {
          notifier.success('Reward updated successfully.');
          history.push(`/rewards/`);
        }
      } catch (error) {
        if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
      }
    }

    setIsSubmitting(false);
  }, [isValid, values, rewardId, history]);

  return { isSubmiting, handleUpdateReward: handleUpdateRewardHistorical };
};
const FormContainer = styled.div``;
const CommentsHeader = styled(CardHeader)`
  background: ${(p) => getBoxBackground(p.theme.palette.secondary.main, p.theme.palette.secondary.dark)};
  box-shadow: ${(p) => getBoxShadow(p.theme.palette.secondary.main)};
`;
const TextBody = styled.div`
  margin: 20px;
`;
const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
  float: right;
`;
const RequiredForm = styled.span`
  color: red;
  font-size: 15px;
  margin-left: -12px;
`;
const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;
const RadioGroupAdminContainer = styled(RadioGroup)`
  text-align: center;
  display: block;
`;

const Container = styled.div``;

const DetailsLineWithRadioButtons = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
`;

export default RewardValidation;
