import { PageResource, Query } from 'hooks/usePaging';

import { UserResource } from './authService';
import http from './httpService';

const EXIT_PROCEDURE_TEMPLATES_URL = '/api/exitProcedureTemplates';

export type DescriptionResource = {
  _id: string;
  description: string;
  order: number;
};

export type SaveDescriptionResource = {
  description: string;
};

export type ExitProcedureResource = {
  _id: string;
  title: string;
  createdBy: UserResource;
  creationDate: Date;
  descriptions: DescriptionResource[];
  isActive: boolean;
};

export type SaveExitProcedureResource = {
  title: string;
  descriptions: DescriptionResource[];
  isActive: boolean;
};

export async function getExitProcedureTemplates() {
  const { data } = await http.get<ExitProcedureResource[]>(EXIT_PROCEDURE_TEMPLATES_URL + '/');

  return data;
}

export async function getExitProcedureById(id: string) {
  const { data } = await http.get<ExitProcedureResource>(`${EXIT_PROCEDURE_TEMPLATES_URL}/single/${id}`);

  return data;
}

export async function getExitProcedureTemplatesQuery(query: Query) {
  const { data } = await http.get<PageResource<ExitProcedureResource>>(
    `${EXIT_PROCEDURE_TEMPLATES_URL}/query` + http.toQueryString(query),
  );

  return data;
}

export async function deleteExitProcedureTemplate(id: string) {
  const { data } = await http.delete<ExitProcedureResource[]>(`${EXIT_PROCEDURE_TEMPLATES_URL}/${id}`);

  return data;
}
export async function updateExitProcedureTemplate(exitProcedureTemplate: SaveExitProcedureResource, id: string) {
  const { data } = await http.put<ExitProcedureResource>(
    `${EXIT_PROCEDURE_TEMPLATES_URL}/${id}`,
    exitProcedureTemplate,
  );

  return data;
}
export async function copyExitProcedureTemplate(id: string) {
  const { data } = await http.get<ExitProcedureResource[]>(`${EXIT_PROCEDURE_TEMPLATES_URL}/copy/${id}`);

  return data;
}

export async function createExitProcedureTemplate(exitProcedureTemplate: SaveExitProcedureResource) {
  const { data } = await http.post<ExitProcedureResource>(
    `${EXIT_PROCEDURE_TEMPLATES_URL}`,
    exitProcedureTemplate,
  );

  return data;
}