import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { CatalogResource, RoleResource } from 'shared/types';
import styled from 'styled-components';

type Props = {
  roleOptions: CatalogResource[];
  userRoles: RoleResource[];
  setUserRoles: any;
};

function not(a: CatalogResource[], b: CatalogResource[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: CatalogResource[], b: CatalogResource[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferList: React.FC<Props> = (props) => {
  const { roleOptions, userRoles, setUserRoles } = props;

  const [checked, setChecked] = useState<CatalogResource[]>([]);
  const [left, setLeft] = useState<CatalogResource[]>(roleOptions);
  const [right, setRight] = useState<CatalogResource[]>(userRoles.filter(role => role.isPublic));

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: CatalogResource) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    setUserRoles(right.concat(left));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setUserRoles(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setUserRoles(not(right, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    setUserRoles([]);
  };

  const customList = (items: CatalogResource[]) => (
    <PaperContainer>
      <List dense component="div" role="list">
        {items.map((value: CatalogResource) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value.name} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </PaperContainer>
  );

  return (
    <Container>
      <GridContainer container spacing={2} justify="center" alignItems="center">
        <Grid item>{customList(left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <ButtonContainer
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </ButtonContainer>
            <ButtonContainer
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </ButtonContainer>
            <ButtonContainer
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </ButtonContainer>
            <ButtonContainer
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </ButtonContainer>
          </Grid>
        </Grid>
        <Grid item>{customList(right)}</Grid>
      </GridContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const PaperContainer = styled(Paper)`
  width: 200px;
  height: 230px;
  overflow: auto;
  background-color: white;
`;

const GridContainer = styled(Grid)`
  margin: auto;
  justify-content: left;
`;

const ButtonContainer = styled(Button)`
  margin: 0.5;
`;

export default TransferList;
