import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import {
  CareerDevelopmentPlanFilter,
  CareerDevelopmentPlanResource,
  getAllCareerDevelopmentPlans,
} from 'services/careerDevelopmentPlanService';
import { getCdpStatus } from 'services/cdpStatusService';
import { getAllLeaders } from 'services/employeeService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [cdpStatuses, setCdpStatuses] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newStatuses = await getCdpStatus();
      const newLeaders = await getAllLeaders();

      setLeaders(collectionEmployeeToItem(newLeaders));
      setCdpStatuses(collectionToItem(newStatuses));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, leaders, cdpStatuses };
};

export const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

export const useCareerDevelopmentPlanEffect = (filter: Query & CareerDevelopmentPlanFilter) => {
  const [careerDevelopmentPlans, setCareerDevelopmentPlans] = useState<PageResource<CareerDevelopmentPlanResource>>({
    totalItems: 0,
    items: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchCareerDevelopmentPlans = useCallback(async () => {
    setLoading(true);

    const data = await getAllCareerDevelopmentPlans(filter);

    setLoading(false);
    setCareerDevelopmentPlans(data);
  }, [filter]);

  useEffect(() => {
    fetchCareerDevelopmentPlans();
  }, [fetchCareerDevelopmentPlans, filter]);

  return { careerDevelopmentPlans, loading, setLoading, fetchCareerDevelopmentPlans };
};

export const useDisplayModalEffect = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return { showModal, setShowModal };
};

export const useSeletedEmployeeOneOnOneEffect = (seletedEmployee: string) => {
  const [selectedEmployee, setSelectedEmployee] = useState('');

  useEffect(() => {
    setSelectedEmployee(seletedEmployee);
  }, [seletedEmployee]);

  return { selectedEmployee, setSelectedEmployee };
};
