import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

import { Column } from '../types';
import TableHeaderRow from './table-header-cell';

type Props = {
  isSortAscending?: boolean;
  sortByColumn?: string;
  onSort?(columnKey: string): any;
  columns: Column[];
};

const TableHeader: React.FC<Props> = (props) => {
  return (
    <TableHead>
      <TableRow>
        {props.columns.map((c) => (
          <TableHeaderRow
            data-testid="column-test-id"
            isSortAscending={props.isSortAscending}
            key={c.path}
            label={c.label}
            onSort={props.onSort}
            sortKey={c.sortKey}
            sortByColumn={props.sortByColumn}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
