import {
  isPermissionGranted,
  ROLE_ADMIN,
  ROLE_DEVMANAGER,
  ROLE_FINANCE,
  ROLE_HR,
  ROLE_IT,
  ROLE_LEADER,
  ROLE_REWARDS,
} from 'helpers/roleHelper';
import { handleLogout, UserResource } from 'services/authService';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

export type RouteItem = {
  to: string;
  icon: string;
  name: string;
  onClick?(): void;
  iconStyle?: any;
  enabled?: boolean;
  level: number;
  parent?: string;
  visibility: boolean;
  children?: boolean;
};

export const privateRoutes = (user: UserResource): RouteItem[] => {
  const routes = [
    {
      to: '/employee-jobtitles',
      icon: 'work',
      name: 'Employee Summary',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_FINANCE, ROLE_DEVMANAGER, ROLE_IT], user),
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'people',
      name: 'One on Ones',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER], user),
      onClick: clearLocalStorage,
      children: true,
    },
    {
      to: '/one-on-ones',
      icon: 'person',
      name: 'One on Ones Process',
      parent: 'One on Ones',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER], user),
      onClick: clearLocalStorage,
    },
    {
      to: '/action-items',
      icon: 'notifications',
      name: 'Action Items',
      parent: 'One on Ones',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER], user),
      onClick: clearLocalStorage,
    },
    {
      to: '/one-on-one-template',
      icon: 'bookmarks',
      name: 'Template',
      parent: 'One on Ones',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      onClick: clearLocalStorage,
    },
    {
      to: '/one-on-one-reports',
      icon: 'reorder',
      name: 'Reports',
      parent: 'One on Ones',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_DEVMANAGER], user),
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'folder',
      name: 'CDP',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_LEADER, ROLE_HR, ROLE_DEVMANAGER], user),
      children: true,
    },
    {
      to: '/career-development-plan',
      icon: 'assistant',
      name: 'CDP Process',
      parent: 'CDP',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_LEADER, ROLE_HR, ROLE_DEVMANAGER], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/development-plan-templates',
      icon: 'bookmarks',
      name: 'Templates',
      parent: 'CDP',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/rewards',
      icon: 'payments',
      name: 'Rewards',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_FINANCE, ROLE_REWARDS], user),
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'people',
      name: 'HR',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR], user),
      children: true,
    },
    {
      to: '/insurances',
      icon: 'noteAdd',
      name: 'Insurance Summary',
      parent: 'HR',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/referrals',
      icon: 'group_add',
      name: 'Referrals',
      parent: 'HR',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_FINANCE], user),
      onClick: clearLocalStorage,
    },
    {
      to: '/birthdays',
      icon: 'cake',
      name: 'Birthdays',
      parent: 'HR',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/anniversaries',
      icon: 'update',
      name: 'Anniversaries',
      parent: 'HR',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'grid_view',
      name: 'Onboarding',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_IT], user),
      onClick: clearLocalStorage,
      children: true,
    },
    {
      to: '/onboarding-process-screen',
      icon: 'space_dashboard',
      name: 'Onboarding Process',
      parent: 'Onboarding',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_IT], user),
      onClick: clearLocalStorage,
    },
    {
      to: '/onboarding-process-templates',
      icon: 'bookmarks',
      name: 'Templates',
      parent: 'Onboarding',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR], user),
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'event_busy',
      name: 'Exit Procedure',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_IT], user),
      onClick: clearLocalStorage,
      children: true,
    },
    {
      to: '/exit-procedure-process',
      icon: 'running_with_errors',
      name: 'Exit Procedure Process',
      parent: 'Exit Procedure',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_IT], user),
      onClick: clearLocalStorage,
    },
    {
      to: '/exit-procedure-templates',
      icon: 'bookmarks',
      name: 'Templates',
      parent: 'Exit Procedure',
      level: 1,
      enabled: false,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR], user),
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'timer',
      name: 'Timesheets',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_FINANCE, ROLE_HR, ROLE_DEVMANAGER, ROLE_LEADER], user),
      children: true,
    },
    {
      to: '/timesheets-summary',
      icon: 'list',
      name: 'Summary',
      parent: 'Timesheets',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_FINANCE, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/timesheet-management',
      icon: 'create',
      name: 'Management',
      parent: 'Timesheets',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_FINANCE, ROLE_HR], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'tune',
      name: 'Parameters',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_FINANCE, ROLE_DEVMANAGER], user),
      children: true,
    },
    {
      to: '/employees',
      icon: 'person',
      name: 'Employees',
      parent: 'Parameters',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_FINANCE], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    // {
    //   to: '/authorized-people',
    //   icon: 'howToReg',
    //   name: 'Authorized People',
    //   parent: 'Parameters',
    //   level: 1,
    //   visibility: isPermissionGranted([ROLE_ADMIN, ROLE_DEVMANAGER], user),
    //   enabled: false,
    //   onClick: clearLocalStorage,
    // },
    {
      to: '/process-assignment',
      icon: 'assignment',
      name: 'Process Assignment',
      parent: 'Parameters',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_DEVMANAGER], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/projects',
      icon: 'folder',
      name: 'Projects',
      parent: 'Parameters',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_FINANCE], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/jobtitles',
      icon: 'work',
      name: 'Job Titles',
      parent: 'Parameters',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'security',
      name: 'Security',
      level: 0,
      enabled: true,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      children: true,
    },
    {
      to: '/users',
      icon: 'business',
      name: 'Users / Roles',
      parent: 'Security',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '',
      icon: 'flag',
      name: 'HitGoals',
      level: 0,
      enabled: true,
      // visibility: isPermissionGranted([ROLE_ADMIN, ROLE_DEVMANAGER, ROLE_LEADER], user), //TODO: enable this line when modle is done
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      children: true,
    },
    {
      to: '/hitGoals-process',
      icon: 'sports_score',
      name: 'HitGoals Process In Process',
      parent: 'HitGoals',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN, ROLE_DEVMANAGER, ROLE_LEADER], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/categories',
      icon: 'dashboard_customize',
      name: 'Categories',
      parent: 'HitGoals',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/reason',
      icon: 'ballot',
      name: 'Reason',
      parent: 'HitGoals',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/key-result-types',
      icon: 'label',
      name: 'Types',
      parent: 'HitGoals',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
    {
      to: '/hitGoal-templates',
      icon: 'bookmarks',
      name: 'Templates',
      parent: 'HitGoals',
      level: 1,
      visibility: isPermissionGranted([ROLE_ADMIN], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
  ];

  return routes;
};

export const logoutRoutes: RouteItem[] = [
  {
    to: '/login',
    icon: 'login',
    name: 'Logout',
    onClick: handleLogout,
    level: 0,
    visibility: true,
    enabled: true,
    iconStyle: { transform: 'rotate(180deg)' },
  },
];

export const loginRoutes: RouteItem[] = [
  { to: '/login', icon: 'login', name: 'Login', level: 0, visibility: true, enabled: true },
];

export const privateRoutesNavBar: RouteItem[] = [
  {
    to: '/login',
    icon: 'login',
    name: 'Logout',
    onClick: handleLogout,
    iconStyle: { transform: 'rotate(180deg)' },
    level: 0,
    visibility: true,
    enabled: true,
  },
];
