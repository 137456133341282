/* eslint-disable react-hooks/rules-of-hooks */
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import { EmployeeResourceCompact } from 'services/employeeService';
import styled from 'styled-components';

type Props = {
  showModal: boolean;
  setShowModal: any;
  employeeList: EmployeeResourceCompact[];
};

const EmployeeListModal: React.FC<Props> = (props) => {
  const { showModal, setShowModal, employeeList } = props;

  if (!employeeList || !showModal) return <div></div>;

  const handleClose = () => {
    setShowModal(false);
  };


  return (
    <div>
      <ModalContainer
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <ContentContainer>
            <Title>{'Employee list'}</Title>
            
            <Wrapper>
              <TableDiv>
                <CategoryContainer>
                  <thead>
                    <CategoryHeader>Name</CategoryHeader>
                  </thead>
                  <tbody>
                    {employeeList?.map((employee) => (
                      <tr>
                        <CategoryCell>{employee.name + ' ' + employee.lastName}</CategoryCell>
                      </tr>
                    ))}
                  </tbody>
                </CategoryContainer>
              </TableDiv>
            </Wrapper>
          </ContentContainer>
        </Fade>
      </ModalContainer>
    </div>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  background-color: ${(p) => p.theme.palette.background.paper};
  box-shadow: ${(p) => p.theme.shadows[5]};
  align-items: center;
  justify-content: center;
  min-width: 450px;
  min-height: 540px;
  text-align: center;
`;

const CategoryContainer = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
`;
const CategoryHeader = styled.th`
  border-bottom: 1px solid #e0e0e0;
`;
const CategoryCell = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  width: 500px;
  max-width: 500px;
  min-width: 150px;
`;

const Wrapper = styled.div`
  overflow: auto;
  padding: 10px;
  max-height: 800px;
`;
const TableDiv = styled.div`
  padding-bottom: 10px;
`;

const Title = styled.div`
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 20px;
`;

export default EmployeeListModal;
