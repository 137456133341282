import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResourceTitle } from 'shared/types';

import { EmployeeResource } from './employeeService';
import http from './httpService';

const OP_URL = '/api/screenOnboardingProcess';

export type ScreenOnboardingProcessFilter = {
  search: string;
  status: string;
};

export type ScreenOnboardingProcessResource = {
  _id: string;
  employee: EmployeeResource;
  creationDate: string;
  template: CatalogResourceTitle;
  descriptions: Description[];
  isCompleted: string;
};
export type Description = {
  _id: string;
  description: string;
  position: number;
  opStatus: string;
};
export type SaveDescription = {
  description: string;
  position: number;
  opStatus: string;
};

export type CreateScreenOnboardingProcessResource = {
  employee: string;
  template: string;
};

export type SaveScreenOnboardingProcessResource = {
  descriptions: SaveDescription[];
};

export async function getAllScreenOnboardingProcess(filter: ScreenOnboardingProcessFilter & Query) {
  const { data } = await http.get<PageResource<ScreenOnboardingProcessResource>>(
    OP_URL + '/' + http.toQueryString(filter),
  );

  return data;
}

export async function getOldOpsById(query: Query, id: string) {
  const { data } = await http.get<PageResource<ScreenOnboardingProcessResource>>(
    `${OP_URL}/${id}/ops` + http.toQueryString(query),
  );

  return data;
}

export async function getOpById(id: string) {
  const { data } = await http.get<ScreenOnboardingProcessResource>(`${OP_URL}/${id}`);

  return data;
}

export async function updateOp(op: SaveScreenOnboardingProcessResource, id: string) {
  const { data } = await http.put<SaveScreenOnboardingProcessResource>(`${OP_URL}/${id}`, op);

  return data;
}

export async function createOp(op: CreateScreenOnboardingProcessResource) {
  const { data } = await http.post<ScreenOnboardingProcessResource>(`${OP_URL}`, op);

  return data;
}

export async function deleteScreenOnboardingProcess(id: string) {
  const { data } = await http.delete<ScreenOnboardingProcessResource>(`${OP_URL}/${id}`);

  return data;
}

export async function getPreviousOpById(id: string | undefined) {
  if (!id) return;
  const { data } = await http.get<ScreenOnboardingProcessResource>(`${OP_URL}/${id}/previousOp`);

  return data;
}
