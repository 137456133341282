import DateInputPicker from 'components/date-input-picker';
import MultiSelectBase from 'components/multi-select';
import SingleSelectBase from 'components/single-select';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px;
`;

export const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
`;

export const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

export const DateInputContainer = styled(DateInputPicker)`
  max-width: 175px;
  margin-right: 10px;
`;

export const LabelContainer = styled.div`
  display: inline-flex;
`;