import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { UserResource } from 'services/authService';
import { deleteUser } from 'services/usersService';
import { RoleResource } from 'shared/types';

const fixedColumns: Column<UserResource>[] = [
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
    render: (item) => `${item.name} ${item.lastName}`,
  },
  {
    label: 'Roles',
    sortKey: 'roles',
    path: 'roles',
    render: (item) => renderRoles(item.roles),
  },
];

const renderRoles = (roles: RoleResource[]) => {
  const rolesArray = roles.filter((role) => role.isPublic).map((x) => x.name);

  return rolesArray.sort().join(', ');
};

const variableColumns = (fetchUsers: any, setLoading: any, editAndDeleteEnabled: boolean) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable = {editAndDeleteEnabled}
        deleteEnable = {editAndDeleteEnabled}
        fetchItems={fetchUsers}
        deleteItem={deleteUser}
        setLoading={setLoading}
        url={'users'}
        name={'user'}
        deleteTitle={'Delete User'}
        deleteMessage={'Do you want to delete this user?'}
      />
    ),
  };
};

const columns = (fetchUsers: any, setLoading: any, editAndDeleteEnabled: boolean) => {
  return [...fixedColumns, variableColumns(fetchUsers, setLoading, editAndDeleteEnabled)];
};

export default columns;
