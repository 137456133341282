import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteRate, RateResource } from 'services/salaryService';
import { decryptText } from 'utils/encryptionMethods/encryption';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<RateResource>[] = [
  {
    label: 'Effective Date',
    sortKey: 'effectiveDate',
    path: 'effectiveDate',
    render: (item) => {
      const formatedDate = item.effectiveDate ? stringDateToHumanFormat(item.effectiveDate) : '';

      return formatedDate;
    },
  },
  {
    label: 'Rate',
    sortKey: 'rate',
    path: 'rate',
    render: (item) => {
      const decryptedRate = item.rate ? parseFloat(decryptText(item.rate).replace('$', '').replace(',', '')) : '-';

      return `$${decryptedRate.toLocaleString()}`;
    },
  },
  {
    label: 'Increase',
    sortKey: 'increase',
    path: 'increase',
    render: (item) => {
      const decryptedIncrease =
        item.increase && decryptText(item.increase).replace('$', '').replace(',', '')
          ? `$${parseFloat(decryptText(item.increase).replace('$', '').replaceAll(',', '')).toLocaleString()}`
          : '-';

      return decryptedIncrease;
    },
  },
  {
    label: 'User',
    sortKey: 'user',
    path: 'user',
    render: (item) => {
      return item.updatedBy?.name || item.createdBy?.name;
    },
  },
  {
    label: 'Comments',
    sortKey: 'comments',
    path: 'comments',
    render: (item) => {
      const commentsText = item.comments ? item.comments : '-';

      return commentsText;
    },
  },
];

const variableColumns = (fetchRates: any, setLoading: any, employeeId: string) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchRates}
        deleteItem={deleteRate}
        setLoading={setLoading}
        url={`employees/${employeeId}/rates`}
        name={'Rate'}
        deleteTitle={'Delete Rate'}
        deleteMessage={'Do you want to delete this rate?'}
      />
    ),
  };
};

const columns = (fetchRates: any, setLoading: any, employeeId: string) => {
  return [...fixedColumns, variableColumns(fetchRates, setLoading, employeeId)];
};

export default columns;
