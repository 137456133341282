import { KeyResultForHitGoalResource } from 'services/hitgoalProcessService';
import { HitgoalTemplateResource } from 'services/hitgoalsService';
import { HITGOAL_STATUS } from 'shared/types';

export const createTemplateCopy = (selectedTemplate: HitgoalTemplateResource) => {
  const hitGoalKetResults: KeyResultForHitGoalResource[] = [];
  const defaultHitGoalResource = {
    _id: '',
    objective: selectedTemplate!.objective,
    employee: '',
    reasonID: '',
    type: '',
    comment: '',
    createdBy: '',
    deadLine: new Date().toString(),
    createdAt: new Date().toISOString(),
    keyResults: hitGoalKetResults,
    category: selectedTemplate.category?._id,
  };

  if (selectedTemplate && selectedTemplate?.keyResults) {
    selectedTemplate?.keyResults.map((keyResult) =>
      hitGoalKetResults.push({ ...keyResult, status: HITGOAL_STATUS.INCOMPLETE }),
    );
  }

  return defaultHitGoalResource;
};
