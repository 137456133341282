import http from './httpService';
import { StatusService } from './rewardsService';

const REWARDS_PAYMENT_REQUEST_URL = 'api/rewardsPaymentRequest';

export type RewardPaymentRequest = {
  _id?: string;
  paymentStatusID: string;
  paymentDate: any;
  requestComment?: string;
  rewStatusID?: StatusService;
};

export type PaymentStatus = {
  _id?: string;
  statusName: string;
};

export async function getRewardPaymentById(id: string) {
  const { data } = await http.get<RewardPaymentRequest>(`${REWARDS_PAYMENT_REQUEST_URL}/${id}/paymentRequest`);

  return data;
}

export async function getPaymentStatus() {
  const { data } = await http.get<PaymentStatus[]>(`${REWARDS_PAYMENT_REQUEST_URL}/statuses`);

  return data;
}

export async function updateRewardPayment(paymentData: any, id: string) {
  const { data } = await http.put<RewardPaymentRequest>(
    `${REWARDS_PAYMENT_REQUEST_URL}/${id}/updatePaymentRequest`,
    paymentData,
  );

  return data;
}
