import BodyTitle from 'components/bodyTitle';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import Container from 'components/container';
import MultiSelect from 'components/multi-select';
import SingleSelect from 'components/single-select';
import { LoadingContainer, Spinner } from 'pages/hitgoal-process/hitgoal/hitgoal-form/styles';
import React from 'react';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import { useAuthorizedPeopleEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

const AuthorizedPeopleList: React.FC = () => {
  const { loading, employees, leaders, authorizedEmployeesInput, useInput } = useAuthorizedPeopleEffect();

  return (
    <Container>
      <Card>
        <CardHeader title="Authorized People" loading={loading} />
        <CardBody>
          <BodyTitle text={'Set authorized people'} />
          {loading && (
            <LoadingContainer>
              <Spinner />
            </LoadingContainer>
          )}
          <div style={{ display: loading ? 'none' : 'block' }}>
            <SingleSelect
              id="leaders"
              name="leaders"
              label="Employees"
              options={leaders}
              rigthSpacing
              {...useInput('leader')}
            />

            <MultiSelect
              id="employees"
              name="employees"
              label="Authorized People"
              options={employees}
              fullWidth
              customWidth="300px"
              {...authorizedEmployeesInput}
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AuthorizedPeopleList;
