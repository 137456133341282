import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import SingleSelectBase from 'components/single-select';
import { Query } from 'hooks/usePaging';
import { countryValues } from 'pages/hr/commonValues';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Item } from 'shared/types';
import styled from 'styled-components';

import { InsuranceFilter } from '..';

type Props = {
  search: string;
  insuranceStatuses: Item[];
  filter: InsuranceFilter & Query;
  setFilter(value: InsuranceFilter & Query): void;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const Filter: React.FC<Props> = (props) => {
  const { filter, insuranceStatuses, setFilter } = props;
  const [search, setSearch] = useState('');
  const [insuranceStatus, setInsuranceStatus] = useState('');
  const [country, setCountry] = useState('');

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(
      () => setFilter({ ...filter, search: search, insuranceStatus: insuranceStatus, country: country }),
      2000,
    );

    return () => clearTimeout(timeToClearRef.current);
  }, [search]);

  useEffect(() => {
    setFilter({ ...filter, search: search, insuranceStatus: insuranceStatus, country: country });

  }, [insuranceStatus, country]);

  const handleOnTextChange = useCallback((event: OnChangeEvent) => {
    const { value } = event.target;

    setSearch(value as string);
  }, []);

  const handleOnChange = useCallback((event: OnChangeEvent) => {
    const { value } = event.target;

    setInsuranceStatus(value as string);
  }, []);

  const handleOnCountryChange = useCallback((event: OnChangeEvent) => {
    const { value } = event.target;

    setCountry(value as string);
  }, []);

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByName'}
        inputProps={{
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label="Search by name"
        variant="outlined"
        name="search"
        onChange={handleOnTextChange}
      />
      <SingleSelect
        id={'insuranceStatusSelect'}
        name="insuranceStatus"
        options={insuranceStatuses}
        label={'Status'}
        onChange={handleOnChange}
        value={insuranceStatus}
      />

      <SingleSelect
        id={'countrySelect'}
        name="country"
        options={countryValues}
        label={'Country'}
        onChange={handleOnCountryChange}
        value={country}
      />
    </Container>
  );
};

const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: inline-block;
  flex: 1;
  padding: 15px 0;
`;

const TextFieldWrapper = styled(TextField)`
  margin-right: 5px;
`;

export default Filter;
