import * as yup from 'yup';

export type AuthorizedPeopleResource = {
  leader: string;
  authorizedEmployees: string[];
};

export const schemaAuthorizedPeopleResource = {
  leader: yup.string().required().label('Leader'),
  authorizedEmployees: yup.array(yup.string().optional()),
};

export type SearchFilter = {
  searchText: string;
};

export type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: string;
}>;
