import { Hidden } from '@material-ui/core';
import DrawerBase from '@material-ui/core/Drawer';
import FusionHitIcon from 'assets/icon.png';
import React from 'react';
import hexToRGB from 'shared/style/utilities/hexToRGB';
import styled from 'styled-components';

import NavItem from './nav-item';
import useSideBar from './sideBar.hooks';

export type RouteItem = {
  to: string;
  icon: string;
  name: string;
  onClick?(): void;
  iconStyle?: any;
};

type Props = {
  logoText: string;
  mobileOpen: boolean;
  setMobileOpen(value: boolean): void;
  drawerWidth: number;
};

const Sidebar: React.FC<Props> = (props) => {
  const { logoText, mobileOpen, setMobileOpen, drawerWidth } = props;
  const { routes, setRoutes } = useSideBar();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <BrandContainer drawerWidth={drawerWidth}>
        <BrandLink>
          <ImageContainer>
            <Image src={FusionHitIcon} alt="logo" />
          </ImageContainer>
          {logoText}
        </BrandLink>
      </BrandContainer>
      <RoutesContainer drawerWidth={drawerWidth}>
        {routes.map((route, index) => (
          <NavItem {...route} routes={routes} setRoutes={setRoutes} key={index} />
        ))}
      </RoutesContainer>
      <BackgroundContainer />
    </div>
  );

  return (
    <nav>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer variant="permanent" open>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

const RoutesContainer = styled.div<{ drawerWidth: number }>`
  position: relative;
  overflow: auto;
  width: ${(p) => p.drawerWidth}px;
  z-index: 4;
`;

const BrandContainer = styled.div<{ drawerWidth: number }>`
  position: relative;
  padding: 15px 15px;
  z-index: 4;
  display: flex;
  width: ${(p) => p.drawerWidth}px;

  ::after {
    right: 15px;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    background-color: ${(p) => hexToRGB(p.theme.palette.divider, '0.3')};
  }
`;

const ImageContainer = styled.div`
  width: 30px;
  display: inline-block;
  max-height: 30px;
  margin-left: 10px;
  margin-right: 15px;
`;

const Image = styled.img`
  width: 35px;
  top: 18px;
  position: absolute;
  vertical-align: middle;
  border: 0;
`;

const Drawer = styled(DrawerBase)`
  border: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  &:after {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    content: '';
    display: flex;
    background: ${(p) => p.theme.palette.common.black};
    opacity: 0.8;
  }
`;

const BrandLink = styled.a`
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  padding: 5px 0;
  display: block;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
  background-color: transparent;
  color: ${(p) => p.theme.palette.common.white};
`;

export default Sidebar;
