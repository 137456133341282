import { Box, TableCell as TableCellBase, TableSortLabel } from '@material-ui/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';

type Props = {
  onSort?(columnKey: string): any;
  label?: string;
  isSortAscending?: boolean;
  sortByColumn?: string;
  sortKey?: string;
  sortingDisabled?: boolean;
};

const TableHeaderCell: React.FC<Props> = (props) => {
  const { sortKey, sortByColumn, isSortAscending, label, onSort, sortingDisabled, ...rest } = props;

  const isSameColumn = Boolean(sortByColumn && sortKey && sortByColumn === sortKey);
  const sortDirection = isSortAscending ? 'asc' : 'desc';
  const direction = isSameColumn ? sortDirection : undefined;

  const handleOnClick = useCallback(() => onSort && onSort(sortKey || ''), [onSort, sortKey]);

  return (
    <TableCell sortDirection={onSort && sortDirection} {...rest}>
      {isDefined(sortKey) && (
        <TableSortLabel active={isSameColumn} direction={direction} onClick={handleOnClick} hideSortIcon={!onSort}>
          {label}
        </TableSortLabel>
      )}
      {!isDefined(sortKey) && <Box color={'black'} paddingRight={5}>{label}</Box>}
    </TableCell>
  );
};

const isDefined = (value: any) => value !== null && value !== undefined;

const TableCell = styled(TableCellBase)`
  color: ${(p) => p.theme.palette.primary.main};
`;

export default TableHeaderCell;
