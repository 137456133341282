import { PageResource, Query } from 'hooks/usePaging';
import { GenericNameValueTupleResource } from 'shared/types';

import { UserResource } from './authService';
import http from './httpService';
import { JobTitleResource } from './jobTitleService';

const SALARIES_URL = '/api/salaries';

export type SalaryResource = {
  _id: string;
  employee: string;
  salary: string;
  increase: string;
  jobTitle: JobTitleResource;
  increaseType: GenericNameValueTupleResource;
  comments: string;
  date: string;
  createdBy?: UserResource | null;
  updatedBy?: UserResource | null;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
};

export type SaveSalaryResource = {
  employee: string | undefined;
  salary: string;
  increase: string | undefined;
  jobTitle: string;
  increaseType: string | undefined;
  comments: string;
  date: string | null;
};

export type RateResource = {
  _id: string;
  employee: string;
  rate: string;
  increase: string;
  comments: string;
  effectiveDate: string;
  createdBy?: UserResource | null;
  updatedBy?: UserResource | null;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
};

export type SaveRateResource = {
  employee: string | undefined;
  rate: string;
  increase: string | undefined;
  comments: string;
  effectiveDate: string | null;
};

export async function getSalariesByEmployeeId(id: string, query: Query) {
  const { data } = await http.get<PageResource<SalaryResource>>(
    `${SALARIES_URL}/` + http.toQueryString({ ...query, employeeId: id }),
  );

  return data;
}

export async function getRatesByEmployeeId(id: string, query: Query) {
  const { data } = await http.get<PageResource<RateResource>>(
    `${SALARIES_URL}/rates` + http.toQueryString({ ...query, employeeId: id }),
  );

  return data;
}

export async function saveSalary(salary: SaveSalaryResource) {
  const { data } = await http.post<SalaryResource>(`${SALARIES_URL}`, salary);

  return data;
}

export async function saveRate(rate: SaveRateResource) {
  const { data } = await http.post<RateResource>(`${SALARIES_URL}/rate`, rate);

  return data;
}

export async function getLastSalaryByEmployeeId(id: string) {
  const { data } = await http.get<SalaryResource>(`${SALARIES_URL}/lastSalary/${id}`);

  return data;
}

export async function getLastRateByEmployeeId(id: string) {
  const { data } = await http.get<RateResource>(`${SALARIES_URL}/lastRate/${id}`);

  return data;
}

export async function deleteSalary(id: string) {
  const { data } = await http.delete<SalaryResource>(`${SALARIES_URL}/${id}`);

  return data;
}

export async function deleteRate(id: string) {
  const { data } = await http.delete<RateResource>(`${SALARIES_URL}/rate/${id}`);

  return data;
}

export async function getSalaryById(id: string) {
  const { data } = await http.get<SalaryResource>(`${SALARIES_URL}/single/${id}`);

  return data;
}

export async function getRateById(id: string) {
  const { data } = await http.get<RateResource>(`${SALARIES_URL}/single/rate/${id}`);

  return data;
}

export async function updateSalary(salary: SaveSalaryResource, id: string) {
  const { data } = await http.put<SalaryResource>(`${SALARIES_URL}/${id}`, salary);

  return data;
}

export async function updateRate(rate: SaveRateResource, id: string) {
  const { data } = await http.put<RateResource>(`${SALARIES_URL}/rate/${id}`, rate);

  return data;
}
