import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import SingleSelect from 'components/single-select';
import { useForm } from 'hooks/useForm';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createOp } from 'services/screenOnboardingProcessService';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCatalogs, useFetchOpEffect } from './hooks';

const schema = {
  employee: yup.string().required().label('Employee'),
  template: yup.string().required().label('Template'),
};

const ScreenOnboardingProcessCreationForm: React.FC = () => {
  const { op } = useFetchOpEffect();
  const { templates, employees, loading } = useCatalogs();
  const { values } = useForm(op, schema as any, loading);
  const { isSubmiting, handleCreateOp } = useSubmitForm(values, true);

  const [employeeSelected, setEmployeeSelected] = useState('');
  const [templateSelected, setTemplateSelected] = useState('');
  let [isValid, setIsValid] = React.useState(false);

  const handleTemplateChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    values.template = (event.target.value as string) || '';
    setTemplateSelected(event.target.value as string);

    if (values.employee && values.template) setIsValid(true);
    else setIsValid(false);
  };

  const handleChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'employee') {
      values.employee = (event.target.value as string) || '';
      setEmployeeSelected(event.target.value as string);
    }

    if (values.employee && values.template) setIsValid(true);
    else setIsValid(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader loading={loading} title={'New Onboarding Process'} />
        <CardBody>
          <StatusContainer>
            <BodyTitle>Onboarding Process Information</BodyTitle>
            <DetailsLine>
              <OptionsContainer>
                <SelectContainer>
                  <SingleSelectContainer
                    id={'employeeSelect'}
                    name={'employee'}
                    label={'Employee'}
                    options={employees}
                    onChange={handleChange}
                    value={employeeSelected}
                  />
                </SelectContainer>

                <SelectContainer>
                  <SingleSelectContainer
                    id={'template'}
                    name={'template'}
                    label={'Template'}
                    options={templates}
                    onChange={handleTemplateChange}
                    value={templateSelected}
                  ></SingleSelectContainer>
                </SelectContainer>
              </OptionsContainer>
            </DetailsLine>
          </StatusContainer>

          <ButtonContainer>
            {isSubmiting && <Spinner size={30} />}
            <Submit disabled={isSubmiting || loading || !isValid} onClick={handleCreateOp}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

const useSubmitForm = (values: any, isValid: boolean) => {
  const history = useHistory();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const handleCreateOp = useCallback(async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    setIsSubmitting(true);

    try {
      const newOp = await createOp(values);

      if (newOp) {
        const isCreating = '' + newOp._id + ',create';

        notifier.success('Onboarding process created successfully.');
        history.push(`/onboarding-process-screen/${isCreating}`);
      }
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    }

    setIsSubmitting(false);
  }, [isValid, values, history]);

  return { isSubmiting, handleCreateOp };
};

const Container = styled.div`
  margin: 0 15px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
`;

const SelectContainer = styled.div`
  min-width: 160px;
  padding: 0 5px;
  width: 300px;
  float: left;
`;

const OptionsContainer = styled.div`
  float: left;
  display: block;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const SingleSelectContainer = styled(SingleSelect)`
  width: 290px;
  margin-bottom: 10px;
`;

const StatusContainer = styled.div``;

export default ScreenOnboardingProcessCreationForm;
