import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import React, { ComponentProps } from 'react';
import styled from 'styled-components';

type Props = {
  id: string;
  rowsMax: number;
  defaultValue?: string;
  value?: string;
  name?: string;
  backgroundColor: string;
  onBlur: any;
  onChange?: any;
} & ComponentProps<typeof TextareaAutosize>;

const TextAreaAutoResize: React.FC<Props> = (props) => {
  const { id, rowsMax, defaultValue, value, name, onBlur, onChange, backgroundColor, ...rest } = props;

  return (
    <TextAutoResize
      id={id}
      rowsMax={rowsMax}
      defaultValue={defaultValue}
      onBlur={onBlur}
      value={value}
      name={name}
      onChange={onChange}
      backgroundColor={backgroundColor}
      {...rest}
    />
  );
};

const TextAutoResize = styled(TextareaAutosize)<{ backgroundColor: string }>`
  font-size: 12;
  width: 100%;
  min-height: 41px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 300;
  line-height: 1.4em;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  resize: none;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'none')};
`;

export default TextAreaAutoResize;
