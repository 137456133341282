import { FormControl, MenuItem, TextField } from '@material-ui/core';
import React, { ComponentProps } from 'react';
import { Item } from 'shared/types';

type Props = {
  empty?: boolean;
  options: Item[];
  label: string;
  disabledId?: string;
  fullWidthEnabled?: boolean;
} & ComponentProps<typeof TextField>;

const SingleSelectNoWidth: React.FC<Props> = (props) => {
  const { empty = true, options, label, fullWidthEnabled = false, disabledId = '', ...rest } = props;

  return (
    <FormControl variant="outlined" fullWidth={fullWidthEnabled}>
      <TextField variant="outlined" select label={label} {...rest} inputProps={{ 'data-testid': 'single-select-test' }}>
        {empty && <MenuItem value={''}>Select a value</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id} disabled={!!disabledId && disabledId === option.id}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SingleSelectNoWidth;
