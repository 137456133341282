import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getCategories } from 'services/categoriesService';
import { getAllEmployees } from 'services/employeeService';
import {
  getAllHitgoalProcessStatus,
  getAllHitgoalsProcess,
  getProcessAssignedLeaders,
  HitgoalProcessFilter,
  HitgoalProcessResource,
} from 'services/hitgoalProcessService';
import { getKeyResultAreas } from 'services/hitgoalsService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const [reasons, setReasons] = useState<Item[]>([]);
  const [categories, setCategories] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const employeeList = await getAllEmployees();
      const leaderList = await getProcessAssignedLeaders();
      const statusList = await getAllHitgoalProcessStatus();
      const reasonList = await getKeyResultAreas();
      const categoryList = await getCategories();

      setEmployees(collectionEmployeeToItem(employeeList));
      setLeaders(collectionEmployeeToItem(leaderList));
      setStatuses(collectionToItem(statusList));
      setReasons(collectionToItem(reasonList));
      setCategories(collectionToItem(categoryList));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, employees, leaders, statuses, reasons, categories };
};
export const useHitgoalsProcessEffect = (filter: HitgoalProcessFilter & Query) => {
  const defaultResponse: PageResource<HitgoalProcessResource> = { totalItems: 0, items: [] };
  const [hitgoalsProcess, setHitgoalsProcess] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);

  const fetchHitgoalsProcess = useCallback(async () => {
    setLoading(true);

    const data = await getAllHitgoalsProcess(filter);

    setLoading(false);
    setHitgoalsProcess(data);
  }, [filter]);

  useEffect(() => {
    fetchHitgoalsProcess();
  }, [fetchHitgoalsProcess, filter]);

  return { hitgoalsProcess, loading, setLoading, fetchHitgoalsProcess };
};
