import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';

type Props = {
  page: number;
  totalItems: number;
  pageSize: number;
  onChangePage(newPage: number): any;
};

const TablePaginationFooter: React.FC<Props> = (props) => {
  const { onChangePage, page, totalItems, pageSize } = props;

  const handleChangePage = useCallback((e, page) => onChangePage(page + 1), [onChangePage]);

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          id="tablePaginationId"
          count={totalItems}
          rowsPerPageOptions={[]}
          rowsPerPage={pageSize}
          page={page - 1}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
  );
};

export default TablePaginationFooter;
