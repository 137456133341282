/* eslint-disable react/jsx-no-bind */
import { Container, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import { monthName } from 'pages/hr/commonValues';
import React from 'react';
import { HoursPerMonth } from 'services/timesheetService';
import styled from 'styled-components';
import constants from 'utils/constants';

const fixedColumns: Column<HoursPerMonth>[] = [
  {
    label: 'Year',
    path: 'year',
    sortKey: 'year',
  },
  {
    label: 'Month',
    path: 'month',
    sortKey: 'month',
    render: (item) => `${monthName(item.month)}`,
  },
  {
    label: 'Day',
    path: 'day',
    sortKey: 'day',
  },
  {
    label: 'Description',
    path: 'description',
    sortKey: 'description',
  },
  {
    label: 'Country',
    path: 'country',
    sortKey: 'country',
    render: (item) => {
      return item.country;
    },
  },
];

const variableColumns = (
  fetchHoursPerMonth: any,
  setLoading: any,
  handleEditHoliday: Function,
  editingId: string,
  handleSaveAfterEditHoliday: Function,
  handleDeleteHoliday: any,
) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <ContainerEdit>
        {item._id !== editingId && (
          <Container>
            <IconButton
              onClick={() => {
                handleEditHoliday(item._id);
              }}
              color="primary"
              disabled={!!editingId}
            >
              <EditIcon />
            </IconButton>
          </Container>
        )}
        {item._id === editingId && (
          <Container>
            <IconButton onClick={() => handleSaveAfterEditHoliday(item._id, item)} color="primary">
              <SaveIcon />
            </IconButton>
          </Container>
        )}
        <TableRowAction
          id={item._id}
          deleteEnable
          fetchItems={fetchHoursPerMonth}
          setLoading={setLoading}
          url={'hourspermonth'}
          name={constants.HOLIDAY_DAY_TYPE}
          deleteItem={handleDeleteHoliday}
          deleteTitle={'Delete Holiday'}
          deleteMessage={'Do you want to delete this holiday?'}
        />
      </ContainerEdit>
    ),
  };
};

const columns = (
  fetchHoursPerMonth: any,
  setLoading: any,
  handleEditHoliday: Function,
  editingId: string,
  handleSaveAfterEditHoliday: Function,
  handleDeleteHoliday: Function,
) => {
  return [
    ...fixedColumns,
    variableColumns(
      fetchHoursPerMonth,
      setLoading,
      handleEditHoliday,
      editingId,
      handleSaveAfterEditHoliday,
      handleDeleteHoliday,
    ),
  ];
};

const ContainerEdit = styled.div`
  display: flex;
  float: right;
`;

export default columns;
