import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { NumberFormatCustom } from 'components/numberFormatCustom';
import SingleSelectNoWidth from 'components/single-select-no-width';
import TextAreaAutoResize from 'components/textAreaAutoResize';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { TimesheetDayType } from 'services/timesheetService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import constants from 'utils/constants';
import {
  checkIsCurrentDay,
  checkIsWeekend,
  rowbackgroundColor,
  stringDateToDayOfWeekAndNumber,
} from 'utils/formatters/date';

type Props = {
  dayId: string;
  dayDate: Date;
  dayType: TimesheetDayType;
  daySubType: TimesheetDayType | null;
  dayDescription: string;
  dayTimeSpent: number;
  dayTypes: Item[];
  daySubTypes: Item[];
  value?: string;
  name?: string;
  handleOnHourBlur: any;
  handleDescriptonOnChange: any;
  handleOnDayTypeChange: any;
  handleOnDaySubTypeChange: any;
  handleOnDescriptionBlur: any;
  handleOnDayRemoveRow: any;
  handleOnHourChange: any;
  paidHolidays: boolean;
  paidVacations: boolean;
  isDisabled: boolean;
};

const DayRow: React.FC<Props> = (props) => {
  const {
    dayId,
    dayDate,
    dayTypes,
    daySubTypes,
    dayType,
    daySubType,
    dayDescription,
    dayTimeSpent,
    handleOnHourBlur,
    handleDescriptonOnChange,
    handleOnDayTypeChange,
    handleOnDaySubTypeChange,
    handleOnDescriptionBlur,
    handleOnHourChange,
    handleOnDayRemoveRow,
    paidHolidays,
    paidVacations,
    isDisabled,
  } = props;

  const dayTypesForWeekends = dayTypes.filter(
    (dayType) => dayType.label === constants.HOLIDAY_DAY_TYPE || dayType.label === 'Normal',
  );
  const handleOnHourBlurRow = useCallback((e) => handleOnHourBlur(dayId, e.target.value, dayDate), [
    dayDate,
    dayId,
    handleOnHourBlur,
  ]);
  const handleOnDayTypeChangeRow = useCallback((e) => handleOnDayTypeChange(dayId, e.target.value), [
    dayId,
    handleOnDayTypeChange,
  ]);
  const handleOnDaySubTypeChangeRow = useCallback((e) => handleOnDaySubTypeChange(dayId, e.target.value), [
    dayId,
    handleOnDaySubTypeChange,
  ]);
  const handleOnDescriptionBlurRow = useCallback((e) => handleOnDescriptionBlur(dayId, e.target.value), [
    dayId,
    handleOnDescriptionBlur,
  ]);
  const handleOnDayRemove = useCallback((e) => handleOnDayRemoveRow(dayId, e.target.value), [
    dayId,
    handleOnDayRemoveRow,
  ]);
  const [momentDate, setMomentDate] = useState<Moment>(moment.utc());
  const [isWeekend, setWeekend] = useState(false);

  useEffect(() => {
    if (dayDate) {
      setMomentDate(moment.utc(dayDate));
      setWeekend(checkIsWeekend(dayDate.toString()));
    }
  }, [dayDate]);

  return (
    <Row>
      <DateContainer
        id={`txt_day${momentDate.date()}_day`}
        isWeekend={isWeekend}
        isCurrentDay={checkIsCurrentDay(dayDate.toString())}
      >
        {stringDateToDayOfWeekAndNumber(dayDate.toString())}
      </DateContainer>
      <TrackingTypeContainer>
        <SingleSelectShort
          id={`dropdown_day${momentDate.date()}_tracking_type`}
          options={isWeekend ? dayTypesForWeekends : dayTypes}
          InputProps={{
            style: { fontSize: 10, backgroundColor: rowbackgroundColor(dayDate.toString(), dayType.name) },
          }}
          label=""
          fullWidthEnabled={true}
          empty={false}
          value={dayType._id}
          onChange={handleOnDayTypeChangeRow}
          disabled={dayType.name === constants.HALF_DAY_VACATION_DAY_TYPE || isDisabled}
        />
      </TrackingTypeContainer>
      {dayType.name === constants.LICENCE_BENEFIT_DAY_TYPE && !isDisabled && (
        <SubTrackingTypeContainer>
          <SingleSelectShort
            id={`dropdown_day${momentDate.date()}_sub_tracking_type`}
            options={daySubTypes}
            InputProps={{
              style: { fontSize: 10, backgroundColor: rowbackgroundColor(dayDate.toString(), dayType.name) },
            }}
            label={daySubType?._id ? '' : 'Select one'}
            fullWidthEnabled={true}
            empty={false}
            value={daySubType?._id || ''}
            onChange={handleOnDaySubTypeChangeRow}
            required
          />
        </SubTrackingTypeContainer>
      )}
      <DescriptionContainer
        isHalfDay={dayType.name === constants.HALF_DAY_VACATION_DAY_TYPE && !isDisabled}
        isLicenceBenefit={dayType.name === constants.LICENCE_BENEFIT_DAY_TYPE && !isDisabled}
      >
        <TextAreaAutoResize
          id={`text_day${momentDate.date()}_task_description`}
          rowsMax={3}
          value={dayDescription}
          name={dayDate + 'description'}
          onBlur={handleOnDescriptionBlurRow}
          onChange={handleDescriptonOnChange}
          backgroundColor={rowbackgroundColor(dayDate.toString(), dayType.name)}
          disabled={isDisabled}
          style={{ minHeight: '43px' }}
        />
      </DescriptionContainer>

      {dayType.name === constants.HALF_DAY_VACATION_DAY_TYPE && !isDisabled && (
        <IconButton onClick={handleOnDayRemove} style={{ marginLeft: -3, marginTop: -8 }}>
          <RemoveIcon color="error" />
        </IconButton>
      )}
      <HoursContainer>
        <HoursTextField backgroundColor={rowbackgroundColor(dayDate.toString(), dayType.name)}>
          <TextField
            id={`txt_day${momentDate.date()}_time_spent`}
            required
            fullWidth
            inputProps={{
              maxLength: 4,
            }}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            inputMode="numeric"
            name={dayDate + 'hours'}
            variant="outlined"
            value={dayTimeSpent}
            onBlur={handleOnHourBlurRow}
            onChange={handleOnHourChange}
            disabled={isDisabled}
          />
        </HoursTextField>
        <HoursSpan>hrs</HoursSpan>
      </HoursContainer>

      <InfoContainer
        style={{
          visibility:
            (paidHolidays && dayType.name === constants.HOLIDAY_DAY_TYPE) ||
            (paidVacations && dayType.name === constants.VACATION_DAY_TYPE) ||
            (paidVacations && dayType.name === constants.HALF_DAY_VACATION_DAY_TYPE)
              ? 'visible'
              : 'hidden',
        }}
      >
        <Tooltip title={constants.PROJECT_CONFIG_TOOLTIP} arrow>
          <IconButton color="primary" aria-label="day info">
            <Info />
          </IconButton>
        </Tooltip>
      </InfoContainer>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const DateContainer = styled.div<{ isWeekend: boolean; isCurrentDay: boolean }>`
  width: 7%;
  padding: 5px 0px;
  padding-top: 10px;
  margin-right: 5px;
  margin-left: 0px;
  margin-bottom: 8px;
  display: block;
  align-items: center;
  text-align: center;
  color: ${(props) => (props.isWeekend ? '#FFA500' : '#000000')};
  background-color: ${(props) => (props.isCurrentDay ? '#C8E6F1' : 'none')};
  font-weight: ${(props) => (props.isCurrentDay ? 'normal' : '')};
  border-color: red;
  border-radius: 6px;
  border-width: 2px;
`;

const TrackingTypeContainer = styled.div`
  width: 10%;
  display: flex !important;
`;

const SubTrackingTypeContainer = styled.div`
  width: 10%;
  display: flex !important;
  margin: 0 0 0 5px;
`;

const SingleSelectShort = styled(SingleSelectNoWidth)`
  flex-grow: 1;
  display: flex;

  .MuiInputLabel-root {
    font-size: smaller;
  }
`;

const DescriptionContainer = styled.div<{ isHalfDay: boolean; isLicenceBenefit: boolean }>`
  width: ${(props) => (props.isHalfDay ? '71%' : props.isLicenceBenefit ? '64%' : '74%')};
  margin: 0 5px;
  display: block;
`;

const HoursContainer = styled.div`
  width: 6%;
  display: block;
  margin: 0;
`;

const InfoContainer = styled.div`
  width: 3%;
  display: block;
  margin-top: -3px;
  margin-left: -10px;
`;

const HoursTextField = styled.div<{ backgroundColor: string }>`
  width: 52px;
  float: left;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'none')};
`;

const HoursSpan = styled.span`
  padding: 10px 0 10px 5px;
  float: left;
`;

export default React.memo(DayRow, (prev, next) => {
  return (
    prev.dayDescription === next.dayDescription &&
    prev.dayType === next.dayType &&
    prev.dayTimeSpent === next.dayTimeSpent
  );
});
