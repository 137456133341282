import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { UserResource } from 'services/authService';
import { getAllUsersQuery } from 'services/usersService';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import { SearchFilter } from '.';

const localStoragePrefix = '_users';

export const useUsersEffect = (query: Query & SearchFilter) => {
  const defaultResponse: PageResource<UserResource> = { totalItems: 0, items: [] };
  const [users, setUsers] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>(localStorage.getItem('searchText_users') || '');
  const [isActive, setIsActive] = useState(true);

  const fetchUsers = async () => {
    if (searchText === '') {
      setLoading(true);
    }
    query.searchText = searchText;
    query.isActive = isActive;
    const usersResult = await getAllUsersQuery(query);

    setLoading(false);
    setUsers(usersResult);
  };

  const onSearchTextChange = (event: any) => {
    const currentValue = event.target.value;

    saveInfoInLocalStorage('searchText', currentValue, localStoragePrefix);

    query.page = 1;
    setSearchText(currentValue);
  };

  const onActiveChange = (event: any) => {
    setLoading(true);
    const { checked } = event.target;

    setIsActive(Boolean(checked));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchUsers();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText, query, isActive]);

  return { users, loading, searchText, setLoading, fetchUsers, onSearchTextChange, isActive, onActiveChange };
};
