export type Item = {
  id: string;
  label: string;
};

export type CatalogResource = {
  _id: string;
  name: string;
};
export type ReasonResource = {
  _id: string;
  reason: string;
};
export type StatusResource = {
  _id: string;
  statusName: string;
};

export type CatalogResourceTitle = {
  _id: string;
  title: string;
};

export type EmployeeAndLeader = {
  employeeId: string;
  leaderId: string;
};

export type StateResource = {
  StateName: string;
  Cities: Array<string>;
};

export type CountryResource = {
  CountryName: string;
  States: Array<StateResource>;
};
export type ItemUser = {
  id: string;
  label: string;
  name: string;
  lastName: string;
  email: string;
};
export type OnboardingItem = {
  id: string;
  description: string;
  opStatus: string;
};
export type ExitItem = {
  id: string;
  description: string;
  epStatus: string;
};
export type RoleResource = {
  _id: string;
  name: string;
  isPublic: boolean;
};

export enum HITGOAL_STATUS {
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete',
}

export type listItems = {
  propertyId: string;
  options: Item[];
};

export type GenericNameValueTupleResource = {
  _id: string;
  name: string;
  parent: string;
};
