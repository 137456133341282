/* eslint-disable react-hooks/rules-of-hooks */
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import StandardTable from 'components/standard-table';
import React from 'react';
import styled from 'styled-components';

import columns from './columns';
import { useHistoricalRewardsEffect } from './hooks';

type Props = {
  showModal: boolean;
  setShowModal: any;
  seletedRewardId: string;
  selectedEmployee: string;
};

const HistoryRewardModal: React.FC<Props> = (props) => {
  const { showModal, setShowModal, seletedRewardId, selectedEmployee } = props;

  if (!seletedRewardId || !showModal) return <div></div>;

  const handleClose = () => {
    setShowModal(false);
  };

  const { historicals, loading } = useHistoricalRewardsEffect(seletedRewardId);

  return (
    <div>
      <ModalContainer
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <ContentContainer>
            <BoxTitle>
              <Typography variant="h6">Case History</Typography>
              <CloseIcon onClick={handleClose}>
                <CloseIconShape />
              </CloseIcon>
            </BoxTitle>
            <h3>{selectedEmployee?.length ? selectedEmployee : ''}</h3>
            {loading && (
              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            )}
            {!loading && (
              <Box>
                {historicals?.length ? (
                  <StandardTable
                    keyColumn="status"
                    data={historicals}
                    totalItems={historicals.length}
                    columns={columns()}
                  />
                ) : (
                  <NoRecords>There are no records to display. </NoRecords>
                )}
              </Box>
            )}
          </ContentContainer>
        </Fade>
      </ModalContainer>
    </div>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CloseIconShape = styled(CancelOutlinedIcon)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const ContentContainer = styled.div`
  background-color: ${(p) => p.theme.palette.background.paper};
  border: 2px solid #000;
  box-shadow: ${(p) => p.theme.shadows[5]};
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  min-width: 450px;
  min-height: 540px;
  text-align: center;

  th:nth-child(2), th:nth-child(3){
    text-align: center;
  }
  td:nth-child(2), td:nth-child(3){
    text-align: center;
  }
`;

const NoRecords = styled.div`
  padding: 15px;
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const BoxTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
  .MuiTypography-root {
    width: 100%;
  }
`;

const CloseIcon = styled(IconButton)`
  position: fixed;
`;

export default HistoryRewardModal;
