import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeResource, getAllEmployees, getAllEmployeesQuery, getAllLeaders } from 'services/employeeService';
import { getJobTitles } from 'services/jobTitleService';
import { getProjects } from 'services/projectService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

export const useEmployeeCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [employeesFilter, setEmployees] = useState<Item[]>([]);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [projects, setProjects] = useState<Item[]>([]);
  const [jobTitles, setJobTitles] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newEmployees = await getAllEmployees();
      const newLeaders = await getAllLeaders();
      const newProjects = await getProjects();
      const newJobTitles = await getJobTitles();

      setEmployees(collectionEmployeeToItem(newEmployees));
      setLeaders(collectionEmployeeToItem(newLeaders));
      setProjects(collectionToItem(newProjects));
      setJobTitles(collectionToItem(newJobTitles));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, employeesFilter, leaders, projects, jobTitles };
};

export const useEmployeesEffect = (query: Query) => {
  const defaultResponse: PageResource<EmployeeResource> = { totalItems: 0, items: [] };
  const [employees, setEmployees] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);

  const fetchEmployees = useCallback(async () => {
    setLoading(true);

    const newEmployees = await getAllEmployeesQuery(query);

    setLoading(false);
    setEmployees(newEmployees);
  }, [query]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  return { employees, loading, setLoading, fetchEmployees };
};
