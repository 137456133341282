import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AlertDialog from 'components/alert-dialog';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';

type Props = {
  id: string;
  editEnable?: boolean;
  deleteEnable?: boolean;
  fetchItems?: () => Promise<void>;
  deleteItem?: (id: string) => Promise<any>;
  setLoading?: (param: boolean) => void;
  url?: string;
  name?: string;
  deleteTitle?: string;
  deleteMessage?: string;
};

const TableRowAction: React.FC<Props> = (props) => {
  const {
    id,
    editEnable = false,
    deleteEnable = false,
    fetchItems,
    deleteItem,
    setLoading,
    url = '',
    name,
    deleteTitle = '',
    deleteMessage = '',
  } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleEditNavigation = () => {
    if (name === 'One On One') {
      saveInfoInLocalStorage('oneOnOneMode', '', '');
      saveInfoInLocalStorage('inputValue', '', '_form');
    } else if (name === 'career development plan') {
      saveInfoInLocalStorage('cdpMode', '', '');
      saveInfoInLocalStorage('inputValue', '', '_form');
    }
    history.push(`/${url}/${id}`);
  };

  const handleDelete = useCallback(async () => {
    if (deleteItem) {
      const result = await deleteItem(id);

      if (!result) {
        setOpen(false);
        return;
      }
    }
    if (setLoading) setLoading(true);
    if (fetchItems) await fetchItems();
    if (setLoading) setLoading(false);
    notifier.success(`${name} deleted successfully`);
  }, [id, name, setLoading, fetchItems, deleteItem]);

  const showConfirmationModal = useCallback(async () => {
    setOpen(true);
  }, []);

  return (
    <ContainerButtons>
      {editEnable && (
        <Container>
          <IconButton onClick={handleEditNavigation} color="primary">
            <EditIcon />
          </IconButton>
        </Container>
      )}
      {deleteEnable && (
        <Container>
          <IconButton onClick={showConfirmationModal} color="primary">
            <DeleteIcon />
          </IconButton>
          <AlertDialog
            title={deleteTitle}
            message={deleteMessage}
            open={open}
            setOpen={setOpen}
            acceptConfirmation={handleDelete}
          />
        </Container>
      )}
    </ContainerButtons>
  );
};

const Container = styled.div`
  display: inline-block;
`;

const ContainerButtons = styled.div`
  text-align: end;
  display: contents;
`;

export default TableRowAction;
