/* eslint-disable react/jsx-no-bind */
import { IconButton, Link } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import styled from 'styled-components';

import { Column } from '../types';

type Props = {
  data: any[];
  columns: Column[];
  keyColumn?: string;
  onDetailPressed: any;
};

const TableContent: React.FC<Props> = (props) => {
  const { data, columns, onDetailPressed, keyColumn = '_id' } = props;

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item[keyColumn]} data-testid="table-row-test-id">
          {columns.map((column) => (
            <TableCell data-testid="table-cell-test-id" key={`${item[keyColumn]}_${column.label}`}>
              {(isNaN(renderCell(item, column)) || (renderCell(item, column) === '0')) &&(
              renderCell(item, column)
              )}
              
              {!isNaN(renderCell(item, column)) && renderCell(item, column) !== '0' &&(
              <Link component="button" onClick={()=> onDetailPressed(getExtraData(item, column))}>
              {renderCell(item, column)} 
                </Link>
              )}
              
              

              {/* <VisibilityButtonContainer 
              disabled={isNaN(renderCell(item, column)) || renderCell(item, column) === '0'}
              aria-label="viewDetail"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={()=> onDetailPressed(getExtraData(item, column))}>
                  <VisibilityIconEye visibility={isNaN(renderCell(item, column)) || renderCell(item, column) === '0'? 'hidden': 'visible'}/>
                </VisibilityButtonContainer> */}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

function renderCell(item: any, column: Column) {
  if (column.render) return column.render(item);
  return getValue(item, column.path!);
}

function getValue(value: any, path: string) {
  return path.split('.').reduce((prev, current) => prev[current], value);
}

function getExtraData(item: any, column: Column) {
  if (column.extraData) return column.extraData(item);
}

const VisibilityButtonContainer = styled(IconButton)`
  padding: 0 5px;
  height: 30px;
`;

const VisibilityIconEye = styled(VisibilityIcon)`
  color: ${(p) => p.theme.palette.primary.light};
`;

export default TableContent;
