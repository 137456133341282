import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { DevelopmentPlanResource, getDevelopmentPlanTemplatesQuery } from 'services/developmentPlanTemplate';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import { SearchFilter } from '.';

const localStorageSufix = '_developmentPlans';

export const useDevelopmentPlansEffect = (query: Query & SearchFilter) => {
  const defaultResponse: PageResource<DevelopmentPlanResource> = { totalItems: 0, items: [] };
  const [developmentPlanTemplates, setDevelopmentPlanTemplates] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);

  const fetchDevelopmentPlanTemplates = useCallback(async () => {
    setLoading(true);
    const developmentPlans = await getDevelopmentPlanTemplatesQuery(query);

    setLoading(false);
    setDevelopmentPlanTemplates(developmentPlans);
  }, [query]);

  useEffect(() => {
    fetchDevelopmentPlanTemplates();
  }, [fetchDevelopmentPlanTemplates, query]);

  return {
    developmentPlanTemplates,
    loading,
    setLoading,
    fetchDevelopmentPlanTemplates,
  };
};
