import { PageResource, Query } from 'hooks/usePaging';

import { UserResource } from './authService';
import http from './httpService';

const ONBOARDING_PROCESS_TEMPLATES_URL = '/api/onboardingProcessTemplates';

export type DescriptionResource = {
  _id: string;
  description: string;
  order: number;
};

export type SaveDescriptionResource = {
  description: string;
};

export type OnboardingProcessResource = {
  _id: string;
  title: string;
  createdBy: UserResource;
  creationDate: Date;
  descriptions: DescriptionResource[];
  isActive: boolean;
};

export type SaveOnboardingProcessResource = {
  title: string;
  descriptions: DescriptionResource[];
  isActive: boolean;
};

export async function getOnboardingProcessTemplates() {
  const { data } = await http.get<OnboardingProcessResource[]>(ONBOARDING_PROCESS_TEMPLATES_URL + '/');

  return data;
}

export async function getOnboardingProcessById(id: string) {
  const { data } = await http.get<OnboardingProcessResource>(`${ONBOARDING_PROCESS_TEMPLATES_URL}/single/${id}`);

  return data;
}

export async function getOnboardingProcessTemplatesQuery(query: Query) {
  const { data } = await http.get<PageResource<OnboardingProcessResource>>(
    `${ONBOARDING_PROCESS_TEMPLATES_URL}/query` + http.toQueryString(query),
  );

  return data;
}

export async function deleteOnboardingProcessTemplate(id: string) {
  const { data } = await http.delete<OnboardingProcessResource[]>(`${ONBOARDING_PROCESS_TEMPLATES_URL}/${id}`);

  return data;
}
export async function updateOnboardingProcessTemplate(onboardingProcessTemplate: SaveOnboardingProcessResource, id: string) {
  const { data } = await http.put<OnboardingProcessResource>(
    `${ONBOARDING_PROCESS_TEMPLATES_URL}/${id}`,
    onboardingProcessTemplate,
  );

  return data;
}
export async function copyOnboardingProcessTemplate(id: string) {
  const { data } = await http.get<OnboardingProcessResource[]>(`${ONBOARDING_PROCESS_TEMPLATES_URL}/copy/${id}`);

  return data;
}

export async function createOnboardingProcessTemplate(onboardingProcessTemplate: SaveOnboardingProcessResource) {
  const { data } = await http.post<OnboardingProcessResource>(
    `${ONBOARDING_PROCESS_TEMPLATES_URL}`,
    onboardingProcessTemplate,
  );

  return data;
}