import moment from 'moment';
import { monthName } from 'pages/hr/commonValues';

export const stringDateToHumanFormat = (date: string) => {
  if (!date) return '';
  const formattedDate = date.split('T')[0].split('-');

  return `${formattedDate[2]}/${monthName(parseInt(formattedDate[1])).substring(0, 3)}/${formattedDate[0]}`;
};

export const stringDateToHumanFormatMonthName = (date: moment.Moment) => {
  return date ? date.format('MMMM Do, YYYY') : '';
};

export const stringDateToMonthYearFormat = (date: string | undefined) => {
  return date ? moment.utc(date).format('MMM YYYY') : '';
};

export const stringDateToDayOfWeekAndNumber = (date: string | undefined) => {
  if (!date) return '';
  const dayName = moment.utc(new Date(date)).format('dddd').substr(0, 3);
  const dayNumber = moment.localeData().ordinal(parseInt(moment.utc(new Date(date)).format('DD')));

  return `${dayName} ${dayNumber}`;
};

export const checkIsWeekend = (date: string) => {
  if (!date) return false;
  const day = moment.utc(new Date(date)).day();

  return day === 6 || day === 0;
};

export const checkIsCurrentDay = (date: string) => {
  if (!date) return false;
  return moment.utc(date).startOf('day').isSame(moment().startOf('day'), 'day');
};

export const rowbackgroundColor = (date: string, dayTypeName: string) => {
  if (!date) return 'none';
  const day = moment.utc(new Date(date)).day();

  if (day === 6 || day === 0) return '#F5F4F1';
  else if (dayTypeName !== 'Normal') return '#DDEBDF';
  else return 'transparent';
};

export const calculateDaysLeft = (endDate: moment.Moment, initialDate: moment.Moment) => {
  return endDate.diff(initialDate, 'days');
};

export const yearOptions = () => {
  const currentDate = new Date();

  return [
    { id: (currentDate.getFullYear() - 1).toString(), label: (currentDate.getFullYear() - 1).toString() },
    { id: currentDate.getFullYear().toString(), label: currentDate.getFullYear().toString() },
    { id: (currentDate.getFullYear() + 1).toString(), label: (currentDate.getFullYear() + 1).toString() },
  ];
};

export const calculateTimeUntilToday = (date: string | null | undefined) => {
  if (!date) return '';
  const startDate = moment.utc(date);

  const years = moment().diff(startDate, 'years');
  const totalMonths = moment().diff(startDate, 'months');
  const months = totalMonths - years * 12;
  let timeSinceDate = '';

  if (years > 0) {
    timeSinceDate = timeSinceDate.concat(years + ' ');
    if (years === 1) timeSinceDate = timeSinceDate.concat('year');
    else timeSinceDate = timeSinceDate.concat('years');
  }
  if (months > 0) {
    if (years > 0) timeSinceDate = timeSinceDate.concat('\u000A');
    timeSinceDate = timeSinceDate.concat(months + ' months');
  } else if (totalMonths === 0) {
    return '0 Months';
  }
  return timeSinceDate;
};
export const getLastBussinessDay = (lastBussinessDate: moment.Moment) => {
  switch (lastBussinessDate.day()) {
    // If it is Saturday(6), or Sunday (0), Get the previous Friday (5)
    case 0:
      return lastBussinessDate.subtract(2, 'days');
    case 6:
      return lastBussinessDate.subtract(1, 'days');
    default:
      return lastBussinessDate;
  }
};

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export const convertNumberToMonth = (month: string) => {
  const monthNumbers = [
    ['January', '01'],
    ['February', '02'],
    ['March', '03'],
    ['April', '04'],
    ['May', '05'],
    ['Jun', '06'],
    ['July', '07'],
    ['August', '08'],
    ['September', '09'],
    ['October', '10'],
    ['November', '11'],
    ['December', '12'],
    ['-', ''],
  ];
  const monthArray = monthNumbers.filter((e) => e[1] === month);

  return monthArray[0][0];
};

export const checkAndTransformDate = (date: string) => {
  try {
    if (moment(date, 'DD/MMM/YY', true).isValid())
      return moment.utc(date).startOf('day').format().replace('00:00:', '00:00:00.').replace('Z', '0Z');
    return null;
  } catch (e) {
    return null;
  }
};

export const dateFormatUsed = 'dd/MMM/yyyy';
export const dateFormatUsedDisplay = 'dd/MMM/yyyy';
export const dateFormatforStringConvertion = 'DD/MMM/yyyy';
