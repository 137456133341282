/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import HistoryIcon from '@material-ui/icons/History';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { CareerDevelopmentPlanResource, deleteCareerDevelopmentPlan } from 'services/careerDevelopmentPlanService';
import { BLUE, FRUIT_SALAD } from 'shared/style/utilities/colors';
import styled from 'styled-components';
import { stringDateToMonthYearFormat } from 'utils/formatters/date';

const fixedColumns: Column<CareerDevelopmentPlanResource>[] = [
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Leader Name',
    sortKey: 'employee.leader.name',
    path: 'employee.leader.name',
    render: (item) =>
      `${item.employee.leader ? item.employee.leader!.name + ' ' + item.employee.leader!.lastName : '-'}`,
  },
  {
    label: 'Assigned to',
    sortKey: 'leaderAssigned.name',
    path: 'leaderAssigned.name',
    render: (item) => `${item.leaderAssigned ? item.leaderAssigned.name + ' ' + item.leaderAssigned.lastName : '-'}`,
  },
  {
    label: 'Status',
    sortKey: 'cdpStatus.name',
    path: 'cdpStatus.name',
  },
  {
    label: 'Period',
    sortKey: 'period',
    path: 'period',
    render: (item) => `${item.period ? renderPeriodDate(item.period) : '-' }`,
  },
];

const renderPeriodDate = (date: string) => {
  const periodDate = new Date(date);
  
  return periodDate.toLocaleString('default', {
    year: "numeric",
    month: "long",
  });
};

const variableColumns = (
  fetchCareerDevelopmentPlans: any,
  setLoading: any,
  onHistoryPressed: any,
  getPDF: any,
  showConfirmationModal: any,
) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <Container>
          <IconButtonColor
            onClick={() => showConfirmationModal(item._id, item.emailSent)}
            coloricon={item.emailSent ? FRUIT_SALAD : BLUE}
          >
            <EmailIcon />
          </IconButtonColor>
        </Container>
        <Container>
          <IconButton onClick={() => onHistoryPressed(item.employee._id)} color="primary">
            <HistoryIcon />
          </IconButton>
        </Container>
        <Container>
          <IconButton
            onClick={() =>
              getPDF(
                item._id,
                `${item.employee.name} ${item.employee.lastName} ${stringDateToMonthYearFormat(item.period)}`,
              )
            }
            color="primary"
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Container>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchCareerDevelopmentPlans}
          deleteItem={deleteCareerDevelopmentPlan}
          setLoading={setLoading}
          url={'career-development-plan'}
          name={'career development plan'}
          deleteTitle={'Delete Development Plan Template'}
          deleteMessage={'Do you want to delete this career development plan?'}
        />
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const IconButtonColor = styled(IconButton)<{ coloricon: string }>`
  color: ${(p) => p.coloricon};
`;

const columns = (
  fetchDevelopmentPlanTemplates: any,
  setLoading: any,
  onHistoryPressed: any,
  getPDF: any,
  showConfirmationModal: any,
) => {
  return [
    ...fixedColumns,
    variableColumns(fetchDevelopmentPlanTemplates, setLoading, onHistoryPressed, getPDF, showConfirmationModal),
  ];
};

export default columns;
