import { UserResource } from 'services/authService';

export const ROLE_ADMIN = 'admin';
export const ROLE_HR = 'hr';
export const ROLE_LEADER = 'leader';
export const ROLE_EMPLOYEE = 'employee';
export const ROLE_FINANCE = 'finance';
export const ROLE_IT = 'it';
export const ROLE_DEVMANAGER = 'devmanager';
export const ROLE_REWARDS = 'rewards';

export const isPermissionGranted = (rolesToCheck: Array<String>, user: UserResource | null) => {
  let hasPermission = false;

  if (!user) return false;

  user?.roles.map((role) => {
    if (rolesToCheck.indexOf(role.name) !== -1) hasPermission = true;
  });
  return hasPermission;
};
