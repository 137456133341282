import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import * as yup from 'yup';

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  order?: number | undefined;
  value: unknown;
}>;

saveInfoInLocalStorage('inputValue', '', '_form');

export const useForm = <T extends object>(
  defaultValues: T,
  schema: yup.ObjectSchemaDefinition<T>,
  isLoading: boolean = false,
) => {
  const [values, setValues] = useState(defaultValues);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    async function changeValid() {
      const isFormValid = await yup.object().shape(schema).isValid(values);

      setIsValid(isFormValid);
    }

    changeValid();
  }, [values, schema]);

  const useInput = (name: keyof T, customInputValue = '') => {
    const [error, setError] = useState<string | null>();
    const value = !isLoading ? values[name] : '';
    const loadingInput = isLoading;
    const onChange = useCallback(
      (event: any) => {
        const { value } = event.target;

        if (!loadingInput) {
          saveInfoInLocalStorage(customInputValue || 'inputValue', 'changed', '_form');
        }
        setValues((prev) => ({ ...prev, [name]: value }));
        setError(validate(value, schema[name] as any));
      },
      [loadingInput, name, customInputValue],
    );

    return { onChange, value, error: Boolean(error), helperText: error };
  };

  const useRadioButton = (name: keyof T, customInputValue = '') => {
    const [error, setError] = useState<string | null>();
    const currentValues = !isLoading ? (values[name] as any) : false;
    const loadingCheckbox = isLoading;

    const onChange = useCallback(
      (event: any) => {
        const { checked, value } = event.target;

        currentValues.splice(0, currentValues.length);

        const newValue = [...currentValues, value];

        if (checked) {
          setValues((prev) => ({ ...prev, [name]: newValue }));
          setError(validate(newValue, schema[name] as any));

          if (!loadingCheckbox) {
            saveInfoInLocalStorage(customInputValue || 'inputValue', 'changed', '_form');
          }

          return;
        }
      },
      [currentValues, customInputValue, loadingCheckbox, name],
    );

    return { onChange, error: Boolean(error), helperText: error };
  };

  const useDateInput = (name: keyof T, triggerAction: any = null) => {
    const [error, setError] = useState<string | null>();
    const value = !isLoading ? values[name] : '';
    const loadingInput = isLoading;
    const onChange = useCallback(
      (event: any) => {
        let { value } = { value: event };

        if (!loadingInput) {
          saveInfoInLocalStorage('inputValue', 'changed', '_form');
        }
        setValues((prev) => ({ ...prev, [name]: value }));

        let dateIsValid = true;

        if (value) {
          var date = moment(value);

          dateIsValid = date.isValid();
          if (triggerAction) triggerAction(date);
        } else if (value === null) {
          dateIsValid = true;
        }
        setError(dateIsValid ? null : 'Invalid Date');
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [loadingInput, name, triggerAction],
    );

    return { onChange, value, error: Boolean(error), helperText: error };
  };

  const useInputMultiSelect = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const currentValues = !isLoading ? (values[name] as any) : [];

    const onChange = useCallback(
      (event: OnChangeEvent) => {
        const { value } = event.target;

        setValues((values) => ({ ...values, [name]: value }));
        setError(validate(value, schema[name] as any));
      },
      [name],
    );

    return { onChange, currentValues, error: Boolean(error), helpertext: error };
  };

  const useCheckboxArray = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const currentValues = !isLoading ? (values[name] as any) : false;
    const loadingCheckbox = isLoading;

    const onChange = useCallback(
      (event: any) => {
        const { checked, value } = event.target;

        if (checked) {
          const newValue = [...currentValues, value];

          setValues((values) => ({ ...values, [name]: newValue }));
          setError(validate(newValue, schema[name] as any));

          if (!loadingCheckbox) {
            saveInfoInLocalStorage('inputValue', 'changed', '_form');
          }

          return;
        }

        if (!loadingCheckbox) {
          saveInfoInLocalStorage('inputValue', 'changed', '_form');
        }

        const newValue = currentValues.filter((x: string) => x !== value);

        setValues((values) => ({
          ...values,
          [name]: newValue,
        }));
        setError(validate(newValue, schema[name] as any));
      },
      [currentValues, loadingCheckbox, name],
    );

    return { onChange, error: Boolean(error), helperText: error };
  };

  const useCheckbox = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const loadingCheckbox = isLoading;

    const onChange = useCallback(
      (event: any) => {
        const { checked } = event.target;

        if (!loadingCheckbox) {
          saveInfoInLocalStorage('inputValue', 'changed', '_form');
        }

        setValues((values) => ({
          ...values,
          [name]: checked,
        }));
        setError(validate(checked, schema[name] as any));
      },
      [loadingCheckbox, name],
    );

    return { onChange, error: Boolean(error), helperText: error };
  };

  const useSwitch = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const value = !isLoading ? values[name] : false;

    const onChange = useCallback(
      (event: any) => {
        const { checked } = event.target;

        setValues((prev) => ({ ...prev, [name]: checked }));
        setError(validate(value, schema[name] as any));
      },
      [name, value],
    );

    return { onChange, error: error, checked: Boolean(value) };
  };

  const useCurrencyInput = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const value = !isLoading ? values[name] : '';
    const loadingInput = isLoading;
    const onChange = useCallback(
      (event: any) => {
        const { value } = event.target;

        if (!loadingInput) {
          saveInfoInLocalStorage('inputValue', 'changed', '_form');
        }
        setValues((prev) => ({ ...prev, [name]: value.replace('$', '') }));
        setError(validate(value, schema[name] as any));
      },
      [name, loadingInput],
    );

    return { onChange, value, error: Boolean(error), helperText: error };
  };

  return {
    useInput,
    useCheckboxArray,
    useCheckbox,
    useRadioButton,
    useSwitch,
    useDateInput,
    useInputMultiSelect,
    useCurrencyInput,
    values,
    isValid,
    setIsValid,
    setValues,
  };
};

const validate = <T = any>(value: any, schema: yup.Schema<T[keyof T]>) => {
  try {
    schema.validateSync(value);
    return null;
  } catch (ex) {
    return ex.errors[0] as string;
  }
};
