import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DateInputPicker from 'components/date-input-picker';
import MultiSelectBase from 'components/multi-select';
import { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useRef } from 'react';
import { RewardFilter } from 'services/rewardsService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  search: string;
  reasons: Item[];
  statuses: Item[];
  filter: RewardFilter & Query;
  defaultFilter: RewardFilter;
  setDefaultFilter: any;
  setFilter(value: RewardFilter & Query): void;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_reward';

const Filter: React.FC<Props> = (props) => {
  const { filter, reasons, statuses, setFilter, defaultFilter, setDefaultFilter } = props;

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setFilter({ ...filter, ...defaultFilter, page: 1 }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter]);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event?.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: value, page: 1 });
    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByNameInput'}
        inputProps={{
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={'Search by Name'}
        variant="outlined"
        name="search"
        value={defaultFilter.search}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'reasonsSelect'}
        name="reasons"
        options={reasons}
        currentValues={defaultFilter.reasons || []}
        label={'Reasons'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'statusSelect'}
        name="status"
        options={statuses}
        currentValues={defaultFilter.status || []}
        label={'Status'}
        onChange={handleOnChange}
      />

      <DateInputContainer
        views={['year', 'month']}
        label={'Start date'}
        minDate={new Date('2020-01-01')}
        name={'startDate'}
        value={defaultFilter.startDate ? defaultFilter.startDate?.toString() : ''}
        onChange={handleOnChange}
      />
      <DateInputContainer
        views={['year', 'month']}
        label={'End date'}
        minDate={new Date('2020-02-01')}
        name={'endDate'}
        value={filter.endDate ? filter.endDate?.toString() : ''}
        onChange={handleOnChange}
      />
    </Container>
  );
};

const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
`;
const TextFieldWrapper = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: inline-block;
  flex: 1;
  padding: 10px 0;
`;

const DateInputContainer = styled(DateInputPicker)`
  max-width: 175px;
  margin-right: 10px;
`;

export default Filter;
