import { CatalogResource } from 'shared/types';

import http from './httpService';

const ACTION_ITEMS_PRIORITIES_URL = '/api/actionItemPriorities';

export async function getAllActionItemPriorities() {
  const { data } = await http.get<CatalogResource[]>(ACTION_ITEMS_PRIORITIES_URL);

  return data;
}
