import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource } from 'shared/types';

import { EmployeeResource } from './employeeService';
import http from './httpService';

const HITGOALS_PROCESS_URL = '/api/hitgoalsProcess';

export type HitgoalProcessFilter = {
  employees: string[];
  reasons: string[];
  status: string[];
  categories: string[];
  startDate: string | null;
  endDate: string | null;
  searchText: string;
  leaders: string[];
};
export type ReasonService = {
  _id: string;
  name: string;
};
export type StatusService = {
  _id: string;
  order: number;
  statusName: string;
};
export type HitgoalProcessResource = {
  _id: string;
  objective: string;
  employee: EmployeeResource;
  createdBy: EmployeeResource;
  reasonID: ReasonService;
  category: ReasonService;
  hitgoalProcessStatusID: StatusService;
  deadLine: string;
  isUnsuccessful: boolean;
  keyResults: KeyResultForHitGoalResource[];
};

//Hitgoals
export type KeyResultForHitGoalResource = {
  _id: string;
  keyResult: string;
  source: string;
  status: string;
};

export type HitgoalResource = {
  _id?: string;
  objective: string;
  employee: string;
  reasonID: string;
  type: string;
  category: string;
  comment?: string;
  createdBy: string;
  deadLine: string;
  createdAt?: string;
  keyResults?: KeyResultForHitGoalResource[];
  isUnsuccessful?: boolean;
  unsuccessfulReason?: string;
};

export async function getAllHitgoalProcessStatus() {
  const { data } = await http.get<CatalogResource[]>(`${HITGOALS_PROCESS_URL}/statuses/`);

  return data;
}

export async function getAllHitgoalProcessReasons() {
  const { data } = await http.get<CatalogResource[]>(`${HITGOALS_PROCESS_URL}/reasons/`);

  return data;
}

export async function getProcessAssignedLeaders() {
  const { data } = await http.get<CatalogResource[]>(`${HITGOALS_PROCESS_URL}/process-assigned-leaders/`);

  return data;
}
export async function getAllHitgoalsProcess(filter: HitgoalProcessFilter & Query) {
  const { data } = await http.get<PageResource<HitgoalProcessResource>>(
    HITGOALS_PROCESS_URL + http.toQueryString(filter),
  );

  return data;
}
export async function deleteHitgoalProcess(id: string) {
  const { data } = await http.delete<HitgoalProcessResource>(`${HITGOALS_PROCESS_URL}/${id}`);

  return data;
}

export async function createHitgoal(hitgoalData: HitgoalResource) {
  console.log('hitgoalData', hitgoalData);
  const { data } = await http.post<HitgoalResource>(`${HITGOALS_PROCESS_URL}/create`, hitgoalData);

  return data;
}

export async function updateHitgoal(hitgoalData: HitgoalResource, id: string) {
  const { data } = await http.put<HitgoalResource>(`${HITGOALS_PROCESS_URL}/update/${id}`, hitgoalData);

  return data;
}

export async function getHitgoalById(id: string) {
  const { data } = await http.get<HitgoalResource>(`${HITGOALS_PROCESS_URL}/${id}`);

  return data;
}
