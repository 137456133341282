import { useCallback, useEffect, useState } from 'react';
import { DevelopmentPlanResource, getDevelopmentPlanById } from 'services/developmentPlanTemplate';

export const useDisplayModalEffect = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return { showModal, setShowModal };
};

export const useCDPTemplateEffect = (templateId: string) => {
  const [template, setTemplate] = useState<DevelopmentPlanResource>();
  const [loading, setLoading] = useState(true);

  const fetchTemplate = useCallback(async () => {
    if (!templateId) return;
    setLoading(true);
    const data = await getDevelopmentPlanById(templateId);

    setLoading(false);
    setTemplate(data);
  }, [templateId]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  return { template, loading };
};
