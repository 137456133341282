import { useEffect, useState } from 'react';
import { Item } from 'shared/types';
import { yearOptions } from 'utils/formatters/date';

export const useCatalogs = () => {
  const [availableYears] = useState<Item[]>(yearOptions);

  useEffect(() => {
    async function fetchCatalogs() {}

    fetchCatalogs();
  }, []);

  return { availableYears };
};
