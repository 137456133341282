export default {
  REWARD_PENDING_APPROVAL_STATUS_NAME: 'Pending Approval',
  REWARD_REJECTED_STATUS_NAME: 'Rejected',
  REWARD_APPROVED_STATUS_NAME: 'Approved',
  REWARD_PENDING_REFUND_STATUS_NAME: 'Pending Refund',
  REWARD_REFUNDED_STATUS_NAME: 'Refunded',
  REQUIRED_FIELD_TEXT: 'This is a required field',
  PROJECT_CONFIG_TOOLTIP: 'Custom project’s configuration applied.',
  ATTACH_SUPPORT_TIMESHEET_TOOLTIP: 'Attach support file.',
  DOWNLOAD_SUPPORT_TIMESHEET_TOOLTIP: 'Download support file.',
  ERROR_FILE_SIZE_EXCEDED: 'The file you are trying to upload exceeds the 1MB limit.',
  MSG_UPLOAD_FILE_CONFIRMATION: 'Are you sure that you want to upload this file? ',
  MSG_DELETE_FILE_CONFIRMATION: 'Are you sure that you want to delete this file?',
  ERROR_GENERIC: 'Somehting went wrong. Please try again',
  MSG_FILE_DELETED_SUCCESSFULLY: 'File deleted successfully',
  ERROR_FILE_DOWNLOAD: 'Error downloading the file, please try again...',
  DOWNLOAD_TIMESHEET_TOOLTIP: 'Download timesheet as PDF',
  MSG_DOWNLOAD_ALL_TIMESHEETS: 'Downloading all timesheets, this process may take a while...',
  MSG_LONG_PROCESS_WAIT: "Please don't refresh this page",
  PROJECT_CHANGE_TITLE: 'Changing project for Employee',
  PROJECT_CHANGE_EFFECTIVE_DATE_INFO: 'The Client Salary Period will get updated based on this date',
  HOLIDAY_DAY_TYPE: 'Holiday',
  VACATION_DAY_TYPE: 'Vacation',
  SICK_DAY_TYPE: 'Sick',
  HALF_DAY_VACATION_DAY_TYPE: 'Half Day Vacation',
  LICENCE_BENEFIT_DAY_TYPE: 'License Benefit',
};
