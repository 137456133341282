import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import moment from 'moment';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { clearLocalStorage, saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import { OneOnOneFilter } from '../../../services/oneOnOneService';
import columns from './columns';
import Filter from './filter';
import { useCatalogFilter, useOneOnOnesEffect } from './hooks';
import HistoryModal from './modal';
import { useDisplayModalEffect, useSeletedEmployeeOneOnOneEffect } from './modal/hooks';

clearLocalStorage(); //Clear local storage on refresh

const OneOnOneTable: React.FC = () => {
  const localSearchFilter = localStorage.getItem('search_1on1');
  const localLeadersFilter = localStorage.getItem('leaders_1on1');
  const localLeaderAssigned = localStorage.getItem('leaderAssigned_1on1');
  const localStatus = localStorage.getItem('status_1on1');
  const localStartDate = localStorage.getItem('startDate_1on1');
  const localEndDate = localStorage.getItem('endDate_1on1');

  const DEFAULT_QUERY: Query & OneOnOneFilter = {
    leaders: localLeadersFilter?.split(',') || [],
    leaderAssigned: localLeaderAssigned?.split(',') || [],
    status: localStatus?.split(',') || [],
    startDate: localStartDate || moment.utc().subtract('month').toString(),
    endDate: localEndDate || moment.utc().toString(),
    search: localSearchFilter || '',
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'employee.name',
  };

  const DEFAULT_FILTER: OneOnOneFilter = {
    leaders: localLeadersFilter?.split(',') || [],
    leaderAssigned: localLeaderAssigned?.split(',') || [],
    status: localStatus?.split(',') || [],
    startDate: localStartDate || moment.utc().toString(),
    endDate: localEndDate || moment.utc().toString(),
    search: localSearchFilter || '',
  };

  const { query, setQuery, handlePageChange, handleColumnSorting } = usePaging(DEFAULT_QUERY);
  const { oneOnOnes, loading: oneOnOneLoading, setLoading, fetchOneOnOnes } = useOneOnOnesEffect(query);
  const { leaders, statuses, loading: catalogLoading } = useCatalogFilter();
  const initialLoading = useInitialLoading(oneOnOneLoading || catalogLoading);
  const isLoading = oneOnOneLoading || catalogLoading;
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_FILTER);

  //TODO: This should check admin users, it is hard coded because admin validation is not done yet.
  const user = useCurrentUser();
  const canDelete =
    Boolean(user) &&
    ['ylopez@fusionhit.com', 'csantana@fusionhit.com', 'dsolano@fusionhit.com', 'eperaza@fusionhit.com'].includes(
      user?.email || '',
    );

  const { showModal, setShowModal } = useDisplayModalEffect(false);
  const { selectedEmployee, setSelectedEmployee } = useSeletedEmployeeOneOnOneEffect('');
  const onHistoryPressed = (employeeId: string) => {
    setSelectedEmployee(employeeId);
    setShowModal(true);
  };
  const history = useHistory();
  const onReadOnlyPressed = (oneOnOnId: string) => {
    saveInfoInLocalStorage('oneOnOneMode', 'readOnly', '');
    history.push(`/one-on-ones/${oneOnOnId}`);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          title="One on Ones"
          subTitle="All the one on ones information"
          newPageRedirectionLink="/one-on-ones/new"
          loading={isLoading}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter
              defaultFilter={defaultFilter}
              setDefaultFilter={setDefaultFilter}
              search={''}
              leaders={leaders}
              statuses={statuses}
              setFilter={setQuery}
              filter={query}
              totalItems={oneOnOnes.totalItems}
            />
            {oneOnOnes?.items?.length ? (
              <StandardTable
                keyColumn="_id"
                data={oneOnOnes.items}
                totalItems={oneOnOnes.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchOneOnOnes, setLoading, canDelete, onHistoryPressed, onReadOnlyPressed)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>

      <HistoryModal showModal={showModal} setShowModal={setShowModal} seletedEmployeeId={selectedEmployee} />
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 15px;
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default OneOnOneTable;
