import { EmployeeResourceCompact } from './employeeService';
import http from './httpService';

const REFERRAL_URL = '/api/employees';

export type ReferralFilter = {
  search: string;
  status: string;
};

export type ReferralResource = {
  _id: string;
  name: string;
  lastName: string;
  statusReferral: string;
};

export type SaveReferralResource = {
  statusReferral: string;
};
export type ReferralStatus = {
  _id: string;
  name: string;
};

export type ReferralResourceWithReferBy = {
  _id: string;
  name: string;
  lastName: string;
  statusReferral: string;
  referBy?: EmployeeResourceCompact;
};

export async function getReferralById(id: string) {
  const { data } = await http.get<ReferralResourceWithReferBy>(`${REFERRAL_URL}/${id}/get-referral`);

  return data;
}

export async function updateReferral(referral: SaveReferralResource, id: string) {
  const { data } = await http.put<SaveReferralResource>(`${REFERRAL_URL}/${id}/update-referral`, referral);

  return data;
}
