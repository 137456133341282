import { CatalogResource } from 'shared/types';

import http from './httpService';

const INSURANCE_STATUS_URL = '/api/insuranceStatuses';

export async function getInsuranceStatuses() {
  const { data } = await http.get<CatalogResource[]>(INSURANCE_STATUS_URL);

  return data;
}