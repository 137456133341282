/* eslint-disable react/react-in-jsx-scope */
import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AlertDialog from 'components/alert-dialog';
import React, { useState } from 'react';
import { useCallback } from 'react';

type Props = {
  id: string;
  fetchItems?: () => Promise<void>;
  copyItem: (id: string) => Promise<any>;
};

const CopyButton: React.FC<Props> = (props) => {
  const { id, fetchItems, copyItem } = props;

  const [open, setOpen] = useState(false);

  const onCopyHandler = useCallback(async () => {
    if (copyItem) await copyItem(id);
    if (fetchItems) await fetchItems();
    setOpen(false);
  }, [id, copyItem, fetchItems]);

  const showConfirmationModal = useCallback(async () => {
    setOpen(true);
  }, []);

  return (
    <div>
      <IconButton onClick={showConfirmationModal} color="primary">
        <FileCopyIcon />
      </IconButton>
      <AlertDialog
        title={'Copy Exit Procedure Template'}
        message={'Do you want to copy this exit procedure template'}
        open={open}
        setOpen={setOpen}
        acceptConfirmation={onCopyHandler}
      />
    </div>
  );
};

export default CopyButton;
