import { Checkbox, FormControlLabel, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ExportButton from 'components/export-button';
import MultiSelect from 'components/multi-select';
import SingleSelectBase from 'components/single-select';
import { Query } from 'hooks/usePaging';
import React, { useCallback, useRef, useState } from 'react';
import { loadCsvFileProcessAssigned, ProcessFilter } from 'services/processService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  processTypes: Item[];
  leaders: Item[];
  filter: ProcessFilter & Query;
  setFilter(value: ProcessFilter & Query): void;
  defaultFilter: ProcessFilter;
  setDefaultFilter: any;
  totalItems: number;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_processes';
const Filter: React.FC<Props> = (props) => {
  const { filter, processTypes, leaders, setFilter, defaultFilter, setDefaultFilter, totalItems } = props;

  const timeToClearRef = useRef(0);
  const [searchText, setSearchText] = useState('');
  const [disableUnassigned, setDisableUnassigned] = useState(
    localStorage.getItem('unassigned_processes') ? !Boolean(localStorage.getItem('unassigned_processes')) : true,
  );

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      if (name === 'processType') {
        if (!value) {
          filter.unassigned = false;
          defaultFilter.unassigned = false;
        }
        setDisableUnassigned(value ? false : true);
      }
      saveInfoInLocalStorage(name, value as string, localStoragePrefix);

      if (name !== 'search') {
        setDefaultFilter({ ...defaultFilter, [name!]: value, page: 1 });
        setFilter({ ...filter, [name!]: value, page: 1 });
      } else {
        setSearchText(value as string);
        timeToClearRef.current = setTimeout(
          () => setFilter({ ...filter, search: (value as string) || '', page: 1 }),
          2000,
        );
        return () => clearTimeout(timeToClearRef.current);
      }
    },
    [defaultFilter, filter, setDefaultFilter, setFilter],
  );

  const handleOnCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;

      saveInfoInLocalStorage(name, checked.toString(), localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: checked, page: 1 });
      setFilter({ ...filter, [name!]: checked, page: 1 });
    },
    [setDefaultFilter, defaultFilter, setFilter, filter],
  );

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByName'}
        inputProps={{
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={'Search by Name'}
        variant="outlined"
        name="search"
        value={searchText}
        onChange={handleOnChange}
      />
      <SingleSelect
        id={'processTypeSelect'}
        name="processType"
        options={processTypes}
        label={'Process Type'}
        onChange={handleOnChange}
        value={filter.processType}
      />
      <MultiSelect
        id={'leaderSelect'}
        name="leaders"
        options={leaders}
        label={'Leader Assigned'}
        onChange={handleOnChange}
        currentValues={filter.leaders || []}
      />
      <LabelContainer>
        <FormControlLabel
          id={'unassignedCheckbox'}
          control={
            <Checkbox
              value={filter.unassigned}
              checked={filter.unassigned}
              onChange={handleOnCheckboxChange}
              name="unassigned"
            />
          }
          label="Show Unassigned"
          disabled={disableUnassigned}
        />
      </LabelContainer>

      {totalItems > 0 ? (
        <ExportButton
          filter={filter}
          filename={'ProcessAssignment.csv'}
          totalRows={totalItems}
          csvDataLoader={loadCsvFileProcessAssigned}
        />
      ) : undefined}
    </Container>
  );
};

const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 0;
`;

const TextFieldWrapper = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const LabelContainer = styled.div`
  display: inline-flex;
  margin-left: 5px;
`;

export default Filter;
