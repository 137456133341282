import { useCallback, useEffect, useState } from 'react';
import { generateHoursPerMonth, getMaxHoursByYear, HoursPerMonth, SaveHoursPerMonth } from 'services/timesheetService';

const defaultMaxHoursResource = {
  year: new Date().getFullYear().toString(),
  month: '',
  totalHours: '',
};

export const useMaxHoursPerMonthEffect = (defaultYear: string) => {
  const [maxHoursPerMonth, setMaxHoursPerMonth] = useState<HoursPerMonth[]>();
  const [saveMaxHoursPerMonth, setSaveMaxHoursPerMonth] = useState<SaveHoursPerMonth>(defaultMaxHoursResource);
  const [loadingMaxHoursPerMonth, setLoadingMaxHoursPerMonth] = useState(false);
  const [yearSelected, setYearSelected] = useState(defaultYear);

  const fetchMaxHoursPerMonth = useCallback(async () => {
    if (!yearSelected) {
      setMaxHoursPerMonth([]);
      return;
    }

    setLoadingMaxHoursPerMonth(true);
    const maxHoursResource = await getMaxHoursByYear(yearSelected);

    setSaveMaxHoursPerMonth(defaultMaxHoursResource);

    if (maxHoursResource) {
      setMaxHoursPerMonth(maxHoursResource);
    }
    setLoadingMaxHoursPerMonth(false);
  }, [yearSelected]);

  useEffect(() => {
    if (yearSelected) fetchMaxHoursPerMonth();
  }, [yearSelected, fetchMaxHoursPerMonth]);

  const handleGenerateHoursByMonth = async () => {
    setLoadingMaxHoursPerMonth(true);

    const maxHoursResource = await generateHoursPerMonth(yearSelected);

    setMaxHoursPerMonth(maxHoursResource);
    
    setLoadingMaxHoursPerMonth(false);
  };

  return {
    saveMaxHoursPerMonth,
    loading: loadingMaxHoursPerMonth,
    maxHoursPerMonth,
    yearSelected,
    setYearSelected,
    setSaveMaxHoursPerMonth,
    fetchMaxHoursPerMonth,
    setMaxHoursPerMonth,
    handleGenerateHoursByMonth,
  };
};
