import { isPermissionGranted, ROLE_EMPLOYEE } from 'helpers/roleHelper';
import { handleLogout, UserResource } from 'services/authService';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

export type RouteItem = {
  to: string;
  icon: string;
  name: string;
  onClick?(): void;
  iconStyle?: any;
  enabled?: boolean;
  level: number;
  parent?: string;
  visibility: boolean;
  children?: boolean;
};

export const privateRoutes = (user: UserResource): RouteItem[] => {
  const routes = [
    {
      to: '/timesheets-summary',
      icon: 'list',
      name: 'Summary',
      parent: 'Timesheets',
      level: 1,
      visibility: isPermissionGranted([ROLE_EMPLOYEE], user),
      enabled: false,
      onClick: clearLocalStorage,
    },
  ];

  return routes;
};

export const logoutRoutes: RouteItem[] = [
  {
    to: '/login',
    icon: 'login',
    name: 'Logout',
    onClick: handleLogout,
    level: 0,
    visibility: true,
    enabled: true,
    iconStyle: { transform: 'rotate(180deg)' },
  },
];

export const loginRoutes: RouteItem[] = [
  { to: '/login', icon: 'login', name: 'Login', level: 0, visibility: true, enabled: true },
];

export const privateRoutesNavBar: RouteItem[] = [
  {
    to: '/login',
    icon: 'login',
    name: 'Logout',
    onClick: handleLogout,
    iconStyle: { transform: 'rotate(180deg)' },
    level: 0,
    visibility: true,
    enabled: true,
  },
];
