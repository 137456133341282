import { CircularProgress, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CardBody from 'components/card/card-body';
import SingleSelectNoWidth from 'components/single-select-no-width';
import StandardTable from 'components/standard-table';
import { useForm } from 'hooks/useForm';
import { monthValues } from 'pages/hr/commonValues';
import { useState } from 'react';
import React from 'react';
import { createMaxHoursPerMonth } from 'services/timesheetService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useMaxHoursPerMonthEffect } from './hooks';
import hoursPerMonthColumns from './hoursPerMonthColumns';

type Props = {
  availableYears: Item[];
};

const maxHoursSchema = {
  year: yup.string().required().label('Year').typeError('Value is not valid'),
  month: yup.string().required().label('Month').typeError('Value is not valid'),
  totalHours: yup.string().required().label('Hours'),
};

const MaxHoursPerMonthForm: React.FC<Props> = (props) => {
  const { availableYears } = props;
  const {
    maxHoursPerMonth,
    saveMaxHoursPerMonth,
    setSaveMaxHoursPerMonth,
    yearSelected,
    setYearSelected,
    loading,
    fetchMaxHoursPerMonth,
    setMaxHoursPerMonth,
    handleGenerateHoursByMonth,
  } = useMaxHoursPerMonthEffect(new Date().getFullYear().toString());
  const { useInput, values, isValid, setIsValid } = useForm(saveMaxHoursPerMonth, maxHoursSchema);
  const [isSubmiting, setIsSubmitting] = useState(false);

  const handleSaveMourMaxPerMonth = async () => {
    if (!values) return notifier.error('Please, fix the errors on the form before submitting');
    if (parseInt(values.totalHours) < 0) return notifier.error('Invalid hours value');
    setIsSubmitting(true);

    try {
      values.year = yearSelected;
      const newMaxHoursPerMonth = await createMaxHoursPerMonth(values);

      if (newMaxHoursPerMonth) {
        notifier.success('Max hours for month saved successfully.');
        setSaveMaxHoursPerMonth({ year: yearSelected, month: '', totalHours: '' });
        setMaxHoursPerMonth(newMaxHoursPerMonth);
      }
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    }

    setIsSubmitting(false);
  };

  const onYearChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'year') {
      console.log('event.target.value: ' + event.target.value);
      setYearSelected(event.target.value as string);
      values.year = event.target.value as string;

      if (values.month && values.totalHours && values.year) setIsValid(true);
      else setIsValid(false);

      console.log('yearSelected: ' + yearSelected);
    }
  };

  return (
    <Container>
      <CardBody>
        <InfoContainer>
          <BodyTitle>Set Hour Max per Month</BodyTitle>
          <OptionsContainer>
            <SingleSelectShort
              id={'maxHourYear'}
              label={'Year'}
              options={availableYears}
              defaultValue={new Date().getFullYear()}
              required
              fullWidth
              name={'year'}
              value={yearSelected}
              onChange={onYearChange}
            />

            <SingleSelectMedium
              id={'maxHourMonth'}
              label={'Month'}
              options={monthValues}
              required
              fullWidth
              {...useInput('month')}
            />

            <TextFieldShort
              id={'availableHours'}
              required
              fullWidth
              label="Hours"
              variant="outlined"
              inputProps={{
                maxLength: 3,
              }}
              type="number"
              {...useInput('totalHours')}
            />

            <ButtonContainer>
              {isSubmiting && <Spinner size={30} />}
              <Submit
                id={'saveHoursByPeriodButton'}
                disabled={isSubmiting || loading || !isValid}
                onClick={handleSaveMourMaxPerMonth}
              >
                Save
              </Submit>
            </ButtonContainer>

            <ButtonContainer>
              {isSubmiting && <Spinner size={30} />}
              <Submit
                id={'generateHoursByMonthButton'}
                disabled={isSubmiting || loading || maxHoursPerMonth?.length === 12}
                onClick={handleGenerateHoursByMonth}
              >
                Generate automatically
              </Submit>
            </ButtonContainer>
          </OptionsContainer>

          {loading && (
            <LoadingContainer>
              <Spinner />
            </LoadingContainer>
          )}

          {!loading && (
            <CardBody>
              {maxHoursPerMonth?.length ? (
                <StandardTable
                  keyColumn="_id"
                  data={maxHoursPerMonth}
                  totalItems={maxHoursPerMonth.length}
                  columns={hoursPerMonthColumns(fetchMaxHoursPerMonth, loading)}
                />
              ) : (
                <NoRecords>There are no max hours per month for this year yet </NoRecords>
              )}
            </CardBody>
          )}
        </InfoContainer>
      </CardBody>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const OptionsContainer = styled.div`
  min-width: 160px;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex: 1;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const SingleSelectMedium = styled(SingleSelectNoWidth)`
  width: 140px;
  display: block;
  margin-right: 15px;
`;

const SingleSelectShort = styled(SingleSelectNoWidth)`
  width: 100px !important;
  display: block;
  margin-right: 15px;
`;

const TextFieldShort = styled(TextField)`
  width: 100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 42px;
`;

const InfoContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default MaxHoursPerMonthForm;
