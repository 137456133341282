import { useEffect, useState } from 'react';

export const useDisplayModalEffect = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return { showModal, setShowModal };
};