import { Column } from 'components/standard-table/types';
import { monthValues } from 'pages/hr/commonValues';
import React from 'react';
import { EmployeeResource } from 'services/employeeService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const columns: Column<EmployeeResource>[] = [
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
    render: (item) => `${item.name} ${item.lastName}`,
  },
  {
    label: 'Leader',
    sortKey: 'leader.name',
    path: 'leader.name',
    render: (item) => `${item.leader ? item.leader.name + ' ' + item.leader.lastName : '-'}`,
  },
  {
    label: 'Project',
    sortKey: 'project.name',
    path: 'project.name',
    render: (item) => `${item.project ? item.project.name : '-'}`,
  },
  {
    label: 'Job Title',
    sortKey: 'jobTitle.order',
    path: 'jobTitle.order',
    render: (item) => `${item.jobTitle ? item.jobTitle.name : '-'}`,
  },
  {
    label: 'Hire Date',
    sortKey: 'hireDate',
    path: 'hireDate',
    render: (item) =>
      `${
        item.hireDate && stringDateToHumanFormat(item.hireDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.hireDate)
          : '-'
      }`,
  },
  {
    label: 'Country',
    sortKey: 'homeAddress.country',
    path: 'homeAddress.country',
    render: (item) => `${item.homeAddress?.country || ''}`,
  },
  {
    label: 'Salary Period',
    sortKey: 'salaryPeriodMonth',
    path: 'salaryPeriodMonth',
    render: (item) => (
      <SalaryPeriodDiv>{item.salaryPeriodMonth ? salaryMonthName(item.salaryPeriodMonth) : '-'}</SalaryPeriodDiv>
    ),
  },
  {
    label: 'Client Period',
    sortKey: 'clientSalaryPeriodMonth',
    path: 'clientSalaryPeriodMonth',
    render: (item) => `${item.clientSalaryPeriodMonth ? salaryMonthName(item.clientSalaryPeriodMonth) : '-'}`,
  },
];

const salaryMonthName = (number: string) => {
  return monthValues.filter((e) => e.id === number)[0].label;
};

const SalaryPeriodDiv = styled.div`
  padding: 0 16px;
`;

export default columns;
