import { isPermissionGranted, ROLE_ADMIN } from 'helpers/roleHelper';
import { useCallback, useEffect, useState } from 'react';
import { UserResource } from 'services/authService';
import {
  getRewardHistoricalById,
  RewardResourceValidation,
  SaveRewardResourceValidation,
} from 'services/rewardsHistoricalService';
import { getRewardById } from 'services/rewardsService';
import Constants from 'utils/constants';

const defaultRewardResource = {
  requestComment: '',
  rewStatusID: '',
};

export const useRewardEffect = (id: string, user: UserResource) => {
  const [saveReward, setSaveReward] = useState<SaveRewardResourceValidation>(defaultRewardResource);
  const [rewardHistorical, setRewardHistorical] = useState<RewardResourceValidation>();
  const [loading, setLoading] = useState(false);
  const [hasPermission, setHasPermision] = useState(false);

  const fetchReward = useCallback(async () => {
    setLoading(true);
    setHasPermision(isPermissionGranted([ROLE_ADMIN], user));
    const rewardData = await getRewardById(id);

    if (rewardData.rewStatusID.statusName !== Constants.REWARD_PENDING_APPROVAL_STATUS_NAME) {
      const rewardResult = await getRewardHistoricalById(
        id,
        rewardData.rewStatusID.statusName === Constants.REWARD_REJECTED_STATUS_NAME && rewardData.realAmount === ''
          ? Constants.REWARD_REJECTED_STATUS_NAME
          : Constants.REWARD_APPROVED_STATUS_NAME,
      );

      if (rewardResult) {
        setSaveReward(mapRewardResponse(rewardResult));
        setRewardHistorical(rewardResult);
      }
    }

    setLoading(false);
  }, [id, user]);

  useEffect(() => {
    if (id) fetchReward();
  }, [id, fetchReward]);

  return {
    rewardHistorical,
    setRewardHistorical,
    loading,
    saveReward,
    setSaveReward,
    setLoading,
    fetchReward,
    hasPermission,
  };
};

const mapRewardResponse = (reward: RewardResourceValidation) => {
  let validation = 'yes';

  if (reward.rewStatusID.statusName === Constants.REWARD_REJECTED_STATUS_NAME) {
    validation = 'no';
  }
  return {
    rewStatusID: validation,
    requestComment: reward.requestComment || '',
  } as SaveRewardResourceValidation;
};
