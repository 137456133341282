import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { JobTitleResource } from 'services/jobTitleService';
import { deleteJobTitle } from 'services/jobTitleService';

const fixedColumns: Column<JobTitleResource>[] = [
  {
    label: 'Order',
    sortKey: 'order',
    path: 'order',
  },
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
  },
];

const variableColumns = (fetchJobTitles: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchJobTitles}
        deleteItem={deleteJobTitle}
        setLoading={setLoading}
        url={'jobtitles'}
        name={'job title'}
        deleteTitle={'Delete Job Titles'}
        deleteMessage={'Do you want to delete this job title?'}
      />
    ),
  };
};

const columns = (fetchJobTitles: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchJobTitles, setLoading)];
};

export default columns;
