import { CircularProgress } from '@material-ui/core';
import Card from 'components/card';
import CardHeader from 'components/card/card-header';
import React from 'react';
import styled from 'styled-components';

import HolidaysPerYearForm from './holidaysPerYear';
import { useCatalogs } from './hooks';
import MaxHoursPerMonthForm from './maxHoursPerMonth';

const TimeSheetManagementForm: React.FC = () => {
  const loading = false;
  const { availableYears } = useCatalogs();

  return (
    <Container>
      <Card>
        <CardHeader loading={loading} title={`${!loading ? 'Time Sheet Management' : 'Loading'}`} />
        {loading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <MaxHoursPerMonthForm availableYears={availableYears} />
        <HolidaysPerYearForm availableYears={availableYears} />
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

export default TimeSheetManagementForm;
