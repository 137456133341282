import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource, RoleResource } from 'shared/types';

import { UserResource } from './authService';
import http from './httpService';

const USER_URL = '/api/users';

export type SaveUserResource = {
  name: string;
  lastName: string;
  email: string;
  roles: RoleResource[];
};

export async function getAllUsers() {
  const { data } = await http.get<CatalogResource[]>(USER_URL);

  return data;
}

export async function getAllUsersQuery(query: Query) {
  const { data } = await http.get<PageResource<UserResource>>(USER_URL + '/query' + http.toQueryString(query));

  return data;
}

export async function getUserById(id: string) {
  const { data } = await http.get<UserResource>(`${USER_URL}/${id}`);

  return data;
}

export async function createUser(user: SaveUserResource) {
  const { data } = await http.post<UserResource>(`${USER_URL}`, user);

  return data;
}

export async function updateUser(user: SaveUserResource, id: string) {
  const { data } = await http.put<UserResource>(`${USER_URL}/${id}`, user);

  return data;
}

export async function deleteUser(id: string) {
  const { data } = await http.delete<UserResource>(`${USER_URL}/${id}`);

  return data;
}
