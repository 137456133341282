import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { CategoryResource, deleteCategory } from 'services/categoriesService';

const fixedColumns: Column<CategoryResource>[] = [
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
  },
];

const variableColumns = (fetchCategories: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchCategories}
        deleteItem={deleteCategory}
        setLoading={setLoading}
        url={'categories'}
        name={'Categories'}
        deleteTitle={'Delete Category'}
        deleteMessage={'Do you want to delete this Category?'}
      />
    ),
  };
};

const columns = (fetchProjects: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchProjects, setLoading)];
};

export default columns;
