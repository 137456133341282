import { FormControl, MenuItem, TextField } from '@material-ui/core';
import React, { ComponentProps } from 'react';
import { Item } from 'shared/types';
import styled from 'styled-components';

type Props = {
  empty?: boolean;
  options: Item[];
  label: string;
  customWidth?: string;
  rigthSpacing?: boolean;
  leftSpacing?: boolean;
} & ComponentProps<typeof TextField>;

const SingleSelect: React.FC<Props> = (props) => {
  const {
    empty = true,
    options,
    label,
    customWidth = '185px',
    leftSpacing = false,
    rigthSpacing = false,
    ...rest
  } = props;

  return (
    <Container variant="outlined" width={customWidth} leftspacing={leftSpacing} rightspacing={rigthSpacing}>
      <TextField variant="outlined" select label={label} {...rest} inputProps={{ 'data-testid': 'single-select-test' }}>
        {empty && <MenuItem value={''}>Select a value</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Container>
  );
};

const Container = styled(FormControl)<{ width: string; leftspacing: boolean; rightspacing: boolean }>`
  width: ${(props) => props.width};
  margin-right: ${(props) => (props.rightspacing ? '15px' : '0px')};
  margin-left: ${(props) => (props.rightspacing ? '15px' : '0px')};
`;

export default SingleSelect;
