import { CountryResource } from 'shared/types';

import http from './httpService';

const COUNTRIES_URL = '/api/countriesInformation';

export async function getCountries() {
  const { data } = await http.get<CountryResource[]>(COUNTRIES_URL);

  return data;
}
