import { useCallback, useEffect, useState } from 'react';
import { getKeyResultAreaById, KeyResultAreaResource, SaveKeyResultAreaResource } from 'services/hitgoalsService';

const defaultAreaResource = {
  name: '',
};

export const useKeyResultAreaEffect = (id?: string) => {
  const [area, setArea] = useState<SaveKeyResultAreaResource>(defaultAreaResource);
  const [loading, setLoading] = useState(false);

  const fetchProject = useCallback(async () => {
    setLoading(true);
    const projectResult = await getKeyResultAreaById(id!);

    if (projectResult) {
      setArea(mapAreaResponse(projectResult));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchProject();
  }, [id, fetchProject]);

  return { area, loading };
};

const mapAreaResponse = (area: KeyResultAreaResource) => {
  return {
    name: area.name,
  } as SaveKeyResultAreaResource;
};
