import { FormControlLabel, IconButton, Switch, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardHeader from 'components/card/card-header';
import SingleSelect from 'components/single-select';
import React from 'react';
import { useParams } from 'react-router-dom';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';

import { useHitGoal } from './hooks';
import KeyResultItemForm from './key-result-item';
import {
  BodyTitle,
  ButtonContainer,
  CardBodyStyled,
  Container,
  DetailsLine,
  DetailsLineKeyResult,
  DetailsLineList,
  IconContainer,
  LoadingContainer,
  NoRecords,
  OptionsDisplay,
  SingleSelectContainer,
  SingleSelectContainerInverted,
  SingleSelectDescriptionContainer,
  Spinner,
  Submit,
  TableFirstTitleContainer,
  TableLastTitleContainer,
  TableMiddleTitleContainer,
  TableThirdTitleContainer,
  TableTitleContainer,
  TextFieldDescriptionContainer,
} from './styles';

const HitgoalCreationForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    loading,
    employees,
    reasons,
    types,
    categories,
    users,
    handleAddNewKeyResult,
    handleOnNewKeyResult,
    newKeyResult,
    enabledAddKeyResult,
    keyResults,
    handleOnMoveTemplateUp,
    handleOnMoveTemplateDown,
    handleOnDeteleTemplate,
    handleSaveHitgoalItem,
    handleOnKeyResultChange,
    keyResultStatus,
    useInput,
    useDateInput,
    values,
    isValid,
    savingType,
    useSwitch,
    handleOnReasonChange,
    unsuccessfulReasonText,
    unsuccessfulErrorText,
    isCopy,
  } = useHitGoal(id!);
  const subTitle = id ? 'Update the hitgoal info' : 'Create a new hitgoal';

  const display = loading ? 'none' : 'block';

  return (
    <Container>
      <Card>
        <CardHeader
          loading={loading}
          title={`Hitgoal: ${!loading ? values?.objective : 'Loading'}`}
          subTitle={subTitle}
        />
        {loading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>HitGoal Information</BodyTitle>

          <DetailsLine>
            <TextField
              id={'objective'}
              name="objective"
              required
              fullWidth
              label="Objective"
              inputProps={{ maxLength: 280 }}
              variant="outlined"
              {...useInput('objective')}
            />
          </DetailsLine>
          <OptionsDisplay>
            <SingleSelectContainer
              id={'employeeSelect'}
              name={'employee'}
              label={'Employee'}
              options={employees}
              customWidth="260px"
              {...useInput('employee')}
              required
            />

            <SingleSelectContainer
              id={'categorySelect'}
              name={'category'}
              label={'Category'}
              options={categories}
              {...useInput('category')}
              customWidth="260px"
              required
              disabled={isCopy}
            />

            <SingleSelectContainer
              id={'reasonSelect'}
              name={'reason'}
              label={'Reason'}
              options={reasons}
              {...useInput('reasonID')}
              customWidth="260px"
              required
            />

            <SingleSelectContainer
              id={'typeSelect'}
              name={'type'}
              label={'Type'}
              options={types}
              {...useInput('type')}
              customWidth="260px"
              required
            />

            <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
              <KeyboardDatePicker
                id={'deadline'}
                label="Deadline"
                minDate={new Date()}
                format={dateFormatUsed}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                {...useDateInput('deadLine')}
                value={useDateInput('deadLine').value as string}
              />
            </Tooltip>
          </OptionsDisplay>

          <OptionsDisplay>
            <TextField
              id={'comment'}
              multiline
              fullWidth
              rows={4}
              inputProps={{ maxLength: 5000 }}
              label="Comments"
              variant="outlined"
              {...useInput('comment')}
            />
          </OptionsDisplay>

          <br />
          <BodyTitle>Key Results</BodyTitle>

          <DetailsLineList>
            {keyResults.length ? (
              <>
                <TableTitleContainer>
                  <TableFirstTitleContainer>Title</TableFirstTitleContainer>
                  <TableMiddleTitleContainer>Description</TableMiddleTitleContainer>
                  <TableThirdTitleContainer>Status</TableThirdTitleContainer>
                  <TableLastTitleContainer>Action</TableLastTitleContainer>
                </TableTitleContainer>
                {keyResults.map((keyResult, index) => (
                  <KeyResultItemForm
                    key={index}
                    keyResultItem={keyResult}
                    index={index}
                    itemsCount={keyResults.length}
                    onMovingTemplateUp={handleOnMoveTemplateUp}
                    onMovingTemplateDown={handleOnMoveTemplateDown}
                    onDeleteKeyResult={handleOnDeteleTemplate}
                    keyResultStatus={keyResultStatus}
                    onValueChange={handleOnKeyResultChange}
                  />
                ))}
              </>
            ) : (
              <NoRecords>There are no Key Results to display, try adding a new one. </NoRecords>
            )}
          </DetailsLineList>

          <DetailsLineKeyResult>
            <TextFieldDescriptionContainer
              fullWidth
              label="New key result"
              inputProps={{ maxLength: 280 }}
              variant="outlined"
              name={'keyResult'}
              value={newKeyResult.keyResult}
              onChange={handleOnNewKeyResult}
            />

            <TextFieldDescriptionContainer
              fullWidth
              label="Source"
              variant="outlined"
              name={'source'}
              inputProps={{ maxLength: 280 }}
              value={newKeyResult.source}
              onChange={handleOnNewKeyResult}
            />

            <SingleSelectDescriptionContainer>
              <SingleSelect
                options={keyResultStatus}
                label="Status"
                name={'status'}
                value={newKeyResult.status}
                onChange={handleOnNewKeyResult}
                empty={false}
              />
            </SingleSelectDescriptionContainer>
            <IconContainer>
              <IconButton disabled={enabledAddKeyResult} color="primary" onClick={handleAddNewKeyResult}>
                <AddCircleIcon fontSize={'large'} />
              </IconButton>
            </IconContainer>
          </DetailsLineKeyResult>
          <DetailsLine>
            <FormControlLabel
              control={<Switch id={'unsuccessfulSwitch'} name="unsuccessful" {...useSwitch('isUnsuccessful')} />}
              label="HitGoal Unsuccessful"
            />
          </DetailsLine>
          {values.isUnsuccessful && (
            <DetailsLine>
              <TextField
                id={'unsuccessfulReason'}
                multiline
                fullWidth
                rows={4}
                inputProps={{ maxLength: 5000 }}
                label="Reason"
                variant="outlined"
                value={unsuccessfulReasonText}
                required
                onChange={handleOnReasonChange}
                error={!!unsuccessfulErrorText}
                helperText={unsuccessfulErrorText}
              />
            </DetailsLine>
          )}

          <DetailsLine>
            <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
              <KeyboardDatePicker
                id={'creationDateSelect'}
                label="Creation Date"
                disabled={true}
                format={dateFormatUsed}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                value={values.createdAt}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={() => {}}
              />
            </Tooltip>

            <SingleSelectContainerInverted
              id={'createdBy'}
              name={'createdBy'}
              label={'Created By'}
              options={users}
              customWidth="260px"
              {...useInput('createdBy')}
              disabled
            />
          </DetailsLine>

          <ButtonContainer>
            {savingType && <Spinner size={30} />}
            <Submit
              id={'saveHitgoalButton'}
              disabled={savingType || loading || !isValid}
              onClick={handleSaveHitgoalItem}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

export default HitgoalCreationForm;
