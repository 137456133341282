import Routing from 'components/routing';
import RoutingTimesheet from 'components/routingTimesheet';
import Providers from 'providers';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer position="bottom-right" pauseOnFocusLoss={false} autoClose={3000} />
    <Providers>
      {window.location.hostname === 'staging-devsimple.fusionhit.com' && <Routing />}
      {window.location.hostname === 'staging-timesheet.fusionhit.com' && <RoutingTimesheet />}
    </Providers>

    {process.env.NODE_ENV === 'development' && (
      <Providers>
        {window.location.host === 'localhost:3000' && <Routing />}
        {window.location.host === 'localhost:3001' && <RoutingTimesheet />}
      </Providers>
    )}
    {process.env.NODE_ENV === 'production' && (
      <Providers>
        {window.location.hostname === 'devsimple.fusionhit.com' && <Routing />}
        {window.location.hostname === 'timesheet.fusionhit.com' && <RoutingTimesheet />}
      </Providers>
    )}
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
