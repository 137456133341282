import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getAllEmployees, getAllLeaders } from 'services/employeeService';
import { getAllProcesses, ProcessFilter, ProcessResource } from 'services/processService';
import { getAllProcessTypes } from 'services/processTypeService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [processTypes, setProcessTypes] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newEmployees = await getAllEmployees();
      const newProcessTypes = await getAllProcessTypes();
      const newLeaders = await getAllLeaders();

      setEmployees(collectionEmployeeToItem(newEmployees));
      setProcessTypes(collectionToItem(newProcessTypes));
      setLeaders(collectionEmployeeToItem(newLeaders));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, employees, processTypes, leaders };
};

export const useProcessesEffect = (filter: ProcessFilter & Query) => {
  const defaultResponse: PageResource<ProcessResource> = { totalItems: 0, items: [] };
  const [processes, setProcesses] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);

  const fetchProcesses = useCallback(async () => {
    setLoading(true);

    const data = await getAllProcesses(filter);

    setLoading(false);

    setProcesses(data);
  }, [filter]);

  useEffect(() => {
    fetchProcesses();
  }, [fetchProcesses, filter]);

  return { processes, loading, setLoading, fetchProcesses };
};
