import { Icon, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import React from 'react';

type Props = {
  to: string;
  icon: string;
  name: string;
  iconStyle?: any;
  onClick?(): void;
};

const NavBarItem: React.FC<Props> = (props) => {
  const { icon, name, iconStyle, onClick } = props;

  return (
    <MenuItem id={name + '-id'} onClick={onClick}>
      <ListItemIcon>
        <Icon style={iconStyle}>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={name} />
    </MenuItem>
  );
};

export default NavBarItem;
