import { CircularProgress, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AlertDialog from 'components/alert-dialog';
import CardBody from 'components/card/card-body';
import SingleSelectNoWidth from 'components/single-select-no-width';
import StandardTableEditable from 'components/standard-table-editable';
import { useForm } from 'hooks/useForm';
import moment from 'moment';
import { monthValues } from 'pages/hr/commonValues';
import { useState } from 'react';
import React from 'react';
import { copyLastYearHolidays, HolidayFilter, saveHolidayPerYear } from 'services/timesheetService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import hoursPerMonthColumns from './holidaysPerYearColumns';
import { useHolidaysPerYerEffect } from './hooks';

type Props = {
  availableYears: Item[];
};

const holidaysSchema = {
  year: yup.string().required().label('Year'),
  month: yup.string().required().label('Month'),
  day: yup.string().required().label('Hours').typeError('Value is not valid'),
  description: yup.string().required().label('Description'),
  country: yup.string().required().label('Country'),
};

const HolidaysPerYearForm: React.FC<Props> = (props) => {
  const { availableYears } = props;
  const {
    holidays,
    saveHolidaysPerYear,
    yearSelected,
    setYearSelected,
    loading,
    setSaveHolidaysPerYear,
    setHolidaysPerYear,
    fetchHolidaysByFilter,
    countrySelected,
    setCountrySelected,
    countries,
    handleEditHoliday,
    handleSaveAfterEditHoliday,
    editingHoliday,
    optionsForEditMode,
    handleDeleteHoliday,
  } = useHolidaysPerYerEffect(new Date().getFullYear().toString(), 'Costa Rica', availableYears);
  const { useInput, values, isValid, setIsValid } = useForm(saveHolidaysPerYear, holidaysSchema);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const onYearChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'year') {
      values.year = event.target.value as string;

      setYearSelected(event.target.value as string);
      if (values.month && values.day && parseInt(values.day) <= 31 && values.year && values.description)
        setIsValid(true);
      else setIsValid(false);
    }
  };

  const onCountryChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'country') {
      values.country = event.target.value as string;

      setCountrySelected(event.target.value as string);
      if (values.month && values.day && parseInt(values.day) <= 31 && values.year && values.description)
        setIsValid(true);
      else setIsValid(false);
    }
  };
  const onCopyLastYearHolidays = () => {
    setOpenConfirmationDialog(false);
    handleCopyFromLastYear();
  };

  const showConfirmationModal = () => {
    setOpenConfirmationDialog(true);
  };

  const checkValidDate = () => {
    try {
      const dateToSave = `${values.year}-${values.month}-${values.day}`;
      var m = moment(dateToSave, 'YYYY-MM-DD');

      return m.isValid();
    } catch {
      return false;
    }
  };

  const handleSaveHoliday = async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    if (!checkValidDate() || parseInt(values.day || '0') < 1) {
      notifier.error('The date is invalid');
      return;
    }

    setIsSubmitting(true);
    try {
      values.year = yearSelected;
      const newholidayPerYear = await saveHolidayPerYear(values);

      if (newholidayPerYear) {
        notifier.success('Max hours for month saved successfully');

        setSaveHolidaysPerYear({ year: yearSelected, month: '', day: '', description: '', country: countrySelected });
        setHolidaysPerYear(newholidayPerYear);
      }
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    } finally {
      setIsSubmitting(false);
    }

    fetchHolidaysByFilter();
  };

  const handleCopyFromLastYear = async () => {
    setIsSubmitting(true);
    const holiFilter: HolidayFilter = { year: yearSelected, country: countrySelected };

    try {
      const newholidayPerYear = await copyLastYearHolidays(holiFilter);

      if (newholidayPerYear) {
        notifier.success('Holidays copied successfully');
        fetchHolidaysByFilter();
      }
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    }

    setIsSubmitting(false);
  };

  return (
    <Container>
      <CardBody>
        <InfoContainer>
          <BodyTitle>Set holidays</BodyTitle>
          <OptionsContainer>
            <SingleSelectShort
              id={'holidayYear'}
              label={'Year'}
              options={availableYears}
              value={yearSelected}
              required
              name={'year'}
              fullWidth
              onChange={onYearChange}
            />

            <SingleSelectMedium
              id={'holidayMonth'}
              label={'Month'}
              options={monthValues}
              required
              fullWidth
              {...useInput('month')}
            />

            <TextFieldShort
              id={'holidayDay'}
              required
              label="Day"
              variant="outlined"
              inputProps={{
                maxLength: 2,
                max: 31,
              }}
              type="number"
              {...useInput('day')}
            />

            <TextFieldMedium
              fullWidth
              id={'holidayDescription'}
              required
              inputProps={{
                maxLength: 200,
              }}
              label="Description"
              variant="outlined"
              {...useInput('description')}
            />

            <SingleSelectCountry
              id={'country'}
              label={'Country'}
              options={countries}
              value={countrySelected}
              required
              name={'country'}
              fullWidth
              onChange={onCountryChange}
            />

            <ButtonContainer>
              {isSubmiting && <Spinner size={30} />}
              <Submit
                id={'saveHolidayButton'}
                disabled={isSubmiting || loading || !isValid}
                onClick={handleSaveHoliday}
              >
                Save
              </Submit>
            </ButtonContainer>

            <ButtonContainer>
              {isSubmiting && <Spinner size={30} />}
              <Submit
                id={'copyHolidays'}
                disabled={isSubmiting || loading || !yearSelected}
                onClick={showConfirmationModal}
              >
                Copy from last year
              </Submit>
            </ButtonContainer>
          </OptionsContainer>

          {loading && (
            <LoadingContainer>
              <Spinner />
            </LoadingContainer>
          )}

          {!loading && (
            <CardBody>
              {holidays?.length ? (
                <StandardTableEditable
                  keyColumn="_id"
                  data={holidays}
                  totalItems={holidays.length}
                  columns={hoursPerMonthColumns(
                    fetchHolidaysByFilter,
                    loading,
                    handleEditHoliday,
                    editingHoliday,
                    handleSaveAfterEditHoliday,
                    handleDeleteHoliday,
                  )}
                  editId={editingHoliday}
                  dropdDownOptions={optionsForEditMode}
                />
              ) : (
                <NoRecords>There are no holidays for this year yet </NoRecords>
              )}
            </CardBody>
          )}
        </InfoContainer>
      </CardBody>

      <AlertDialog
        title={'Are you sure you want to copy last years holidays?'}
        message={`Holidays from ${parseInt(yearSelected) - 1} will be added to ${yearSelected}`}
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        acceptConfirmation={onCopyLastYearHolidays}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const OptionsContainer = styled.div`
  min-width: 160px;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex: 1;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const SingleSelectMedium = styled(SingleSelectNoWidth)`
  width: 140px;
  display: block;
  margin-right: 15px;
`;

const SingleSelectCountry = styled(SingleSelectNoWidth)`
  width: 140px;
  display: block;
  margin-left: 20px;
`;

const SingleSelectShort = styled(SingleSelectNoWidth)`
  width: 100px !important;
  display: block;
  margin-right: 15px;
`;

const TextFieldShort = styled(TextField)`
  width: 90px;
  margin-right: 15px;
`;

const TextFieldMedium = styled(TextField)`
  width: 180px;
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 42px;
`;

const InfoContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default HolidaysPerYearForm;
