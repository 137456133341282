import { Column } from 'components/detailed-table/types';
import { OneOnOneReportResource } from 'services/oneOnOneService';

const fixedColumns: Column<OneOnOneReportResource>[] = [
  {
    label: 'Leader',
    sortKey: 'leaderAssigned.name',
    path: 'leaderAssigned.name',
    render: (item) =>  item.leaderAssigned? `${item.leaderAssigned?.name} ${item.leaderAssigned?.lastName}` : '',
  },
  {
    label: 'Open',
    sortKey: 'OpenCount',
    path: 'status.open',
    render: (item) => `${item.statusList.find(status => status.status.name === 'Open')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'Open')?.employeeList || undefined,
  },
  {
    label: 'In Progress',
    sortKey: 'In ProgressCount',
    path: 'status.inProgress',
    render: (item) => `${item.statusList.find(status => status.status.name === 'In Progress')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'In Progress')?.employeeList || undefined,
  },
  {
    label: 'On Hold',
    sortKey: 'On HoldCount',
    path: 'status.onHold',
    render: (item) => `${item.statusList.find(status => status.status.name === 'On Hold')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'On Hold')?.employeeList || undefined,
  },
  {
    label: 'Completed',
    sortKey: 'CompletedCount',
    path: 'status.completed',
    render: (item) => `${item.statusList.find(status => status.status.name === 'Completed')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'Completed')?.employeeList || undefined,
  },
  {
    label: 'Communicate',
    sortKey: 'CommunicateCount',
    path: 'status.communicate',
    render: (item) => `${item.statusList.find(status => status.status.name === 'Communicate')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'Communicate')?.employeeList || undefined,
  },
];

const actionItemColumns = () => {
  return [...fixedColumns];
};

export default actionItemColumns;
