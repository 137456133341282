/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteReward, RewardResource } from 'services/rewardsService';
import styled from 'styled-components';
import { amountAdd } from 'utils/formatters/amount';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<RewardResource>[] = [
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Reason',
    sortKey: 'reasonID.reason',
    path: 'reasonID.reason',
    render: (item) => `${item.reasonID.reason}`,
  },
  {
    label: 'Estimated Amount',
    sortKey: 'estimatedAmount',
    path: 'estimatedAmount',
    render: (item) => `${item.estimatedAmount ? amountAdd(item.estimatedAmount) : '-'}`,
  },
  {
    label: 'Status',
    sortKey: 'rewStatusID.statusName',
    path: 'rewStatusID.statusName',
    render: (item) => <StatusHighlighter name={item.rewStatusID.statusName} />,
  },
  {
    label: 'Date',
    sortKey: 'requestDate',
    path: 'requestDate',
    render: (item) =>
      `${
        item.requestDate && stringDateToHumanFormat(item.requestDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.requestDate)
          : '-'
      }`,
  },
  {
    label: 'Update Date',
    sortKey: 'updateDate',
    path: 'updateDate',
    render: (item) =>
      `${
        item.updateDate && stringDateToHumanFormat(item.updateDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.updateDate)
          : '-'
      }`,
  },
];

const variableColumns = (fetchRewards: any, setLoading: any, getPDF: any, onHistoryPressed: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <Container>
          <IconButton onClick={() => onHistoryPressed(item)} color="primary">
            <HistoryIcon />
          </IconButton>
        </Container>
        <Container>
          <IconButton onClick={() => getPDF(item._id)} color="primary" disabled={item.invoice === ''}>
            <PictureAsPdfIcon />
          </IconButton>
        </Container>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchRewards}
          deleteItem={deleteReward}
          setLoading={setLoading}
          url={'rewards'}
          name={'Rewards'}
          deleteTitle={'Delete Reward'}
          deleteMessage={'Do you want to delete this reward?'}
        />
      </Container>
    ),
  };
};
const Container = styled.div`
  display: flex;
  float: right;
`;

const columns = (fetchRewards: any, setLoading: any, getPDF: any, onHistoryPressed: any) => {
  return [...fixedColumns, variableColumns(fetchRewards, setLoading, getPDF, onHistoryPressed)];
};

export default columns;
