import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { ActionItemResource, deleteActionItem } from 'services/actionItemService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<ActionItemResource>[] = [
  {
    label: 'Title',
    sortKey: 'title',
    path: 'title',
    render: (item) => <Title>{item.title}</Title>,
  },
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Assigned to',
    sortKey: 'leaderAssigned.name',
    path: 'leaderAssigned.name',
    render: (item) => `${item.leaderAssigned ? item.leaderAssigned.name + ' ' + item.leaderAssigned.lastName : '-'}`,
  },
  {
    label: 'Created by',
    sortKey: 'createdBy.name',
    path: 'createdBy.name',
    render: (item) => `${item.createdBy ? item.createdBy.name + ' ' + item.createdBy.lastName : '-'}`,
  },
  {
    label: 'Status',
    sortKey: 'status.name',
    path: 'status.name',
    render: (item) => <StatusHighlighter name={item.status.name} />,
  },
  {
    label: 'Priority',
    sortKey: 'priority.name',
    path: 'priority.name',
    render: (item) => <StatusHighlighter name={item.priority.name} />,
  },
  {
    label: 'Creation Date',
    sortKey: 'creationDate',
    path: 'creationDate',
    render: (item) =>
      `${
        item.creationDate && stringDateToHumanFormat(item.creationDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.creationDate)
          : '-'
      }`,
  },
  {
    label: 'Due Date',
    sortKey: 'dueDate',
    path: 'dueDate',
    render: (item) =>
      `${
        item.dueDate && stringDateToHumanFormat(item.dueDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.dueDate)
          : '-'
      }`,
  },
];

const variableColumns = (fetchActionItems: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchActionItems}
        deleteItem={deleteActionItem}
        setLoading={setLoading}
        url={'action-items'}
        name={'Action Item'}
        deleteTitle={'Delete Action Item'}
        deleteMessage={'Do you want to delete this action item?'}
      />
    ),
  };
};

const columns = (fetchActionItems: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchActionItems, setLoading)];
};

const Title = styled.div`
  max-width: 200px;
`;

export default columns;
