import '../utils/styles/custom_styles.css';
import 'react-toastify/dist/ReactToastify.css';

import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { LIGHT_THEME } from '../shared/style/theme';
import UserProvider from './UserProvider';

const Providers: React.FC = (props) => {
  const { children } = props;

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={LIGHT_THEME}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={LIGHT_THEME}>
            <UserProvider>{children}</UserProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default Providers;
