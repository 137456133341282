import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteProcess, ProcessResource } from 'services/processService';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<ProcessResource>[] = [
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Leader',
    sortKey: 'employee.leader.name',
    path: 'employee.leader.name',
    render: (item) => `${item.employee.leader ? item.employee.leader.name + ' ' + item.employee.leader.lastName : '-'}`,
  },
  {
    label: 'Process Type',
    sortKey: 'processType.name',
    path: 'processType.name',
    render: (item) => `${item.processType?.name || '-'}`,
  },
  {
    label: 'Periodicity',
    sortKey: 'periodicity',
    path: 'periodicity',
    render: (item) =>
      `${item.periodicity ? item.periodicity.toString() + ' ' + (item.periodicity === 1 ? 'Month' : 'Months') : '-'}`,
  },
  {
    label: 'Status',
    sortKey: 'isActive',
    path: 'isActive',
    render: (item) => `${item.isActive ? (item.isActive ? 'Active' : 'Inactive') : '-'}`,
  },
  {
    label: 'Hire Date',
    sortKey: 'employee.hireDate',
    path: 'employee.hireDate',
    render: (item) => {
      const formatedDate = item.employee.hireDate ? stringDateToHumanFormat(item.employee.hireDate) : '-';

      return formatedDate;
    },
  },
  {
    label: 'Next Period Date',
    sortKey: 'nextPeriodStartDate',
    path: 'nextPeriodStartDate',
    render: (item) => {
      const formatedDate = item.nextPeriodStartDate ? stringDateToHumanFormat(item.nextPeriodStartDate) : '-';

      return formatedDate;
    },
  },
];

const variableColumns = (fetchProcess: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable={!!item}
        fetchItems={fetchProcess}
        deleteItem={deleteProcess}
        setLoading={setLoading}
        url={!!item ? 'process-assignment' : 'process-assignment/new'}
        name={'Process'}
        deleteTitle={'Delete Process'}
        deleteMessage={'Do you want to delete this process?'}
      />
    ),
  };
};

const columns = (fetchProcess: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchProcess, setLoading)];
};

export default columns;
