import { CircularProgress, TextField, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import CurrencyInput from 'components/currencyInput';
import SingleSelect from 'components/single-select';
import { useForm } from 'hooks/useForm';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { saveRate, updateRate } from 'services/salaryService';
import styled from 'styled-components';
import { encryptText } from 'utils/encryptionMethods/encryption';
import { dateFormatUsed, dateFormatUsedDisplay, stringDateToHumanFormat } from 'utils/formatters/date';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCatalogs, useRatesEffect } from './hooks';

const schemaRate = {
  employee: yup.string().optional().label('Employee'),
  rate: yup.string().required().label('Rate'),
  increase: yup.string().optional().label('Increase'),
  comments: yup.string().required().label('Comments'),
  effectiveDate: yup.string().required().label('Date'),
};

const RateForm: React.FC = () => {
  const { id: employeeId, rateId } = useParams<{ id: string; rateId: string }>();

  const { employee, loadingCatalogs, dateFirst } = useCatalogs(employeeId!);
  const {
    rate,
    lastIncrease,
    loadingRate,
    rateResultObject,
    isLastRate,
    lastRate,
    handleOnRateResult,
  } = useRatesEffect(rateId, employeeId);

  const [savingRate, setSavingRate] = useState(false);
  const { useInput, useDateInput, useCurrencyInput, values, isValid } = useForm(rate, schemaRate);
  const display = loadingCatalogs && loadingRate ? 'none' : 'block';
  const subTitle = rateId && rateId !== 'new' ? 'Update rate' : 'Add a new rate record';
  const history = useHistory();

  const handleSaveRate = async () => {
    if (!isValid) {
      notifier.error('Please, fix the errors on the form before submitting');
      return;
    }
    setSavingRate(true);
    values.employee = employeeId;

    const valuesToSend = { ...values };

    valuesToSend.rate = encryptText(valuesToSend.rate.replace(',', ''));
    valuesToSend.increase = valuesToSend.increase ? encryptText(valuesToSend.increase.replace(',', '')) : '';

    if (rateId && rateId !== 'new') {
      const response = await updateRate(valuesToSend, rateId);

      if (response) {
        notifier.success('Rate updated successfully.');
      }

      setSavingRate(false);
      return;
    }

    const newRate = await saveRate(valuesToSend);

    if (newRate) {
      handleOnRateResult(newRate);
      notifier.success('Rate added successfully.');
      history.push(`/employees/${employeeId}/rates/${newRate._id}`);
    }
    setSavingRate(false);
  };

  const calculateNewRate = () => {
    if (!values.increase)
      return `New rate: \u000A $${parseFloat(lastRate.replaceAll(/\s/g, '').replaceAll(',', '')).toLocaleString()}`;
    const currentRate = lastRate.replace('$', '').replaceAll(',', '');
    const increase = values.increase.replace('$', '').replaceAll(',', '');
    let newRate = parseFloat(currentRate) + parseFloat(increase);

    if (rateId && rateId !== 'new') {
      newRate = newRate - parseFloat(lastIncrease.replace('$', '').replaceAll(',', ''));
    }

    if (isNaN(newRate)) newRate = parseFloat(currentRate);

    values.rate = newRate.toLocaleString();

    return `New rate: \u000A $${newRate.toLocaleString()}`;
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={loadingCatalogs}
          title={`Employee: ${!loadingCatalogs ? employee?.name + ' ' + employee?.lastName : 'Loading'}`}
          subTitle={subTitle}
        />
        {loadingCatalogs && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>New rate Information</BodyTitle>

          <DetailsLine>
            <OptionsContainer>
              <TextBody hidden={!!lastRate && dateFirst !== rate.effectiveDate}>
                <TextField
                  required
                  label="New Rate"
                  variant="outlined"
                  name="newRate"
                  id="newRate"
                  {...useCurrencyInput('rate')}
                  InputProps={{
                    inputComponent: CurrencyInputContainer as any,
                  }}
                  disabled={!isLastRate}
                />
              </TextBody>

              <TextBodyFlexParent hidden={!lastIncrease || !lastRate || dateFirst === rate.effectiveDate}>
                <TextBodyFlex>
                  <TextFieldIncrease
                    required
                    label="Increase"
                    variant="outlined"
                    name="increase"
                    id="increase"
                    {...useCurrencyInput('increase')}
                    InputProps={{
                      inputComponent: CurrencyInputContainer as any,
                    }}
                    disabled={!isLastRate || dateFirst === rate.effectiveDate}
                  />
                  <div className={'visualization'}>
                    <NewRateDiv style={{ whiteSpace: 'pre-wrap' }}>{calculateNewRate()}</NewRateDiv>
                  </div>
                </TextBodyFlex>
              </TextBodyFlexParent>

              <SelectContainerWide>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainer
                    id={'dateSelect'}
                    required
                    label="Effective Date"
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    disabled={!isLastRate && dateFirst === rate.effectiveDate}
                    {...useDateInput('effectiveDate')}
                    InputLabelProps={{
                      shrink: values.effectiveDate ? true : false,
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Tooltip>
              </SelectContainerWide>
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <TextWideBody>
              <MultiLineTetFieldContainer
                id={'rateCommentsInput'}
                multiline
                fullWidth
                required
                minRows={2}
                label="Comments"
                variant="outlined"
                {...useInput('comments')}
              />
            </TextWideBody>
          </DetailsLine>

          {rateId && rateId !== 'new' && (
            <div>
              <DetailsLine>
                <TextBody>
                  <TextField
                    label="Created By"
                    variant="outlined"
                    disabled={true}
                    fullWidth
                    value={rateResultObject?.createdBy?.name + ' ' + rateResultObject?.createdBy?.lastName}
                  />
                </TextBody>

                <TextBody>
                  <TextField
                    label="Creation date"
                    variant="outlined"
                    disabled={true}
                    fullWidth
                    value={stringDateToHumanFormat(rateResultObject?.createdAt ? rateResultObject.createdAt : '')}
                  />
                </TextBody>
              </DetailsLine>

              {rateResultObject && rateResultObject.updatedBy && (
                <DetailsLine>
                  <TextBody>
                    <TextField
                      label="Updated By"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                      value={
                        rateResultObject?.updatedBy
                          ? rateResultObject.updatedBy.name + ' ' + rateResultObject.updatedBy.lastName
                          : ''
                      }
                    />
                  </TextBody>

                  <TextBody>
                    <TextField
                      label="Update date"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                      value={stringDateToHumanFormat(rateResultObject?.updatedAt ? rateResultObject.updatedAt : '')}
                    />
                  </TextBody>
                </DetailsLine>
              )}
            </div>
          )}

          <ButtonContainer>
            {savingRate && <Spinner size={30} />}
            <Submit disabled={savingRate || loadingCatalogs || !isValid} onClick={handleSaveRate}>
              Save rate
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const ButtonContainer = styled.div`
  display: block;
  padding: 20px;
  text-align: right;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
`;

const TextBody = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 280px;
  float: left;
`;

const TextBodyFlexParent = styled.div`
  float: left;
`;

const TextBodyFlex = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 280px;
  float: left;
  display: flex;
`;

const CurrencyInputContainer = styled(CurrencyInput)`
  margin-right: 20px;
  width: 233px;
  float: left;
`;

const OptionsContainer = styled.div`
  display: block;
  float: left;
`;

const SingleSelectContainer = styled(SingleSelect)`
  width: 280px;
`;

const SelectContainerWide = styled.div`
  min-width: 160px;
  width: 280px;
  display: block;
  float: left;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: -5px;
`;

const DateInputPickerContainer = styled(KeyboardDatePicker)`
  width: 280px;
`;

const TextWideBody = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 100%;
  margin-bottom: 30px;
`;

const TextFieldIncrease = styled(TextField)`
  width: 200px;
`;

const MultiLineTetFieldContainer = styled(TextField)`
  height: 50px !important;
`;

const NewRateDiv = styled.span`
  padding-left: 8px;
  align-self: center;
`;

export default RateForm;
