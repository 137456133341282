import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

import { StyledBackdrop, StyledBox } from './styles';

type Props = {
  isOpen: boolean;
  title: string;
  subtitle: string;
};

const FullScreenLoading = (props: Props) => {
  const { isOpen, title, subtitle } = props;

  return (
    <StyledBackdrop open={isOpen}>
      <StyledBox>
        <CircularProgress color="inherit" size={50} />
        <br />
        <br />
        <Typography variant="h6">{title}</Typography>
        <Typography>{subtitle}</Typography>
      </StyledBox>
    </StyledBackdrop>
  );
};

export default FullScreenLoading;
