import { CatalogResource } from 'shared/types';

import http from './httpService';

const FEELINGS_URL = '/api/feelings';

export async function getAllFeelings() {
  const { data } = await http.get<CatalogResource[]>(FEELINGS_URL);

  return data;
}
