import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteKeyResultType, KeyResultTypeResource } from 'services/hitgoalsService';

const fixedColumns: Column<KeyResultTypeResource>[] = [
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
  },
];

const variableColumns = (fetchKeyResultTypes: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchKeyResultTypes}
        deleteItem={deleteKeyResultType}
        setLoading={setLoading}
        url={'key-result-types'}
        name={'Key Result Type'}
        deleteTitle={'Delete Key Result Type'}
        deleteMessage={'Do you want to delete this Key Result Type?'}
      />
    ),
  };
};

const columns = (fetchProjects: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchProjects, setLoading)];
};

export default columns;
