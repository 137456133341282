import Card from 'components/card';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React from 'react';
import { HitgoalProcessFilter } from 'services/hitgoalProcessService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import Filter from './filter';
import { useCatalogFilter, useHitgoalsProcessEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

const HitgoalProcessList: React.FC = () => {
  const localEmployees = localStorage.getItem('employees_hitgoalProcess');
  const localSearchFilter = localStorage.getItem('search_hitgoalProcess');
  const localReason = localStorage.getItem('reasons_hitgoalProcess');
  const localStatus = localStorage.getItem('status_hitgoalProcess');
  const localStartDate = localStorage.getItem('startDate_hitgoalProcess');
  const localEndDate = localStorage.getItem('endDate_hitgoalProcess');
  const localCategory = localStorage.getItem('categories_hitgoalProcess');
  const localLeaders = localStorage.getItem('leaders_hitgoalProcess');

  const DEFAULT_QUERY: Query & HitgoalProcessFilter = {
    employees: localEmployees?.split(',') || [],
    reasons: localReason?.split(',') || [],
    status: localStatus?.split(',') || [],
    startDate: localStartDate || '',
    endDate: localEndDate || '',
    searchText: localSearchFilter || '',
    categories: localCategory?.split(',') || [],
    leaders: localLeaders?.split(',') || [],
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'objective',
  };

  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const { employees, leaders, statuses, reasons, categories, loading } = useCatalogFilter();
  const { hitgoalsProcess, setLoading, fetchHitgoalsProcess } = useHitgoalsProcessEffect(query);

  return (
    <Container>
      <Card>
        <CardHeader
          title="HitGoals Process"
          newPageRedirectionLink={'/hitGoals-process/new'}
          secondaryRedirectionLink={'/hitGoal-templates'}
          loading={loading}
        />
        <Filter
          employees={employees}
          leaders={leaders}
          statuses={statuses}
          reasons={reasons}
          categories={categories}
          filter={query}
          setFilter={setQuery}
          localStoragePrefix={'_hitgoalProcess'}
        />
        {hitgoalsProcess?.items?.length ? (
          <StandardTable
            keyColumn="_id"
            data={hitgoalsProcess.items}
            totalItems={hitgoalsProcess.totalItems}
            page={query.page}
            pageSize={query.pageSize}
            isSortAscending={Boolean(query.isSortAscending)}
            sortByColumn={query.sortByColumn}
            onChangePage={handlePageChange}
            columns={columns(fetchHitgoalsProcess, setLoading)}
            onSort={handleColumnSorting}
          />
        ) : (
          <NoRecords>There are no records to display, please check your filters. </NoRecords>
        )}
      </Card>
    </Container>
  );
};
const Container = styled.div`
  padding: 0 15px;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default HitgoalProcessList;
