import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { ExitProcedureResource, getExitProcedureTemplatesQuery } from 'services/exitProcedureTemplate';

import { SearchFilter } from '.';


export const useExitProcedureEffect = (query: Query & SearchFilter) => {
  const defaultResponse: PageResource<ExitProcedureResource> = { totalItems: 0, items: [] };
  const [exitProcedureTemplates, setExitProcedureTemplates] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);

  const fetchExitProcedureTemplates = useCallback(async () => {
   
    setLoading(true);
    
    const exitProcedure = await getExitProcedureTemplatesQuery(query);

    setLoading(false);
    setExitProcedureTemplates(exitProcedure);
  }, [query]);


  useEffect(() => {
    fetchExitProcedureTemplates();
  }, [fetchExitProcedureTemplates]);

  return {
    exitProcedureTemplates,
    loading,
    setLoading,
    fetchExitProcedureTemplates,
  };
};
