import { useCallback, useEffect, useState } from 'react';
import {
  getReferralById,
  ReferralResource,
  ReferralResourceWithReferBy,
  SaveReferralResource,
} from 'services/referralService';
import { getStatusesReferral } from 'services/statusReferral';
import { Item } from 'shared/types';
import { collectionToItem, collectionToItemIdLabel } from 'utils/mappers/item';

const defaultReferralResource = {
  statusReferral: '',
};

export const useReferralEffect = (id: string) => {
  const [saveReferral, setSaveReferral] = useState<SaveReferralResource>(defaultReferralResource);
  const [referral, setReferral] = useState<ReferralResourceWithReferBy>();
  const [loading, setLoading] = useState(false);
  const [statusReferralSelected, setStatusReferralSelected] = useState<string>('');

  const fetchReferral = useCallback(async () => {
    setLoading(true);
    const referralResult = await getReferralById(id);

    if (referralResult) {
      setSaveReferral(mapReferralResponse(referralResult));
      setReferral(referralResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchReferral();
  }, [id, fetchReferral]);

  return {
    referral,
    setReferral,
    loading,
    saveReferral,
    setSaveReferral,
    setLoading,
    fetchReferral,
    statusReferralSelected,
    setStatusReferralSelected,
  };
};

const mapReferralResponse = (referral: ReferralResource) => {
  return {
    statusReferral: referral.statusReferral || '',
  } as SaveReferralResource;
};

export const useCatalogs = (id: string) => {
  const [loading, setLoading] = useState(true);

  const [statusesReferral, setStatusesReferral] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [statusReferralResult] = await Promise.all([getStatusesReferral()]);

      setStatusesReferral(collectionToItemIdLabel(statusReferralResult));

      setLoading(false);
    }
    fetchCatalogs();
  }, [id]);

  return { statusesReferral, loading };
};

export const useReadOnlyEffect = (
  localStorageValue: string | null,
  referraldFinished: boolean,
  adminPermission: boolean,
) => {
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (adminPermission) {
      setReadOnly(false);
      return;
    }

    if (localStorageValue && localStorageValue === 'readOnly') {
      setReadOnly(true);
    } else if (referraldFinished) {
      setReadOnly(true);
    } else setReadOnly(false);
  }, [adminPermission, referraldFinished, localStorageValue]);

  return { isReadOnly, setReadOnly };
};
