import { Button, CircularProgress, FormControlLabel, Radio, RadioGroup, TextField, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import CurrencyInput from 'components/currencyInput';
import { useForm } from 'hooks/useForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SaveRewardRefund, updateRewardRefund } from 'services/rewardsRefundService';
import getBoxBackground from 'shared/style/utilities/getBoxBackground';
import getBoxShadow from 'shared/style/utilities/getBoxShadow';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import Constants from 'utils/constants';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useRewardRefund } from './hooks';

type Props = {
  rewardId: string;
  statusInTheMainScreen: string;
};

const schemaRewardRefund = {
  realAmount: yup.string().required().label('Amount').typeError('Invalid Number'),
  requestComment: yup.string().default('').required().label('Comment'),
  purchaseCompany: yup.string().default('').required().label('purchaseCompany'),
  purchasedDate: yup.string().required().label('purchasedDate').typeError('Invalid date'),
  purchased: yup.string().required().label('Purchased'),
  electronicInvoice: yup.string().required().label('electronicInvoice'),
};

export const RewardRefund: React.FC<Props> = (props) => {
  const [rewardRefundComment] = useState('');
  const { rewardId, statusInTheMainScreen } = props;
  const {
    saveRewardRefund,
    loading,
    isAttachButtonEnable,
    setIsAttachButtonEnable,
    invoiceFile,
    setInvoiceFile,
    fileNameLabel,
    setFileNameLabel,
    hasPermission,
  } = useRewardRefund(rewardId);

  const { useInput, useDateInput, useCurrencyInput, values, isValid, setIsValid } = useForm(
    saveRewardRefund,
    schemaRewardRefund,
    loading,
  );

  const { isSubmiting, handleUpdateRewardRefund } = useSubmitForm(
    values,
    true,
    rewardId,
    rewardRefundComment,
    invoiceFile,
  );

  const handleRadioButtonYes = () => {
    setIsAttachButtonEnable('Yes');

    let formData = new FormData();

    setInvoiceFile(formData);
    setFileNameLabel('');
    if (isAttachButtonEnable === 'Yes') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleRadioButtonNo = () => {
    setIsAttachButtonEnable('No');
    if (isAttachButtonEnable === 'No') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  function getFile() {
    //Read File
    let elementFile = document.getElementById('contained-button-file') as HTMLInputElement;
    let selectedFile: any = null;

    if (elementFile) selectedFile = elementFile?.files;

    //Check File is not Empty
    if (selectedFile.length > 0) {
      setFileNameLabel(selectedFile[0].name);

      // Select the very first file from list
      let fileToLoad = selectedFile[0];

      let formData = new FormData();

      formData.append('file', fileToLoad);
      formData.append('filename', selectedFile[0].name);

      if (fileToLoad) setInvoiceFile(formData);
    }
  }

  useEffect(() => {
    if (
      (isValid && isAttachButtonEnable === '') ||
      (isAttachButtonEnable === 'No' && fileNameLabel === '') ||
      isAttachButtonEnable === 'false' ||
      values.requestComment === '' ||
      values.requestComment === undefined ||
      values.purchaseCompany === '' ||
      values.purchased === '' ||
      values.realAmount === '' ||
      values.realAmount === undefined ||
      !values.purchasedDate
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [
    isValid,
    setIsValid,
    isAttachButtonEnable,
    fileNameLabel,
    values.requestComment,
    values.purchaseCompany,
    values.purchasedDate,
    values.realAmount,
    values.purchased,
  ]);

  return (
    <Container>
      <Card>
        <FormHeader loading={loading} title={`Refund Request`} />
        <CardBody>
          <div>
            <DetailsLine>
              <TextField
                required
                fullWidth
                id="purchaseCompany"
                label="What is the name of the company you purchase from?"
                variant="outlined"
                name={'title'}
                inputProps={{ maxLength: 280 }}
                {...useInput('purchaseCompany')}
                disabled={!hasPermission || saveRewardRefund.realAmount !== '' ? true : false}
              />
            </DetailsLine>
            <DetailsLine>
              <TextField
                required
                fullWidth
                inputProps={{ maxLength: 280 }}
                id="purchased"
                label="What did you purchase?"
                variant="outlined"
                name={'title'}
                disabled={!hasPermission || saveRewardRefund.realAmount !== '' ? true : false}
                {...useInput('purchased')}
              />
            </DetailsLine>
            <DetailsLineDate>
              <OptionsContainer>
                <DateContainer>
                  <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                    <KeyboardDatePicker
                      id={'purchasedDate'}
                      label="Purchase Date"
                      format={dateFormatUsed}
                      inputVariant="outlined"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputLabelProps={{
                        shrink: values.purchasedDate ? true : false,
                      }}
                      disabled={!hasPermission || saveRewardRefund.realAmount !== '' ? true : false}
                      required
                      // eslint-disable-next-line react/jsx-no-bind
                      {...useDateInput('purchasedDate')}
                    />
                  </Tooltip>
                </DateContainer>
                <AmountContainer>
                  <TextField
                    label="Amount"
                    variant="outlined"
                    name="realAmount"
                    id="realAmount"
                    required
                    disabled={!hasPermission || saveRewardRefund.realAmount !== '' ? true : false}
                    {...useCurrencyInput('realAmount')}
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                    }}
                  />
                </AmountContainer>
              </OptionsContainer>
            </DetailsLineDate>
            <SectionDetail>
              <BodyTitle>
                Did you request an Electronic Invoice providing FusionHit's information?<RequiredForm>*</RequiredForm>
              </BodyTitle>
              <DetailsLineWithRadioButtons>
                <RadioGroupAdminContainer {...useInput('electronicInvoice')}>
                  <ItemRadioButton>
                    <FormControlLabel
                      disabled={!hasPermission || saveRewardRefund.realAmount !== '' ? true : false}
                      value={'yes'}
                      control={<Radio required />}
                      label={<RadioLabel>Yes</RadioLabel>}
                      name="radioBtnYes"
                      onChange={handleRadioButtonYes}
                    />
                  </ItemRadioButton>
                  <ItemRadioButton>
                    <FormControlLabel
                      disabled={!hasPermission || saveRewardRefund.realAmount !== '' ? true : false}
                      value={'no'}
                      control={<Radio />}
                      label={<RadioLabel>No</RadioLabel>}
                      name="radioBtnNo"
                      onChange={handleRadioButtonNo}
                    />
                  </ItemRadioButton>
                </RadioGroupAdminContainer>
              </DetailsLineWithRadioButtons>
            </SectionDetail>
            <SectionDetail>
              <BodyTitle>
                If you didn't request an Electronic Invoice please attach the invoice of the purchase.
              </BodyTitle>
              <DetailsLine>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="application/pdf"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={getFile}
                    disabled={
                      !hasPermission ||
                      statusInTheMainScreen === Constants.REWARD_PENDING_REFUND_STATUS_NAME ||
                      statusInTheMainScreen === Constants.REWARD_REFUNDED_STATUS_NAME ||
                      statusInTheMainScreen === Constants.REWARD_REJECTED_STATUS_NAME ||
                      isAttachButtonEnable.toLowerCase() === 'yes' ||
                      isAttachButtonEnable.toLowerCase() === '' ||
                      isAttachButtonEnable.toLowerCase() === 'false'
                        ? true
                        : false
                    }
                  />
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    style={UploadButton}
                    disabled={
                      !hasPermission ||
                      statusInTheMainScreen === Constants.REWARD_REFUNDED_STATUS_NAME ||
                      statusInTheMainScreen === Constants.REWARD_REJECTED_STATUS_NAME ||
                      statusInTheMainScreen === Constants.REWARD_PENDING_REFUND_STATUS_NAME ||
                      isAttachButtonEnable.toLowerCase() !== 'no' ||
                      isAttachButtonEnable.toLowerCase() === '' ||
                      isAttachButtonEnable.toLowerCase() === 'false'
                        ? true
                        : false
                    }
                  >
                    Upload
                  </Button>
                  <RequiredForm>
                    {isAttachButtonEnable.toLowerCase() === 'yes' ||
                    isAttachButtonEnable.toLowerCase() === '' ||
                    isAttachButtonEnable.toLowerCase() === 'false'
                      ? ''
                      : '*'}
                  </RequiredForm>
                </label>
                <FileNameLabel>
                  <b>{fileNameLabel}</b>
                </FileNameLabel>
              </DetailsLine>
              <TextBody>
                <TextField
                  required
                  multiline
                  fullWidth
                  rows={4}
                  disabled={!hasPermission || saveRewardRefund.realAmount !== '' ? true : false}
                  inputProps={{ maxLength: 5000 }}
                  id="requestComment"
                  label="Comment"
                  variant="outlined"
                  {...useInput('requestComment')}
                />
              </TextBody>
            </SectionDetail>
            <ButtonContainer>
              {isSubmiting && <Spinner size={30} />}
              {saveRewardRefund?.rewStatusID?.statusName === Constants.REWARD_APPROVED_STATUS_NAME ? (
                <Submit disabled={isSubmiting || loading || !isValid} onClick={handleUpdateRewardRefund}>
                  Save
                </Submit>
              ) : (
                ''
              )}
            </ButtonContainer>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

const useSubmitForm = (
  values: SaveRewardRefund,
  isValid: boolean,
  rewardId: string,
  rewardRefundComment: string,
  invoiceFile: FormData = new FormData(),
) => {
  const history = useHistory();
  const [isSubmiting, setIsSubmitting] = useState(false);

  const handleUpdateRewardRefund = useCallback(async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    setIsSubmitting(true);
    if (rewardId) {
      try {
        invoiceFile?.delete('refundData');
        invoiceFile?.append('refundData', JSON.stringify(values));
        const newRewardRefund = await updateRewardRefund(invoiceFile, rewardId);

        if (newRewardRefund) {
          notifier.success('Reward updated successfully.');
          history.push(`/rewards/`);
        }
      } catch (error) {
        if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
      }
    }

    setIsSubmitting(false);
  }, [invoiceFile, isValid, rewardId, values, history]);

  return { isSubmiting, handleUpdateRewardRefund: handleUpdateRewardRefund };
};

const TextBody = styled.div`
  margin-top: 50px;
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const UploadButton = {
  marginTop: '20%',
  color: 'white',
};

const FormHeader = styled(CardHeader)`
  background: ${(p) => getBoxBackground(p.theme.palette.secondary.main, p.theme.palette.secondary.dark)};
  box-shadow: ${(p) => getBoxShadow(p.theme.palette.secondary.main)};
`;

const AmountContainer = styled.div`
  margin-left: 17%;
  margin-top: 15px;
  width: 40%;
  float: left;
  display: flex;
  align-items: center;
`;

const DateContainer = styled.div`
  margin-top: 15px;
  width: 40%;
  float: left;
  display: flex;
  align-items: center;
`;

const OptionsContainer = styled.div`
  display: block;
  float: left;
`;

const RadioLabel = styled.span`
  font-weight: 300;
`;

const RequiredForm = styled.span`
  color: red;
  font-size: 15px;
  margin-left: 5px;
`;

const FileNameLabel = styled.label`
  color: #a5a5a5;
  margin-top: 26px;
  margin-left: 10px;
`;

const Container = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
  float: right;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 60%;
`;

const DetailsLineDate = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 90%;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

const DetailsLineWithRadioButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SectionDetail = styled.div`
  padding-left: 20px;
`;

const ItemRadioButton = styled.div`
  flex-direction: column;
`;

const RadioGroupAdminContainer = styled(RadioGroup)`
  padding: 10px;
`;

const Input = styled('input')({
  display: 'none',
});
