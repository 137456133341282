import { Paper } from '@material-ui/core';
import React from 'react';
import { BirthdayByMonthResource } from 'services/humanResourcesService';
import { Item } from 'shared/types';
import styled from 'styled-components';

type Props = {
  month: Item;
  birthdaysByMonth: BirthdayByMonthResource[];
};

const RowDetail: React.FC<Props> = (props) => {
  const { month, birthdaysByMonth } = props;

  return (
    <PaperContainer>
      <TitleText>{month.label}</TitleText>
      <div>
        {birthdaysByMonth.map((value) =>
          value.birthdayList.map((employeeBirthday) => (
            <BirthdayEmployeeRowContainer>
              <BirthdayDayContainer>{employeeBirthday.birthDayNumber} </BirthdayDayContainer>
              &nbsp;-&nbsp;
              <div>{employeeBirthday.fullName}</div>
            </BirthdayEmployeeRowContainer>
          )),
        )}
      </div>
    </PaperContainer>
  );
};

const TitleText = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 5px;
  background-color: ${(p) => p.theme.palette.secondary.main};
  color: ${(p) => p.theme.palette.common.white};
  font-size: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const BirthdayDayContainer = styled.div`
  font-weight: bold;
`;

const BirthdayEmployeeRowContainer = styled.div`
  display: flex;
  orientation: horizontal;
  padding: 5px;
  font-size: 16px;
`;

const PaperContainer = styled(Paper)`
  background-color: ${(p) => p.theme.palette.common.white};
`;

export default RowDetail;
