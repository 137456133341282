import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteKeyResultArea, KeyResultAreaResource } from 'services/hitgoalsService';

const fixedColumns: Column<KeyResultAreaResource>[] = [
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
  },
];

const variableColumns = (fetchKeyResultAreas: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchKeyResultAreas}
        deleteItem={deleteKeyResultArea}
        setLoading={setLoading}
        url={'reason'}
        name={'Reason'}
        deleteTitle={'Delete Reason'}
        deleteMessage={'Do you want to delete this Reason?'}
      />
    ),
  };
};

const columns = (fetchProjects: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchProjects, setLoading)];
};

export default columns;
