import { Grid } from '@material-ui/core';
import React from 'react';
import { AnniversaryByMonthResource } from 'services/humanResourcesService';
import { Item } from 'shared/types';

import RowDetail from './rows-detail';

type Props = {
  months: Item[];
  anniversariesByMonth: AnniversaryByMonthResource[];
};

const FormRow: React.FC<Props> = (props) => {
  const { months, anniversariesByMonth } = props;

  return (
    <>
      <Grid item xs={3}>
        <RowDetail
          month={months[0]}
          anniversariesByMonth={anniversariesByMonth.filter((value) => value._id === months[0].id)}
        />
      </Grid>
      <Grid item xs={3}>
        <RowDetail
          month={months[1]}
          anniversariesByMonth={anniversariesByMonth.filter((value) => value._id === months[1].id)}
        />
      </Grid>
      <Grid item xs={3}>
        <RowDetail
          month={months[2]}
          anniversariesByMonth={anniversariesByMonth.filter((value) => value._id === months[2].id)}
        />
      </Grid>
      <Grid item xs={3}>
        <RowDetail
          month={months[3]}
          anniversariesByMonth={anniversariesByMonth.filter((value) => value._id === months[3].id)}
        />
      </Grid>
    </>
  );
};

export default FormRow;
