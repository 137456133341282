import { IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import getBoxBackground from 'shared/style/utilities/getBoxBackground';
import getBoxShadow from 'shared/style/utilities/getBoxShadow';
import hexToRGB from 'shared/style/utilities/hexToRGB';
import styled from 'styled-components';

type Props = {
  title: string;
  icon?: string;
  subTitle?: string;
  newPageRedirectionLink?: string | undefined;
  loading?: boolean;
  secondaryRedirectionLink?: string;
};

const CardHeader: React.FC<Props> = (props) => {
  const { children, title, subTitle, newPageRedirectionLink, secondaryRedirectionLink = '', loading, ...rest } = props;

  return (
    <Container {...rest}>
      {children}
      {!children && (
        <>
          <Titles>
            <Title>{title}</Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </Titles>
          <LoadingContainer>{loading && <Spinner />}</LoadingContainer>
          {newPageRedirectionLink && (
            <IconContainer>
              <IconButton href={newPageRedirectionLink} color="primary">
                <AddCircleIcon fontSize={'small'} />
                <Label>New</Label>
              </IconButton>
              {newPageRedirectionLink === '/career-development-plan/new' && (
                <IconButton
                  href={'https://drive.google.com/file/d/1klVlsELm9qnRb_Zb_7WlGYK5TjSVaCFv/view?usp=sharing '}
                  target={'_blank'}
                  color="secondary"
                >
                  <VisibilityIconEye />
                  <Label>Manual</Label>
                </IconButton>
              )}
              {newPageRedirectionLink === '/hitGoals-process/new' && (
                <IconButton color="secondary" href={secondaryRedirectionLink}>
                  <AddCircleIcon fontSize={'small'} />
                  <Label>Copy</Label>
                </IconButton>
              )}
            </IconContainer>
          )}
        </>
      )}
    </Container>
  );
};

const Titles = styled.div``;
const Title = styled.h4`
  color: ${(p) => p.theme.palette.common.white};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
  text-align: left;
`;
const SubTitle = styled.p`
  color: ${(p) => hexToRGB(p.theme.palette.common.white, '0.62')};
  margin: 0;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.common.white};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -20px 15px 0 15px;
  padding: 15px;
  border-radius: 3px;
  border-bottom: none;
  background: ${(p) => getBoxBackground(p.theme.palette.common.black, p.theme.palette.common.black)};
  box-shadow: ${(p) => getBoxShadow(p.theme.palette.common.black)};
  z-index: 3 !important;
`;

const Label = styled.div`
  margin-left: 5px;
  font-size: 22px;
`;

const IconContainer = styled.div`
  margin-left: 5px;
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
`;
const VisibilityIconEye = styled(VisibilityIcon)`
  color: #ff9500;
`;

export default CardHeader;
