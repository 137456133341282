import { saveAs } from 'file-saver';
import { PageResource, Query } from 'hooks/usePaging';
import { monthValues } from 'pages/hr/commonValues';
import { useCallback, useEffect, useState } from 'react';
import { getAllLeaders } from 'services/employeeService';
import { getProjectsForTimesheets } from 'services/projectService';
import {
  getAllTimesheetsQuery,
  getCurrentMonthInfo,
  HoursPerMonth,
  loadAllReportsTimeSheetZip,
  loadPtoReport,
  loadXlsReportTimeSheetFile,
  TimeSheetFilter,
  TimesheetResource,
} from 'services/timesheetService';
import { Item } from 'shared/types';
import { convertNumberToMonth, yearOptions } from 'utils/formatters/date';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';
import notifier from 'utils/notifiers/notifier';

export const useTimesheetCatalogFilter = () => {
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [loadingXlsx, setLoadingXlsx] = useState(false);
  const [downloadingTimesheets, setDownloadingTimesheets] = useState(false);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [projects, setProjects] = useState<Item[]>([]);
  const [months, setMonths] = useState<Item[]>([]);
  const [years, setYears] = useState<Item[]>([]);
  const [currentMonthInfo, setCurrentMonthInfo] = useState<HoursPerMonth>();

  const handleOnReport = async (query: Query & TimeSheetFilter) => {
    await loadXlsReportTimeSheetFile(query.month, query.year);

    setLoadingXlsx(true);
    const data = await loadXlsReportTimeSheetFile(query.month, query.year);

    if (data) {
      const pdfBlob = new Blob([data], { type: 'text/xlsx' });
      const pdfName = `MonthlyReport_${query.month}_${query.year}.xlsx`;

      saveAs(pdfBlob, pdfName);
    } else {
      notifier.error('Error generating XLSX file, please try again...');
    }

    setLoadingXlsx(false);
  };

  const handleOnDownloadAll = async (query: Query & TimeSheetFilter) => {
    setDownloadingTimesheets(true);
    const data = await loadAllReportsTimeSheetZip(query.month, query.year, query.projects);

    if (data) {
      const zipName = `Timesheets - ${convertNumberToMonth(query.month)}, ${query.year}.zip`;
      const blob = new Blob([data], { type: 'application/zip' });

      saveAs(blob, zipName); //file-saver npm package
    } else {
      notifier.error('Error generating ZIP file, please try again...');
    }
    setDownloadingTimesheets(false);
  };

  const handleOnPtoDownload = async (query: Query & TimeSheetFilter) => {
    setLoadingXlsx(true);
    const data = await loadPtoReport(query.month, query.year);

    if (data) {
      const pdfBlob = new Blob([data], { type: 'text/xlsx' });
      const pdfName = `PtoMontlyReport_${query.month}_${query.year}.xlsx`;

      saveAs(pdfBlob, pdfName);
    } else {
      notifier.error('Error generating XLSX file, please try again...');
    }

    setLoadingXlsx(false);
  };

  useEffect(() => {
    async function loadCatalog() {
      const projectsResponse = await getProjectsForTimesheets();
      const currentMonthInfoResponse = await getCurrentMonthInfo();
      const leadersResult = await getAllLeaders();
      const leaderItems = collectionEmployeeToItem(leadersResult);

      setProjects(collectionToItem(projectsResponse));
      setLeaders(leaderItems);
      setMonths(monthValues);
      setYears(yearOptions);
      if (currentMonthInfoResponse) setCurrentMonthInfo(currentMonthInfoResponse);

      setLoadingCatalogs(false);
    }
    loadCatalog();
  }, []);

  return {
    loadingCatalogs,
    leaders,
    projects,
    months,
    years,
    currentMonthInfo,
    handleOnReport,
    loadingXlsx,
    handleOnDownloadAll,
    handleOnPtoDownload,
    downloadingTimesheets,
  };
};

export const useTimesheetsEffect = (filter: Query) => {
  const [timesheets, setTimesheets] = useState<PageResource<TimesheetResource>>({ totalItems: 0, items: [] });
  const [loading, setLoading] = useState(true);

  const fetchTimesheet = useCallback(async () => {
    setLoading(true);

    const timesheetResult = await getAllTimesheetsQuery(filter);

    if (timesheetResult) setTimesheets(timesheetResult);
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    fetchTimesheet();
  }, [fetchTimesheet]);

  return { timesheets, loading, setLoading, fetchTimesheet };
};

export const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};
