import { CircularProgress, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  CategoryResource,
  createDevelopmentPlanTemplate,
  RatingResource,
  updateDevelopmentPlanTemplate,
} from 'services/developmentPlanTemplate';
import styled from 'styled-components';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import DevelopmentPlanCategoryForm from './category';
import { useDevelopmentPlanTemplateEffect } from './hooks';
import RatingForm from './rating';
import { useForm } from './useForm';

const schema = {
  title: yup.string().required().label('Title'),
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

type Props = {};

const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};

const DevelopmentPlanTemplateForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    saveDevelopmentPlanTemplate,
    developmentPlanTemplate,
    creationdate,
    loading,
    setSaveDevelopmentPlanTemplate,
  } = useDevelopmentPlanTemplateEffect(id);

  const history = useHistory();
  const [savingDevelopmentPlan, setSavingDevelopmentPlan] = useState(false);
  const subTitle = id ? 'Update the development plan template' : 'Create a new development plan template';
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';
  const [enabledAddCategory, setEnableAddCategory] = useState(true);
  const [enabledAddRating, setEnabledAddRating] = useState(true);

  const { useInput, isValid } = useForm(
    schema,
    setSaveDevelopmentPlanTemplate,
    saveDevelopmentPlanTemplate,
    saveDevelopmentPlanTemplate,
  );

  const [newCategory, setNewCategory] = useState<CategoryResource>({
    _id: generateKey('id'),
    title: '',
    description: '',
    order: 1,
  });
  const [newRating, setNewRating] = useState<RatingResource>({
    _id: generateKey('id'),
    order: 1,
    description: '',
  });

  const handleOnNewCategory = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      setNewCategory({ ...newCategory, [name!]: value });
    },
    [newCategory],
  );

  const handleAddNewCategory = () => {
    if (newCategory.title !== '' && newCategory.description.trim() !== '') {
      const newCategories = [...saveDevelopmentPlanTemplate.categories, newCategory];

      setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, categories: newCategories });
      setNewCategory({
        _id: generateKey('id'),
        title: '',
        description: '',
        order: 1,
      });
    }
  };

  const handleOnNewRating = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      setNewRating({ ...newRating, [name!]: value });
    },
    [newRating],
  );

  const handleAddNewRating = () => {
    if (newRating.order.toString().trim() !== '' && newRating.description.trim() !== '') {
      const newRatings = [...saveDevelopmentPlanTemplate.ratings, newRating];

      setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, ratings: newRatings });
      setNewRating({
        _id: generateKey('id'),
        order: 1,
        description: '',
      });
    }
  };

  const handleSaveDevelopmentPlanItem = async () => {
    setSavingDevelopmentPlan(true);

    orderRatingsAndCategories();

    if (id) {
      const response = await updateDevelopmentPlanTemplate(saveDevelopmentPlanTemplate, id);

      if (response) notifier.success('Development plan template updated successfully.');
      setSavingDevelopmentPlan(false);
      return;
    }
    try {
      const newDevelopmentPlanTemplate = await createDevelopmentPlanTemplate(saveDevelopmentPlanTemplate);

      if (newDevelopmentPlanTemplate) {
        notifier.success('Development plan template created successfully.');
        history.push(`/development-plan-templates/${newDevelopmentPlanTemplate._id}`);
      }
    } finally {
      setSavingDevelopmentPlan(false);
    }
  };

  const orderRatingsAndCategories = () => {
    let order = 1;

    saveDevelopmentPlanTemplate.categories.forEach((category) => {
      category.order = order++;
    });

    order = 1;
    saveDevelopmentPlanTemplate.ratings.forEach((rating) => {
      rating.order = order++;
    });
  };

  useEffect(() => {
    setEnableAddCategory(newCategory.title === '' || newCategory.description.trim() === '');
  }, [newCategory]);

  useEffect(() => {
    setEnabledAddRating(newRating.order.toString() === '' || newRating.description.trim() === '');
  }, [newRating]);

  if (!loading && !savingDevelopmentPlan) {
    newRating.order = saveDevelopmentPlanTemplate.ratings.length + 1;
  }
  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Development Plan Template: ${!initialLoading ? saveDevelopmentPlanTemplate?.title : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Information</BodyTitle>
          <br />
          <DetailsLineInformation>
            <TextField
              required
              fullWidth
              label="Title"
              variant="outlined"
              name={'title'}
              value={saveDevelopmentPlanTemplate.title}
              {...useInput()}
            />
          </DetailsLineInformation>

          <BodyTitle>Categories</BodyTitle>

          <DetailsLineList>
            {saveDevelopmentPlanTemplate.categories.length ? (
              <>
                <TableTitleContainer>
                  <TableFirstTitleContainer>Title</TableFirstTitleContainer>
                  <TableMiddleTitleContainer>Description</TableMiddleTitleContainer>
                  <TableLastTitleContainer>Action</TableLastTitleContainer>
                </TableTitleContainer>
                {saveDevelopmentPlanTemplate.categories.map((category, index) => (
                  <DevelopmentPlanCategoryForm
                    key={category._id}
                    category={category}
                    index={index}
                    itemsCount={saveDevelopmentPlanTemplate.categories.length}
                    saveDevelopmentPlanTemplate={saveDevelopmentPlanTemplate}
                    setSaveDevelopmentPlanTemplate={setSaveDevelopmentPlanTemplate}
                  />
                ))}
              </>
            ) : (
              <NoRecords>There are no categories to display, try adding a new category. </NoRecords>
            )}
          </DetailsLineList>

          <ParentContainer>
            <ContainerBox>
              <div>
                <Separator></Separator>
              </div>

              <ContainerBox>
                <TextFieldContainer
                  label="New category"
                  variant="outlined"
                  name={'title'}
                  value={newCategory.title}
                  onChange={handleOnNewCategory}
                />
                <TextFieldDescriptionContainer
                  multiline
                  fullWidth
                  rows={4}
                  label="Description"
                  variant="outlined"
                  name={'description'}
                  value={newCategory.description}
                  onChange={handleOnNewCategory}
                />
                <IconContainer>
                  <IconButton disabled={enabledAddCategory} color="primary" onClick={handleAddNewCategory}>
                    <AddCircleIcon fontSize={'large'} />
                  </IconButton>
                </IconContainer>
              </ContainerBox>
            </ContainerBox>
          </ParentContainer>

          <BodyTitle>Ratings</BodyTitle>
          <DetailsLineList>
            {saveDevelopmentPlanTemplate?.ratings?.length ? (
              <>
                <TableTitleContainer>
                  <TableFirstTitleContainer>Order</TableFirstTitleContainer>
                  <TableMiddleTitleContainer>Description</TableMiddleTitleContainer>
                  <TableLastTitleContainer>Action</TableLastTitleContainer>
                </TableTitleContainer>
                {saveDevelopmentPlanTemplate.ratings.map((rating, index) => (
                  <RatingForm
                    rating={rating}
                    key={rating._id}
                    index={index}
                    itemsCount={saveDevelopmentPlanTemplate.ratings.length}
                    saveDevelopmentPlanTemplate={saveDevelopmentPlanTemplate}
                    setSaveDevelopmentPlanTemplate={setSaveDevelopmentPlanTemplate}
                  />
                ))}
              </>
            ) : (
              <NoRecords>There are no records to display, try adding a new rating. </NoRecords>
            )}
          </DetailsLineList>

          <ParentContainer>
            <ContainerBox>
              <div>
                <Separator></Separator>
              </div>

              <ContainerBox>
                <TextFieldContainer
                  label="Order"
                  variant="outlined"
                  name={'order'}
                  value={newRating.order}
                  type="number"
                  disabled
                  onChange={handleOnNewRating}
                />
                <TextFieldDescriptionContainer
                  multiline
                  fullWidth
                  rows={4}
                  label="Description"
                  variant="outlined"
                  name={'description'}
                  value={newRating.description}
                  onChange={handleOnNewRating}
                />
                <IconContainer>
                  <IconButton disabled={enabledAddRating} color="primary" onClick={handleAddNewRating}>
                    <AddCircleIcon fontSize={'large'} />
                  </IconButton>
                </IconContainer>
              </ContainerBox>
            </ContainerBox>
          </ParentContainer>

          {id && (
            <>
              <BodyTitle>Creation information</BodyTitle>
              <br />
              <DetailsLineInformation>
                <TextFieldInformation
                  disabled={true}
                  label="Created by"
                  variant="outlined"
                  value={
                    developmentPlanTemplate?.createdBy
                      ? developmentPlanTemplate.createdBy.name + ' ' + developmentPlanTemplate.createdBy.lastName
                      : ''
                  }
                />
                <TextFieldInformation disabled={true} label="Creation date" variant="outlined" value={creationdate} />
              </DetailsLineInformation>
            </>
          )}

          <ButtonContainer>
            {savingDevelopmentPlan && <Spinner size={30} />}
            <Submit disabled={savingDevelopmentPlan || loading || !isValid} onClick={handleSaveDevelopmentPlanItem}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};
const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;
const ContainerBox = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 50px;
  text-align: left;
`;
const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const TextFieldInformation = styled(TextField)`
  width: 300px;
  padding-right: 20px;
`;

const DetailsLineInformation = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const DetailsLineList = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: -15px;
`;

const TableFirstTitleContainer = styled.h4`
  text-align: left;
  font-size: 20;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  flex: 1;
  padding-left: 105px;
  width: 300px;
  color: ${(p) => p.theme.palette.primary.light};
`;

const TableMiddleTitleContainer = styled.h4`
  text-align: left;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  width: 100%;
  color: ${(p) => p.theme.palette.primary.light};
  flex: 1;
  padding-left: 50px;
`;

const TableLastTitleContainer = styled.h4`
  text-align: right;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${(p) => p.theme.palette.primary.light};
  flex: 1;
  padding-right: 3%;
`;
const ParentContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
  margin-left: 20px;
  padding-right: 35px;
`;
const TextFieldContainer = styled(TextField)`
  width: 300px;
  display: block;
`;

const TextFieldDescriptionContainer = styled(TextField)`
  width: 100%;
  display: block;
  margin-left: 10px;
`;

const IconContainer = styled.div`
  padding-top: 25px;
`;

const Separator = styled.div`
  margin-left: -30px;
  orientation: vertical;
  display: flex;
  width: 50px;
  padding-top: 10px;
`;

export default DevelopmentPlanTemplateForm;
