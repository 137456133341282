import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ExportButton from 'components/export-button';
import MultiSelectBase from 'components/multi-select';
import SingleSelectBase from 'components/single-select';
import { Query } from 'hooks/usePaging';
import { monthValues } from 'pages/hr/commonValues';
import React, { useCallback, useEffect, useRef } from 'react';
import { EmployeeFilter, loadCsvFile } from 'services/employeeService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  employees: Item[];
  leaders: Item[];
  projects: Item[];
  jobTitles: Item[];
  onlyLeaders: boolean;
  filter: EmployeeFilter & Query;
  setFilter(value: EmployeeFilter & Query): void;
  search: string;
  defaultFilter: EmployeeFilter;
  setDefaultFilter: any;
  totalItems: number;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_employees';

const Filter: React.FC<Props> = (props) => {
  const { filter, leaders, projects, jobTitles, setFilter, defaultFilter, setDefaultFilter, totalItems } = props;

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setFilter({ ...filter, ...defaultFilter, page: 1 }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter.search]);

  useEffect(() => {
    setFilter({ ...filter, ...defaultFilter, page: 1 });
  }, [defaultFilter.leaders, defaultFilter.projects, defaultFilter.jobTitles, defaultFilter.salaryPeriodMonth, defaultFilter.clientSalaryPeriodMonth]);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: value, page: 1 });
    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByName'}
        inputProps={{
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={'Search by Name'}
        variant="outlined"
        name="search"
        value={defaultFilter.search}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'leaderSelect'}
        name="leaders"
        options={leaders}
        currentValues={filter.leaders || []}
        label={'Leaders'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'projectSelect'}
        name="projects"
        options={projects}
        label={'Projects'}
        onChange={handleOnChange}
        currentValues={filter.projects}
      />
      <SingleSelect
        id={'jobTitleSelect'}
        name="jobTitles"
        options={jobTitles}
        label={'Job Titles'}
        onChange={handleOnChange}
        value={filter.jobTitles}
      />
      <SingleSelect
        id={'salaryPeriodSelect'}
        name="salaryPeriodMonth"
        options={monthValues}
        label={'Salary Period'}
        onChange={handleOnChange}
        value={filter.salaryPeriodMonth}
      />
      <SingleSelect
        id={'clientSalaryPeriodSelect'}
        name="clientSalaryPeriodMonth"
        options={monthValues}
        label={'Client Salary Period'}
        onChange={handleOnChange}
        value={filter.clientSalaryPeriodMonth}
      />
      {totalItems > 0 ? (
        <ExportButton
          filter={filter}
          filename={'EmployeeJobTitle.csv'}
          totalRows={totalItems}
          csvDataLoader={loadCsvFile}
        />
      ) : undefined}
    </Container>
  );
};

const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
`;

const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 0;
`;

const TextFieldWrapper = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export default Filter;
