import { EmployeeAndLeader, Item } from 'shared/types';

export const collectionToItem = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x._id, label: x.name }));
};
export const collectionToItemReason = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x._id, label: x.reason }));
};
export const collectionToItemStatus = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x._id, label: x.statusName }));
};

export const collectionEmployeeToItem = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x._id, label: `${x.name} ${x.lastName}` }));
};

export const collectionEmployeeWithLeader = (collection: any[]) => {
  return collection.map<EmployeeAndLeader>((x) => ({ employeeId: x._id, leaderId: x.leader }));
};

export const countriesToItem = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x.CountryName, label: x.CountryName }));
};

export const statesToItem = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x.StateName, label: x.StateName }));
};

export const citiesToItem = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x, label: x }));
};

export const collectionToItemWithTitle = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x._id, label: x.title }));
};
export const collectionToItemOnboardingProcess = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x.name, label: x.name }));
};
export const collectionToItemIdLabel = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x.name, label: x.name }));
};
export const collectionToItemExitProcedure = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x.name, label: x.name }));
};
export const collectionToReasonId = (collection: any[]) => {
  return collection.map<Item>((x) => ({ id: x._id, label: x.reason }));
};
