import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteEmployeeProjectHistory, ProjectHistoryListResource } from 'services/employeeService';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<ProjectHistoryListResource>[] = [
  {
    label: 'EffectiveDate',
    sortKey: 'effectiveDate',
    path: 'effectiveDate',
    render: (item) => {
      const formatedDate = item.effectiveDate ? stringDateToHumanFormat(item.effectiveDate) : '';

      return formatedDate;
    },
  },
  {
    label: 'Project Change Description',
    sortKey: 'projectFrom.name',
    path: 'projectFrom.name',
    render: (item) => {
      return (
        <p>
          {item.projectFrom?.name || ''} → <strong>{item.projectTo.name}</strong>
        </p>
      );
    },
  },
  {
    label: 'Comments',
    sortKey: 'comments',
    path: 'comments',
    render: (item) => {
      const commentsText = item.comments ? item.comments : '-';

      return commentsText;
    },
  },
];

const variableColumns = (fetchProjectHistory: any, setLoading: any, employeeId: string) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        fetchItems={fetchProjectHistory}
        setLoading={setLoading}
        url={`employees/${employeeId}/project-history`}
        name={'Project History'}
        deleteEnable
        deleteTitle="Delete project history record"
        deleteMessage="Do you want to delete this record?"
        deleteItem={deleteEmployeeProjectHistory}
      />
    ),
  };
};
const columns = (fetchSalaries: any, setLoading: any, employeeId: string) => {
  return [...fixedColumns, variableColumns(fetchSalaries, setLoading, employeeId)];
};

export default columns;
