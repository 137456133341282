import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeResource, getEmployeeById } from 'services/employeeService';
import { getSalariesByEmployeeId, SalaryResource } from 'services/salaryService';

export const useSalariesEffect = (employee: EmployeeResource, query: Query) => {
  const defaultResponse: PageResource<SalaryResource> = { totalItems: 0, items: [] };
  const [salaries, setSalaries] = useState(defaultResponse);
  const [loadingSalaries, setLoadingSalaries] = useState(true);

  const fetchSalaries = useCallback(async () => {
    if (!employee) return;
    setLoadingSalaries(true);
    const salariesResult = await getSalariesByEmployeeId(employee._id, query);

    setSalaries(salariesResult);

    setLoadingSalaries(false);
  }, [employee, query]);

  useEffect(() => {
    fetchSalaries();
  }, [fetchSalaries, query]);

  return {
    employee,
    salaries,
    loadingSalaries,
    setLoading: setLoadingSalaries,
    fetchSalaries,
  };
};

export const useCatalogs = (id: string) => {
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [employee, setEmployee] = useState<EmployeeResource>();

  useEffect(() => {
    async function fetchCatalogs() {
      setLoadingCatalogs(true);

      const employeeResult = await getEmployeeById(id!);

      if (employeeResult) {
        setEmployee(employeeResult);
      }

      setLoadingCatalogs(false);
    }

    fetchCatalogs();
  }, [id]);

  return {
    employee,
    loadingCatalogs,
  };
};
