import { CircularProgress, Grid, IconButton, Switch, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import DateInputPicker from 'components/date-input-picker';
import SingleSelect from 'components/single-select';
import { useForm } from 'hooks/useForm';
import { useState } from 'react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createProcess, updateProcess } from 'services/processService';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import { getEmployeeName } from 'utils/general/generalMethods';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCatalogs, useProcessEffect, useProcessType } from './hooks';

const schema = {
  employee: yup.string().required().label('Employee'),
  periodicity: yup.number().required().label('Periodicity'),
  nextPeriodStartDate: yup.string().required().label('NextPeriodStartDate'),
  isActive: yup.boolean().required().label('Status'),
  processType: yup.string().required().label('ProcessType'),
  careerDevelopmentPlanTemplate: yup.string().optional().label('careerDevelopmentPlanTemplate'),
};

const ProcessForm: React.FC = () => {
  const { id, employeeId, processTypeId } = useParams<{ id: string; employeeId: string; processTypeId: string }>();
  const {
    employees,
    processTypes,
    cdpTemplates,
    cdpTemplatesAllInfo,
    periodicityOptions,
    loading: catalogLoading,
  } = useCatalogs();
  const { saveProcess, loading, process } = useProcessEffect(id, employeeId, processTypeId);
  const { useInput, useSwitch, values, isValid, setIsValid } = useForm(saveProcess, schema, catalogLoading);
  const title = id ? getEmployeeName(process?.employee) : 'New record';
  const subTitle = id ? 'Update the process' : 'Create a new process';
  const [showCDP, setShowCDP] = useState(false);
  const getProcessTypeName = (processType: String) => {
    if (!processType || !processTypes) return '';
    var selectedProcessType = processTypes.filter((pt) => pt.id === processType);

    return selectedProcessType && selectedProcessType[0] ? selectedProcessType[0].label : '';
  };

  const { processType, setProcessType } = useProcessType(
    values.processType,
    setShowCDP,
    getProcessTypeName(values.processType),
  );

  const [selectedCDPTemplate, setSelectedCDPTemplate] = useState(values.careerDevelopmentPlanTemplate);

  const getCdpInfo = (processType?: String) => {
    if (!processType) return '';
    var selectedCDP = cdpTemplatesAllInfo.filter((cdp) => cdp._id === processType);

    let text = '';
    let categoriesText = '';
    let ratingsText = '';

    if (selectedCDP[0]) {
      selectedCDP[0].categories.forEach((category) => {
        if (categoriesText !== '') categoriesText = categoriesText.concat(', ');
        categoriesText = categoriesText.concat(category.title);
      });
      selectedCDP[0].ratings.forEach((rating) => {
        if (ratingsText !== '') ratingsText = ratingsText.concat(', ');
        ratingsText = ratingsText.concat(rating.description);
      });

      text = text.concat('Categories: ', categoriesText, ' | ', 'Ratings: ', ratingsText);
    }

    return text || '';
  };

  const handleOnProcessTypeChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    const selectedProcessType = event.target.value as string;
    const processTypeName = getProcessTypeName(selectedProcessType);

    if (processTypeName !== 'Career Development Plan') {
      values.careerDevelopmentPlanTemplate = '';
      setSelectedCDPTemplate('');
      values.periodicity = 1;
      setShowCDP(false);
    } else {
      values.periodicity = 4;
      setShowCDP(true);
    }

    values.processType = selectedProcessType || '';
    setProcessType(selectedProcessType || '');
    if (values.employee && values.processType && processTypeName !== 'Career Development Plan') {
      setIsValid(true);
    } else if (
      values.processType &&
      processTypeName === 'Career Development Plan' &&
      !values.careerDevelopmentPlanTemplate
    ) {
      setIsValid(false);
    } else if (!values.processType) {
      setIsValid(false);
    }
  };

  const handleOnCDPTemplateChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    const selectedCDP = event.target.value as string;

    values.careerDevelopmentPlanTemplate = selectedCDP || '';

    setSelectedCDPTemplate(selectedCDP || '');
    if (values.employee && processType && selectedCDP) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const [isSubmiting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const handleSaveProcess = async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    setIsSubmitting(true);

    try {
      if (id) {
        const response = await updateProcess(values, id);

        if (response.data) {
          notifier.success('Process updated successfully.');
        }
      } else {
        if (
          getProcessTypeName(values.processType) === 'Career Development Plan' &&
          !values.careerDevelopmentPlanTemplate
        ) {
          notifier.error('Career Development Plan not selected');
          setIsSubmitting(false);
          return;
        }

        const newProcess = await createProcess(values);

        if (newProcess) {
          history.push(`/process-assignment/${newProcess._id}`);
          notifier.success('Process saved successfully.');
        }
      }
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    }

    setIsSubmitting(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={loading}
          title={`Process: ${!loading ? title : 'Loading'}`}
          subTitle={subTitle}
          newPageRedirectionLink={id ? '/process-assignment/new' : ''}
        />
        {loading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBody>
          <InfoContainer>
            <BodyTitle>Assignment and type</BodyTitle>
            <DetailsLine>
              <OptionsContainer>
                <SelectContainer>
                  <SingleSelect
                    id={'employeeSelect'}
                    label={'Employee'}
                    options={employees}
                    {...useInput('employee')}
                    required
                  />
                </SelectContainer>
                <SelectContainer>
                  <SingleSelect
                    id={'processTypeSelect'}
                    label={'Process Type'}
                    options={processTypes}
                    value={processType}
                    required
                    onChange={handleOnProcessTypeChange}
                  />
                </SelectContainer>
                <SelectContainerTooltip
                  style={{
                    display: showCDP ? 'block' : 'none',
                  }}
                >
                  <SingleSelectField
                    id={'careerDevPlanSelect'}
                    label={'Career dev. plan templ.'}
                    options={cdpTemplates}
                    value={values.careerDevelopmentPlanTemplate}
                    onChange={handleOnCDPTemplateChange}
                  />
                  {values.careerDevelopmentPlanTemplate !== '' && (
                    <TooltipField title={getCdpInfo(values.careerDevelopmentPlanTemplate)}>
                      <IconButton aria-label="alertsEmails">
                        <VisibilityIcon />
                      </IconButton>
                    </TooltipField>
                  )}
                </SelectContainerTooltip>
              </OptionsContainer>
            </DetailsLine>
          </InfoContainer>
          <div />
          <InfoContainer>
            <BodyTitle>Process Info</BodyTitle>
            <DetailsLine>
              <OptionsContainer>
                <SelectContainer>
                  <SingleSelect
                    id={'periodicitySelect'}
                    label={'Periodicity (Months)'}
                    empty={false}
                    options={periodicityOptions}
                    required
                    {...useInput('periodicity')}
                  />
                </SelectContainer>

                <TypographyContainer component={'span'}>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Inactive</Grid>
                    <Grid item>
                      <Switch id={'switchStatusSelect'} name="status" {...useSwitch('isActive')} />
                    </Grid>
                    <Grid item>Active</Grid>
                  </Grid>
                </TypographyContainer>
              </OptionsContainer>
            </DetailsLine>
          </InfoContainer>

          <BodyTitle>Next period start date</BodyTitle>
          <DetailsLine>
            <SelectContainer>
              <DateInputPicker
                id={'periodStartSelect'}
                views={['year', 'month']}
                label="Next Date"
                minDate={new Date()}
                {...(useInput('nextPeriodStartDate') as any)}
              />
            </SelectContainer>
          </DetailsLine>
          <ButtonContainer>
            {isSubmiting && <Spinner size={30} />}
            <Submit
              id={'saveProcessButton'}
              disabled={isSubmiting || loading || catalogLoading || !isValid}
              onClick={handleSaveProcess}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const OptionsContainer = styled.div`
  float: left;
  display: block;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
`;

const SelectContainer = styled.div`
  min-width: 210px;
  padding: 0 5px;
  display: block;
  float: left;
`;

const SelectContainerTooltip = styled.div`
  min-width: 270px;
  padding: 0 5px;
  display: block;
  float: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const InfoContainer = styled.div`
  display: inline-block;
`;

const TypographyContainer = styled(Typography)<{ component: string }>`
  margin-left: 15px;
`;

const TooltipField = styled(Tooltip)`
  float: right;
`;

const SingleSelectField = styled(SingleSelect)`
  width: 210px;
`;

export default ProcessForm;
