import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import SingleSelectNoValue from 'components/single-select-no-value';
import { isPermissionGranted, ROLE_ADMIN, ROLE_FINANCE, ROLE_HR } from 'helpers/roleHelper';
import { useForm } from 'hooks/useForm';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { updateReferral } from 'services/referralService';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';

import { useCatalogs, useReadOnlyEffect, useReferralEffect } from './hooks';

saveInfoInLocalStorage('inputValue', '', '_form');
type Props = {};

const ReferralsForm: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const { statusesReferral, loading: catalogLoading } = useCatalogs(id);

  const { referral, saveReferral, setSaveReferral, setStatusReferralSelected, loading } = useReferralEffect(id);
  const [savingReferral, setSavingReferral] = useState(false);
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';

  const handleSaveScreenReferral = async () => {
    setSavingReferral(true);

    const saveReferralObject = {
      statusReferral: saveReferral!.statusReferral,
    };
    const response = await updateReferral(saveReferralObject, id);

    if (response) {
      notifier.success('Referral updated successfully.');
      saveInfoInLocalStorage('inputValue', '', '_form');
    }

    setSavingReferral(false);
    return;
  };

  const handleStatusReferralChange = (event: any) => {
    const { value } = event.target;

    setStatusReferralSelected(value);

    setSaveReferral({ ...saveReferral, statusReferral: value });
  };

  const user = useCurrentUser();
  const userHasAdminPermission = () => {
    return isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_FINANCE], user);
  };
  const localReadOnly = localStorage.getItem('referralMode');
  const { isReadOnly } = useReadOnlyEffect(
    localReadOnly,
    !localStorage.getItem('inputValue_form') ||
      (localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') !== 'changed') ||
      false,
    userHasAdminPermission(),
  );

  return (
    <Container>
      <Card>
        <Prompt
          when={
            localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') === 'changed'
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <CardHeader
          loading={initialLoading}
          title={`Employee: ${!initialLoading ? `${referral?.name} ${referral?.lastName}` : 'Loading'}`}
          subTitle={`Refer By: ${
            !initialLoading ? `${referral?.referBy?.name} ${referral?.referBy?.lastName}` : 'Loading'
          }`}
        />

        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Referral Information:</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainer>
                <SingleSelectNoValue
                  id={'statusReferral'}
                  name={'statusReferral'}
                  label={'Status'}
                  options={statusesReferral}
                  onChange={handleStatusReferralChange}
                  value={saveReferral.statusReferral}
                ></SingleSelectNoValue>
              </SelectContainer>
            </OptionsContainer>
          </DetailsLine>

          <ButtonContainer>
            {savingReferral && <Spinner size={30} />}
            <Submit disabled={savingReferral || loading || isReadOnly} onClick={handleSaveScreenReferral}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const SelectContainer = styled.div`
  min-width: 160px;
  padding: 0 5px;
  width: 300px;
  float: left;
`;

const OptionsContainer = styled.div`
  float: left;
  display: block;
`;

const Container = styled.div`
  margin: 0 15px;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
  float: right;
`;

const DetailsLine = styled.div`
  display: block;
  padding-left: 30px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300px;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: 15px;
`;

export default ReferralsForm;
