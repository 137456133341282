import React from 'react';
import hexToRGB from 'shared/style/utilities/hexToRGB';
import styled from 'styled-components';

const Card: React.FC = (props) => {
  const { children, ...rest } = props;

  return <Container {...rest}>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: 0.875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #fff;
  box-shadow: ${(p) => `0 1px 4px 0 ${hexToRGB(p.theme.palette.common.black, '0.14')}`};
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
`;

export default Card;
