import usePaging, { PageResource, Query } from 'hooks/usePaging';
import { useEffect, useState } from 'react';
import { getKeyResultAreasQuery, KeyResultAreaResource } from 'services/hitgoalsService';
import { DEFAULT_QUERY_WITH_SEARCH } from 'utils/general/generalMethods';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import { SearchFilter } from '.';

const localStoragePrefix = '_areas';

export const useAreasEffect = () => {
  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY_WITH_SEARCH);
  const defaultResponse: PageResource<KeyResultAreaResource> = { totalItems: 0, items: [] };
  const [areas, setAreas] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>(localStorage.getItem('searchText_areas') || '');
  const fetchAreas = async () => {
    if (searchText === '') {
      setLoading(true);
    }
    query.searchText = searchText;
    const newAreas = await getKeyResultAreasQuery(query);

    setLoading(false);
    setAreas(newAreas);
  };

  const onSearchTextChange = (event: any) => {
    const currentValue = event.target.value;

    saveInfoInLocalStorage('searchText', currentValue, localStoragePrefix);
    setQuery({ ...query, page: 1 });
    setSearchText(currentValue);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAreas();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText, query]);

  return {
    areas,
    loading,
    searchText,
    setLoading,
    fetchAreas,
    onSearchTextChange,
    handlePageChange,
    handleColumnSorting,
    query,
  };
};
