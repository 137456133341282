import { Divider, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useState } from 'react';
import { CategoryResource, SaveDevelopmentPlanResource } from 'services/developmentPlanTemplate';
import styled from 'styled-components';
import * as yup from 'yup';

import { useForm } from '../useForm';

type Props = {
  category: CategoryResource;
  setSaveDevelopmentPlanTemplate: any;
  saveDevelopmentPlanTemplate: SaveDevelopmentPlanResource;
  index: number;
  itemsCount: number;
};

const schema = {
  title: yup.string().required().label('Title'),
  description: yup.string().required().label('Description'),
};

const DevelopmentPlanCategoryForm: React.FC<Props> = (props) => {
  const { category, setSaveDevelopmentPlanTemplate, saveDevelopmentPlanTemplate, index, itemsCount } = props;
  const [ownCategory, setOwnCategory] = useState(category);
  const { useInput } = useForm(schema, setOwnCategory, ownCategory, category);

  const handleDelete = useCallback(() => {
    const newCategories = saveDevelopmentPlanTemplate.categories.filter((_, i) => i !== index);

    setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, categories: newCategories });
  }, [index, saveDevelopmentPlanTemplate, setSaveDevelopmentPlanTemplate]);

  const handleMovingUp = useCallback(() => {
    const tmp = saveDevelopmentPlanTemplate.categories[index - 1];

    saveDevelopmentPlanTemplate.categories[index - 1] = saveDevelopmentPlanTemplate.categories[index];
    saveDevelopmentPlanTemplate.categories[index] = tmp;

    const newCategories = saveDevelopmentPlanTemplate.categories;

    setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, categories: newCategories });
  }, [index, saveDevelopmentPlanTemplate, setSaveDevelopmentPlanTemplate]);

  const handleMovingDown = useCallback(() => {
    const tmp = saveDevelopmentPlanTemplate.categories[index + 1];

    saveDevelopmentPlanTemplate.categories[index + 1] = saveDevelopmentPlanTemplate.categories[index];
    saveDevelopmentPlanTemplate.categories[index] = tmp;

    const newCategories = saveDevelopmentPlanTemplate.categories;

    setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, categories: newCategories });
  }, [index, saveDevelopmentPlanTemplate, setSaveDevelopmentPlanTemplate]);

  return (
    <ParentContainer>
      <Container>
        <div>
          <IconContainerArrows>
            <IconButton color="primary" onClick={handleMovingUp} disabled={index === 0}>
              <UpIcon fontSize={'default'} />
            </IconButton>
            <IconButton color="primary" onClick={handleMovingDown} disabled={index === itemsCount - 1}>
              <DownIcon fontSize={'default'} />
            </IconButton>
          </IconContainerArrows>
        </div>

        <Container>
          <TextFieldContainer
            label="Category"
            variant="outlined"
            name={'title'}
            {...useInput()}
            value={ownCategory.title}
          />
          <TextFieldDescriptionContainer
            multiline
            fullWidth
            rows={3}
            label="Description"
            variant="outlined"
            name={'description'}
            value={ownCategory.description}
            {...useInput()}
          />
          <IconContainer>
            <IconButton color="primary" onClick={handleDelete}>
              <DeleteIcon fontSize={'large'} />
            </IconButton>
          </IconContainer>
        </Container>
      </Container>

      <DividerContainer />
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 50px;
  text-align: left;
`;

const TextFieldContainer = styled(TextField)`
  width: 300px;
  display: block;
`;

const TextFieldDescriptionContainer = styled(TextField)`
  width: 100%;
  display: block;
  margin-left: 10px;
`;

const IconContainer = styled.div`
  padding-top: 25px;
`;

const IconContainerArrows = styled.div`
  margin-left: -30px;
  orientation: vertical;
  display: flex;
  width: 50px;
  padding-top: 10px;
`;

const DividerContainer = styled(Divider)`
  margin-bottom: 25px;
`;

export default DevelopmentPlanCategoryForm;
