import { useCallback, useEffect, useState } from 'react';
import {
  CategoryResource,
  DevelopmentPlanResource,
  getDevelopmentPlanById,
  RatingResource,
  SaveDevelopmentPlanResource,
} from 'services/developmentPlanTemplate';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const defaultDevelopmentPlanTemplateResource = {
  title: '',
  categories: new Array<CategoryResource>(),
  ratings: new Array<RatingResource>(),
};

export const useDevelopmentPlanTemplateEffect = (id?: string) => {
  const [saveDevelopmentPlanTemplate, setSaveDevelopmentPlanTemplate] = useState<SaveDevelopmentPlanResource>(
    defaultDevelopmentPlanTemplateResource,
  );
  const [developmentPlanTemplate, setDevelopmentPlanTemplate] = useState<DevelopmentPlanResource>();
  const [creationdate, setCreationDate] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchDevelopmentPlanTemplate = useCallback(async () => {
    setLoading(true);
    const developmentPlanTemplateResult = await getDevelopmentPlanById(id!);

    if (developmentPlanTemplateResult) {
      setSaveDevelopmentPlanTemplate(mapDevelopmentPlanTemplateResponse(developmentPlanTemplateResult));
      setDevelopmentPlanTemplate(developmentPlanTemplateResult);
      setCreationDate(stringDateToHumanFormat(developmentPlanTemplateResult.creationDate.toString()));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchDevelopmentPlanTemplate();
  }, [id, fetchDevelopmentPlanTemplate]);

  return {
    saveDevelopmentPlanTemplate,
    developmentPlanTemplate,
    creationdate,
    loading,
    setSaveDevelopmentPlanTemplate,
  };
};

const mapDevelopmentPlanTemplateResponse = (developmentPlanTemplate: DevelopmentPlanResource) => {
  return {
    title: developmentPlanTemplate.title,
    categories: developmentPlanTemplate.categories,
    ratings: developmentPlanTemplate.ratings,
  } as DevelopmentPlanResource;
};
