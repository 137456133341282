import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getJobTitlesQuery, JobTitleResource } from 'services/jobTitleService';

import { SearchFilter } from '.';


export const useJobTitlesEffect = (query: Query & SearchFilter) => {
  const defaultResponse: PageResource<JobTitleResource> = { totalItems: 0, items: [] };
  const [jobTitles, setJobTitles] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);

  const fetchJobTitles = useCallback(async () => {
    setLoading(true);

    const newJobTitles = await getJobTitlesQuery(query);

    setLoading(false);
    setJobTitles(newJobTitles);
  }, [query]);

  useEffect(() => {
    fetchJobTitles();
  }, [fetchJobTitles]);

  return { jobTitles, loading, setLoading, fetchJobTitles };
};
