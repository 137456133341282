import { CircularProgress } from '@material-ui/core';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import FullScreenLoading from 'components/fullScreenLoading';
import StandardTable from 'components/standard-table';
import { saveAs } from 'file-saver';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loadPdfTimeSheetFileInfo, TimeSheetFilter } from 'services/timesheetService';
import styled from 'styled-components';
import constants from 'utils/constants';
import { stringDateToMonthYearFormat } from 'utils/formatters/date';
import notifier from 'utils/notifiers/notifier';

import columns from './columns';
import Filter from './filter';
import { useInitialLoading, useTimesheetCatalogFilter, useTimesheetsEffect } from './hooks';

const currentDate = new Date();

const TimeSheetSummaryForm: React.FC = () => {
  const localLeadersFilter = localStorage.getItem('leaders_timesheets');
  const localProjectsFilter = localStorage.getItem('projects_timesheets');
  const localMonthFilter = localStorage.getItem('month_timesheets');
  const localYearFilter = localStorage.getItem('year_timesheets');
  const localSearchFilter = localStorage.getItem('search_timesheets');
  const history = useHistory();

  const DEFAULT_QUERY: Query & TimeSheetFilter = {
    leaders: localLeadersFilter?.split(',') || [],
    projects: localProjectsFilter?.split(',') || [],
    month: localMonthFilter || '0' + (currentDate.getMonth() + 1),
    year: localYearFilter || currentDate.getFullYear().toString(),
    search: localSearchFilter || '',
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'project.name',
  };

  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const { timesheets, loading } = useTimesheetsEffect(query);
  const {
    projects,
    leaders,
    months,
    years,
    loadingCatalogs,
    currentMonthInfo,
    handleOnReport,
    handleOnDownloadAll,
    handleOnPtoDownload,
    loadingXlsx,
    downloadingTimesheets,
  } = useTimesheetCatalogFilter();
  const isLoading = loading || loadingCatalogs || loadingXlsx;
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_QUERY);
  const initialLoading = useInitialLoading(loading || loadingCatalogs);

  const checkTimeSheetEmployee = (timesheetId: string, employeeId: string) => {
    history.push(`/timesheets-summary/${employeeId}?timesheetId=${timesheetId}`);
  };

  const downloadTimeSheetEmployee = async (
    timesheetId: string,
    employeeName: string,
    dateSelected: string,
    projectName: string,
  ) => {
    const data = await loadPdfTimeSheetFileInfo(timesheetId);

    if (data) {
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      const pdfName = `${projectName} - ${employeeName} - ${stringDateToMonthYearFormat(dateSelected)}.pdf`;

      saveAs(pdfBlob, pdfName);
    } else {
      notifier.error('Error generating PDF file, please try again...');
    }
  };

  return (
    <Container>
      <FullScreenLoading
        isOpen={downloadingTimesheets}
        title={constants.MSG_DOWNLOAD_ALL_TIMESHEETS}
        subtitle={constants.MSG_LONG_PROCESS_WAIT}
      />
      <Card>
        <CardHeader
          title="Time sheets summary"
          subTitle={`Hours this month: ${currentMonthInfo ? currentMonthInfo.totalHours : '-'}`}
          loading={isLoading}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter
              leaders={leaders}
              projects={projects}
              months={months}
              years={years}
              setFilter={setQuery}
              filter={query}
              defaultFilter={defaultFilter}
              setDefaultFilter={setDefaultFilter}
              // eslint-disable-next-line react/jsx-no-bind
              handleOnReport={() => handleOnReport(defaultFilter)}
              // eslint-disable-next-line react/jsx-no-bind
              handleOnDownloadAll={() => handleOnDownloadAll(defaultFilter)}
              // eslint-disable-next-line react/jsx-no-bind
              handleOnPtoDownload={() => handleOnPtoDownload(defaultFilter)}
            />
            {timesheets?.items.length ? (
              <StandardTable
                keyColumn="_id"
                data={timesheets.items}
                totalItems={timesheets.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(checkTimeSheetEmployee, downloadTimeSheetEmployee)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  height: 91vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default TimeSheetSummaryForm;
