/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import React from 'react';
import { HistoryOneOnOneResource, OneOnOneResource } from 'services/oneOnOneService';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<OneOnOneResource>[] = [
  {
    label: 'Assigned to',
    sortKey: '',
    path: 'leaderAssigned.name',
    render: (item) => `${item.leaderAssigned ? item.leaderAssigned.name + ' ' + item.leaderAssigned.lastName : '-'}`,
  },
  {
    label: 'Status',
    sortKey: '',
    path: 'status.name',
    render: (item) => <StatusHighlighter name={item.status.name} />,
  },
  {
    label: 'Period',
    sortKey: '',
    path: 'creationDate',
    render: (item) => {
      return stringDateToHumanFormat(item.creationDate);
    },
  },
];

const variableColumns = (onViewPressed: any) => {
  return {
    path: 'check',
    render: (item: HistoryOneOnOneResource) => (
      <div>
        {(item.status.name === 'Complete' || item.status.name === 'Not Done') && (
          <IconButton onClick={() => onViewPressed(item._id)} color="primary">
            <VisibilityIcon />
          </IconButton>
        )}
        {(item.status.name === 'Open' || item.status.name === 'In Progress') && (
          <IconButton onClick={() => onViewPressed(item._id)} color="primary">
            <EditIcon />
          </IconButton>
        )}
      </div>
    ),
  };
};
const columns = (onViewPressed: any) => {
  return [...fixedColumns, variableColumns(onViewPressed)];
};

export default columns;
