import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import DetailedTable from 'components/detailed-table';
import { useDisplayModalEffect } from 'hooks/useGeneralEffect';
import usePaging, { Query } from 'hooks/usePaging';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { EmployeeResourceCompact } from 'services/employeeService';
import { ReportFilter } from 'services/oneOnOneService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import actionItemColumns from './actionItemColums';
import Filter from './filter';
import { useCatalogFilter, useReportsEffect } from './hooks';
import EmployeeListModal from './modal';
import oneOnOneColumns from './oneOnOneColumns';

clearLocalStorage(); //Clear local storage on refresh

const OneOnOneReportList: React.FC = () => {
  const localLeadersFilter = localStorage.getItem('leaderAssigned_1on1Report');
  const localStartDate = localStorage.getItem('startDate_1on1Report');
  const localEndDate = localStorage.getItem('endDate_1on1Report');

  const DEFAULT_QUERY: Query & ReportFilter = {
    leaderAssigned: localLeadersFilter?.split(',') || [],
    startDate: localStartDate
      ? moment.utc(localStartDate).toDate()
      : moment.utc().startOf('month').startOf('day').toDate(),
    endDate: localEndDate
      ? moment.utc(localEndDate).toDate()
      : moment.utc().add(1, 'month').startOf('month').startOf('day').toDate(),
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'leaderAssigned.name',
  };

  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const {
    handlePageChange: handlePageChangeActionItem,
    handleColumnSorting: handleColumnSortingActionItem,
    query: queryActionItem,
    setQuery: setQueryActionItem,
  } = usePaging(DEFAULT_QUERY);
  const { oneOnOneReport, actionItemReport, loading: actionItemLoading } = useReportsEffect(query, queryActionItem);
  const { leaders, loading: catalogLoading } = useCatalogFilter();
  const initialLoading = useInitialLoading(actionItemLoading || catalogLoading);
  const isLoading = actionItemLoading || catalogLoading;
  const { showModal, setShowModal } = useDisplayModalEffect(false);
  const [employeeList, setEmployeeList] = useState<EmployeeResourceCompact[]>([]);

  const onDetailPressed = (employeesList: EmployeeResourceCompact[]) => {
    setEmployeeList(employeesList);
    setShowModal(true);
  };

  return (
    <Container>
      <Card>
        <CardHeader title="One on one reports" newPageRedirectionLink="/action-items/new" loading={isLoading} />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}

        {!initialLoading && (
          <CardBody key="oneOnOneReportCardBody">
            <Filter
              leaders={leaders}
              setFilterOneOnOnes={setQuery}
              filterOneOnOnes={query}
              filterActionItems={queryActionItem}
              setFilterActionItems={setQueryActionItem}
            />

            <BodyTitle>One on one Report</BodyTitle>

            {oneOnOneReport?.items?.length ? (
              <DetailedTable
                key="oneOnOneReportTable"
                keyColumn={'_id'}
                data={oneOnOneReport.items}
                totalItems={oneOnOneReport.totalItems}
                onDetailPressed={onDetailPressed}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={oneOnOneColumns()}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}

        {!initialLoading && (
          <CardBody>
            <BodyTitle>Action items one on one Report</BodyTitle>

            {actionItemReport?.items?.length ? (
              <DetailedTable
                key="actionItemReportTable"
                keyColumn={'_id'}
                data={actionItemReport.items}
                totalItems={actionItemReport.totalItems}
                onDetailPressed={onDetailPressed}
                page={queryActionItem.page}
                pageSize={queryActionItem.pageSize}
                isSortAscending={Boolean(queryActionItem.isSortAscending)}
                sortByColumn={queryActionItem.sortByColumn}
                onChangePage={handlePageChangeActionItem}
                columns={actionItemColumns()}
                onSort={handleColumnSortingActionItem}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>

      <EmployeeListModal showModal={showModal} setShowModal={setShowModal} employeeList={employeeList} />
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

export default OneOnOneReportList;
