import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResourceTitle } from 'shared/types';

import { EmployeeResource } from './employeeService';
import http from './httpService';

const EXIT_PROCEDURE_PROCESS_URL = '/api/exitProcedureProcess';

export type ExitProcedureProcessFilter = {
  search: string;
  status: string;
};

export type ExitProcedureProcessResource = {
  _id: string;
  employee: EmployeeResource;
  creationDate: string;
  template: CatalogResourceTitle;
  descriptions: Description[];
  isCompleted: string;
};
export type Description = {
  _id: string;
  description: string;
  position: number;
  epStatus: string;
};
export type SaveDescription = {
  description: string;
  position: number;
  epStatus: string;
};

export type CreateExitProcedureProcessResource = {
  employee: string;
  template: string;
};

export type SaveExitProcedureProcessResource = {
  descriptions: SaveDescription[];
};

export async function getAllExitProcedureProcess(filter: ExitProcedureProcessFilter & Query) {
  const { data } = await http.get<PageResource<ExitProcedureProcessResource>>(
    EXIT_PROCEDURE_PROCESS_URL + '/' + http.toQueryString(filter),
  );

  return data;
}

export async function getOldEpsById(query: Query, id: string) {
  const { data } = await http.get<PageResource<ExitProcedureProcessResource>>(
    `${EXIT_PROCEDURE_PROCESS_URL}/${id}/eps` + http.toQueryString(query),
  );

  return data;
}

export async function getEpById(id: string) {
  const { data } = await http.get<ExitProcedureProcessResource>(`${EXIT_PROCEDURE_PROCESS_URL}/${id}`);

  return data;
}

export async function updateEp(ep: SaveExitProcedureProcessResource, id: string) {
  const { data } = await http.put<SaveExitProcedureProcessResource>(`${EXIT_PROCEDURE_PROCESS_URL}/${id}`, ep);

  return data;
}

export async function createEp(ep: CreateExitProcedureProcessResource) {
  const { data } = await http.post<ExitProcedureProcessResource>(`${EXIT_PROCEDURE_PROCESS_URL}`, ep);

  return data;
}

export async function deleteExitProcedureProcess(id: string) {
  const { data } = await http.delete<ExitProcedureProcessResource>(`${EXIT_PROCEDURE_PROCESS_URL}/${id}`);

  return data;
}

export async function getPreviousEpById(id: string | undefined) {
  if (!id) return;
  const { data } = await http.get<ExitProcedureProcessResource>(`${EXIT_PROCEDURE_PROCESS_URL}/${id}/previousEp`);

  return data;
}
