import { useEffect, useState } from 'react';

export const useCountDownEffect = (countdownTime: number, redirectionFunction: any) => {
  const [seconds, setSeconds] = useState(countdownTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) setSeconds(seconds - 1);

      if (seconds === 0) {
        redirectionFunction();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [redirectionFunction, seconds]);

  return { seconds };
};
