import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteSalary, SalaryResource } from 'services/salaryService';
import { decryptText } from 'utils/encryptionMethods/encryption';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<SalaryResource>[] = [
  {
    label: 'Date',
    sortKey: 'date',
    path: 'date',
    render: (item) => {
      const formatedDate = item.date ? stringDateToHumanFormat(item.date) : '';

      return formatedDate;
    },
  },
  {
    label: 'Salary',
    sortKey: 'salary',
    path: 'salary',
    render: (item) => {
      const decryptedSalary = item.salary.replaceAll(/\s/g, '').replaceAll(',', '')
        ? parseFloat(decryptText(item.salary).replaceAll(/\s/g, '').replaceAll(',', '').replace('$', ''))
        : '-';

      return `$${decryptedSalary.toLocaleString()}`;
    },
  },
  {
    label: 'Increase Type',
    sortKey: 'increaseType.name',
    path: 'increaseType.name',
    render: (item) => {
      return `${item.increaseType?.name || '-'}`;
    },
  },
  {
    label: 'Increase',
    sortKey: 'increase',
    path: 'increase',
    render: (item) => {
      const decryptedIncrease =
        item.increase && decryptText(item.increase).replaceAll(',', '').replaceAll(/\s/g, '')
          ? `$${parseFloat(
              decryptText(item.increase).replaceAll(/\s/g, '').replaceAll(',', '').replace('$', ''),
            ).toLocaleString()}`
          : '-';

      return decryptedIncrease;
    },
  },
  {
    label: 'Job Title',
    sortKey: 'jobTitle.name',
    path: 'jobTitle.name',
  },
  {
    label: 'Comments',
    sortKey: 'comments',
    path: 'comments',
    render: (item) => {
      const commentsText = item.comments ? item.comments : '-';

      return commentsText;
    },
  },
];

const variableColumns = (fetchSalaries: any, setLoading: any, employeeId: string) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchSalaries}
        deleteItem={deleteSalary}
        setLoading={setLoading}
        url={`employees/${employeeId}/salaries`}
        name={'Salary'}
        deleteTitle={'Delete Salary'}
        deleteMessage={'Do you want to delete this salary?'}
      />
    ),
  };
};

const columns = (fetchSalaries: any, setLoading: any, employeeId: string) => {
  return [...fixedColumns, variableColumns(fetchSalaries, setLoading, employeeId)];
};

export default columns;
