import usePaging, { PageResource, Query } from 'hooks/usePaging';
import moment from 'moment';
import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { getAllRewardReason } from 'services/rewardsReasonService';
import { getAllRewards, RewardFilter, RewardResource } from 'services/rewardsService';
import { getAllRewardStatus } from 'services/rewardsStatusService';
import { Item } from 'shared/types';
import Constants from 'utils/constants';
import { collectionToItemReason, collectionToItemStatus } from 'utils/mappers/item';

const localSearchFilter = localStorage.getItem('search_reward');
const localReason = localStorage.getItem('reason_reward');
const localStatus = localStorage.getItem('status_reward');
const localStartDate = localStorage.getItem('startDate_reward');
const localEndDate = localStorage.getItem('endDate_reward');

const DEFAULT_QUERY: Query & RewardFilter = {
  reasons: localReason?.split(',') || [],
  status: localStatus?.split(',') || [],
  startDate: localStartDate || '',
  endDate: localEndDate || '',
  search: localSearchFilter || '',
  pageSize: 20,
  page: 1,
  isSortAscending: 0,
  sortByColumn: 'requestDate',
};

const DEFAULT_FILTER: RewardFilter = {
  reasons: localReason?.split(',') || [],
  status: localStatus?.split(',') || [],
  startDate: localStartDate || moment.utc().toString(),
  endDate: localEndDate || moment.utc().toString(),
  search: localSearchFilter || '',
};

export const useRewardsEffect = () => {
  const [loading, setLoading] = useState(true);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);

  const [reasons, setReasons] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_FILTER);


  const defaultResponse: PageResource<RewardResource> = { totalItems: 0, items: [] };
  const [rewards, setRewards] = useState(defaultResponse);
  const user = useCurrentUser();

  useEffect(() => {
    async function loadCatalog() {
      let newStatuses = await getAllRewardStatus();
      const newReasons = await getAllRewardReason();

      newStatuses = newStatuses.filter((item) => item.statusName !== 'Missing Info');

      const reasonsCollection = collectionToItemReason(newReasons);
      const statusesCollection = collectionToItemStatus(newStatuses);
      const statusIds = statusesCollection
        .filter(
          (status) =>
            status.label !== Constants.REWARD_REFUNDED_STATUS_NAME &&
            status.label !== Constants.REWARD_REJECTED_STATUS_NAME,
        )
        .map((item) => item.id);
      const reasonIds = reasonsCollection.filter((reason) => reason.label !== 'preSelected').map((item) => item.id);

      setReasons(reasonsCollection);
      setStatuses(statusesCollection);
      setQuery({ ...query, status: statusIds, reasons: reasonIds });

      setLoadingCatalogs(false);
    }
    loadCatalog();
  }, []);

  const fetchRewards = useCallback(async () => {
    setLoading(true);

    const data = await getAllRewards(query);

    setLoading(false);
    setRewards(data);
  }, [query]);

  useEffect(() => {
    if (!loadingCatalogs) fetchRewards();
  }, [fetchRewards, loadingCatalogs, query, user]);

  return {
    loadingCatalogs,
    reasons,
    statuses,
    handlePageChange,
    handleColumnSorting,
    query,
    setQuery,
    rewards,
    loading,
    setLoading,
    fetchRewards,
    defaultFilter,
    setDefaultFilter,
  };
};

export const useDisplayModalEffect = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return { showModal, setShowModal };
};

export const useSeletedEmployeeEffect = (seleted: string) => {
  const [seletedEmployee, setSeletedEmployee] = useState('');

  useEffect(() => {
    setSeletedEmployee(seleted);
  }, [seleted]);

  return { seletedEmployee, setSeletedEmployee };
};

export const useSeletedRewardEffect = (seleted: string) => {
  const [seletedRewardId, setSeletedRewardId] = useState('');

  useEffect(() => {
    setSeletedRewardId(seleted);
  }, [seleted]);

  return { seletedRewardId, setSeletedRewardId };
};
