import { isPermissionGranted, ROLE_ADMIN } from 'helpers/roleHelper';
import { PageResource, Query } from 'hooks/usePaging';
import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { getProjectsQuery, ProjectResource } from 'services/projectService';

import { SearchFilter } from '.';

export const useProjectsEffect = (query: Query & SearchFilter) => {
  const defaultResponse: PageResource<ProjectResource> = { totalItems: 0, items: [] };
  const [projects, setProjects] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);
  const [isReadOnly, setReadOnly] = useState<boolean>(false);
  const user = useCurrentUser();

  const fetchProjects = useCallback(async () => {
      setLoading(true);

    const newProjects = await getProjectsQuery(query);

    setLoading(false);
    setProjects(newProjects);
    setReadOnly(!isPermissionGranted([ROLE_ADMIN], user));
  }, [query, user]);


  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return { projects, loading, setLoading, fetchProjects, isReadOnly };
};
