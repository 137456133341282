import CryptoJS from 'crypto-js';
import config from 'utils/config';

const encryptionKey = config.encryptionKey;

export const encryptText = (text: string) => {
  return CryptoJS.AES.encrypt(JSON.stringify(text), encryptionKey).toString();
};

export const decryptText = (text: string) => {
  if (!text) return '';
  const bytes = CryptoJS.AES.decrypt(text, encryptionKey);

  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
