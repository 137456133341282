export const fetchItems = () => {
  console.log('fetching items');
  return Promise.resolve();
};

export const deleteItem = (id: string) => {
  return Promise.resolve();
};

export const setLoading = (flag: boolean) => {
  console.log('change loading ' + flag);
};
