import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
}

const CurrencyInput = (props: NumberFormatCustomProps) => {
  const { inputRef, ...other } = props;

  return <NumberFormat {...other} getInputRef={inputRef} prefix="$" thousandSeparator isNumericString />;
};

export default CurrencyInput;
