import { CatalogResource } from 'shared/types';

import http from './httpService';

const EMPLOYEE_TYPES_URL = '/api/employeeTypes';

export async function getTypes() {
  const { data } = await http.get<CatalogResource[]>(EMPLOYEE_TYPES_URL);

  return data;
}
