import usePaging, { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getAllActionItemPriorities } from 'services/actionItemPriorityService';
import { ActionItemFilter, ActionItemResource, getAllActionItem } from 'services/actionItemService';
import { getAllActionItemStatus } from 'services/actionItemStatusService';
import { getAllEmployees, getAllLeaders } from 'services/employeeService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

export const useActionItemsEffect = () => {
  const localEmployeesFilter = localStorage.getItem('employees_actionItem');
  const localLeadersFilter = localStorage.getItem('leaderAssigned_actionItem');
  const localPriority = localStorage.getItem('priority_actionItem');
  const localStatus = localStorage.getItem('status_actionItem');
  const localUser = localStorage.getItem('cratedBy_actionItem');
  const localStartDate = localStorage.getItem('startDate_actionItem');
  const localEndDate = localStorage.getItem('endDate_actionItem');

  const DEFAULT_QUERY: Query & ActionItemFilter = {
    employees: localEmployeesFilter?.split(',') || [],
    leaderAssigned: localLeadersFilter?.split(',') || [],
    status: localStatus?.split(',') || [],
    priority: localPriority || '',
    dueDate: null,
    startDate: localStartDate || '',
    endDate: localEndDate || '',
    createdBy: localUser || '',
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'creationDate',
  };

  const defaultResponse: PageResource<ActionItemResource> = { totalItems: 0, items: [] };
  const [actionItems, setActionItems] = useState(defaultResponse);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const [priorities, setPriorities] = useState<Item[]>([]);
  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);

  useEffect(() => {
    async function loadCatalog() {
      const newEmployees = await getAllEmployees();
      const newStatuses = await getAllActionItemStatus();
      const newPriorities = await getAllActionItemPriorities();
      const newLeaders = await getAllLeaders();

      setEmployees(collectionEmployeeToItem(newEmployees));
      setLeaders(collectionEmployeeToItem(newLeaders));
      setStatuses(collectionToItem(newStatuses));
      setPriorities(collectionToItem(newPriorities));

      if (!localStatus) {
        const statusIds = collectionToItem(newStatuses)
          .filter((status) => status.label !== 'Completed' && status.label !== 'Not Done')
          .map((item) => item.id);

        setQuery({ ...query, status: statusIds });
      }
      setLoadingCatalogs(false);
    }
    loadCatalog();
  }, []);

  const fetchActionItems = useCallback(async () => {
    setLoading(true);

    const data = await getAllActionItem(query);

    setActionItems(data);
    setLoading(false);
  }, [query]);

  useEffect(() => {
    if (!loadingCatalogs) fetchActionItems();
  }, [fetchActionItems, loadingCatalogs, query, statuses.length]);

  return {
    actionItems,
    loading,
    setLoading,
    fetchActionItems,
    handlePageChange,
    handleColumnSorting,
    query,
    setQuery,
    employees,
    leaders,
    statuses,
    priorities,
    loadingCatalogs,
  };
};
