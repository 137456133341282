import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import CommentsHolder from 'components/comments-holder';
import { useForm } from 'hooks/useForm';
import React, { useState } from 'react';
import { createComment } from 'services/actionItemService';
import getBoxBackground from 'shared/style/utilities/getBoxBackground';
import getBoxShadow from 'shared/style/utilities/getBoxShadow';
import styled from 'styled-components';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCommentsEffect } from './hooks';

const schema = {
  comment: yup.string().required().label('Comment'),
};

type Props = {
  actionItemId: string;
};

const Comments: React.FC<Props> = (props) => {
  const { actionItemId } = props;
  const [userComment, setUserComment] = useState({ comment: '' });
  const [isSavingComment, setIsSavingComment] = useState(false);
  const { comments, setComments, loading } = useCommentsEffect(actionItemId);
  const { useInput, values, isValid } = useForm(userComment, schema);

  const handleSaveComment = async () => {
    if (!isValid) {
      notifier.error(`Please, fill the comment before submitting `);
      return;
    }

    setIsSavingComment(true);

    const actionItemComments = await createComment(actionItemId, values);

    if (actionItemComments) {
      setComments(actionItemComments);
      setUserComment({ comment: '' });

      notifier.success('Comment added successfully.');
    }

    setIsSavingComment(false);
  };

  return (
    <Card>
      <CommentsHeader loading={loading || isSavingComment} title={`Comments for the Action Item`} />
      <CardBody>
        <FormContainer>
          <BodyTitle>Comment:</BodyTitle>
          <TextBody>
            <ExpandTextField
              required
              multiline
              fullWidth
              minRows={4}
              maxRows={Infinity}
              id="commentInput"
              label="Description"
              variant="outlined"
              inputProps={{ maxLength: 5000 }}
              {...useInput('comment')}
            />
          </TextBody>
          <ButtonContainer>
            {loading && <Spinner size={30} />}
            <Submit id={'submitComment'} disabled={loading || !isValid} onClick={handleSaveComment}>
              Save
            </Submit>
          </ButtonContainer>
        </FormContainer>
        <CommentsHolder data={comments}></CommentsHolder>
      </CardBody>
    </Card>
  );
};

const CommentsHeader = styled(CardHeader)`
  background: ${(p) => getBoxBackground(p.theme.palette.secondary.main, p.theme.palette.secondary.dark)};
  box-shadow: ${(p) => getBoxShadow(p.theme.palette.secondary.main)};
`;

const TextBody = styled.div`
  margin: 30px;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  text-align: left;
  font-size: 1.3em;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const ExpandTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: auto !important;
  }
`;

const FormContainer = styled.div``;

export default Comments;
