import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  message: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  acceptConfirmation: () => any;
  onCancel?: () => any;
};

const AlertDialog: React.FC<Props> = (props) => {
  const { title, message, open, setOpen, acceptConfirmation, onCancel = undefined } = props;

  const handleClose = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleContainer id="alert-dialog-title">{title}</DialogTitleContainer>
      <DialogContent>
        <DialogContentTextContainer id="alert-dialog-description">{message}</DialogContentTextContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={acceptConfirmation} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitleContainer = styled(DialogTitle)`
  background-color: ${(p) => p.theme.palette.secondary.main};
  color: ${(p) => p.theme.palette.common.white};
`;

const DialogContentTextContainer = styled(DialogContentText)`
  padding-top: 20px;
  color: ${(p) => p.theme.palette.text.primary};
`;

export default AlertDialog;
