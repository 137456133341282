import { CatalogResource } from 'shared/types';

import http from './httpService';

const ONE_ON_ONE_STATUS_URL = '/api/status';

export async function getAllOneOnOneStatus() {
  const { data } = await http.get<CatalogResource[]>(ONE_ON_ONE_STATUS_URL);

  return data;
}
