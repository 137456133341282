import { StatusResource } from 'shared/types';

import http from './httpService';

const REWARD_STATUS_URL = '/api/rewardsStatus';

export async function getAllRewardStatus() {
  const { data } = await http.get<StatusResource[]>(REWARD_STATUS_URL);

  return data;
}
