import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCategories } from 'services/categoriesService';
import {
  getHitgoalTemplatesQuery,
  getTemplateAssignedLeaders,
  HitgoalTemplateFilter,
  HitgoalTemplateResource,
} from 'services/hitgoalsService';
import { Item } from 'shared/types';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

import { createTemplateCopy } from '../common/commonMethods';

const localStorageSufix = '_hitgoalTemplates';

export const useHitgoalTemplateEffect = (query: Query & HitgoalTemplateFilter) => {
  const defaultResponse: PageResource<HitgoalTemplateResource> = { totalItems: 0, items: [] };
  const [hitgoalTemplates, setHitgoalTemplates] = useState(defaultResponse);
  const [ownerList, setOwnerList] = useState<Item[]>([]);
  const [categoryList, setCategoryList] = useState<Item[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>(localStorage.getItem('searchText_hitgoalTemplates') || '');
  const history = useHistory();

  const fetchTemplates = useCallback(async () => {
    setLoading(true);
    const newHitgoalTemplates = await getHitgoalTemplatesQuery(query);

    setHitgoalTemplates(newHitgoalTemplates);
    setLoading(false);
  }, [query]);

  const onSearchTextChange = (event: any) => {
    const currentValue = event.target.value;

    saveInfoInLocalStorage('searchText', currentValue, localStorageSufix);
    setSearchText(currentValue);
  };

  const onCopyTemplate = (templateId: string) => {
    const selectedTemplate = hitgoalTemplates.items.find((template) => template._id === templateId);

    if (selectedTemplate) {
      const copyResult = createTemplateCopy(selectedTemplate);

      history.push(`/hitGoals-process/new`, copyResult);
    }
  };

  useEffect(() => {
    async function loadInfo() {
      setLoading(true);
      const categories = await getCategories();

      setCategoryList(collectionToItem(categories));
      setLoading(false);
    }
    loadInfo();
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    async function loadOwners() {
      const owners = await getTemplateAssignedLeaders(query.status);

      setOwnerList(collectionEmployeeToItem(owners));

      const newHitgoalTemplates = await getHitgoalTemplatesQuery(query);

      setHitgoalTemplates(newHitgoalTemplates);
    }

    query.owners = [];
    loadOwners();
  }, [query.status]);

  return {
    hitgoalTemplates,
    ownerList,
    categoryList,
    loading,
    searchText,
    setLoading,
    fetchTemplates,
    onSearchTextChange,
    onCopyTemplate,
  };
};
