import { isPermissionGranted, ROLE_ADMIN } from 'helpers/roleHelper';
import { useForm } from 'hooks/useForm';
import moment from 'moment';
import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCategories } from 'services/categoriesService';
import {
  createHitgoalTemplate,
  getHitgoalTemplateById,
  getKeyResults,
  HitgoalTemplateResource,
  KeyResultForTemplateResource,
  KeyResultResource,
  SaveHitgoalTemplateResource,
  updateHitgoalTemplate,
} from 'services/hitgoalsService';
import { Item } from 'shared/types';
import { dateFormatforStringConvertion } from 'utils/formatters/date';
import { collectionToItem } from 'utils/mappers/item';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { createTemplateCopy } from '../common/commonMethods';
import { schemaKeyResultItem } from './key-result-item';

const defaultTemplateResource = {
  objective: '',
  keyResults: new Array<KeyResultForTemplateResource>(),
  owner: '',
  status: true,
  createdAt: moment.utc(new Date()).format(dateFormatforStringConvertion),
  category: '',
  lastReviewDate: null,
  official: false,
};

const schemaHitgoalTemplate = {
  objective: yup.string().required().label('Objective'),
  keyResults: yup.array().of(schemaKeyResultItem.required()).optional().label('Key Results'),
  owner: yup.string().label('owner'),
  status: yup.boolean().required().default(true).label('Status'),
  createdAt: yup.string().optional().label('Created At'),
  category: yup.string().required().label('Category'),
  lastReviewDate: yup.string().optional().nullable().label('Last review date'),
  official: yup.boolean().required().default(false).label('Official'),
};

export const useCatalogsEffect = () => {
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);

  const [keyResultList, setKeyResultList] = useState<KeyResultResource[]>([]);
  const [categories, setCategories] = useState<Item[]>([]);

  const fetchProject = useCallback(async () => {
    setLoadingCatalogs(true);

    const keyResults = await getKeyResults();
    const categories = await getCategories();

    const categoriesItems = collectionToItem(categories);

    setKeyResultList(keyResults);
    setCategories(categoriesItems);

    setLoadingCatalogs(false);
  }, []);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return { keyResultList, loadingCatalogs, categories };
};

export const useTemplateEffect = (id: string) => {
  const location = useLocation<{ displayCopyButton: boolean }>();
  const [hitgoalTemplate, setHitgoalTemplate] = useState<SaveHitgoalTemplateResource>(defaultTemplateResource);
  const [keyResults, setKeyResults] = useState<KeyResultForTemplateResource[]>([]);
  const { useInput, useDateInput, values, isValid } = useForm(hitgoalTemplate, schemaHitgoalTemplate);
  const [loading, setLoading] = useState(false);
  const [hitgoalTemplateOwner, setHitgoalTemplateOwner] = useState('');
  const [lastReviewDate, setLastReviewDate] = useState(null);
  const [displayCopyButton, setDisplayCopyButton] = useState(
    Boolean(location.state ? location.state.displayCopyButton : true),
  );
  const user = useCurrentUser();
  const history = useHistory();
  const [savingType, setSavingType] = useState(false);
  const [hasPermission, setHasPermision] = useState(false);
  let templateResponse;

  const onCopyTemplate = () => {
    const selectedCategory = { _id: values.category, name: '' };

    if (id) {
      const copyResult = createTemplateCopy({
        ...values,
        _id: id,
        category: selectedCategory,
        createdAt: moment.utc(new Date()).format(dateFormatforStringConvertion),
      });

      history.push(`/hitGoals-process/new`, copyResult);
    }
  };

  const onLastReviewDateUpdate = (value?: any) => {
    values.lastReviewDate = value;
    setLastReviewDate(value);
    setDisplayCopyButton(false);
  };

  const fetchProject = useCallback(async () => {
    setLoading(true);
    setHasPermision(isPermissionGranted([ROLE_ADMIN], user));
    if (id) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      templateResponse = await getHitgoalTemplateById(id!);
    }

    setHitgoalTemplateOwner(
      id ? templateResponse.createdBy?.name + ' ' + templateResponse.createdBy?.lastName : user?.name + ' ' + user?.lastName,
    );

    if (templateResponse) {
      setHitgoalTemplate(mapHitgoalTemplateResponse(templateResponse));
      setKeyResults(templateResponse.keyResults);
      setLastReviewDate(templateResponse.lastReviewDate || null);
    }
    setLoading(false);
  }, [id, user]);

  useEffect(() => {
    fetchProject();
  }, [id, fetchProject]);

  const onKeyResultsChange = () => {
    setDisplayCopyButton(false);
  };

  useEffect(() => {
    if (values !== hitgoalTemplate) {
      onKeyResultsChange();
    }
  }, [values, keyResults]);

  const handleSaveHitgoalTemplateItem = async () => {
    if (!isValid) {
      notifier.error('Please, fix the errors on the form before submitting');
      return;
    }

    setSavingType(true);

    values.keyResults = keyResults;

    if (id) {
      const response = await updateHitgoalTemplate(values, id);

      if (response) notifier.success('Hitgoal template updated successfully.');
      setKeyResults(response.keyResults || []);
      setSavingType(false);
      setDisplayCopyButton(true);
      return;
    }

    const newHitgoalTemplate = await createHitgoalTemplate(values);

    if (newHitgoalTemplate) {
      notifier.success('HitGoal template created successfully.');
      history.push({ pathname: `/hitGoal-templates/${newHitgoalTemplate._id}`, state: { displayCopyButton: false } });
    }
    setSavingType(false);
  };

  return {
    hitgoalTemplate,
    setHitgoalTemplate,
    hitgoalTemplateOwner,
    loading,
    onCopyTemplate,
    keyResults,
    setKeyResults,
    useInput,
    values,
    isValid: isValid && keyResults.length > 0,
    useDateInput,
    onLastReviewDateUpdate,
    lastReviewDate,
    displayCopyButton,
    setDisplayCopyButton,
    onKeyResultsChange,
    savingType,
    handleSaveHitgoalTemplateItem,
    hasPermission,
  };
};

const mapHitgoalTemplateResponse = (template: HitgoalTemplateResource) => {
  return {
    objective: template.objective,
    keyResults: template.keyResults,
    owner: template.createdBy,
    status: template.status,
    createdAt: moment.utc(template.createdAt).format(dateFormatforStringConvertion),
    category: template.category?._id || '',
    official: template.official,
  } as SaveHitgoalTemplateResource;
};
