import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import React from 'react';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import { monthValues } from '../commonValues';
import { useAnniversaresEffect } from './hooks';
import FormRow from './rows';

clearLocalStorage(); //Clear local storage on refresh

const Anniversaries: React.FC = () => {
  const { anniversariesByMonth, loading } = useAnniversaresEffect();

  return (
    <Container>
      <Card>
        <CardHeader title="Anniversaries" subTitle="" loading={loading} />
        {loading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!loading && (
          <CardBody>
            {anniversariesByMonth?.length ? (
              <div>
                <Grid container item xs={12} spacing={3}>
                  <FormRow
                    months={[monthValues[0], monthValues[1], monthValues[2], monthValues[3]]}
                    anniversariesByMonth={anniversariesByMonth}
                  />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <FormRow
                    months={[monthValues[4], monthValues[5], monthValues[6], monthValues[7]]}
                    anniversariesByMonth={anniversariesByMonth}
                  />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <FormRow
                    months={[monthValues[8], monthValues[9], monthValues[10], monthValues[11]]}
                    anniversariesByMonth={anniversariesByMonth}
                  />
                </Grid>
              </div>
            ) : (
              <NoRecords>There are no records to display. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    padding: 0px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default Anniversaries;
