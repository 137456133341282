import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getAllActionItemPriorities } from 'services/actionItemPriorityService';
import { ActionItemReportResource, getActionItemsReport } from 'services/actionItemService';
import { getAllActionItemStatus } from 'services/actionItemStatusService';
import { getAllEmployees, getAllLeaders } from 'services/employeeService';
import { getOneOnOnesReport, OneOnOneReportResource, ReportFilter } from 'services/oneOnOneService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const [priorities, setPriorities] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newEmployees = await getAllEmployees();
      const newStatuses = await getAllActionItemStatus();
      const newPriorities = await getAllActionItemPriorities();
      const newLeaders = await getAllLeaders();

      setEmployees(collectionEmployeeToItem(newEmployees));
      setLeaders(collectionEmployeeToItem(newLeaders));
      setStatuses(collectionToItem(newStatuses));
      setPriorities(collectionToItem(newPriorities));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, employees, leaders, statuses, priorities };
};

export const useReportsEffect = (filterOneOnOnes: ReportFilter & Query, filterActionItems: ReportFilter & Query) => {
  const defaultOneOnOneResponse: PageResource<OneOnOneReportResource> = { totalItems: 0, items: [] };
  const defaultActionItemResponse: PageResource<ActionItemReportResource> = { totalItems: 0, items: [] };
  const [oneOnOneReport, setOneOnOneReport] = useState(defaultOneOnOneResponse);
  const [actionItemReport, setActionItemReport] = useState(defaultActionItemResponse);
  const [loading, setLoading] = useState(true);

  const fetchOneOnOnes = useCallback(async () => {
    setLoading(true);
    const oneOnOneData = await getOneOnOnesReport(filterOneOnOnes);

    setLoading(false);
    setOneOnOneReport(oneOnOneData);
  }, [filterOneOnOnes]);

  const fetchActionItems = useCallback(async () => {
    setLoading(true);
    const actionItemData = await getActionItemsReport(filterActionItems);

    setLoading(false);
    setActionItemReport(actionItemData);
  }, [filterActionItems]);

  useEffect(() => {
    fetchOneOnOnes();
  }, [fetchOneOnOnes, filterOneOnOnes]);

  useEffect(() => {
    fetchActionItems();
  }, [fetchActionItems, filterActionItems]);

  return { oneOnOneReport, actionItemReport, loading, setLoading, fetchActionItems, fetchOneOnOnes };
};
