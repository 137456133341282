import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource } from 'shared/types';

import { UserResource } from './authService';
import { CategoryResource } from './categoriesService';
import http from './httpService';

const HITGOALS_URL = '/api/hitgoals';

export type KeyResultAreaResource = {
  _id: string;
  name: string;
};

export type KeyResultTypeResource = {
  _id: string;
  name: string;
};

export type HitgoalTypeResource = {
  _id: string;
  name: string;
};

export type KeyResultFilter = {
  searchText: string;
  areas: string[];
  types: string[];
  status: boolean;
};

export type SaveKeyResultAreaResource = {
  name: string;
};

export type SaveKeyResultTypeResource = {
  name: string;
};

export type KeyResultResource = {
  _id: string;
  areas: KeyResultAreaResource[];
  type: KeyResultTypeResource;
  keyResult: string;
  source: string;
  estimatedDuration: string;
  status: boolean;
  createdAt: string;
};

export type SaveKeyResultResource = {
  areas: string[];
  type: string;
  keyResult: string;
  source: string;
  estimatedDuration: string;
  status: boolean;
  createdAt?: string;
};

export type HitgoalTemplateResource = {
  _id: string;
  objective: string;
  keyResults?: KeyResultForTemplateResource[];
  createdBy?: UserResource;
  status: boolean;
  createdAt: string;
  category?: CategoryResource | null;
  lastReviewDate?: string | null;
  official: boolean;
};

export type SaveHitgoalTemplateResource = {
  objective: string;
  keyResults?: KeyResultForTemplateResource[];
  status: boolean;
  createdAt?: string;
  category: string;
  lastReviewDate?: string | null;
  official: boolean;
};

export type HitgoalTemplateFilter = {
  searchText: string;
  owners: string[];
  categories: string[];
  status: boolean;
  official: boolean;
};

export type KeyResultForTemplateResource = {
  _id: string;
  keyResult: string;
  source: string;
};

/*************************
Key Results
**************************/
//Areas
export async function getKeyResultAreas() {
  const { data } = await http.get<KeyResultAreaResource[]>(`${HITGOALS_URL}/areas`);

  return data;
}
export async function getKeyResultAreasQuery(query: Query) {
  const { data } = await http.get<PageResource<KeyResultAreaResource>>(
    `${HITGOALS_URL}/areas/query` + http.toQueryString(query),
  );

  return data;
}

export async function getKeyResultAreaById(id: string) {
  const { data } = await http.get<KeyResultAreaResource>(`${HITGOALS_URL}/areas/${id}`);

  return data;
}

export async function deleteKeyResultArea(id: string) {
  const { data } = await http.delete<KeyResultAreaResource[]>(`${HITGOALS_URL}/areas/${id}`);

  return data;
}

export async function updateKeyResultArea(keyResultArea: SaveKeyResultAreaResource, id: string) {
  const { data } = await http.put<KeyResultAreaResource>(`${HITGOALS_URL}/areas/${id}`, keyResultArea);

  return data;
}

export async function createKeyResultArea(keyResultArea: SaveKeyResultAreaResource) {
  const { data } = await http.post<KeyResultAreaResource>(`${HITGOALS_URL}/areas`, keyResultArea);

  return data;
}

//Types
export async function getKeyResultTypes() {
  const { data } = await http.get<KeyResultTypeResource[]>(`${HITGOALS_URL}/types`);

  return data;
}

export async function getKeyResultTypesQuery(query: Query) {
  const { data } = await http.get<PageResource<KeyResultTypeResource>>(
    `${HITGOALS_URL}/types/query` + http.toQueryString(query),
  );

  return data;
}

export async function getKeyResultTypeById(id: string) {
  const { data } = await http.get<KeyResultTypeResource>(`${HITGOALS_URL}/types/${id}`);

  return data;
}

export async function deleteKeyResultType(id: string) {
  const { data } = await http.delete<KeyResultTypeResource[]>(`${HITGOALS_URL}/types/${id}`);

  return data;
}

export async function updateKeyResultType(keyResultArea: SaveKeyResultTypeResource, id: string) {
  const { data } = await http.put<KeyResultTypeResource>(`${HITGOALS_URL}/types/${id}`, keyResultArea);

  return data;
}

export async function createKeyResultType(keyResultArea: SaveKeyResultTypeResource) {
  const { data } = await http.post<KeyResultTypeResource>(`${HITGOALS_URL}/types`, keyResultArea);

  return data;
}

//Key Results

export async function getKeyResults() {
  const { data } = await http.get<KeyResultResource[]>(`${HITGOALS_URL}/key-results`);

  return data;
}

export async function getKeyResultsQuery(query: Query) {
  const { data } = await http.get<PageResource<KeyResultResource>>(
    `${HITGOALS_URL}/key-results/query` + http.toQueryString(query),
  );

  return data;
}

export async function getKeyResultById(id: string) {
  const { data } = await http.get<KeyResultResource>(`${HITGOALS_URL}/key-results/${id}`);

  return data;
}

export async function updateKeyResult(keyResult: SaveKeyResultResource, id: string) {
  const { data } = await http.put<KeyResultResource>(`${HITGOALS_URL}/key-results/${id}`, keyResult);

  return data;
}

export async function createKeyResult(keyResult: SaveKeyResultResource) {
  const { data } = await http.post<KeyResultResource>(`${HITGOALS_URL}/key-results`, keyResult);

  return data;
}

export async function deleteKeyResult(id: string) {
  const { data } = await http.delete<KeyResultResource[]>(`${HITGOALS_URL}/key-results/${id}`);

  return data;
}

//Goal templates

export async function getHitgoalTypes() {
  const { data } = await http.get<HitgoalTypeResource[]>(`${HITGOALS_URL}/templates/types/`);

  return data;
}

export async function getHitgoalTemplates() {
  const { data } = await http.get<HitgoalTemplateResource[]>(`${HITGOALS_URL}/templates`);

  return data;
}

export async function getHitgoalTemplatesQuery(query: Query) {
  const { data } = await http.get<PageResource<HitgoalTemplateResource>>(
    `${HITGOALS_URL}/templates/query` + http.toQueryString(query),
  );

  return data;
}

export async function getHitgoalTemplateById(id: string) {
  const { data } = await http.get<HitgoalTemplateResource>(`${HITGOALS_URL}/templates/${id}`);

  return data;
}

export async function updateHitgoalTemplate(hitgoalTemplate: SaveHitgoalTemplateResource, id: string) {
  const { data } = await http.put<HitgoalTemplateResource>(`${HITGOALS_URL}/templates/${id}`, hitgoalTemplate);

  return data;
}

export async function createHitgoalTemplate(hitgoalTemplate: SaveHitgoalTemplateResource) {
  const { data } = await http.post<HitgoalTemplateResource>(`${HITGOALS_URL}/templates`, hitgoalTemplate);

  return data;
}

export async function deleteHitgoalTemplate(id: string) {
  const { data } = await http.delete<KeyResultResource[]>(`${HITGOALS_URL}/templates/${id}`);

  return data;
}

export async function getTemplateAssignedLeaders(status: boolean) {
  const { data } = await http.get<CatalogResource[]>(`${HITGOALS_URL}/template-assigned-leaders/${status.valueOf()}`);

  return data;
}
