import { Column } from 'components/detailed-table/types';
import { OneOnOneReportResource } from 'services/oneOnOneService';

const fixedColumns: Column<OneOnOneReportResource>[] = [
  {
    label: 'Leader',
    sortKey: 'leaderAssigned.name',
    path: 'leaderAssigned.name',

    render: (item) =>  item.leaderAssigned? `${item.leaderAssigned?.name} ${item.leaderAssigned?.lastName}` : '',
  },
  {
    label: 'Open',
    sortKey: 'OpenCount',
    path: 'Open',
    render: (item) => `${item.statusList.find(status => status.status.name === 'Open')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'Open')?.employeeList || undefined,
  },
  {
    label: 'In Progress',
    sortKey: 'In ProgressCount',
    path: 'In Progress',
    render: (item) => `${item.statusList.find(status => status.status.name === 'In Progress')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'In Progress')?.employeeList || undefined,
  },
  {
    label: 'Completed',
    sortKey: 'CompleteCount',
    path: 'Completed',
    render: (item) => `${item.statusList.find(status => status.status.name === 'Complete')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'Complete')?.employeeList || undefined,
  },
  {
    label: 'Not Done',
    sortKey: 'Not DoneCount',
    path: 'notDone',
    render: (item) => `${item.statusList.find(status => status.status.name === 'Not Done')?.count || 0}`,
    extraData: (item) => item.statusList.find(status => status.status.name === 'Not Done')?.employeeList || undefined,
  },
];

const oneOnOneColumns = () => {
  return [...fixedColumns];
};

export default oneOnOneColumns;
