import { InputAdornment, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import { useAreasEffect } from './hooks';

export type SearchFilter = {
  searchText: string;
};

clearLocalStorage(); //Clear local storage on refresh

const HitgoalsReasonsList: React.FC = () => {
  const {
    areas,
    loading: projectsLoading,
    searchText,
    setLoading,
    fetchAreas,
    onSearchTextChange,
    handlePageChange,
    handleColumnSorting,
    query,
  } = useAreasEffect();
  const initialLoading = useInitialLoading(projectsLoading);
  const isLoading = projectsLoading;

  return (
    <Container>
      <Card>
        <CardHeader title="Reason" subTitle="" loading={isLoading} newPageRedirectionLink={'/reason/new'} />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <SearchBar>
                  <TextField
                    id="standard-search"
                    label="Search"
                    type="search"
                    value={searchText}
                    onChange={onSearchTextChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </SearchBar>
              </Grid>
            </Grid>
            {areas?.items.length ? (
              <StandardTable
                keyColumn="_id"
                data={areas.items}
                totalItems={areas.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchAreas, setLoading)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    text-align: right;
  }
`;
const SearchBar = styled.div`
  margin: 10px 0px 20px 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;
const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default HitgoalsReasonsList;
