import { GenericNameValueTupleResource } from 'shared/types';

import http from './httpService';

const GENERIC_NAME_VALUE_TUPLES_URL = '/api/genericNameValueTuples';

export async function getAllGenericNameValuesByParent(parentName: string) {
  const { data } = await http.get<GenericNameValueTupleResource[]>(`${GENERIC_NAME_VALUE_TUPLES_URL}/${parentName}`);

  return data;
}
