import { useCallback, useEffect, useState } from 'react';
import { getOpStatus } from 'services/opStatusService';
import {
  Description,
  getOpById,
  SaveScreenOnboardingProcessResource,
  ScreenOnboardingProcessResource,
} from 'services/screenOnboardingProcessService';
import { Item, OnboardingItem } from 'shared/types';
import { collectionToItemOnboardingProcess } from 'utils/mappers/item';

const defaultScreenOnboardingProcessResource = {
  descriptions: [],
};

export const useScreenOnboardingProcessEffect = (id: string) => {
  const [saveScreenOnboardingProcess, setSaveScreenOnboardingProcess] = useState<SaveScreenOnboardingProcessResource>(
    defaultScreenOnboardingProcessResource,
  );
  const [screenOnboardingProcess, setScreenOnboardingProcess] = useState<ScreenOnboardingProcessResource>();
  const [loading, setLoading] = useState(false);

  const fetchScreenOnboardingProcess = useCallback(async () => {
    setLoading(true);
    const screenOnboardingProcessResult = await getOpById(id);

    if (screenOnboardingProcessResult) {
      setSaveScreenOnboardingProcess(mapScreenOnboardingProcessResponse(screenOnboardingProcessResult));
      setScreenOnboardingProcess(screenOnboardingProcessResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchScreenOnboardingProcess();
  }, [id, fetchScreenOnboardingProcess]);

  return {
    screenOnboardingProcess,
    setScreenOnboardingProcess,
    loading,
    saveScreenOnboardingProcess,
    setSaveScreenOnboardingProcess,
    setLoading,
    fetchScreenOnboardingProcess,
  };
};

const mapScreenOnboardingProcessResponse = (op: ScreenOnboardingProcessResource) => {
  return {
    descriptions: mapDescriptions(op.descriptions),
  } as SaveScreenOnboardingProcessResource;
};

const mapDescriptions = (descriptions: Description[]) => {
  return descriptions.map((description) => ({
    description: description.description,
    position: description.position,
    opStatus: description.opStatus,
  }));
};

export const useCatalogs = (id: string) => {
  const [loading, setLoading] = useState(true);

  const [descriptions, setDescriptions] = useState<OnboardingItem[]>([]);
  const [opStatuses, setOpStatuses] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [descriptionsResult, opStatusResult] = await Promise.all([getOpById(id), getOpStatus()]);

      const descriptionItems: OnboardingItem[] = descriptionsResult.descriptions.map((actualOnboardingProcess) => ({
        id: actualOnboardingProcess._id,
        description: actualOnboardingProcess.description,
        opStatus: actualOnboardingProcess.opStatus,
      }));

      const opStatusItems = collectionToItemOnboardingProcess(opStatusResult);

      setDescriptions(descriptionItems);
      setOpStatuses(opStatusItems);

      setLoading(false);
    }

    fetchCatalogs();
  }, [id]);

  return { descriptions, opStatuses, loading };
};

export const useReadOnlyEffect = (localStorageValue: string | null, opFinished: boolean, adminPermission: boolean) => {
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (adminPermission) {
      setReadOnly(false);
      return;
    }

    if (localStorageValue && localStorageValue === 'readOnly') {
      setReadOnly(true);
    } else if (opFinished) {
      setReadOnly(true);
    } else setReadOnly(false);
  }, [adminPermission, opFinished, localStorageValue]);

  return { isReadOnly, setReadOnly };
};
