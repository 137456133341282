/* eslint-disable react/jsx-no-bind */
import { DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import { Item } from 'shared/types';
import styled from 'styled-components';
import constants from 'utils/constants';
import { dateFormatUsed } from 'utils/formatters/date';
import { OnChangeEvent } from 'utils/objectTypes/types';

import { ProjectChangeRecord } from '../hooks';
import useProjectChangeDialog from './projectChangeDialog.hooks';

type Props = {
  projectChangeRecord: ProjectChangeRecord;
  setProjectChangeRecord: Function;
  oldProject: string;
  newProject: string;
  open: boolean;
  setOpen: Function;
  acceptConfirmation: Function;
  cancelAction: Function;
  projectList: Item[];
};

const ProjectChangeDialog: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    acceptConfirmation,
    projectChangeRecord,
    setProjectChangeRecord,
    oldProject,
    newProject,
    projectList,
    cancelAction,
  } = props;

  const { newProjectName, oldProjectName } = useProjectChangeDialog(projectList, newProject, oldProject);

  const handleClose = () => {
    setOpen(false);
    cancelAction();
  };

  const handleOnEffectiveDateChange = (event: any) => {
    let { value } = { value: event };

    setProjectChangeRecord({ ...projectChangeRecord, effectiveDate: value });
  };

  const handleOnChange = (event: OnChangeEvent) => {
    const { name, value } = event?.target;

    setProjectChangeRecord({ ...projectChangeRecord, [name!]: value });
  };

  const dialogDescription = (
    <div style={{ marginTop: '-30px' }}>
      <p>
        <span style={{ color: 'gray' }}>{oldProjectName} </span>
        {'→ '}
        <strong>{newProjectName}</strong>{' '}
      </p>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleContainer id="alert-dialog-title">{constants.PROJECT_CHANGE_TITLE}</DialogTitleContainer>
      <DialogContent>
        <DialogContentTextContainer id="alert-dialog-description">{dialogDescription}</DialogContentTextContainer>
        <DateInputPickerContainerShort
          id={'effectiveProjectChangeDateSelect'}
          label="Effective Date"
          format={dateFormatUsed}
          clearable
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          value={projectChangeRecord.effectiveDate}
          InputLabelProps={{
            shrink: projectChangeRecord.effectiveDate ? true : false,
          }}
          InputProps={{ readOnly: true }}
          inputVariant="outlined"
          onChange={handleOnEffectiveDateChange}
          helperText={constants.PROJECT_CHANGE_EFFECTIVE_DATE_INFO}
          fullWidth
          required
        />
        <br />
        <br />
        <TextField
          id={'projectChangeCommentsInput'}
          name="comments"
          multiline
          fullWidth
          minRows={4}
          inputProps={{ maxLength: 5000 }}
          label="Comments"
          variant="outlined"
          value={projectChangeRecord.comments}
          onChange={handleOnChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => acceptConfirmation()}
          color="primary"
          autoFocus
          disabled={!projectChangeRecord.effectiveDate}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitleContainer = styled(DialogTitle)`
  background-color: ${(p) => p.theme.palette.secondary.main};
  color: ${(p) => p.theme.palette.common.white};
`;

const DialogContentTextContainer = styled(DialogContentText)`
  padding-top: 20px;
  color: ${(p) => p.theme.palette.text.primary};
`;

const DateInputPickerContainerShort = styled(KeyboardDatePicker)`
  .MuiButtonBase-root {
    margin-right: -14px;
  }
`;

export default ProjectChangeDialog;
