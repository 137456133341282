import React from 'react';

import { StyledTitle } from './styles';

interface Props {
  text: string;
}

const BodyTitle = (props: Props) => {
  const { text } = props;

  return <StyledTitle>{text}</StyledTitle>;
};

export default BodyTitle;
