import http from './httpService';

const ONE_ON_ONE_STATUS_URL = '/api/cdpstatus';

export type CdpStatusResource = {
  _id: string;
  order: number;
  name: string;
  description: string;
};

export async function getCdpStatus() {
  const { data } = await http.get<CdpStatusResource[]>(ONE_ON_ONE_STATUS_URL);

  return data;
}
