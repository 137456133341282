/* eslint-disable react/jsx-no-bind */
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteHitgoalProcess, HitgoalProcessResource } from 'services/hitgoalProcessService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';
import { statusObjectToString } from 'utils/formatters/hitgoalProcessStatus';

const isDateInPast = (date: string) => {
  const comparingDate = new Date(date).setHours(0, 0, 0, 0);
  const currentDate = new Date().setHours(0, 0, 0, 0);

  return comparingDate < currentDate;
};

const fixedColumns: Column<HitgoalProcessResource>[] = [
  {
    label: 'Category',
    sortKey: 'category.name',
    path: 'category.name',
    render: (item) => `${item.category?.name || ''}`,
  },
  {
    label: 'Objective',
    sortKey: 'objective',
    path: 'objective',
  },
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Reason',
    sortKey: 'reasonID.name',
    path: 'reasonID.name',
    render: (item) => `${item.reasonID.name}`,
  },
  {
    label: 'Deadline',
    sortKey: 'deadLine',
    path: 'deadLine',
    render: (item) =>
      `${
        item.deadLine && stringDateToHumanFormat(item.deadLine) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.deadLine)
          : '-'
      }`,
  },
  {
    label: 'Status',
    sortKey: 'hitgoalProcessStatusID.statusName',
    path: 'hitgoalProcessStatusID.statusName',
    render: (item) => (
      <div>
        {
          <StatusHighlighter
            name={statusObjectToString(
              item.keyResults,
              isDateInPast(item.deadLine) && item.hitgoalProcessStatusID.statusName === 'Incomplete'
                ? 'Uncompleted'
                : item.hitgoalProcessStatusID.statusName,
            )}
          />
        }
      </div>
    ),
  },
  {
    label: 'Created By',
    sortKey: 'createdBy.name',
    path: 'createdBy.name',
    render: (item) => `${item.createdBy.name} ${item.createdBy.lastName}`,
  },
];

const variableColumns = (fetchHitgoalsProcess: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchHitgoalsProcess}
          deleteItem={deleteHitgoalProcess}
          setLoading={setLoading}
          url={'hitGoals-process'}
          name={'HitGoal Process'}
          deleteTitle={'Delete HitGoal Process'}
          deleteMessage={'Do you want to delete this hitgoal process?'}
        />
      </Container>
    ),
  };
};
const Container = styled.div`
  display: flex;
  float: right;
`;

const columns = (fetchRewards: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchRewards, setLoading)];
};

export default columns;
