import http from './httpService';

const REWARDSHISTORICALS_URL = '/api/rewardsHistorical';

export type StatusService = {
  _id: string;
  order: number;
  statusName: string;
};
export type SaveRewardResourceValidation = {
  rewStatusID: string;
  requestComment: string;
};
export type RewardResourceValidation = {
  _id: string;
  rewStatusID: StatusService;
  requestComment: string;
};

export type rewardHistorical = {
  user: string;
  status: string;
  date: string;
};

export async function updateRewardHistorical(reward: SaveRewardResourceValidation, id: string) {
  const { data } = await http.put<SaveRewardResourceValidation>(`${REWARDSHISTORICALS_URL}/${id}`, reward);

  return data;
}
export async function getRewardHistoricalById(idRequest: string, rewardStatus: string) {
  const { data } = await http.get<RewardResourceValidation>(`${REWARDSHISTORICALS_URL}/${idRequest}/${rewardStatus}`);

  return data;
}

export async function getRewardHistoricalsById(idRequest: string) {
  const { data } = await http.get<rewardHistorical[]>(`${REWARDSHISTORICALS_URL}/${idRequest}`);

  return data;
}
