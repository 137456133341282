import { CatalogResource } from 'shared/types';

import http from './httpService';

const ROLE_URL = '/api/roles';

type RoleResource = {
  _id: string;
  name: string;
};

export type SaveUserResource = {
  name: string;
  lastName: string;
  email: string;
  roles: RoleResource[];
};

export async function getAllRoles() {
  const { data } = await http.get<CatalogResource[]>(ROLE_URL);

  return data;
}
