import { Breadcrumbs, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { UserResource } from 'services/authService';
import styled from 'styled-components';

type Props = {
  userInfo: UserResource | null;
};

const NavBarBreadcrumbs: React.FC<Props> = (props) => {
  const { userInfo } = props;
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Route>
      {({ location }) => {
        let path = location.pathname;

        if (path.includes('new')) {
          path = path.split('new')[0] + '/new';
        }
        const pathnames = path.split('/').filter((x) => x);

        if (!userInfo) return <BarContainer></BarContainer>;

        return (
          <BarContainer>
            <BackButtonContainer onClick={handleBack} disabled={pathnames.length <= 0}>
              <BackIcon fontSize="small" />
            </BackButtonContainer>

            <BreadcrumbsContainer aria-label="Breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
              <RouterLink color="inherit" to="/">
                Home
              </RouterLink>
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return last ? (
                  <Typography id={value + '-beadcrumb-id'} color="textPrimary" key={to}>
                    {formatPathName(value.includes(',')?"Detail":value)}
                  </Typography>
                ) : (
                  <RouterLink id={value + '-beadcrumb-id'} color="inherit" to={to} key={to}>
                    {formatPathName(value)}
                  </RouterLink>
                );
              })}
            </BreadcrumbsContainer>
          </BarContainer>
        );
      }}
    </Route>
  );
};

const BreadcrumbsContainer = styled(Breadcrumbs)`
  flex-grow: 1;
  padding: 11px;
`;

const BarContainer = styled.div`
  flex-grow: 1;
  display: flex;
  vertical-align: middle;
  margin-top: -2px;
  margin-bottom: -5px;
`;

const BackButtonContainer = styled(IconButton)`
  background: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
`;

const formatPathName = (pathName: string) => {
  const newPathName = renameIfObjectId(pathName);

  const path = newPathName.split('-');

  path.forEach((item, i) => {
    path[i] = item.charAt(0).toUpperCase() + item.slice(1);
  });

  return path.join(' ');
};

//If the path name is an object Id we replace it by "Detail" in order to keep the breadcrumbs legible
const renameIfObjectId = (value: string) => {
  return value.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i) ? 'Detail' : value;
};

export default NavBarBreadcrumbs;
