import usePaging, { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { ActionItemFilter, ActionItemResource, getAllActionItem } from 'services/actionItemService';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

export const useActionItemsEffect = (id: string) => {
  const default_query: Query & ActionItemFilter = {
    employees: [],
    leaderAssigned: [],
    status: [],
    priority: '',
    dueDate: null,
    startDate: null,
    endDate: null,
    pageSize: 100,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'title',
    type: 'OneOnOne',
    typeId: id,
  };

  const [actionItems, setActionItems] = useState<PageResource<ActionItemResource>>({ totalItems: 0, items: [] });
  const [loading, setLoading] = useState(false);
  const { query } = usePaging(default_query);
  const fetchActionItems = useCallback(async () => {
    setLoading(true);
    const actionItemsResult = await getAllActionItem(query);

    if (actionItemsResult) {
      setActionItems(actionItemsResult);

      saveInfoInLocalStorage('inputValue', '', '_form');
    }
    setLoading(false);
  }, [query]);

  useEffect(() => {
    if (id) fetchActionItems();
  }, [id, fetchActionItems]);

  return { actionItems, setActionItems, fetchActionItems, loading, setLoading };
};
