import { useEffect, useState } from 'react';
import { Item } from 'shared/types';

const useProjectChangeDialog = (projectList: Item[], newProject: string, oldProject: string) => {
  const [newProjectName, setNewProjectName] = useState('');
  const [oldProjectName, setOldProjectName] = useState('');

  useEffect(() => {
    setNewProjectName(projectList.find((project) => project.id === newProject)?.label || '');
    setOldProjectName(projectList.find((project) => project.id === oldProject)?.label || '');
  }, [newProject, oldProject, projectList]);

  return {
    newProjectName,
    oldProjectName,
  };
};

export default useProjectChangeDialog;
