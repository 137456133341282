import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import { useForm } from 'hooks/useForm';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createJobTitle, updateJobTitle } from 'services/jobTitleService';
import styled from 'styled-components';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { NumberFormatCustom } from '../../../components/numberFormatCustom';
import { useJobTitleEffect } from './hooks';

const schemaJobTitle = {
  name: yup.string().required().label('Name'),
  order: yup.string().required().label('Order'),
  jobFamily: yup.string().optional().label('Job family'),
};

type Props = {};

const JobTitleForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();
  const { jobTitle, loading } = useJobTitleEffect(id);
  const history = useHistory();
  const [savingJobTitle, setSavingJobTitle] = useState(false);
  const { useInput, values, isValid } = useForm(jobTitle, schemaJobTitle as any);
  const subTitle = id ? 'Update the job title' : 'Create a new job title';
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';

  const handleSaveJobTitleItem = async () => {
    if (!isValid) {
      notifier.error('Please, fix the errors on the form before submitting ');
      return;
    }

    setSavingJobTitle(true);

    if (id) {
      const response = await updateJobTitle(values, id);

      if (response) notifier.success('Job Title updated successfully.');
      setSavingJobTitle(false);
      return;
    }

    const newJobTitle = await createJobTitle(values);

    if (newJobTitle) {
      notifier.success('Job title created successfully.');
      history.push(`/jobtitles/${newJobTitle._id}`);
    }
    setSavingJobTitle(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Job Title: ${!initialLoading ? values?.name : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Job information</BodyTitle>
          <TextBody>
            <TextFieldContainer>
              <TextFieldOrder
                required
                id={'jobTitleOrder'}
                label="Order"
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
                {...useInput('order')}
              />
              <TextFieldName
                required
                id={'jobTitleName'}
                inputProps={{ maxLength: 280 }}
                label="Name"
                variant="outlined"
                {...useInput('name')}
              />
              <TextFieldName
                style={{ marginLeft: '10px' }}
                id={'jobFamily'}
                inputProps={{ maxLength: 280 }}
                label="Job Family"
                variant="outlined"
                {...useInput('jobFamily')}
              />
            </TextFieldContainer>
          </TextBody>
          <ButtonContainer>
            {savingJobTitle && <Spinner size={30} />}
            <Submit disabled={savingJobTitle || loading || !isValid} onClick={handleSaveJobTitleItem}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const TextBody = styled.div`
  margin: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const TextFieldContainer = styled.div`
  min-width: 160px;
  width: 100%;
  padding: 5px;
  display: block;
`;
const TextFieldOrder = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const TextFieldName = styled(TextField)`
  width: 300px;
`;

export default JobTitleForm;
