import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeResource, getEmployeeById } from 'services/employeeService';
import { getRatesByEmployeeId, RateResource } from 'services/salaryService';

export const useRatesEffect = (employee: EmployeeResource, query: Query) => {
  const defaultResponse: PageResource<RateResource> = { totalItems: 0, items: [] };
  const [rates, setRates] = useState(defaultResponse);
  const [loadingRates, setLoadingRates] = useState(true);

  const fetchRates = useCallback(async () => {
    if (!employee) return;
    setLoadingRates(true);
    const ratesResult = await getRatesByEmployeeId(employee._id, query);

    setRates(ratesResult);

    setLoadingRates(false);
  }, [employee, query]);

  useEffect(() => {
    fetchRates();
  }, [fetchRates, query]);

  return {
    employee,
    rates,
    loadingRates,
    setLoadingRates,
    fetchRates,
  };
};

export const useCatalogs = (id: string) => {
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [employee, setEmployee] = useState<EmployeeResource>();

  useEffect(() => {
    async function fetchCatalogs() {
      setLoadingCatalogs(true);

      const employeeResult = await getEmployeeById(id!);

      if (employeeResult) {
        setEmployee(employeeResult);
      }

      setLoadingCatalogs(false);
    }

    fetchCatalogs();
  }, [id]);

  return {
    employee,
    loadingCatalogs,
  };
};
