import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import React, { useState } from 'react';
import { handleLoginManual } from 'services/authService';
import styled from 'styled-components';

type Props = {};

const LoginManual: React.FC<Props> = () => {
  const [email, setEmail] = useState('');
  const [, setPassword] = useState('');

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginCall = async () => {
    await handleLoginManual(email);
  };

  return (
    <Container>
      <Card>
        <CardHeader title={'Manual Login'} />
        <CardBody>
          <SingleSelectWrapper>
            <TextBody>
              <TextFieldContainer
                id={'emailInput'}
                required
                label="email"
                type="email"
                variant="outlined"
                onChange={handleUsernameChange}
              />
            </TextBody>
          </SingleSelectWrapper>

          <SingleSelectWrapper>
            <TextBody>
              <TextFieldContainer
                id={'passwordInput'}
                required
                label="Password"
                type="password"
                variant="outlined"
                onChange={handlePasswordChange}
              />
            </TextBody>
          </SingleSelectWrapper>

          <ButtonContainer>
            <Submit onClick={handleLoginCall}>Login</Submit>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const TextBody = styled.div`
  text-align: left;
  float: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const TextFieldContainer = styled(TextField)`
  width: 280px;
`;

const SingleSelectWrapper = styled.div`
  text-align: left;
  margin: 15px 0 0 15px;
`;

export default LoginManual;
