import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource } from 'shared/types';

import { EmployeeResource, EmployeeResourceCompact } from './employeeService';
import http from './httpService';

const ONE_ON_ONE_URL = '/api/oneOnOnes';

export type OneOnOneFilter = {
  leaders: string[];
  leaderAssigned: string[];
  status: string[];
  startDate: string | null;
  endDate: string | null;
  search: string;
};

export type OneOnOneResource = {
  _id: string;
  employee: EmployeeResource;
  leaderAssigned?: EmployeeResource;
  status: CatalogResource;
  talkingPoint: string;
  feelings: CatalogResource[];
  creationDate: string;
  numberActionItems: string;
};

export type SaveOneOnOneResource = {
  talkingPoint: string;
  leaderAssigned: string;
  feelings: string[];
  status: string;
};

export type CreateOneOnOneResource = {
  employee: string;
  leaderAssigned?: string;
  status: string;
  creationDate: string;
};

export type HistoryOneOnOneResource = {
  _id: string;
  employee: EmployeeResourceCompact;
  leaderAssigned?: EmployeeResourceCompact;
  status: CatalogResource;
  creationDate: Date;
};

export type ReportFilter = {
  leaderAssigned: string[];
  startDate: Date | null;
  endDate: Date | null;
};

export type OneOnOneReportDetail = {
  count: number | null;
  status: CatalogResource;
  employeeList: EmployeeResourceCompact[];
};

export type OneOnOneReportResource = {
  leaderAssigned?: EmployeeResourceCompact;
  statusList: OneOnOneReportDetail[];
};

export async function getAllOneOnOnes(filter: OneOnOneFilter & Query) {
  const { data } = await http.get<PageResource<OneOnOneResource>>(ONE_ON_ONE_URL + http.toQueryString(filter));

  return data;
}

export async function getOneOnOneById(id: string) {
  const { data } = await http.get<OneOnOneResource>(`${ONE_ON_ONE_URL}/${id}`);

  return data;
}

export async function updateOneOnOneBy(oneOnOne: SaveOneOnOneResource, id: string) {
  const { data } = await http.put<OneOnOneResource>(`${ONE_ON_ONE_URL}/${id}`, oneOnOne);

  return data;
}

export async function createOneOnOne(oneOnOne: CreateOneOnOneResource) {
  const { data } = await http.post<OneOnOneResource>(`${ONE_ON_ONE_URL}/`, oneOnOne);

  return data;
}

export async function deleteOneOnOne(id: string) {
  const { data } = await http.delete<OneOnOneResource>(`${ONE_ON_ONE_URL}/${id}`);

  return data;
}

export async function getOldOneOnOnesById(query: Query, id: string) {
  const { data } = await http.get<PageResource<HistoryOneOnOneResource>>(
    `${ONE_ON_ONE_URL}/${id}/oneOnOnes` + http.toQueryString(query),
  );

  return data;
}

export async function getOneOnOnesReport(filter: ReportFilter & Query) {
  const { data } = await http.get<PageResource<OneOnOneReportResource>>(
    `${ONE_ON_ONE_URL}/report` + http.toQueryString(filter),
  );

  return data;
}

export async function loadCsvFileAllInfo(query: Query) {
  const { data } = await http.get<any[]>(ONE_ON_ONE_URL + '/queryAllOneOnOnes.csv' + http.toQueryString(query));

  return data;
}
