import { useCallback, useEffect, useState } from 'react';
import { UserResource } from 'services/authService';
import { EmployeeResource, getAllEmployees, getAllEmployeesWithNoRol } from 'services/employeeService';
import { getAllRoles } from 'services/roleService';
import { getUserById, SaveUserResource } from 'services/usersService';
import { CatalogResource, Item, RoleResource } from 'shared/types';
import { collectionEmployeeToItem } from 'utils/mappers/item';

const defaultUserResource = {
  name: '',
  lastName: '',
  email: '',
  roles: [],
};

export const useUserEffect = (id?: string, employeeList?: EmployeeResource[]) => {
  const [user, setUser] = useState<SaveUserResource>(defaultUserResource);
  const [userRoles, setUserRoles] = useState<RoleResource[]>([]);
  const [loading, setLoading] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState<EmployeeResource>();

  const fetchEmployee = useCallback(async () => {
    setLoading(true);
    const userResult = await getUserById(id!);

    if (employeeList) {
      if (id) {
        const employeeFindById = employeeList.find((employee) => employee.email === userResult.email);

        setEmployeeSelected(employeeFindById);
      }
    }

    if (userResult) {
      setUser(mapUserResponse(userResult));
      setUserRoles(userResult.roles);
    }
    setLoading(false);
  }, [id, employeeList]);

  useEffect(() => {
    if (id && employeeList) fetchEmployee();
  }, [id, fetchEmployee, employeeList]);

  return { user, loading, userRoles, setUserRoles, employeeSelected, setEmployeeSelected };
};

const mapUserResponse = (employee: UserResource) => {
  return {
    name: employee.name,
    lastName: employee.lastName,
    email: employee.email,
    roles: employee.roles || [],
  } as SaveUserResource;
};

export const useCatalogFilter = () => {
  const [loadingCatalogs, setLoading] = useState(true);
  const [roles, setRoles] = useState<CatalogResource[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const loadRoles = await getAllRoles();

      setRoles(loadRoles);
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loadingCatalogs, roles };
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [employeesList, setEmployeesList] = useState<EmployeeResource[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const employeesResult = await getAllEmployees();
      const employeesResultWithNoRol = await getAllEmployeesWithNoRol();

      setEmployeesList(employeesResult);

      const employeeItemsNoRol = collectionEmployeeToItem(employeesResultWithNoRol);

      setEmployees(employeeItemsNoRol);
      setLoading(false);
    }

    fetchCatalogs();
  }, []);

  return { employees, loading, employeesList, setEmployees };
};
