/* eslint-disable react/jsx-no-bind */
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteExitProcedureProcess, ExitProcedureProcessResource } from 'services/exitProcedureProcessService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';
import { statusObjectToString } from 'utils/formatters/statusExitProcedure';

const fixedColumns: Column<ExitProcedureProcessResource>[] = [
  {
    label: 'Date Created',
    sortKey: 'creationDate',
    path: 'creationDate',
    render: (item) => `${item.creationDate ? stringDateToHumanFormat(item.creationDate) : 'Not Assigned'}`,
  },
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Template',
    sortKey: 'template.title',
    path: 'template.title',
  },
  {
    label: 'Status',
    sortKey: 'isCompleted',
    path: 'isCompleted',
    render: (item) => <div>{<StatusHighlighter name={statusObjectToString(item.descriptions)} />}</div>,
  },
];

const variableColumns = (fetchExitProcedureProcess: any, setLoading: any, showConfirmationModal: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchExitProcedureProcess}
          deleteItem={deleteExitProcedureProcess}
          setLoading={setLoading}
          url={'exit-procedure-process'}
          name={'Exit procedure'}
          deleteTitle={'Delete Exit Procedure'}
          deleteMessage={'Do you want to delete this exit procedure?'}
        />
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const columns = (fetchExitProcedureTemplates: any, setLoading: any, showConfirmationModal: any) => {
  return [...fixedColumns, variableColumns(fetchExitProcedureTemplates, setLoading, showConfirmationModal)];
};

export default columns;
