import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CardBody from 'components/card/card-body';
import SingleSelect from 'components/single-select';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

export const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

export const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

export const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

export const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

export const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
`;

export const DetailsLineKeyResult = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
  min-width: 840px !important;
`;

export const DetailsLineList = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

export const SelectContainerWide = styled.div`
  min-width: 160px;
  width: 200px;
  display: flex;
  float: left;
  margin-right: 20px;
  padding-bottom: -5px;
`;

export const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export const TableTitleContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-width: 770px;
  text-align: center;
  margin-left: 15px;
  margin-bottom: -15px;
`;

export const TableFirstTitleContainer = styled.h4`
  text-align: left;
  font-size: 20;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  flex: 1;
  min-width: 40%;
  color: ${(p) => p.theme.palette.primary.light};
`;

export const TableMiddleTitleContainer = styled.h4`
  text-align: left;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  min-width: 40%;
  margin-left: 5px;
  color: ${(p) => p.theme.palette.primary.light};
  flex: 1;
`;

export const TableThirdTitleContainer = styled.h4`
  text-align: left;
  margin-left: 10px;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${(p) => p.theme.palette.primary.light};
  min-width: 14%;
`;

export const TableLastTitleContainer = styled.h4`
  text-align: left;
  margin-left: 10px;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${(p) => p.theme.palette.primary.light};
  min-width: 6%;
`;

export const TextFieldDescriptionContainer = styled(TextField)`
  width: 40%;
  display: block;
  margin-left: 10px;
`;

export const SingleSelectDescriptionContainer = styled.div`
  display: flex;
  margin-left: 10px;
  width: 14%;
`;

export const IconContainer = styled.div`
  margin-left: 5px;
  margin-top: -6px;
  width: 2%;
`;

export const SingleSelectContainer = styled(SingleSelect)`
  margin-bottom: 10px;
  margin-right: 15px;
`;

export const SingleSelectContainerInverted = styled(SingleSelect)`
  margin-bottom: 10px;
  margin-left: 15px;
`;

export const OptionsDisplay = styled(Box)`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
`;
