import { Checkbox, FormControlLabel } from '@material-ui/core';
import MultiSelectBase from 'components/multi-select';
import { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HitgoalTemplateFilter } from 'services/hitgoalsService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import SearchBar from '../../../../../components/searchbar/index';

type Props = {
  owners: Item[];
  categories: Item[];
  filter: HitgoalTemplateFilter & Query;
  setFilter(value: HitgoalTemplateFilter & Query): void;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStorageSufix = '_hitgoalTemplates';

const Filter: React.FC<Props> = (props) => {
  const { filter, owners, categories, setFilter } = props;

  const timeToClearRef = useRef(0);
  const [text, setText] = useState('');

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setFilter({ ...filter, searchText: text, page: 1 }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [filter, setFilter, text]);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      saveInfoInLocalStorage(name, value as string, localStorageSufix);
      if (name !== 'searchText') setFilter({ ...filter, [name!]: value, page: 1 });
      else {
        setText(value as string);
      }
    },
    [filter, setFilter],
  );

  const handleOnCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;

      saveInfoInLocalStorage(name, checked.toString(), localStorageSufix);
      setFilter({ ...filter, [name!]: checked, page: 1 });
    },
    [setFilter, filter],
  );

  return (
    <Container>
      <SearchBar
        id={'searchByNameInput'}
        name="searchText"
        label={'Search by objective'}
        value={text}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'categorySelect'}
        name="categories"
        options={categories}
        label={'Categories'}
        onChange={handleOnChange}
        currentValues={filter.categories || []}
      />
      <MultiSelect
        id={'ownerSelect'}
        name="owners"
        options={owners}
        label={'Created by'}
        onChange={handleOnChange}
        currentValues={filter.owners || []}
      />
      <LabelContainer>
        <FormControlLabel
          id={'isActiveCheckbox'}
          control={<Checkbox checked={filter.status} onChange={handleOnCheckboxChange} name="status" />}
          label="Active"
        />
      </LabelContainer>

      <LabelContainer>
        <FormControlLabel
          id={'isOfficialCheckbox'}
          control={<Checkbox checked={filter.official} onChange={handleOnCheckboxChange} name="official" />}
          label="Show Official Only"
        />
      </LabelContainer>
    </Container>
  );
};

const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
  font-size: 13px !important;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 0;
`;

const LabelContainer = styled.div`
  display: inline-flex;
`;

export default Filter;
