/* eslint-disable react/jsx-no-bind */
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import CurrencyInput from 'components/currencyInput';
import MultiSelect from 'components/multi-select';
import { NumberFormatCustom } from 'components/numberFormatCustom';
import SingleSelect from 'components/single-select';
import UploadXlsxFile from 'components/upload-xlsx-file';
import { useForm } from 'hooks/useForm';
import moment from 'moment';
import { monthValues, monthValuesSalaryPeriod } from 'pages/hr/commonValues';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { addProjectHistory, createEmployee, ProjectHistoryResource, updateEmployee } from 'services/employeeService';
import styled from 'styled-components';
import { encryptText } from 'utils/encryptionMethods/encryption';
import { calculateTimeUntilToday, dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import { isEmail, phoneRegExp } from 'utils/formatValidations/validator';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

//@ts-ignore
import xlsxTemplate from '../../../assets/DevSimple_Employee_Template.xlsx';
import { transformToSaveObject } from './common';
import { useCountriesInformation, useEmployeeEffect, useStatesInformation } from './hooks';
import MutableInputTextOrHidden from './MutableInputTextOrHidden';
import ProjectChangeDialog from './projectChangeDialog/projectChangeDialog';

const schemaEmployee = {
  name: yup.string().required().label('Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().required().label('Email'),
  clientEmail: yup.string().optional().nullable().label('Client Email'),
  leader: yup.string().label('Leader'),
  isLeader: yup.bool().required().label('Is Leader'),
  project: yup.string().label('Project'),
  jobTitle: yup.string().optional().label('Job Title'),
  isActive: yup.boolean().default(true).label('Is Active'),
  birthdayDate: yup.string().required().label('Birthday Date').typeError('Invalid date'),
  hireDate: yup.string().label('Hire Date').typeError('Invalid date'),
  effectiveHireDate: yup.string().nullable().optional().label('Effective Hire Date').typeError('Invalid date'),
  authorizedEmployees: yup.array(yup.string().optional()),

  homeAddressCountry: yup.string().optional().label('Country'),
  homeAddressState: yup.string().optional().label('State'),
  homeAddressCity: yup.string().optional().label('City'),
  homeAddressZipCode: yup.string().optional().label('ZipCode').typeError('Invalid number'),
  homeAddressAddressLine1: yup.string().optional().label('Address Line 1'),
  homeAddressAddressLine2: yup.string().optional().label('Address Line 2'),

  employeeType: yup.string().required().label('Type'),
  mobilePhoneNumber: yup.string().matches(phoneRegExp, 'Invalid phone number'),
  homePhoneNumber: yup.string().matches(phoneRegExp, 'Invalid phone number').default(0).optional(),
  legadmiId: yup.number().optional().label('LegadmiId').typeError('Invalid number'),
  plate1: yup.string().optional(),
  plate2: yup.string().optional(),
  idNumber: yup.string().required().label('Id Number'),
  maritalStatus: yup.string().optional().label('Marital Status'),
  children: yup.number().optional().label('Children').typeError('Invalid number'),

  insuranceStatus: yup.string().optional().label('Insurance Status'),
  insuranceInclusionDate: yup.string().nullable().optional().label('Inclusion Date').typeError('Invalid date'),
  insuranceComments: yup.string().optional(),

  emergencyContactsName1: yup.string().optional().label('Contact Name'),
  emergencyContactsPhoneNumber1: yup
    .string()
    .matches(phoneRegExp, 'Invalid phone number')
    .optional()
    .label('Phone Number'),
  emergencyContactsRelationship1: yup.string().optional().label('Relation'),
  emergencyContactsName2: yup.string().optional().label('Contact Name'),
  emergencyContactsPhoneNumber2: yup
    .string()
    .matches(phoneRegExp, 'Invalid phone number')
    .optional()
    .label('Phone Number'),
  emergencyContactsRelationship2: yup.string().optional().label('Relation'),

  resignationReason: yup.string().default('').optional().label('Resignation Reason'),
  resignationDate: yup.string().nullable().optional().typeError('Invalid date'),
  lastDay: yup.string().nullable().optional().typeError('Invalid date'),
  payrollEndDate: yup.string().nullable().optional().typeError('Invalid date'),
  resignationComments: yup.string().optional(),

  primaryBackendLanguage: yup.string().optional().label('Primary Backend Language'),
  alternativeBackendLanguage: yup.string().optional().label('Alternative Backend Language'),
  primaryFrontendLanguage: yup.string().optional().label('Primary Frontend Language'),
  alternativeFrontendLanguage: yup.string().optional().label('Alternative Frontend Language'),
  otherSkills: yup.string().optional().label('Other Skills'),

  notes: yup.string().optional(),
  salaryPeriodMonth: yup.string().optional(),
  clientSalaryPeriodMonth: yup.string().optional(),
  isTimesheetEnabled: yup.bool().required().label('Time Sheet Required'),
  authorizedTimesheets: yup.array(yup.string().optional()),
  referBy: yup.string().optional().label('Refer By'),
  referralAmount: yup.number().optional().label('Referral Amount').typeError('Invalid amount'),
  yearlyBudget: yup.string().optional().label('Yearly Budget').typeError('Invalid Number'),
  activateRewards: yup.bool().required().label('Activate'),
};

type Props = {};

type OnMultiSelectChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const EmployeesForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();
  const user = useCurrentUser();
  const {
    employee,
    yearlyBudget,
    setYearlyBudget,
    lastSalary,
    setLastSalary,
    loading,
    hasPermission,
    selectedProject,
    selectedtimesheets,
    setSelectedtimesheets,
    selectedReferBy,
    setSelectedReferBy,
    lastRate,
    setLastRate,
    projectChanged,
    setProjectChanged,
    handleProjectOnChange,
    onProjectChangeConfirmation,
    projectChangeRecord,
    setProjectChangeRecord,
    oldProjectId,
    onCancelEvent,
    handleOnProjectHistory,
    resetHistoryDialog,
    getProjectName,
    refreshSavedEmployee,
    calculateSalaryPeriod,
    calculateInsuranceDate,
    handleConvertUploadedFile,
    employeeTypes,
    authorizedEmployees,
    leaders,
    projects,
    maritalStatuses,
    insuranceStatuses,
    loading: catalogLoading,
    countries,
    countriesInfo,
    resignationReasons,
    jobTitles,
    employees,
    displayUploadFileOption,
    handleOnUploadFileClose,
  } = useEmployeeEffect(user!, id);
  const history = useHistory();
  const [savingEmployee, setSavingEmployee] = useState(false);
  const { useInput, useSwitch, useInputMultiSelect, useDateInput, values, isValid, setValues } = useForm(
    employee,
    schemaEmployee as any,
    catalogLoading,
  );
  const { states, loadStates } = useCountriesInformation(values.homeAddressCountry, countriesInfo);
  const { cities, loadCities } = useStatesInformation(
    values.homeAddressCountry,
    values.homeAddressState,
    countriesInfo,
  );
  const subTitle = id ? 'Update the employee' : 'Create a new employee';
  const initialLoading = loading || catalogLoading;
  const display = initialLoading ? 'none' : 'block';
  const authorizedEmployeesInput = useInputMultiSelect('authorizedEmployees');

  const handleCountryChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    const selectedCountry = event.target.value as string;

    values.homeAddressCountry = selectedCountry || '';
    values.homeAddressState = '';
    values.homeAddressCity = '';

    if (selectedCountry && countriesInfo) {
      loadStates();
    }
  };
  const handleChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'referBy') {
      values.referBy = (event.target.value as string) || '';
      setSelectedReferBy(event.target.value as string);
    }
  };

  const handleStateChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    const selectedState = event.target.value as string;

    values.homeAddressState = (event.target.value as string) || '';
    values.homeAddressCity = '';
    if (selectedState && countriesInfo) {
      loadCities();
    }
  };

  const handleSaveEmployeeItem = async () => {
    if (!isValid || (!id && (!lastSalary || !values.jobTitle))) {
      notifier.error(`Please, fix the errors on the form before submitting `);
      return;
    }
    setSavingEmployee(true);
    const employeeObject = transformToSaveObject(values);

    if (!isEmail(employeeObject.email)) {
      notifier.error('Email is not valid');
      setSavingEmployee(false);
      return;
    }

    if (!!employeeObject.clientEmail && !isEmail(employeeObject.clientEmail)) {
      notifier.error('Client email is not valid');
      setSavingEmployee(false);
      return;
    }

    employeeObject.project = selectedProject;
    employeeObject.authorizedTimesheets = selectedtimesheets;
    const projectHistory = createProjectHistoryObject();

    if (id) {
      employeeObject.authorizedEmployees = employeeObject.authorizedEmployees.filter(
        (value, index, authorizedEmployees) => authorizedEmployees.indexOf(value) === index,
      );
      const response = await updateEmployee(employeeObject, id);

      if (response) {
        if (projectHistory) {
          await addProjectHistory(projectHistory);

          resetHistoryDialog();
        }
        if (!employee.project && response.project) {
          await addProjectHistory(newProjectHistoryFromEmpty(response.project._id, response.hireDate!, response._id));
        }

        refreshSavedEmployee(response);
        notifier.success('Employee updated successfully.');
      }
      setSavingEmployee(false);

      setValues({ ...values, email: response.email });
      return;
    }
    try {
      const newEmployee = await createEmployee(employeeObject, encryptText(lastSalary), encryptText(lastRate));

      if (newEmployee) {
        notifier.success('Employee created successfully.');
        history.push(`/employees/${newEmployee._id}`);
        refreshSavedEmployee(newEmployee);

        if (!!newEmployee.project) {
          await addProjectHistory(
            newProjectHistoryFromEmpty(newEmployee.project._id, newEmployee.hireDate!, newEmployee._id),
          );
        }
      }
    } finally {
      setSavingEmployee(false);
    }
  };

  const newProjectHistoryFromEmpty = (projectToSave: string, initialEffectiveDate: string, employeeId: string) => {
    const initialProjectHistory: ProjectHistoryResource = {
      projectTo: projectToSave,
      projectFrom: '',
      comments: 'Initial Project',
      effectiveDate: initialEffectiveDate,
      employee: employeeId,
    };

    return initialProjectHistory;
  };

  const createProjectHistoryObject = () => {
    if (!id || !projectChangeRecord.effectiveDate) return null;

    const projectHistory: ProjectHistoryResource = {
      employee: id,
      effectiveDate: projectChangeRecord.effectiveDate,
      comments: projectChangeRecord.comments,
      projectFrom: oldProjectId,
      projectTo: selectedProject,
    };

    return projectHistory;
  };

  const handleYearlyBudgetOnChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'yearlyBudget') {
      values.yearlyBudget = (event.target.value as string) || '';
      setYearlyBudget(event.target.value as string);
    }
  };

  const handleAuthTimesheetsOnChange = useCallback(
    (event: OnMultiSelectChangeEvent) => {
      const { value } = event.target;

      setSelectedtimesheets(value as string[]);
    },
    [setSelectedtimesheets],
  );

  useEffect(
    () => {
      if (values.hireDate && (!values.clientSalaryPeriodMonth || !id)) {
        const selectedDate = moment.utc(values.hireDate);
        const defaultSalaryPeriod = calculateSalaryPeriod(selectedDate.month());
        const defaultInsuranceDate = calculateInsuranceDate(selectedDate);

        setValues({
          ...values,
          clientSalaryPeriodMonth: monthValues[selectedDate.month()].id,
          salaryPeriodMonth: monthValues[defaultSalaryPeriod].id,
          insuranceInclusionDate: defaultInsuranceDate,
        });
      } else if (!projectChanged && !!projectChangeRecord.effectiveDate && !!id) {
        const projectName = getProjectName(selectedProject);

        const selectedMonth = moment.utc(projectChangeRecord.effectiveDate).month();

        if (projectName !== 'Bench' && projectName !== 'DevSimple') {
          setValues({
            ...values,
            clientSalaryPeriodMonth: monthValues[selectedMonth].id,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.hireDate, projectChanged],
  );

  const handleOnEffectiveDateChange = () => {
    if (!id) return;
    setValues((prev) => ({ ...prev, employeeType: '' }));
    notifier.info('Please update the employee type');
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Employee: ${!initialLoading ? values?.name + ' ' + values?.lastName : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          {displayUploadFileOption && (
            <UploadXlsxFile
              handleConvertUploadedFile={handleConvertUploadedFile}
              handleOnUploadFileClose={handleOnUploadFileClose}
              xlsxTemplate={xlsxTemplate}
            />
          )}

          <BodyTitle>Employee Info</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField
                  id={'name'}
                  required
                  fullWidth
                  inputProps={{
                    maxLength: 32,
                  }}
                  label="Name"
                  variant="outlined"
                  {...useInput('name')}
                />
              </TextBody>

              <TextBody>
                <TextField
                  id={'lastName'}
                  required
                  fullWidth
                  inputProps={{
                    maxLength: 32,
                  }}
                  label="Last Name"
                  variant="outlined"
                  {...useInput('lastName')}
                />
              </TextBody>

              <TextBody>
                <TextField
                  id={'idNumber'}
                  fullWidth
                  required
                  inputProps={{
                    maxLength: 25,
                  }}
                  inputMode="numeric"
                  label="Id Number"
                  variant="outlined"
                  {...useInput('idNumber')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <Tooltip title={`Official Fusioner Start Date. Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainerShort
                    id={'hireDateSelect'}
                    required
                    label="Initial Hire Date"
                    format={dateFormatUsed}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputLabelProps={{
                      shrink: values.hireDate ? true : false,
                    }}
                    InputProps={{ readOnly: true }}
                    inputVariant="outlined"
                    {...useDateInput('hireDate')}
                  />
                </Tooltip>
                <TimeInCompanyDiv style={{ whiteSpace: 'pre-wrap' }}>
                  {calculateTimeUntilToday(values.hireDate)}
                </TimeInCompanyDiv>
              </SelectContainerWide>

              <SelectContainerWide>
                <Tooltip title={`Only for Contract Changes. Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainerShort
                    id={'effectiveHireDateSelect'}
                    label={values.effectiveHireDate ? 'Contract Modification Date' : 'Contract Modif. Date'}
                    format={dateFormatUsed}
                    clearable
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputLabelProps={{
                      shrink: values.effectiveHireDate ? true : false,
                    }}
                    style={{ fontSize: 7 }}
                    InputProps={{ readOnly: true }}
                    inputVariant="outlined"
                    {...useDateInput('effectiveHireDate', handleOnEffectiveDateChange)}
                  />
                </Tooltip>
                <TimeInCompanyDiv style={{ whiteSpace: 'pre-wrap' }}>
                  {calculateTimeUntilToday(values.effectiveHireDate)}
                </TimeInCompanyDiv>
              </SelectContainerWide>

              <SelectContainerWide>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainer
                    id={'birthdaySelect'}
                    required
                    label="Birthday"
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    {...useDateInput('birthdayDate')}
                    InputLabelProps={{
                      shrink: values.birthdayDate ? true : false,
                    }}
                    InputProps={{ readOnly: true }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Tooltip>
              </SelectContainerWide>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <SingleSelectContainer
                  id={'typeSelect'}
                  label={'Type'}
                  required
                  options={employeeTypes}
                  {...useInput('employeeType')}
                />
              </SelectContainerWide>

              <TextBody style={{ display: id ? '' : 'none' }}>
                <TextField
                  id={'jobTitle'}
                  disabled={!values.jobTitle}
                  fullWidth
                  label="Job Title"
                  variant="outlined"
                  defaultValue={values.jobTitle}
                  value={values.jobTitle}
                />
              </TextBody>

              <SelectContainerWide style={{ display: id ? 'none' : '' }}>
                <SingleSelectContainer
                  id={'jobTitleSelect'}
                  label={'Job title'}
                  options={jobTitles}
                  {...useInput('jobTitle')}
                  required={!id}
                />
              </SelectContainerWide>

              <TextBody>
                <TextField
                  id={'legadmiId'}
                  fullWidth
                  required
                  label="Legadmi Id Number"
                  variant="outlined"
                  {...useInput('legadmiId')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <MutableInputTextOrHidden
                hasPermission={hasPermission}
                id={id}
                lastValue={lastSalary}
                setLastValue={setLastSalary}
                onPushNavigationRoute={`/employees/${id}/salaries`}
                fieldType={'salary'}
              />

              <MutableInputTextOrHidden
                hasPermission={hasPermission}
                id={id}
                lastValue={lastRate}
                setLastValue={setLastRate}
                onPushNavigationRoute={`/employees/${id}/rates`}
                fieldType={'rate'}
              />

              <SelectContainerShort>
                <SingleSelectContainerShort
                  id={'clientSalaryPeriodInput'}
                  label={'Client Salary Period'}
                  options={monthValues}
                  {...useInput('clientSalaryPeriodMonth')}
                />
              </SelectContainerShort>

              <SelectContainerShort>
                <SingleSelectContainerShort
                  id={'salaryPeriodInput'}
                  label={'Salary Period'}
                  options={monthValuesSalaryPeriod}
                  {...useInput('salaryPeriodMonth')}
                />
              </SelectContainerShort>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField id={'email'} required fullWidth label="Email" variant="outlined" {...useInput('email')} />
              </TextBody>
              <TextBody>
                <TextField
                  id={'clientEmail'}
                  type="email"
                  fullWidth
                  label="Client's Email"
                  variant="outlined"
                  {...useInput('clientEmail')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField
                  id={'mobilePhoneNumber'}
                  fullWidth
                  label="Mobile Phone"
                  variant="outlined"
                  {...useInput('mobilePhoneNumber')}
                />
              </TextBody>

              <TextBody>
                <TextField
                  id={'homePhoneNumber'}
                  fullWidth
                  label="Home Phone"
                  variant="outlined"
                  {...useInput('homePhoneNumber')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <SingleSelectContainer
                  id={'maritalStatusSelect'}
                  label={'Marital Status'}
                  options={maritalStatuses}
                  {...useInput('maritalStatus')}
                />
              </SelectContainerWide>
              <TextBody>
                <TextField
                  id={'numberOfChildren'}
                  fullWidth
                  inputProps={{
                    maxLength: 2,
                  }}
                  label="Children"
                  variant="outlined"
                  {...useInput('children')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField
                  id={'plateNumber1'}
                  fullWidth
                  inputProps={{
                    maxLength: 10,
                  }}
                  label="Plate #1"
                  variant="outlined"
                  {...useInput('plate1')}
                />
              </TextBody>

              <TextBody>
                <TextField
                  id={'plateNumber2'}
                  fullWidth
                  inputProps={{
                    maxLength: 10,
                  }}
                  label="Plate #2"
                  variant="outlined"
                  {...useInput('plate2')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>
          <br />
          <BodyTitle>Home Address</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainer>
                <SingleSelect
                  id={'country'}
                  name={'country'}
                  label={'Country'}
                  options={countries}
                  value={values.homeAddressCountry}
                  onChange={handleCountryChange}
                />
              </SelectContainer>

              <SelectContainer>
                <SingleSelect
                  id={'state'}
                  name={'state'}
                  label={'State'}
                  options={states}
                  value={values.homeAddressState}
                  onChange={handleStateChange}
                />
              </SelectContainer>

              <SelectContainer>
                <SingleSelect
                  id={'city'}
                  name={'city'}
                  label={'City'}
                  options={cities}
                  {...useInput('homeAddressCity')}
                />
              </SelectContainer>

              <SelectContainer>
                <TextField
                  fullWidth
                  id="zipCode"
                  label="Zip Code"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                  {...useInput('homeAddressZipCode')}
                />
              </SelectContainer>
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <TextFieldAddress
              id={'addressLine1'}
              fullWidth
              label="Address Line 1"
              variant="outlined"
              {...useInput('homeAddressAddressLine1')}
            />
          </DetailsLine>
          <DetailsLine>
            <TextFieldAddress
              id={'addressLine2'}
              fullWidth
              label="Address Line 2"
              variant="outlined"
              {...useInput('homeAddressAddressLine2')}
            />
          </DetailsLine>

          <br />
          <BodyTitle>Insurance</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainer>
                <SingleSelect
                  id={'insuranceStatusSelect'}
                  label={'Status'}
                  options={insuranceStatuses}
                  {...useInput('insuranceStatus')}
                />
              </SelectContainer>

              <SelectContainerWide>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainer
                    id={'inclusionDateSelect'}
                    label="Inclusion Date"
                    format={dateFormatUsed}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputLabelProps={{
                      shrink: values.insuranceInclusionDate ? true : false,
                    }}
                    InputProps={{ readOnly: true }}
                    inputVariant="outlined"
                    {...useDateInput('insuranceInclusionDate')}
                  />
                </Tooltip>
              </SelectContainerWide>
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <TextWideBody>
              <TextField
                id={'insuranceCommentsInput'}
                multiline
                fullWidth
                rows={4}
                inputProps={{ maxLength: 5000 }}
                label="Comments"
                variant="outlined"
                {...useInput('insuranceComments')}
              />
            </TextWideBody>
          </DetailsLine>

          <br />
          <BodyTitle>Emergency Contacts</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField
                  id={'contactName1'}
                  fullWidth
                  label="Contact Name 1"
                  variant="outlined"
                  {...useInput('emergencyContactsName1')}
                />
              </TextBody>
              <TextBody>
                <TextField
                  id={'contact1PhoneNumber'}
                  fullWidth
                  label="Phone Number "
                  variant="outlined"
                  {...useInput('emergencyContactsPhoneNumber1')}
                />
              </TextBody>
              <TextBody>
                <TextField
                  id={'contact1Relationship'}
                  fullWidth
                  label="Relationship"
                  variant="outlined"
                  {...useInput('emergencyContactsRelationship1')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField
                  id={'contactName2'}
                  fullWidth
                  label="Contact Name 2"
                  variant="outlined"
                  {...useInput('emergencyContactsName2')}
                />
              </TextBody>
              <TextBody>
                <TextField
                  id={'contact2PhoneNumber'}
                  fullWidth
                  label="Phone Number "
                  variant="outlined"
                  {...useInput('emergencyContactsPhoneNumber2')}
                />
              </TextBody>
              <TextBody>
                <TextField
                  id={'contact2RelationShip2'}
                  fullWidth
                  label="Relationship"
                  variant="outlined"
                  {...useInput('emergencyContactsRelationship2')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <br />
          <BodyTitle>Leader and project</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <SingleSelect
                  id={'projectSelect'}
                  label={'Project'}
                  options={projects}
                  value={selectedProject}
                  onChange={handleProjectOnChange}
                  customWidth={!id || !employee.project ? '280px' : '240px'}
                />
                {!!id && !!employee.project && (
                  <Tooltip title="Project change history">
                    <SubmitButton
                      id="projectHistory"
                      onClick={handleOnProjectHistory}
                      aria-label="View project history"
                      size="small"
                      style={{ marginLeft: 5, padding: 5, marginTop: 6, height: 35 }}
                      disabled={!!projectChangeRecord.effectiveDate}
                    >
                      <TransferWithinAStationIcon />
                    </SubmitButton>
                  </Tooltip>
                )}
              </SelectContainerWide>

              <SelectContainerWide>
                <MultiSelectContainer
                  id={'authorizedTimesheetsSelect'}
                  label={'Authorized Timesheets'}
                  options={projects}
                  currentValues={selectedtimesheets}
                  onChange={handleAuthTimesheetsOnChange}
                />
              </SelectContainerWide>

              <FormControlLabelOptionContainer
                control={<Switch id="enableTimesheet" name="timesheetEnabled" {...useSwitch('isTimesheetEnabled')} />}
                label="Time Sheet Required"
              />
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <SingleSelectContainer id={'leaderSelect'} label={'Leader'} options={leaders} {...useInput('leader')} />
              </SelectContainerWide>

              <SelectContainerWide>
                <MultiSelectContainer
                  id={'authorizedPeopleSelect'}
                  label={'Authorized People'}
                  options={authorizedEmployees}
                  {...authorizedEmployeesInput}
                />
              </SelectContainerWide>
            </OptionsContainer>
          </DetailsLine>

          <br />
          <BodyTitle>Skills</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField
                  id={'primaryBELanguage'}
                  fullWidth
                  label="Primary Backend Language"
                  variant="outlined"
                  {...useInput('primaryBackendLanguage')}
                />
              </TextBody>
              <TextBody>
                <TextField
                  id={'altBELanguage'}
                  fullWidth
                  label="Alternative Backend Language"
                  variant="outlined"
                  {...useInput('alternativeBackendLanguage')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <DetailsLine>
            <OptionsContainer>
              <TextBody>
                <TextField
                  id={'primaryFELanguage'}
                  fullWidth
                  label="Primary Frontend Language"
                  variant="outlined"
                  {...useInput('primaryFrontendLanguage')}
                />
              </TextBody>
              <TextBody>
                <TextField
                  id={'altFELanguage'}
                  fullWidth
                  label="Alternative Frontend Language"
                  variant="outlined"
                  {...useInput('alternativeFrontendLanguage')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <TextWideBody>
              <TextField
                id={'otherSkills'}
                multiline
                fullWidth
                inputProps={{ maxLength: 5000 }}
                rows={4}
                label="Other Skills"
                variant="outlined"
                {...useInput('otherSkills')}
              />
            </TextWideBody>
          </DetailsLine>
          <br />
          <BodyTitle>Other</BodyTitle>

          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <SingleSelectContainer
                  id={'referBySelect'}
                  name={'referBy'}
                  label={'Refer By'}
                  options={employees}
                  value={selectedReferBy}
                  onChange={handleChange}
                />
              </SelectContainerWide>
              <TextBody>
                <TextField
                  id={'referralAmount'}
                  fullWidth
                  inputProps={{
                    maxLength: 5,
                  }}
                  label="Referral amount"
                  variant="outlined"
                  {...useInput('referralAmount')}
                />
              </TextBody>
            </OptionsContainer>
          </DetailsLine>

          <br />
          <BodyTitle>Rewards Program</BodyTitle>
          <DetailsLine>
            <TextBody>
              <TextField
                label="Yearly budget"
                variant="outlined"
                name="yearlyBudget"
                id="yearlyBudget"
                value={yearlyBudget.replace('$', '')}
                onChange={handleYearlyBudgetOnChange}
                InputProps={{
                  inputComponent: CurrencyInputContainer as any,
                }}
              />
            </TextBody>

            <FormControlLabelOptionContainer
              control={<Switch id="activateRewards" name="activateRewards" {...useSwitch('activateRewards')} />}
              label="Activate"
            />
          </DetailsLine>

          <br />
          <BodyTitle>Resignation / Termination</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <SingleSelectContainer
                  id={'resignationReasonSelect'}
                  label={'Reason'}
                  options={resignationReasons}
                  {...useInput('resignationReason')}
                  required={!!values.resignationDate || !!values.lastDay || !!values.payrollEndDate}
                  error={
                    !values.resignationReason &&
                    (!!values.resignationDate || !!values.lastDay || !!values.payrollEndDate)
                  }
                />
              </SelectContainerWide>

              <SelectContainerWide>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainer
                    id={'resignationDateSelect'}
                    label="Resignation Date"
                    clearable
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: values.resignationDate ? true : false,
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    {...useDateInput('resignationDate')}
                  />
                </Tooltip>
              </SelectContainerWide>

              <SelectContainerWide>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainer
                    id="datePickerDialog"
                    clearable
                    label="Last Day"
                    key="Last Day"
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: values.lastDay ? true : false,
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    {...useDateInput('lastDay')}
                  />
                </Tooltip>
              </SelectContainerWide>

              <SelectContainerWide>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainer
                    id="datePickerEndDateDialog"
                    clearable
                    label="Payroll End Date"
                    key="Payroll End Date"
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: values.payrollEndDate ? true : false,
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    {...useDateInput('payrollEndDate')}
                  />
                </Tooltip>
              </SelectContainerWide>
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <TextWideBody>
              <TextField
                id={'resignationReasonComments'}
                multiline
                fullWidth
                inputProps={{ maxLength: 5000 }}
                rows={4}
                label="Comments"
                variant="outlined"
                {...useInput('resignationComments')}
              />
            </TextWideBody>
          </DetailsLine>

          <br />
          <BodyTitle>Notes</BodyTitle>
          <DetailsLine>
            <TextWideBody>
              <TextField
                id={'notesInput'}
                multiline
                fullWidth
                inputProps={{ maxLength: 5000 }}
                rows={4}
                label="Notes"
                variant="outlined"
                {...useInput('notes')}
              />
            </TextWideBody>
          </DetailsLine>
          <br />
          <BodyTitle>Security</BodyTitle>

          <DetailsLine>
            <OptionsContainer>
              <FormControlLabel
                control={<Switch id={'isActiveSwitch'} name="isActive" {...useSwitch('isActive')} />}
                label="Is Active"
              />

              <FormControlLabel
                control={<Switch id={'isLeaderSwitch'} name="isLeader" {...useSwitch('isLeader')} />}
                label="Is leader"
              />
            </OptionsContainer>
          </DetailsLine>

          <ButtonContainer>
            {savingEmployee && <Spinner size={30} />}
            <Submit
              id={'saveEmployeeButton'}
              disabled={
                savingEmployee ||
                loading ||
                catalogLoading ||
                !isValid ||
                (!values.resignationReason && (!!values.resignationDate || !!values.lastDay || !!values.payrollEndDate))
              }
              onClick={handleSaveEmployeeItem}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>

      <ProjectChangeDialog
        open={projectChanged}
        setOpen={setProjectChanged}
        acceptConfirmation={onProjectChangeConfirmation}
        projectChangeRecord={projectChangeRecord}
        setProjectChangeRecord={setProjectChangeRecord}
        oldProject={oldProjectId}
        newProject={selectedProject}
        projectList={projects}
        cancelAction={onCancelEvent}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 0, 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
`;

const OptionsContainer = styled.div`
  display: block;
  float: left;
`;

const TextBody = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 280px;
  float: left;
  display: flex;
  align-items: center;
`;

const TextWideBody = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 100%;
`;

const TextFieldAddress = styled(TextField)`
  margin-top: 15px;
`;

const SelectContainer = styled.div`
  min-width: 160px;
  display: block;
  float: left;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: -5px;
`;

const SelectContainerWide = styled.div`
  min-width: 160px;
  width: 280px;
  display: flex;
  float: left;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: -5px;
`;

const SelectContainerShort = styled.div`
  min-width: 80px;
  display: block;
  float: left;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: -5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const SingleSelectContainer = styled(SingleSelect)`
  width: 280px;
`;

const SingleSelectContainerShort = styled(SingleSelect)`
  width: 180px;
`;

const MultiSelectContainer = styled(MultiSelect)`
  width: 280px;
`;

const DateInputPickerContainer = styled(KeyboardDatePicker)`
  width: 280px;
`;

const DateInputPickerContainerShort = styled(KeyboardDatePicker)`
  width: 200px;
  .MuiButtonBase-root {
    margin-right: -14px;
  }
`;

const FormControlLabelOptionContainer = styled(FormControlLabel)`
  margin-top: 10px;
`;

const CurrencyInputContainer = styled(CurrencyInput)`
  margin-right: 20px;
  width: 233px;
  float: left;
`;

const TimeInCompanyDiv = styled.span`
  padding-left: 8px;
  align-self: center;
`;

const SubmitButton = styled(IconButton)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
`;

export default EmployeesForm;
