import React from 'react';
import { ActionItemCommentResource } from 'services/actionItemService';
import styled from 'styled-components';

type Props = {
  data: ActionItemCommentResource[];
};

const CommentsHolder: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <Container>
      <Clear />
      {data.map((item) => (
        <Comment key={item._id}>
          <HeaderContainer>
            <ImageContainer>
              <img src={item.createdBy?.picture} alt={'.'} width={20} height={20} />
            </ImageContainer>
            <NameContainer>
              {item.createdBy?.name} {item.createdBy?.lastName}:
            </NameContainer>
            <DateContainer>{renderDate(item.creationDate)}</DateContainer>
          </HeaderContainer>
          <Clear />
          <Description>{item.comment}</Description>
        </Comment>
      ))}
    </Container>
  );
};

const renderDate = (date: string) => {
  const newDate = new Date(date);

  if (newDate.toDateString() === new Date().toDateString()) return <div>Today at: {newDate.toLocaleTimeString()}</div>;

  return <div>{newDate.toLocaleString()}</div>;
};

const Container = styled.div`
  display: block;
`;

const Comment = styled.div`
  display: block;
  border-bottom: 1px solid #dadada;
  clear: both;
  padding: 10px;
`;

const ImageContainer = styled.div`
  height: 20px;
  width: 20px;
  float: left;
`;

const NameContainer = styled.div`
  margin-left: 5px;
  float: left;
`;

const DateContainer = styled.div`
  float: right;
`;

const HeaderContainer = styled.div`
  display: block;
`;
const Description = styled.div`
  display: block;
  text-align: left;
  padding: 10px 20px 5px 10px;
  white-space: pre-wrap;
  word-break: break-all;
`;

const Clear = styled.div`
  clear: both;
`;

export default CommentsHolder;
