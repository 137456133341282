import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 15px;
`;

export const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

export const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

export const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;