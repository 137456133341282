/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import React from 'react';
import { CareerDevelopmentPlanResource } from 'services/careerDevelopmentPlanService';
import { stringDateToMonthYearFormat } from 'utils/formatters/date';

const fixedColumns: Column<CareerDevelopmentPlanResource>[] = [
  {
    label: 'Assigned to',
    sortKey: '',
    path: 'leaderAssigned.name',
    render: (item) => `${item.leaderAssigned ? item.leaderAssigned.name + ' ' + item.leaderAssigned.lastName : '-'}`,
  },
  {
    label: 'Status',
    sortKey: '',
    path: 'cdpStatus.name',
    render: (item) => <StatusHighlighter name={item.cdpStatus.name} />,
  },
  {
    label: 'Period',
    sortKey: '',
    path: 'creationDate',
    render: (item) => {
      return stringDateToMonthYearFormat(item.creationDate);
    },
  },
];

const variableColumns = (onViewPressed: any) => {
  return {
    path: 'check',
    render: (item: CareerDevelopmentPlanResource) => (
      <div>
        <IconButton onClick={() => onViewPressed(item._id)} color="primary">
          <VisibilityIcon />
        </IconButton>
      </div>
    ),
  };
};
const columns = (onViewPressed: any) => {
  return [...fixedColumns, variableColumns(onViewPressed)];
};

export default columns;
