import { useCallback, useEffect, useState } from 'react';
import { getAllActionItemPriorities } from 'services/actionItemPriorityService';
import { ActionItemResource, getActionItemById, SaveActionItemResource } from 'services/actionItemService';
import { getAllActionItemStatus } from 'services/actionItemStatusService';
import { getAllEmployees, getAllLeaders } from 'services/employeeService';
import { EmployeeAndLeader, Item } from 'shared/types';
import { collectionEmployeeToItem, collectionEmployeeWithLeader, collectionToItem } from 'utils/mappers/item';

const defaultActionItemResource = {
  employee: '',
  leaderAssigned: '',
  status: '',
  priority: '',
  title: '',
  description: '',
  dueDate: new Date().toString(),
  completionDate: null,
  createdBy: '',
  type: 'General',
  typeId: 'none',
};

export const useActionItemEffect = (id?: string) => {
  const [actionItem, setActionItem] = useState<SaveActionItemResource>(defaultActionItemResource);
  const [actionItemCreationDate, setActionItemCreationDate] = useState(new Date().toISOString());
  const [actionItemCompletionDate, setActionItemCompletionDate] = useState<string | null>(null);
  const [actionItemCreatedBy, setActionItemCreatedBy] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchActionItem = useCallback(async () => {
    setLoading(true);
    const actionItemResult = await getActionItemById(id!);

    if (actionItemResult) {
      setActionItem(mapActionItemResponse(actionItemResult));
      setActionItemCreationDate(actionItemResult.creationDate);
      setActionItemCompletionDate(
        actionItemResult.completionDate === undefined ? null : actionItemResult.completionDate,
      );
      setActionItemCreatedBy(
        actionItemResult.createdBy ? actionItemResult.createdBy?.name + ' ' + actionItemResult.createdBy?.lastName : '',
      );
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchActionItem();
  }, [id, fetchActionItem]);

  return {
    actionItem,
    actionItemCreationDate,
    actionItemCompletionDate,
    setActionItemCompletionDate,
    loading,
    actionItemCreatedBy,
  };
};

const mapActionItemResponse = (actionItem: ActionItemResource) => {
  return {
    employee: actionItem.employee._id,
    leaderAssigned: actionItem.leaderAssigned._id,
    status: actionItem.status._id,
    priority: actionItem.priority._id,
    title: actionItem.title,
    description: actionItem.description,
    creationDate: actionItem.creationDate,
    dueDate: actionItem.dueDate,
    completionDate: actionItem.completionDate,
    createdBy: actionItem.createdBy,
    type: actionItem.type,
    typeId: actionItem.typeId,
  } as SaveActionItemResource;
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [priorities, setPriorities] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const [employeesWithLeaders, setEmployeesWithLeaders] = useState<EmployeeAndLeader[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [employeesResult, leadersResult, statusResult, prioritiesResult] = await Promise.all([
        getAllEmployees(),
        getAllLeaders(),
        getAllActionItemStatus(),
        getAllActionItemPriorities(),
      ]);

      const statusItems = collectionToItem(statusResult);
      const leaderItems = collectionEmployeeToItem(leadersResult);
      const employeeItems = collectionEmployeeToItem(employeesResult);
      const prioritiesItems = collectionToItem(prioritiesResult);
      const employeesWithLeaders = collectionEmployeeWithLeader(employeesResult);

      setStatuses(statusItems);
      setLeaders(leaderItems);
      setEmployees(employeeItems);
      setPriorities(prioritiesItems);
      setLoading(false);
      setEmployeesWithLeaders(employeesWithLeaders);
    }

    fetchCatalogs();
  }, []);

  return { employees, leaders, statuses, priorities, loading, employeesWithLeaders };
};

export const useEmployeeAndLeaderEffect = (employeeId: string, leaderId: string) => {
  const [employeeSelected, setEmployeeSelected] = useState(employeeId || '');
  const [leaderSelected, setLeaderSelected] = useState(leaderId || '');

  useEffect(() => {
    if (employeeId) setEmployeeSelected(employeeId);
    if (leaderId) setLeaderSelected(leaderId);
  }, [employeeId, leaderId]);

  return { employeeSelected, setEmployeeSelected, leaderSelected, setLeaderSelected };
};
