import { monthValues } from 'pages/hr/commonValues';
import { useCallback, useEffect, useState } from 'react';
import { getCountries } from 'services/countriesService';
import {
  getHolidaysByFilter,
  HolidaysPerYear,
  SaveHolidaysPerYear,
  updateHolidayPerYear,
} from 'services/timesheetService';
import { deleteTimesheetHolidayPerYear } from 'services/timesheetService';
import { Item, listItems } from 'shared/types';
import { countriesToItem } from 'utils/mappers/item';
import notifier from 'utils/notifiers/notifier';

const defaultHolidaysPerYearResource = {
  year: new Date().getFullYear().toString(),
  month: '',
  day: '',
  description: '',
  country: 'Costa Rica',
};

export const useHolidaysPerYerEffect = (defaultYear: string, defaultCountry: string, availableYears: Item[]) => {
  const [holidays, setHolidays] = useState<HolidaysPerYear[]>();
  const [maxHolidaysPerCountry, setHolidaysPerCountry] = useState<HolidaysPerYear[]>();
  const [saveHolidaysPerYear, setSaveHolidaysPerYear] = useState<SaveHolidaysPerYear>(defaultHolidaysPerYearResource);
  const [loadingHolidaysPerYear, setLoadingHolidaysPerYear] = useState(false);
  const [loadingHolidaysPerCountry, setLoadingHolidaysPerCountry] = useState(false);
  const [yearSelected, setYearSelected] = useState(defaultYear);
  const [countrySelected, setCountrySelected] = useState(defaultCountry);
  const [editingHoliday, setEditingHoliday] = useState('');
  const [optionsForEditMode, setOptionsForEditMode] = useState<listItems[]>([
    { propertyId: 'year', options: availableYears },
    { propertyId: 'month', options: monthValues.map((item) => ({ ...item, id: item.label })) },
  ]);

  const holidayFilter = { year: yearSelected, country: countrySelected };
  const handleEditHoliday = (holidayId: string) => {
    setEditingHoliday(holidayId);
  };

  const handleSaveAfterEditHoliday = async (id: string, holidayInfo: SaveHolidaysPerYear) => {
    const result = await updateHolidayPerYear(id, holidayInfo);

    if (result) {
      await getHolidaysByFilter(holidayFilter);

      fetchHolidaysByFilter();
      setEditingHoliday('');
      notifier.success('Holiday updated successfully.');
    }
  };

  const handleDeleteHoliday = async (id: string) => {
    setEditingHoliday('');
    return await deleteTimesheetHolidayPerYear(id);
  };

  const [countries, setCountries] = useState<Item[]>([]);

  const fetchHolidaysByFilter = useCallback(async () => {
    setLoadingHolidaysPerYear(true);
    const holidaysResource = await getHolidaysByFilter(holidayFilter);

    const countriesResult = await getCountries();

    const countriesItems = countriesToItem(countriesResult);

    if (holidaysResource) {
      setHolidays(holidaysResource);
      setHolidaysPerCountry(holidaysResource);
    }
    if (countriesItems) {
      setCountries(countriesItems);
      setOptionsForEditMode((oldOptions) => [...oldOptions, { propertyId: 'country', options: countriesItems }]);
    }
    setLoadingHolidaysPerYear(false);
    setLoadingHolidaysPerCountry(false);
  }, [yearSelected, countrySelected]);

  useEffect(() => {
    if (yearSelected || countrySelected) fetchHolidaysByFilter();
  }, [yearSelected, countrySelected, fetchHolidaysByFilter]);

  return {
    saveHolidaysPerYear,
    loading: loadingHolidaysPerYear,
    loadingCountry: loadingHolidaysPerCountry,
    holidays,
    maxHoursPerMonthCountry: maxHolidaysPerCountry,
    yearSelected,
    setYearSelected,
    setSaveHolidaysPerYear,
    setHolidaysPerYear: setHolidays,
    fetchHolidaysByFilter,
    countrySelected,
    setCountrySelected,
    setHolidaysPerCountry,
    holidayFilter,
    countries,
    handleEditHoliday,
    editingHoliday,
    handleSaveAfterEditHoliday,
    optionsForEditMode,
    handleDeleteHoliday,
  };
};
