import DateInputPicker from 'components/date-input-picker';
import MultiSelectBase from 'components/multi-select';
import { Query } from 'hooks/usePaging';
import moment from 'moment';
import React, { useCallback } from 'react';
import { ReportFilter } from 'services/oneOnOneService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  leaders: Item[];
  filterOneOnOnes: ReportFilter & Query;
  setFilterOneOnOnes(value: ReportFilter & Query): void;
  filterActionItems: ReportFilter & Query;
  setFilterActionItems(value: ReportFilter & Query): void;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_1on1Report';

const Filter: React.FC<Props> = (props) => {
  const { filterOneOnOnes, leaders, setFilterOneOnOnes, filterActionItems, setFilterActionItems } = props;

  const currentDate = moment.utc();

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setFilterOneOnOnes({ ...filterOneOnOnes, [name!]: value, page: 1 });
      setFilterActionItems({ ...filterActionItems, [name!]: value, page: 1 });
    },
    [setFilterOneOnOnes, filterOneOnOnes, setFilterActionItems, filterActionItems],
  );

  return (
    <Container>
      <MultiSelectContainer>
        <MultiSelect
          id={'leadersSelect'}
          name="leaderAssigned"
          options={leaders}
          currentValues={filterOneOnOnes.leaderAssigned || []}
          label={'Assigned to'}
          onChange={handleOnChange}
        />
      </MultiSelectContainer>

      <DateInputContainer
        id={'dateInputSelect'}
        views={['year', 'month']}
        label={'Start date'}
        minDate={new Date('2020-01-01')}
        name={'startDate'}
        value={filterOneOnOnes.startDate?.toString() || currentDate.toString()}
        onChange={handleOnChange}
      />

      <DateInputContainer
        id={'dateInputSelect'}
        views={['year', 'month']}
        label={'End date'}
        minDate={new Date('2020-01-01')}
        name={'endDate'}
        value={filterOneOnOnes.endDate?.toString() || currentDate.add(1, 'month').toString()}
        onChange={handleOnChange}
      />
    </Container>
  );
};

const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
  width: 300px;
`;

const Container = styled.div`
  display: inline-block;
  flex: 1;
  padding: 10px 0;
`;

const MultiSelectContainer = styled.div`
  display: inline-block;
  flex: 1;
  width: 300px;
  margin-right: 10px;
`;

const DateInputContainer = styled(DateInputPicker)`
  max-width: 175px;
  margin-right: 10px;
`;

export default Filter;
