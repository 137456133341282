/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteHitgoalTemplate, HitgoalTemplateResource } from 'services/hitgoalsService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<HitgoalTemplateResource>[] = [
  {
    label: '',
    path: 'official',
    render: (item) => {
      if (item.official) return <CheckBoxIcon style={{ color: green[500], marginRight: '-20px' }} />;
    },
  },
  {
    label: 'Objective',
    sortKey: 'objective',
    path: 'objective',
  },
  {
    label: 'Category',
    sortKey: 'category.name',
    path: 'category.name',
    render: (item) => `${item.category ? item.category.name : '-'}`,
  },
  {
    label: 'Last Review Date',
    sortKey: 'lastReviewDate',
    path: 'lastReviewDate',
    render: (item) =>
      `${
        item.lastReviewDate && stringDateToHumanFormat(item.lastReviewDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.lastReviewDate)
          : '-'
      }`,
  },
  {
    label: 'Created by',
    sortKey: 'createdBy.name',
    path: 'createdBy.name',
    render: (item) => `${item.createdBy ? item.createdBy.name + ' ' + item.createdBy.lastName : '-'}`,
  },
  {
    label: 'Status',
    sortKey: 'status',
    path: 'status',
    render: (item) => {
      return item.status ? 'Active' : 'Inactive';
    },
  },
];

const variableColumns = (fetchTemplates: any, setLoading: any, onCopyTemplate: Function) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <IconButton onClick={() => onCopyTemplate(item._id)} color="primary">
          <FileCopyIcon />
        </IconButton>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchTemplates}
          deleteItem={deleteHitgoalTemplate}
          setLoading={setLoading}
          url={'hitGoal-templates'}
          name={'HitGoal Template'}
          deleteTitle={'Delete Hitgoal Template'}
          deleteMessage={'Do you want to delete this Template?'}
        />
      </Container>
    ),
  };
};

const columns = (fetchTemplates: any, setLoading: any, onCopyTemplate: Function) => {
  return [...fixedColumns, variableColumns(fetchTemplates, setLoading, onCopyTemplate)];
};

const Container = styled.div`
  display: flex;
  float: right;
`;

export default columns;
