import { useForm } from 'hooks/useForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAllLeaders } from 'services/employeeService';
import { getAllFeelings } from 'services/feelingsService';
import { getOneOnOneById, OneOnOneResource, SaveOneOnOneResource, updateOneOnOneBy } from 'services/oneOnOneService';
import { getAllOneOnOneStatus } from 'services/statusService';
import { Item } from 'shared/types';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

const schema = {
  talkingPoint: yup.string().optional().label('Talking points'),
  leaderAssigned: yup.string().label('Leader assigned'),
  feelings: yup.array(yup.string().required()).required(),
  status: yup.string().required().label('Status'),
};

export const useFetchOneOnOneEffect = (id: string) => {
  const [oneOnOne, setOneOnOne] = useState<OneOnOneResource>();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { leaders, statuses, feelings, loading: catalogLoading } = useCatalogs();
  let { values, useInput, useRadioButton, isValid } = useForm(
    useMapOneOnOne(oneOnOne),
    schema as any,
    loading || catalogLoading,
  );
  const [isSubmiting, setIsSubmitting] = useState(false);
  const handleSaveOneOnOne = useCallback(
    async (allowNotifications: boolean = false) => {
      setIsSubmitting(allowNotifications);
      try {
        const response = await updateOneOnOneBy(values, id);

        if (allowNotifications && response) {
          notifier.success('One on One updated successfully.');
        }
        saveInfoInLocalStorage('inputValue1on1', '', '_form');
      } catch (error) {
        if (isBadRequest(error as any)) notifier.error((error as any).response.data.friendlyMessage);
      }

      setIsSubmitting(false);
    },
    [id, values],
  );

  // const { isSubmiting, handleSaveOneOnOne } = useSubmitForm(values, id, isValid);
  const handleOnBlur = async () => {
    if (isValid) await handleSaveOneOnOne();
  };

  const onHistoryPressed = () => {
    setShowModal(true);
  };

  useEffect(() => {
    async function fetchOneOnOne() {
      setLoading(true);
      const oneOnOneResult = await getOneOnOneById(id);

      setLoading(false);
      saveInfoInLocalStorage('inputValue1on1', '', '_form');
      setOneOnOne(oneOnOneResult);
    }

    fetchOneOnOne();
  }, [id]);

  return {
    oneOnOne,
    loading,
    onHistoryPressed,
    showModal,
    setShowModal,
    handleOnBlur,
    leaders,
    statuses,
    feelings,
    values,
    useInput,
    useRadioButton,
    catalogLoading,
    isSubmiting,
    isValid,
    handleSaveOneOnOne,
  };
};

export const useMapOneOnOne = (oneOnOne?: OneOnOneResource | null) => {
  return useMemo<SaveOneOnOneResource>(
    () => ({
      talkingPoint: oneOnOne?.talkingPoint || '',
      leaderAssigned: oneOnOne?.leaderAssigned?._id || '',
      status: oneOnOne?.status._id || '',
      feelings: oneOnOne?.feelings.map((x) => x._id) || [],
    }),
    [oneOnOne],
  );
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const [feelings, setFeelings] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [leadersResult, statusResult, feelingsResult] = await Promise.all([
        getAllLeaders(),
        getAllOneOnOneStatus(),
        getAllFeelings(),
      ]);

      const statusItems = collectionToItem(statusResult);
      const leaderItems = collectionEmployeeToItem(leadersResult);
      const feelingItems = collectionToItem(feelingsResult);

      setStatuses(statusItems);
      setLeaders(leaderItems);
      setFeelings(feelingItems);

      setLoading(false);
      saveInfoInLocalStorage('inputValue1on1', '', '_form');
    }

    fetchCatalogs();
  }, []);

  return { leaders, statuses, feelings, loading };
};

export const useReadOnlyEffect = (key: string | null, id: string, oneOnOne?: OneOnOneResource | null) => {
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (key && key === 'readOnly' && id) {
      if (oneOnOne?.status.name === 'Complete' || oneOnOne?.status.name === 'Not Done') {
        setReadOnly(true);
      }
    } else setReadOnly(false);
  }, [key, id, oneOnOne]);

  return { isReadOnly, setReadOnly };
};
