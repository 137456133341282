/* eslint-disable react-hooks/exhaustive-deps */
import { isPermissionGranted, ROLE_ADMIN, ROLE_FINANCE } from 'helpers/roleHelper';
import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { getRewardHistoricalById } from 'services/rewardsHistoricalService';
import { getPaymentStatus, getRewardPaymentById } from 'services/rewardsPaymentService';
import { RewardPaymentRequest } from 'services/rewardsPaymentService';
import { Item } from 'shared/types';
import Constants from 'utils/constants';

const rewardPaymentRequest: RewardPaymentRequest = {
  paymentStatusID: '',
  paymentDate: null,
  requestComment: '',
};

const useRewardPaymentRequest = (id: string, statusInTheMainScreen: string) => {
  const [saveRewardPaymentRequest, setSaveRewardPaymentRequest] = useState<RewardPaymentRequest>(rewardPaymentRequest);
  const [loading, setLoading] = useState(false);
  const [reward, setReward] = useState<RewardPaymentRequest>();
  const [paymentStatus, setPaymentStatus] = useState<Item[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [hasPermission, setHasPermision] = useState(false);
  const user = useCurrentUser();
  let historicalData;

  const fetchReward = useCallback(async () => {
    setLoading(true);
    const rewardResult = await getRewardPaymentById(id);

    const paymentStatusResult = await getPaymentStatus();

    if (paymentStatusResult.length > 0) {
      const newOptionList: Item[] = paymentStatusResult.map((item) => ({
        id: item._id ?? '',
        label: item.statusName,
      }));

      setPaymentStatus(newOptionList);
    }

    if (rewardResult) {
      if (rewardResult.paymentDate === '1990-01-01T06:00:00.000Z') rewardResult.paymentDate = null;

      if (rewardResult.rewStatusID?.statusName !== Constants.REWARD_PENDING_REFUND_STATUS_NAME) {
        historicalData = await getRewardHistoricalById(id, rewardResult.rewStatusID?.statusName ?? '');
      }

      setSelectedStatus(rewardResult.rewStatusID?.statusName ?? '');
      if (
        rewardResult.rewStatusID?.statusName !== Constants.REWARD_REJECTED_STATUS_NAME &&
        rewardResult.rewStatusID?.statusName !== Constants.REWARD_REFUNDED_STATUS_NAME
      )
        rewardResult.paymentDate = '';

      setSaveRewardPaymentRequest(mapRewardResponse(rewardResult, historicalData?.requestComment || ''));
      setReward(rewardResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchReward();
    setHasPermision(isPermissionGranted([ROLE_ADMIN, ROLE_FINANCE], user));
  }, [fetchReward, id]);

  return {
    saveRewardPaymentRequest,
    setSaveRewardPaymentRequest,
    loading,
    reward,
    paymentStatus,
    selectedStatus,
    hasPermission,
  };
};

const mapRewardResponse = (rewardPayment: RewardPaymentRequest, rewardsComment: string) => {
  return {
    id: rewardPayment?._id || '',
    paymentStatusID: rewardPayment.rewStatusID?._id,
    requestComment: rewardsComment,
    paymentDate: rewardPayment.paymentDate,
  } as RewardPaymentRequest;
};

export default useRewardPaymentRequest;
