import FusionHitIcon from 'assets/icon.png';
import React, { useCallback } from 'react';
import GoogleLogin from 'react-google-login';
import MicrosoftLogin from 'react-microsoft-login';
import { handleEmployeeLogin, handleEmployeeMicrosoftLogin } from 'services/authServiceEmployee';
import styled from 'styled-components';
import config from 'utils/config';
import notifier from 'utils/notifiers/notifier';

type Props = {};

const LoginTimesheet: React.FC<Props> = () => {
  const handleGoogleLogin = useCallback((e) => handleEmployeeLogin(e?.tokenId), []);
  const handleOnFailure = useCallback((error) => {
    console.log('error', error);
    notifier.error('Something went wrong with the login');
  }, []);
  const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

  return (
    <Container>
      <ImageContainer>
        <Image src={FusionHitIcon} alt="FusionHit" />
      </ImageContainer>
      <Title>FusionHit Time Sheet</Title>
      <Text>Use your FusionHit Microsoft account to enter the system</Text>
      {/* <GoogleLogin
        clientId={config.googleId}
        onSuccess={handleGoogleLogin}
        onFailure={handleOnFailure}
        responseType="token"
        buttonText={'Sign with Google account'}
      /> */}
      <br />
      <MicrosoftLogin
        clientId={config.microsoftId}
        authCallback={handleEmployeeMicrosoftLogin}
        tenantUrl={config.microsoftTenantUrl}
        buttonTheme={'light'}
      />

      {environment !== 'production' && (
        <div>
          <br />
          <br />
          <Text style={{ color: 'red', fontWeight: 'bold' }}>
            This is the {environment === 'test' ? 'staging' : environment} environment
          </Text>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  width: 100px;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100%;
  vertical-align: middle;
  border: 0;
`;

const Title = styled.span`
  font-size: 30px;
  font-weight: bold;
`;

const Text = styled.p`
  margin: 30px;
`;

export default LoginTimesheet;
