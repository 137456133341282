import http from './httpService';

const ONE_ON_ONE_TEMPLATE_URL = '/api/oneOnOneTemplates';

export type OneOnOneTemplateResource = {
  _id: string;
  template: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
};

export type SaveOneOnOneTemplateResource = {
  template: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
};

export async function getLastOneOnOneTemplate() {
  const { data } = await http.get<OneOnOneTemplateResource>(ONE_ON_ONE_TEMPLATE_URL);

  return data;
}

export async function updateOneOnOneTemplateById(oneOnOne: SaveOneOnOneTemplateResource, id: string) {
  const { data } = await http.put<OneOnOneTemplateResource>(`${ONE_ON_ONE_TEMPLATE_URL}/${id}`, oneOnOne);

  return data;
}
