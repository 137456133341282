/* eslint-disable react/jsx-no-bind */
import { CircularProgress, FormControlLabel, IconButton, Switch, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import { SingleSelectContainer } from 'pages/hitgoal-process/hitgoal/hitgoal-form/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { KeyResultForTemplateResource } from 'services/hitgoalsService';
import styled from 'styled-components';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import { generateTempKey } from 'utils/general/generalMethods';

import { useCatalogsEffect, useTemplateEffect } from './hooks';
import KeyResultItemForm from './key-result-item';

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

type Props = {};

const HitgoalTemplatesForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();

  const { loadingCatalogs, categories } = useCatalogsEffect();
  const {
    hitgoalTemplate,
    setHitgoalTemplate,
    hitgoalTemplateOwner,
    loading,
    onCopyTemplate,
    keyResults,
    setKeyResults,
    useInput,
    values,
    isValid,
    onLastReviewDateUpdate,
    lastReviewDate,
    displayCopyButton,
    setDisplayCopyButton,
    onKeyResultsChange,
    savingType,
    handleSaveHitgoalTemplateItem,
    hasPermission,
  } = useTemplateEffect(id!);
  const subTitle = id ? 'Update the hitgoal template' : 'Create a new hitgoal template';
  const initialLoading = loading && loadingCatalogs;
  const display = initialLoading ? 'none' : 'block';
  const [enabledAddKeyResult, setEnableAddKeyResult] = useState(true);

  const [newKeyResult, setNewKeyResult] = useState<KeyResultForTemplateResource>({
    _id: generateTempKey('id'),
    keyResult: '',
    source: '',
  });

  const handleOnNewKeyResult = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      setNewKeyResult({ ...newKeyResult, [name!]: value });
    },
    [newKeyResult],
  );

  const handleSwitchOnChange = useCallback(
    (event: any) => {
      const { name, checked } = event.target;

      setHitgoalTemplate({ ...values, [name!]: checked });
    },
    [setHitgoalTemplate, values],
  );

  const handleAddNewKeyResult = () => {
    if (newKeyResult.keyResult !== '' && newKeyResult.source.trim() !== '') {
      const templateWithNewKeyResults = [...keyResults, newKeyResult];

      setKeyResults(templateWithNewKeyResults);

      setNewKeyResult({
        _id: generateTempKey('id'),
        keyResult: '',
        source: '',
      });

      setDisplayCopyButton(false);
    }
  };

  useEffect(() => {
    setEnableAddKeyResult(newKeyResult.keyResult.trim() === '' || newKeyResult.source.trim() === '');
  }, [newKeyResult]);

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Template: ${!initialLoading ? values?.objective : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Key Result Information</BodyTitle>

          <DetailsLine>
            <TextField
              id={'objective'}
              name="objective"
              required
              fullWidth
              label="Objective"
              inputProps={{ maxLength: 280 }}
              variant="outlined"
              {...useInput('objective')}
            />
          </DetailsLine>
          <DetailsLine>
            <SingleSelectContainer
              id={'categoriesSelect'}
              name={'category'}
              label={'Category'}
              options={categories}
              customWidth="290px"
              {...useInput('category')}
              required
            />

            <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
              <KeyboardDatePicker
                id={'lastReviewDateSelect'}
                inputVariant="outlined"
                label="Last Review Date"
                format={dateFormatUsed}
                defaultValue={null}
                disabled={false}
                value={lastReviewDate}
                onChange={onLastReviewDateUpdate}
              />
            </Tooltip>
          </DetailsLine>
          <br />
          <BodyTitle>Key Results</BodyTitle>

          <DetailsLineList>
            {keyResults.length ? (
              <>
                <TableTitleContainer>
                  <TableFirstTitleContainer>Title</TableFirstTitleContainer>
                  <TableMiddleTitleContainer>Description</TableMiddleTitleContainer>
                  <TableLastTitleContainer>Action</TableLastTitleContainer>
                </TableTitleContainer>
                {keyResults.map((keyResult, index) => (
                  <KeyResultItemForm
                    key={keyResult.source}
                    keyResultItem={keyResult}
                    index={index}
                    itemsCount={keyResults.length}
                    setKeyResults={setKeyResults}
                    keyResults={keyResults}
                    onKeyResultsChange={onKeyResultsChange}
                  />
                ))}
              </>
            ) : (
              <NoRecords>There are no Key Results to display, try adding a new one. </NoRecords>
            )}
          </DetailsLineList>

          <DetailsLine>
            <TextFieldDescriptionContainer
              fullWidth
              label="New key result"
              inputProps={{ maxLength: 280 }}
              variant="outlined"
              name={'keyResult'}
              value={newKeyResult.keyResult}
              onChange={handleOnNewKeyResult}
            />

            <TextFieldDescriptionContainer
              fullWidth
              label="Source"
              variant="outlined"
              name={'source'}
              inputProps={{ maxLength: 280 }}
              value={newKeyResult.source}
              onChange={handleOnNewKeyResult}
            />
            <IconContainer>
              <IconButton disabled={enabledAddKeyResult} color="primary" onClick={handleAddNewKeyResult}>
                <AddCircleIcon fontSize={'large'} />
              </IconButton>
            </IconContainer>
          </DetailsLine>
          <br />
          <DetailsLine>
            <OptionsContainer>
              <DetailsLine>
                <TooltipContainer title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <KeyboardDatePicker
                    id={'creationDateSelect'}
                    label="Creation Date"
                    disabled={true}
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    value={values.createdAt}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={() => {}}
                    error={false}
                    helperText=""
                  />
                </TooltipContainer>

                <OptionsContainer>
                  <SelectContainerWide>
                    <TextField
                      label="Created by"
                      variant="outlined"
                      name="owner"
                      id="ownerSelect"
                      disabled={true}
                      value={hitgoalTemplateOwner}
                    />
                  </SelectContainerWide>
                </OptionsContainer>

                <FormControlLabel
                  control={
                    <Switch
                      id={'statusSwitch'}
                      name="status"
                      value={hitgoalTemplate.status}
                      checked={hitgoalTemplate.status}
                      onChange={handleSwitchOnChange}
                    />
                  }
                  label="Is Active"
                />
                {hasPermission && (
                  <FormControlLabel
                    control={
                      <Switch
                        id={'officialSwitch'}
                        name="official"
                        value={hitgoalTemplate.official}
                        checked={hitgoalTemplate.official}
                        onChange={handleSwitchOnChange}
                      />
                    }
                    label="Official"
                  />
                )}
              </DetailsLine>
            </OptionsContainer>
          </DetailsLine>

          <ButtonContainer>
            {id && displayCopyButton && (
              <Button onClick={() => onCopyTemplate()} color="primary">
                Copy this template
              </Button>
            )}

            {savingType && <Spinner size={30} />}
            <Submit
              id={'saveHitgoalTemplateButton'}
              disabled={savingType || loading || !isValid}
              onClick={handleSaveHitgoalTemplateItem}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;
const TooltipContainer = styled(Tooltip)`
  margin-right: 15px;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
`;

const DetailsLineList = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

const OptionsContainer = styled.div`
  display: block;
  float: left;
`;

const SelectContainerWide = styled.div`
  min-width: 160px;
  width: 200px;
  display: flex;
  float: left;
  margin-right: 20px;
  padding-bottom: -5px;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const TableTitleContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: -15px;
`;

const TableFirstTitleContainer = styled.h4`
  text-align: left;
  font-size: 20;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  flex: 1;
  min-width: 47%;
  color: ${(p) => p.theme.palette.primary.light};
`;

const TableMiddleTitleContainer = styled.h4`
  text-align: left;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  min-width: 47%;
  margin-left: 5px;
  color: ${(p) => p.theme.palette.primary.light};
  flex: 1;
`;

const TableLastTitleContainer = styled.h4`
  text-align: left;
  margin-left: 10px;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${(p) => p.theme.palette.primary.light};
  flex: 1;
`;

const TextFieldDescriptionContainer = styled(TextField)`
  width: 47%;
  display: block;
  margin-left: 10px;
`;

const IconContainer = styled.div`
  margin-left: 5px;
  margin-right: 0px;
  margin-top: -6px;
  width: 2%;
`;

export default HitgoalTemplatesForm;
