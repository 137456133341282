import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource } from 'shared/types';

import { UserResource } from './authService';
import { EmployeeResource, EmployeeResourceCompact } from './employeeService';
import http from './httpService';

const ACTION_ITEM_URL = '/api/actionItems';

export type ActionItemFilter = {
  employees: string[];
  leaderAssigned: string[];
  status: string[];
  priority: string;
  dueDate: Date | null;
  startDate: string | null;
  endDate: string | null;
  createdBy?: string | undefined;
  type?: string;
  typeId?: string;
};

export type ActionItemResource = {
  _id: string;
  employee: EmployeeResource;
  leaderAssigned: EmployeeResource;
  status: CatalogResource;
  priority: CatalogResource;
  title: string;
  creationDate: string;
  dueDate: string;
  completionDate?: string | null;
  createdBy?: UserResource;
  description: string;
  type: string;
  typeId: string;
  comments: ActionItemCommentResource[];
};

export type SaveActionItemResource = {
  employee: string;
  leaderAssigned: string;
  status: string;
  priority: string;
  title: string;
  description: string;
  dueDate: string;
  completionDate?: string | null;
  type: string | null;
  typeId: string | null;
};

export type ActionItemCommentResource = {
  _id: string;
  comment: string;
  createdBy: UserResource;
  creationDate: string;
};

export type SaveActionItemCommentResource = {
  comment: string;
};

export type ReportFilter = {
  leaderAssigned: string[];
  startDate: Date | null;
  endDate: Date | null;
};

export type ActionItemReportDetail = {
  count: number | null;
  status: CatalogResource;
  employeeList: EmployeeResourceCompact[];
};

export type ActionItemReportResource = {
  leaderAssigned?: EmployeeResourceCompact;
  statusList: ActionItemReportDetail[];
};

export async function getAllActionItem(filter: ActionItemFilter & Query) {
  const { data } = await http.get<PageResource<ActionItemResource>>(ACTION_ITEM_URL + http.toQueryString(filter));

  return data;
}

export async function getActionItemById(id: string) {
  const { data } = await http.get<ActionItemResource>(`${ACTION_ITEM_URL}/${id}`);

  return data;
}

export async function updateActionItem(actionItem: SaveActionItemResource, id: string) {
  const response = await http.put<ActionItemResource>(`${ACTION_ITEM_URL}/${id}`, actionItem);

  return response;
}

export async function createActionItem(actionItem: SaveActionItemResource) {
  const { data } = await http.post<ActionItemResource>(`${ACTION_ITEM_URL}/`, actionItem);

  return data;
}

export async function deleteActionItem(id: string) {
  const { data } = await http.delete<ActionItemResource>(`${ACTION_ITEM_URL}/${id}`);

  return data;
}

export async function createComment(id: string, comment: SaveActionItemCommentResource) {
  const { data } = await http.post<ActionItemCommentResource[]>(`${ACTION_ITEM_URL}/${id}/comments`, comment);

  return data;
}

export async function getAllComments(id: string) {
  const { data } = await http.get<ActionItemCommentResource[]>(`${ACTION_ITEM_URL}/${id}/comments`);

  return data;
}

export async function getActionItemsReport(filter: ReportFilter & Query) {
  const { data } = await http.get<PageResource<ActionItemReportResource>>(
    `${ACTION_ITEM_URL}/report` + http.toQueryString(filter),
  );

  return data;
}

export async function loadCsvFileAllInfo(query: Query) {
  const { data } = await http.get<any[]>(ACTION_ITEM_URL + '/queryAllActionItems.csv' + http.toQueryString(query));

  return data;
}
