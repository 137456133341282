import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getAllLeaders } from 'services/employeeService';
import { getAllOneOnOnes, OneOnOneFilter, OneOnOneResource } from 'services/oneOnOneService';
import { getAllOneOnOneStatus } from 'services/statusService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newStatuses = await getAllOneOnOneStatus();
      const newLeaders = await getAllLeaders();

      setLeaders(collectionEmployeeToItem(newLeaders));
      setStatuses(collectionToItem(newStatuses));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, leaders, statuses };
};

export const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

export const useOneOnOnesEffect = (filter: Query & OneOnOneFilter) => {
  const [oneOnOnes, setOnOnOnes] = useState<PageResource<OneOnOneResource>>({ totalItems: 0, items: [] });
  const [loading, setLoading] = useState(true);

  const fetchOneOnOnes = useCallback(async () => {
    setLoading(true);

    const data = await getAllOneOnOnes(filter);

    setLoading(false);
    setOnOnOnes(data);
  }, [filter]);

  useEffect(() => {
    fetchOneOnOnes();
  }, [fetchOneOnOnes, filter]);

  return { oneOnOnes, loading, setLoading, fetchOneOnOnes };
};
