import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from 'components/alert-dialog';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import { saveAs } from 'file-saver';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useState } from 'react';
import {
  CareerDevelopmentPlanFilter,
  getCDPPDF,
  sendCareerDevelopmentPlanEmail,
} from 'services/careerDevelopmentPlanService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';

import columns from './columns';
import Filter from './filter';
import {
  useCareerDevelopmentPlanEffect,
  useCatalogFilter,
  useDisplayModalEffect,
  useSeletedEmployeeOneOnOneEffect,
} from './hooks';
import HistoryCdpModal from './modal';

export type SearchFilter = {
  searchText: string;
};

clearLocalStorage(); //Clear local storage on refresh

const CareerDevelopmentPlanList: React.FC = () => {
  const localSearchFilter = localStorage.getItem('search_cdp');
  const localLeadersFilter = localStorage.getItem('leaders_cdp');
  const localLeaderAssigned = localStorage.getItem('leaderAssigned_cdp');
  const localStatusFilter = localStorage.getItem('status_cdp');
  const localDate = localStorage.getItem('date_cdp');

  const DEFAULT_QUERY: Query & CareerDevelopmentPlanFilter = {
    leaders: localLeadersFilter?.split(',') || [],
    leaderAssigned: localLeaderAssigned?.split(',') || [],
    cdpStatus: localStatusFilter?.split(',') || [],
    date: localDate || new Date().toString(),
    search: localSearchFilter || '',
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'employee.name',
  };

  const DEFAULT_FILTER: CareerDevelopmentPlanFilter = {
    leaders: localLeadersFilter?.split(',') || [],
    leaderAssigned: localLeaderAssigned?.split(',') || [],
    cdpStatus: localStatusFilter?.split(',') || [],
    date: localDate || new Date().toString(),
    search: localSearchFilter || '',
  };

  const { query, setQuery, handlePageChange, handleColumnSorting } = usePaging(DEFAULT_QUERY);
  const {
    careerDevelopmentPlans,
    loading: oneOnOneLoading,
    setLoading,
    fetchCareerDevelopmentPlans,
  } = useCareerDevelopmentPlanEffect(query);
  const { leaders, cdpStatuses, loading: catalogLoading } = useCatalogFilter();
  const initialLoading = useInitialLoading(oneOnOneLoading || catalogLoading);
  const isLoading = oneOnOneLoading || catalogLoading;
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_FILTER);

  const { showModal, setShowModal } = useDisplayModalEffect(false);
  const { selectedEmployee, setSelectedEmployee } = useSeletedEmployeeOneOnOneEffect('');

  const onHistoryPressed = (employeeId: string) => {
    setSelectedEmployee(employeeId);
    setShowModal(true);
  };

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [cdpIdForEmail, setCdpIdForEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = async (cdpId: string) => {
    console.log('sending Email');
    setLoading(true);
    await sendCareerDevelopmentPlanEmail(cdpId);
    await fetchCareerDevelopmentPlans();
    setOpenConfirmationDialog(false);
    setLoading(false);
    notifier.success('Email sent');
  };

  const getPDF = async (cdpId: string, name: string) => {
    console.log('get PDF for CDP');
    setLoading(true);
    const data = await getCDPPDF(cdpId);

    const pdfBlob = new Blob([data], { type: 'application/pdf' });

    saveAs(pdfBlob, `CDP ${name}.pdf`);

    setLoading(false);
  };

  const showConfirmationModal = useCallback(async (cdpId: string, emailSent: boolean) => {
    setCdpIdForEmail(cdpId);
    setEmailSent(emailSent);
    setOpenConfirmationDialog(true);
  }, []);

  return (
    <Container>
      <Card>
        <CardHeader
          title="Career Development Plans"
          subTitle="All the career development plans information"
          newPageRedirectionLink="/career-development-plan/new"
          loading={isLoading}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter
              defaultFilter={defaultFilter}
              setDefaultFilter={setDefaultFilter}
              search={''}
              leaders={leaders}
              cdpStatuses={cdpStatuses}
              setFilter={setQuery}
              filter={query}
              totalItems={careerDevelopmentPlans.totalItems}
            />
            {careerDevelopmentPlans?.items?.length ? (
              <StandardTable
                keyColumn="_id"
                data={careerDevelopmentPlans.items}
                totalItems={careerDevelopmentPlans.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(
                  fetchCareerDevelopmentPlans,
                  setLoading,
                  onHistoryPressed,
                  getPDF,
                  showConfirmationModal,
                )}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>

      <HistoryCdpModal showModal={showModal} setShowModal={setShowModal} seletedEmployeeId={selectedEmployee} />

      <AlertDialog
        title={emailSent ? 'Are you sure you want to send the CDP again?' : 'Are you sure you want to send the CDP?'}
        message={
          emailSent
            ? 'The notification email has been already sent, an email will be sent to the leader and employee'
            : 'An email will be sent to the leader and employee'
        }
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        // eslint-disable-next-line react/jsx-no-bind
        acceptConfirmation={() => sendEmail(cdpIdForEmail)}
      />
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    text-align: right;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default CareerDevelopmentPlanList;
