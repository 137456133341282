import { Divider, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useState } from 'react';
import { DescriptionResource, SaveExitProcedureResource } from 'services/exitProcedureTemplate';
import styled from 'styled-components';
import * as yup from 'yup';

import { useForm } from '../useForm';

type Props = {
  description: DescriptionResource;
  setSaveExitProcedureTemplate: any;
  saveExitProcedureTemplate: SaveExitProcedureResource;
  index: number;
  itemsCount: number;
};

const schema = {
  description: yup.string().required().label('Description'),
};

const ExitProcedureDescriptionForm: React.FC<Props> = (props) => {
  const { description, setSaveExitProcedureTemplate, saveExitProcedureTemplate, index, itemsCount } = props;
  const [ownDescription, setOwnDescription] = useState(description);
  const { useInput } = useForm(schema, setOwnDescription, ownDescription, description);

  const handleDelete = useCallback(() => {
    const newDescriptions = saveExitProcedureTemplate.descriptions.filter((_, i) => i !== index);

    setSaveExitProcedureTemplate({ ...saveExitProcedureTemplate, descriptions: newDescriptions });
  }, [index, saveExitProcedureTemplate, setSaveExitProcedureTemplate]);

  const handleMovingUp = useCallback(() => {
    const tmp = saveExitProcedureTemplate.descriptions[index - 1];

    saveExitProcedureTemplate.descriptions[index - 1] = saveExitProcedureTemplate.descriptions[index];
    saveExitProcedureTemplate.descriptions[index] = tmp;

    const newDescriptions = saveExitProcedureTemplate.descriptions;

    setSaveExitProcedureTemplate({ ...saveExitProcedureTemplate, descriptions: newDescriptions });
  }, [index, saveExitProcedureTemplate, setSaveExitProcedureTemplate]);

  const handleMovingDown = useCallback(() => {
    const tmp = saveExitProcedureTemplate.descriptions[index + 1];

    saveExitProcedureTemplate.descriptions[index + 1] = saveExitProcedureTemplate.descriptions[index];
    saveExitProcedureTemplate.descriptions[index] = tmp;

    const newDescriptions = saveExitProcedureTemplate.descriptions;

    setSaveExitProcedureTemplate({ ...saveExitProcedureTemplate, descriptions: newDescriptions });
  }, [index, saveExitProcedureTemplate, setSaveExitProcedureTemplate]);

  return (
    <ParentContainer>
      <Container>
        <div>
          <IconContainerArrows>
            <IconButton color="primary" onClick={handleMovingUp} disabled={index === 0}>
              <UpIcon fontSize={'default'} />
            </IconButton>
            <IconButton color="primary" onClick={handleMovingDown} disabled={index === itemsCount - 1}>
              <DownIcon fontSize={'default'} />
            </IconButton>
          </IconContainerArrows>
        </div>

        <Container>
          <DetailsLine>
            <TextField
              fullWidth
              rows={3}
              label="Try to write a description"
              variant="outlined"
              name={'description'}
              value={ownDescription.description}
              {...useInput()}
            />
          </DetailsLine>
          <IconContainer>
            <IconButton color="primary" onClick={handleDelete}>
              <DeleteIcon fontSize={'large'} />
            </IconButton>
          </IconContainer>
        </Container>
      </Container>

      <DividerContainer />
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
`;
const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
`;

const IconContainer = styled.div`
  margin-top: -8px;
`;

const IconContainerArrows = styled.div`
  margin-right: 30px;
  orientation: vertical;
  display: flex;
  width: 50px;
  padding-top: 10px;
  margin-top: -5px;
`;

const DividerContainer = styled(Divider)`
  margin-bottom: 25px;
`;

export default ExitProcedureDescriptionForm;
