import { PageResource, Query } from 'hooks/usePaging';

import http from './httpService';

const JOBTITLE_URL = '/api/jobTitles';

export type JobTitleResource = {
  _id: string;
  name: string;
  order: string;
  jobFamily: string;
};

export type SaveJobTitleResource = {
  name: string;
  order: string;
  jobFamily: string;
};

export async function getJobTitles() {
  const { data } = await http.get<JobTitleResource[]>(JOBTITLE_URL);

  return data;
}

export async function getJobTitleById(id: string) {
  const { data } = await http.get<JobTitleResource>(`${JOBTITLE_URL}/single/${id}`);

  return data;
}

export async function getJobTitlesQuery(query: Query) {
  const { data } = await http.get<PageResource<JobTitleResource>>(`${JOBTITLE_URL}/query` + http.toQueryString(query));

  return data;
}

export async function deleteJobTitle(id: string) {
  const { data } = await http.delete<JobTitleResource[]>(`${JOBTITLE_URL}/${id}`);

  return data;
}
export async function updateJobTitle(JobTitle: SaveJobTitleResource, id: string) {
  const { data } = await http.put<JobTitleResource>(`${JOBTITLE_URL}/${id}`, JobTitle);

  return data;
}

export async function createJobTitle(JobTitle: SaveJobTitleResource) {
  const { data } = await http.post<JobTitleResource>(`${JOBTITLE_URL}`, JobTitle);

  return data;
}
