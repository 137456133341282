import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import SingleSelect from 'components/single-select';
import StandardTable from 'components/standard-table';
import { useForm } from 'hooks/useForm';
import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { createActionItem, SaveActionItemResource } from 'services/actionItemService';
import { OneOnOneResource } from 'services/oneOnOneService';
import getBoxBackground from 'shared/style/utilities/getBoxBackground';
import getBoxShadow from 'shared/style/utilities/getBoxShadow';
import styled from 'styled-components';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useActionItemEffect, useCatalogs } from '../../../action-item-form/hooks';
import columns from './columns';
import { useActionItemsEffect } from './hooks';

const schema = {
  employee: yup.string().required().label('Employee'),
  leaderAssigned: yup.string().required().label('Leader'),
  status: yup.string().required().label('Status'),
  priority: yup.string().required().label('Priority'),
  title: yup.string().required().max(100).label('Title'),
  description: yup.string().required().label('Description'),
  dueDate: yup.string().required().label('Date'),
  createdBy: yup.string().label('createdBy'),
  type: yup.string().required(),
  typeId: yup.string().required(),
};

type Props = {
  oneOnOneId: string;
  oneOnOne: OneOnOneResource;
  isReadOnly: boolean;
};

const OneOnOneActionItem: React.FC<Props> = (props) => {
  const { oneOnOneId, oneOnOne, isReadOnly } = props;
  const defaultActionItem: SaveActionItemResource = {
    employee: oneOnOne.employee._id,
    leaderAssigned: '',
    status: '',
    priority: '',
    title: '',
    description: '',
    dueDate: new Date().toString(),
    type: 'OneOnOne',
    typeId: oneOnOneId,
  };
  const { actionItem, loading: actionItemLoading } = useActionItemEffect(undefined);
  const { leaders, statuses, priorities, loading: catalogLoading } = useCatalogs();
  const { actionItems, setActionItems, fetchActionItems, loading, setLoading } = useActionItemsEffect(oneOnOneId);
  const [emptyActionItem, setEmptyActionItem] = useState(actionItem);
  const { useInput, useDateInput, values, isValid } = useForm(emptyActionItem, schema, loading);
  const [savingActionItem, setSavingActionItem] = useState(false);
  const newStatuses = statuses.filter((x) => x.label === 'Open');

  actionItem.employee = oneOnOne.employee._id;
  actionItem.type = 'OneOnOne';
  actionItem.typeId = oneOnOneId;

  if (!catalogLoading) {
    actionItem.status = actionItem.status || statuses[statuses.map((stat) => stat.label).indexOf('Open')].id;
  }

  const handleSaveActionItem = async () => {
    if (!isValid) {
      notifier.error(`Please, fix the errors on the form before submitting `);
      return;
    }

    setSavingActionItem(true);

    const newActionItem = await createActionItem(values);

    if (newActionItem) {
      setActionItems({ ...actionItems, items: [...actionItems.items, newActionItem] });

      notifier.success('Action Item created successfully.');
      setEmptyActionItem(defaultActionItem);
    }
    setSavingActionItem(false);
    saveInfoInLocalStorage('inputValue', '', '_form');
  };

  return (
    <Container>
      <Card>
        <Prompt
          when={
            localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') === 'changed'
          }
          message="Are you sure you want to leave without Saving?"
        />
        <CommentsHeader loading={false} title={'Action Items'} />
        <CardBody>
          <FormContainer>
            <DetailsLine>
              <OptionsContainer>
                <SelectContainer>
                  <SingleSelect
                    id={'assignedToSelect'}
                    label={'Assigned To:'}
                    options={leaders}
                    required
                    {...useInput('leaderAssigned')}
                    disabled={isReadOnly}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SingleSelect
                    id={'statusSelect'}
                    label={'Status'}
                    empty={false}
                    options={newStatuses}
                    {...useInput('status')}
                    disabled={isReadOnly}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SingleSelect
                    id={'prioritySelect'}
                    label={'Priority'}
                    required
                    options={priorities}
                    {...useInput('priority')}
                    disabled={isReadOnly}
                  />
                </SelectContainer>
                <SelectContainer>
                  <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                    <KeyboardDatePickerContainer
                      id={'dueDateSelect'}
                      required
                      inputVariant="outlined"
                      label="Due Date"
                      format={dateFormatUsed}
                      minDate={new Date()}
                      {...useDateInput('dueDate')}
                      disabled={isReadOnly}
                    />
                  </Tooltip>
                </SelectContainer>
              </OptionsContainer>
            </DetailsLine>
            <TextBody>
              <TextField
                required
                fullWidth
                inputProps={{
                  maxLength: 100,
                }}
                id={'titleInput'}
                label="Title"
                variant="outlined"
                {...useInput('title')}
                disabled={isReadOnly}
              />
            </TextBody>
            <TextBody>
              <TextField
                required
                multiline
                fullWidth
                rows={4}
                id={'descriptionInput'}
                label="Description"
                variant="outlined"
                {...useInput('description')}
                disabled={isReadOnly}
              />
            </TextBody>
            <ButtonContainer>
              {savingActionItem && <Spinner size={30} />}
              <Submit
                id={'submitActionItem'}
                disabled={savingActionItem || actionItemLoading || loading || catalogLoading || !isValid || isReadOnly}
                onClick={handleSaveActionItem}
              >
                Save
              </Submit>
            </ButtonContainer>
          </FormContainer>
          <StandardTable
            keyColumn="_id"
            data={actionItems.items}
            totalItems={actionItems.totalItems}
            columns={columns(fetchActionItems, setLoading)}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

const Container = styled.div``;

const CommentsHeader = styled(CardHeader)`
  background: ${(p) => getBoxBackground(p.theme.palette.secondary.main, p.theme.palette.secondary.dark)};
  box-shadow: ${(p) => getBoxShadow(p.theme.palette.secondary.main)};
`;

const FormContainer = styled.div``;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
`;

const KeyboardDatePickerContainer = styled(KeyboardDatePicker)`
  width: 185px;
`;

const TextBody = styled.div`
  margin: 30px;
`;

const OptionsContainer = styled.div`
  display: block;
  float: left;
`;

const SelectContainer = styled.div`
  min-width: 160px;
  padding: 0 5px;
  display: block;
  float: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

export default OneOnOneActionItem;
