import { Divider, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useState } from 'react';
import { RatingResource, SaveDevelopmentPlanResource } from 'services/developmentPlanTemplate';
import styled from 'styled-components';
import * as yup from 'yup';

import { useForm } from '../useForm';

type Props = {
  rating: RatingResource;
  setSaveDevelopmentPlanTemplate: any;
  saveDevelopmentPlanTemplate: SaveDevelopmentPlanResource;
  index: number;
  itemsCount: number;
};

const schema = {
  order: yup.number().required().label('Order'),
  description: yup.string().required().label('Description'),
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const RatingForm: React.FC<Props> = (props) => {
  const { rating, setSaveDevelopmentPlanTemplate, saveDevelopmentPlanTemplate, index, itemsCount } = props;
  const [ownRating, setOwnRating] = useState(rating);
  const { useInput } = useForm(schema, setOwnRating, ownRating, rating);

  const handleDelete = useCallback(() => {
    const newRatings = saveDevelopmentPlanTemplate.ratings.filter((_, i) => i !== index);

    setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, ratings: newRatings });
  }, [index, saveDevelopmentPlanTemplate, setSaveDevelopmentPlanTemplate]);

  const handleMovingUp = useCallback(() => {
    const tmp = saveDevelopmentPlanTemplate.ratings[index - 1];

    saveDevelopmentPlanTemplate.ratings[index - 1] = saveDevelopmentPlanTemplate.ratings[index];
    saveDevelopmentPlanTemplate.ratings[index - 1].order = index;
    saveDevelopmentPlanTemplate.ratings[index] = tmp;
    saveDevelopmentPlanTemplate.ratings[index].order = index + 1;

    const newRatings = saveDevelopmentPlanTemplate.ratings;

    setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, ratings: newRatings });
  }, [index, saveDevelopmentPlanTemplate, setSaveDevelopmentPlanTemplate]);

  const handleMovingDown = useCallback(() => {
    const tmp = saveDevelopmentPlanTemplate.ratings[index + 1];

    saveDevelopmentPlanTemplate.ratings[index + 1] = saveDevelopmentPlanTemplate.ratings[index];
    saveDevelopmentPlanTemplate.ratings[index + 1].order = index + 2;
    saveDevelopmentPlanTemplate.ratings[index] = tmp;
    saveDevelopmentPlanTemplate.ratings[index].order = index + 1;

    const newRatings = saveDevelopmentPlanTemplate.ratings;

    setSaveDevelopmentPlanTemplate({ ...saveDevelopmentPlanTemplate, ratings: newRatings });
  }, [index, saveDevelopmentPlanTemplate, setSaveDevelopmentPlanTemplate]);

  return (
    <ParentContainer>
      <Container>
        <div>
          <IconContainerArrows>
            <IconButton color="primary" onClick={handleMovingUp} disabled={index === 0}>
              <UpIcon fontSize={'default'} />
            </IconButton>
            <IconButton color="primary" onClick={handleMovingDown} disabled={index === itemsCount - 1}>
              <DownIcon fontSize={'default'} />
            </IconButton>
          </IconContainerArrows>
        </div>

        <Container>
          <TextFieldContainer
            label="Order"
            variant="outlined"
            name={'order'}
            {...useInput()}
            value={ownRating.order}
            disabled
          />
          <TextFieldDescriptionContainer
            multiline
            fullWidth
            rows={3}
            label="Description"
            variant="outlined"
            name={'description'}
            value={ownRating.description}
            {...useInput()}
          />
          <IconContainer>
            <IconButton color="primary" onClick={handleDelete}>
              <DeleteIcon fontSize={'large'} />
            </IconButton>
          </IconContainer>
        </Container>
      </Container>
      <Divider />
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 50px;
  text-align: left;
`;

const TextFieldContainer = styled(TextField)`
  width: 300px;
  display: block;
`;

const TextFieldDescriptionContainer = styled(TextField)`
  width: 100%;
  display: block;
  margin-left: 10px;
`;

const IconContainer = styled.div`
  padding-top: 25px;
`;

const IconContainerArrows = styled.div`
  margin-left: -30px;
  orientation: vertical;
  display: flex;
  width: 50px;
  padding-top: 10px;
`;

export default RatingForm;
