import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import {
  EmployeeResource,
  getEmployeeById,
  getProjectHistoryByEmployeeId,
  ProjectHistoryResource,
} from 'services/employeeService';

export const useProjectHistoryEffect = (employee: EmployeeResource, query: Query) => {
  const defaultResponse: PageResource<ProjectHistoryResource> = { totalItems: 0, items: [] };
  const [projectHistoryList, setProjectHistoryList] = useState(defaultResponse);
  const [loadingSalaries, setLoadingSalaries] = useState(true);

  const fetchProjectHistory = useCallback(async () => {
    if (!employee) return;
    setLoadingSalaries(true);
    const result = await getProjectHistoryByEmployeeId(employee._id, query);

    setProjectHistoryList(result);

    setLoadingSalaries(false);
  }, [employee, query]);

  useEffect(() => {
    fetchProjectHistory();
  }, [fetchProjectHistory, query]);

  return {
    employee,
    projectHistoryList,
    loadingSalaries,
    setLoading: setLoadingSalaries,
    fetchProjectHistory,
  };
};

export const useCatalogs = (id: string) => {
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [employee, setEmployee] = useState<EmployeeResource>();

  useEffect(() => {
    async function fetchCatalogs() {
      setLoadingCatalogs(true);

      const employeeResult = await getEmployeeById(id!);

      if (employeeResult) {
        setEmployee(employeeResult);
      }

      setLoadingCatalogs(false);
    }

    fetchCatalogs();
  }, [id]);

  return {
    employee,
    loadingCatalogs,
  };
};
