import React, { useContext } from 'react';
import { getCurrentUser, UserResource } from 'services/authService';

const UserContext = React.createContext<UserResource | null>(null);

const UserProvider: React.FC = (props) => {
  const user = getCurrentUser();

  return <UserContext.Provider value={user}>{props.children}</UserContext.Provider>;
};

export default UserProvider;
export const useCurrentUser = () => useContext(UserContext);
