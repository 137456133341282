import { IconButton, Tooltip } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

type HeaderType = {
  label: string;
  key: string;
};

type Props = {
  filter: any;
  filename?: string;
  disable?: boolean;
  totalRows: number;
  headers?: HeaderType[];
  csvDataLoader: any;
};

const ExportButton: React.FC<Props> = (props) => {
  const { filter, filename, disable, totalRows, headers, csvDataLoader } = props;

  const [csvData, setCsvData]: any[] = useState([]);
  const csvInstance = useRef<any | null>(null);

  const asyncExportMethod = async () => {
    const data = await csvDataLoader({ ...filter, pageSize: totalRows });

    setCsvData(data);
  };

  useEffect(() => {
    if (csvData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  return (
    <Fragment>
      <div
        id="downloadButton"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          if (disable) return;
          asyncExportMethod();
        }}
      >
        <Tooltip title={'Download All'}>
          <IconButton>
            <GetApp />
          </IconButton>
        </Tooltip>
      </div>
      {csvData && csvData.length > 0 ? (
        <CSVLink
          data={csvData}
          headers={headers || Object.keys(csvData[0])}
          filename={filename || 'export.csv'}
          ref={csvInstance}
        />
      ) : undefined}
    </Fragment>
  );
};

export default ExportButton;
