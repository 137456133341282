import { Checkbox } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { ProjectResource } from 'services/projectService';
import { deleteProject } from 'services/projectService';

const fixedColumns: Column<ProjectResource>[] = [
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
  },
  {
    label: 'Paid Holidays',
    sortKey: 'holidays',
    path: 'holidays',
    render: (item) =>
      item.holidays ? <Checkbox checked={item.holidays} style={{ color: green[500], marginLeft:20  }} disabled /> : <></>,
  },
  {
    label: 'Paid Vacations',
    sortKey: 'vacations',
    path: 'vacations',
    render: (item) =>
      item.vacations ? <Checkbox checked={item.vacations} style={{ color: green[500], marginLeft:20 }} disabled /> : <></>,
  },
];

const variableColumns = (fetchProjects: any, setLoading: any, readOnly: boolean) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable={!readOnly}
        fetchItems={fetchProjects}
        deleteItem={deleteProject}
        setLoading={setLoading}
        url={'projects'}
        name={'Project'}
        deleteTitle={'Delete Project'}
        deleteMessage={'Do you want to delete this project?'}
      />
    ),
  };
};

const columns = (fetchProjects: any, setLoading: any, readOlny: boolean) => {
  return [...fixedColumns, variableColumns(fetchProjects, setLoading, readOlny)];
};

export default columns;
