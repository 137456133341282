import TableBase from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import React from 'react';
import { listItems } from 'shared/types';
import styled from 'styled-components';

import TableHeader from '../standard-table/table-header';
import TablePaginationFooter from '../standard-table/table-pagination-footer';
import TableContent from './table-content';
import { Column } from './types';

type Props = {
  columns: Column[];
  data: any[];
  keyColumn?: string;
  sortByColumn?: string;
  isSortAscending?: boolean;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  onChangePage?(newPage: number): any;
  onSort?(columnKey: string): any;
  editId?: string;
  dropdDownOptions: listItems[];
};

const StandardTableEditable: React.FC<Props> = (props) => {
  const {
    columns,
    data,
    keyColumn,
    sortByColumn,
    isSortAscending,
    page,
    pageSize,
    totalItems,
    onChangePage,
    onSort,
    editId,
    dropdDownOptions,
  } = props;

  const hasPagination = Boolean(page && totalItems && onChangePage && pageSize);

  return (
    <TableContainer>
      <Table>
        <TableHeader columns={columns} sortByColumn={sortByColumn} isSortAscending={isSortAscending} onSort={onSort} />
        <TableContent
          columns={columns}
          data={data}
          keyColumn={keyColumn}
          editId={editId}
          dropDownOptions={dropdDownOptions}
        />
        {hasPagination && (
          <TablePaginationFooter
            page={page!}
            totalItems={totalItems!}
            onChangePage={onChangePage!}
            pageSize={pageSize!}
          />
        )}
      </Table>
    </TableContainer>
  );
};

const Table = styled(TableBase)`
  padding: 0 20px;
  th,
  td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(224, 224, 224);
  }

  td {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 0.8125rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    line-height: 1.42857143;
    vertical-align: middle;
  }
`;

export default StandardTableEditable;
