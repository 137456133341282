import usePaging, { PageResource } from 'hooks/usePaging';
import { useEffect, useState } from 'react';
import { getKeyResultTypesQuery, KeyResultTypeResource } from 'services/hitgoalsService';
import { DEFAULT_QUERY_WITH_SEARCH } from 'utils/general/generalMethods';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

const localStoragePrefix = '_types';

export const useTypesEffect = () => {
  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY_WITH_SEARCH);
  const defaultResponse: PageResource<KeyResultTypeResource> = { totalItems: 0, items: [] };
  const [types, setTypes] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>(localStorage.getItem('searchText_types') || '');
  const fetchTypes = async () => {
    if (searchText === '') {
      setLoading(true);
    }
    query.searchText = searchText;
    const newTypes = await getKeyResultTypesQuery(query);

    setLoading(false);
    setTypes(newTypes);
  };

  const onSearchTextChange = (event: any) => {
    const currentValue = event.target.value;

    saveInfoInLocalStorage('searchText', currentValue, localStoragePrefix);
    setQuery({ ...query, page: 1 });
    setSearchText(currentValue);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchTypes();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText, query]);

  return {
    types,
    loading,
    searchText,
    setLoading,
    fetchTypes,
    onSearchTextChange,
    handlePageChange,
    handleColumnSorting,
    query,
  };
};
