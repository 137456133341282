import { useCallback, useEffect, useState } from 'react';
import { BirthdayByMonthResource, getBirthdays } from 'services/humanResourcesService';

export const useBirthdaysEffect = () => {
  const [birthdaysByMonth, setBirthdaysByMonth] = useState<BirthdayByMonthResource[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchBirthdays = useCallback(async () => {
    setLoading(true);

    const birthdaysObject = await getBirthdays();

    setLoading(false);

    setBirthdaysByMonth(birthdaysObject.birthdays);
  }, []);

  useEffect(() => {
    fetchBirthdays();
  }, [fetchBirthdays]);

  return { birthdaysByMonth, loading, setLoading, fetchBirthdays };
};
