/* eslint-disable react/jsx-no-bind */
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { EmployeeWithReferResource } from 'services/employeeService';
import { deleteReferral } from 'services/employeeService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<EmployeeWithReferResource>[] = [
  {
    label: 'Hire date',
    sortKey: 'hireDate',
    path: 'hireDate',
    render: (item) => `${item.hireDate ? stringDateToHumanFormat(item.hireDate) : 'Not Assigned'}`,
  },
  {
    label: 'Employee',
    sortKey: 'name',
    path: 'name',
    render: (item) => `${item.name} ${item.lastName}`,
  },
  {
    label: 'Refer by',
    sortKey: 'referBy.name',
    path: 'referBy.name',
    render: (item) => `${item.referBy ? item.referBy.name + ' ' + item.referBy.lastName : '-'}`,
  },
  {
    label: 'Deposit Date',
    sortKey: 'depositDate',
    path: 'depositDate',
    render: (item) => (
      <div>{<StatusHighlighter name={stringDateToHumanFormat(item.depositDate) + ',' + item.statusReferral} />}</div>
    ),
  },
  {
    label: 'Amount',
    sortKey: 'referralAmount',
    path: 'referralAmount',
  },
  {
    label: 'Status',
    sortKey: 'statusReferral',
    path: 'statusReferral',
  },
];

const variableColumns = (fetchReferral: any, setLoading: any, showConfirmationModal: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchReferral}
          deleteItem={deleteReferral}
          setLoading={setLoading}
          url={'referrals'}
          name={'Referrals'}
          deleteTitle={'Delete Referral'}
          deleteMessage={'Do you want to delete this referral?'}
        />
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const columns = (fetchOnboardingProcessTemplates: any, setLoading: any, showConfirmationModal: any) => {
  return [...fixedColumns, variableColumns(fetchOnboardingProcessTemplates, setLoading, showConfirmationModal)];
};

export default columns;
