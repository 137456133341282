import http from './httpService';

const EXIT_PROCEDURE_STATUS_URL = '/api/epstatus';

export type EpStatusResource = {
  _id: string;
  order: number;
  name: string;
  description: string;
};

export async function getEpStatus() {
  const { data } = await http.get<EpStatusResource[]>(EXIT_PROCEDURE_STATUS_URL);

  return data;
}
