import { useForm } from 'hooks/useForm';
import { useEffect, useState } from 'react';
import { getAllEmployees, getAllLeaders } from 'services/employeeService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem } from 'utils/mappers/item';
import { AuthorizedPeopleResource, schemaAuthorizedPeopleResource } from 'utils/objectTypes/types';

const defaultAuthorizedPeopleResource: AuthorizedPeopleResource = {
  leader: '',
  authorizedEmployees: [],
};

export const useAuthorizedPeopleEffect = () => {
  const [loading, setLoading] = useState(true);
  const [authorizedPeolpleObject, setAuthorizedPeolpleObject] = useState<AuthorizedPeopleResource>(
    defaultAuthorizedPeopleResource,
  );
  const { useInput, useInputMultiSelect } = useForm(
    authorizedPeolpleObject,
    schemaAuthorizedPeopleResource as any,
    loading,
  );
  const [employees, setEmployees] = useState<Item[]>([]);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const authorizedEmployeesInput = useInputMultiSelect('authorizedEmployees');

  useEffect(() => {
    async function fetchAuthorizedPeople() {
      const employeeList = await getAllEmployees();
      const leaderList = await getAllLeaders();

      setEmployees(collectionEmployeeToItem(employeeList));
      setLeaders(collectionEmployeeToItem(leaderList));
      setLoading(false);
    }
    fetchAuthorizedPeople();
  }, []);

  return { loading, employees, leaders, authorizedEmployeesInput, useInput };
};
