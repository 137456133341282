import React from 'react';
import styled from 'styled-components';

const CardBody: React.FC = (props) => {
  const { children, ...rest } = props;

  return <Container {...rest}>{children}</Container>;
};

const Container = styled.div`
  padding: '0.9375rem 20px';
  flex: '1 1 auto';
  position: 'relative';
  padding: 20px;
`;

export default CardBody;
