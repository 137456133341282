import { useCallback, useEffect, useState } from 'react';
import {
  CareerDevelopmentPlanResource,
  Category,
  getCdpById,
  SaveCareerDevelopmentPlanResource,
  TemporalSaveCareerDevelopmentPlanResource,
} from 'services/careerDevelopmentPlanService';
import { CdpStatusResource, getCdpStatus } from 'services/cdpStatusService';
import { getAllLeaders } from 'services/employeeService';
import { getJobTitles } from 'services/jobTitleService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';

const defaultTemporalCareerDevelopmentPlanResource = {
  leaderAssigned: '',
  cdpStatus: '',
  newJobTitle: '',
  additionalComments: '',
  actionComments: '',
  jobTitleUpdateApproval: '',
  disciplinaryAction: '',
  adminComments: '',
};

const defaultCareerDevelopmentPlanResource = {
  leaderAssigned: '',
  cdpStatus: '',
  newJobTitle: '',
  additionalComments: '',
  actionComments: '',
  categories: [],
  jobTitleUpdateApproval: '',
  disciplinaryAction: '',
  adminComments: '',
};

export const useCareerDevelopmentPlanEffect = (id: string) => {
  const [temporalSaveCareerDevelopmentPlan, setTemporalSaveCareerDevelopmentPlan] = useState<
    TemporalSaveCareerDevelopmentPlanResource
  >(defaultTemporalCareerDevelopmentPlanResource);
  const [saveCareerDevelopmentPlan, setSaveCareerDevelopmentPlan] = useState<SaveCareerDevelopmentPlanResource>(
    defaultCareerDevelopmentPlanResource,
  );
  const [careerDevelopmentPlan, setCareerDevelopmentPlan] = useState<CareerDevelopmentPlanResource>();
  const [loading, setLoading] = useState(false);

  const fetchCareerDevelopmentPlan = useCallback(async () => {
    setLoading(true);
    const careerDevelopmentPlanResult = await getCdpById(id);

    if (careerDevelopmentPlanResult) {
      setTemporalSaveCareerDevelopmentPlan(mapTemporalCareerDevelopmentPlanResponse(careerDevelopmentPlanResult));
      setSaveCareerDevelopmentPlan(mapCareerDevelopmentPlanResponse(careerDevelopmentPlanResult));
      setCareerDevelopmentPlan(careerDevelopmentPlanResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchCareerDevelopmentPlan();
  }, [id, fetchCareerDevelopmentPlan]);

  return {
    temporalSaveCareerDevelopmentPlan,
    careerDevelopmentPlan,
    setCareerDevelopmentPlan,
    loading,
    setTemporalSaveCareerDevelopmentPlan,
    saveCareerDevelopmentPlan,
    setSaveCareerDevelopmentPlan,
    setLoading,
    fetchCareerDevelopmentPlan,
  };
};

const mapTemporalCareerDevelopmentPlanResponse = (cdp: CareerDevelopmentPlanResource) => {
  return {
    leaderAssigned: cdp.leaderAssigned?._id,
    cdpStatus: cdp.cdpStatus._id,
    newJobTitle: cdp.newJobTitle || '',
    additionalComments: cdp.additionalComments || '',
    actionComments: cdp.actionComments || '',
    jobTitleUpdateApproval: cdp.jobTitleUpdateApproval,
    disciplinaryAction: cdp.disciplinaryAction,
    adminComments: cdp.adminComments,
  } as TemporalSaveCareerDevelopmentPlanResource;
};

const mapCareerDevelopmentPlanResponse = (cdp: CareerDevelopmentPlanResource) => {
  return {
    leaderAssigned: cdp.leaderAssigned?._id,
    cdpStatus: cdp.cdpStatus._id,
    categories: mapCategories(cdp.categories),
    ratings: cdp.ratings,
    newJobTitle: cdp.newJobTitle || '',
    additionalComments: cdp.additionalComments || '',
    actionComments: cdp.actionComments || '',
    jobTitleUpdateApproval: cdp.jobTitleUpdateApproval,
    disciplinaryAction: cdp.disciplinaryAction,
    adminComments: cdp.adminComments,
  } as SaveCareerDevelopmentPlanResource;
};

const mapCategories = (categories: Category[]) => {
  return categories.map((category) => ({
    title: category.title,
    description: category.description,
    position: category.position,
    rating: category.rating,
    comments: category.comments,
  }));
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [cdpStatuses, setCdpStatuses] = useState<CdpStatusResource[]>([]);
  const [jobTitles, setJobTitles] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [leadersResult, statusResult, jobTitlesResult] = await Promise.all([
        getAllLeaders(),
        getCdpStatus(),
        getJobTitles(),
      ]);

      const leaderItems = collectionEmployeeToItem(leadersResult);
      const jobTitlesItems = collectionToItem(jobTitlesResult);

      setCdpStatuses(statusResult);
      setLeaders(leaderItems);
      setJobTitles(jobTitlesItems);
      setLoading(false);
    }

    fetchCatalogs();
  }, []);

  return { leaders, cdpStatuses, jobTitles, loading };
};

export const useReadOnlyEffect = (localStorageValue: string | null, cdpFinished: boolean, adminPermission: boolean) => {
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (adminPermission) {
      setReadOnly(false);
      return;
    }

    if (localStorageValue && localStorageValue === 'readOnly') {
      setReadOnly(true);
    } else if (cdpFinished) {
      setReadOnly(true);
    } else setReadOnly(false);
  }, [adminPermission, cdpFinished, localStorageValue]);

  return { isReadOnly, setReadOnly };
};

export const useCdpStatusInformationEffect = (
  cdpStatusId: string,
  cdpStatuses: CdpStatusResource[],
  isJobTitleReviewCompleted: boolean,
  isDisciplinaryActionCompleted: boolean,
) => {
  const [cdpStatusName, setCdpStatusName] = useState('');
  const [cdpActionCommentLabel, setCdpActionCommentLabel] = useState('');
  const [cdpActionCommentVisibility, setCdpActionCommentVisibility] = useState('');
  const [cdpActionCommentRequired, setCdpActionCommentRequired] = useState(false);
  const [cdpActionAdminJobUpdateOptionVisible, setCdpActionAdminJobUpdateOptionVisible] = useState(false);
  const [cdpActionDisciplinaryOptionVisible, setCdpActionDisciplinaryOptionVisible] = useState(false);
  const [jobTitleDropDownVisible, setJobTitleDropDownVisible] = useState(false);
  const [completedAdditionalMessage, setCompletedAdditionalMessage] = useState('');

  useEffect(() => {
    setCdpStatusName(getSelectedCdpStatusName(cdpStatusId, cdpStatuses));
    setJobTitleDropDownVisible(false);
    if (cdpStatusName === 'In Progress') {
      setCdpActionCommentVisibility('none');
      setCdpActionCommentRequired(false);
    } else setCdpActionCommentVisibility('');

    if (cdpStatusName === 'Completed') {
      if (isJobTitleReviewCompleted) {
        setCdpActionAdminJobUpdateOptionVisible(true);
        setJobTitleDropDownVisible(true);
        setCompletedAdditionalMessage('*Completed via job title review');
        return;
      } else if (isDisciplinaryActionCompleted) {
        setCdpActionDisciplinaryOptionVisible(true);
        setCompletedAdditionalMessage('*Completed via disciplinary action');
        return;
      } else {
        setCdpActionCommentLabel('CDP aditional comments');
        setCdpActionCommentRequired(false);
      }
    } else {
      setCdpActionCommentLabel('Reason');
    }
    if (cdpStatusName !== 'Completed' && cdpStatusName !== 'In Progress') setCdpActionCommentRequired(true);

    if (cdpStatusName === 'Job Title Review') {
      setCdpActionAdminJobUpdateOptionVisible(true);
      setJobTitleDropDownVisible(true);
    } else setCdpActionAdminJobUpdateOptionVisible(false);

    if (cdpStatusName === 'Disciplinary Action') {
      setCdpActionDisciplinaryOptionVisible(true);
    } else setCdpActionDisciplinaryOptionVisible(false);
  }, [cdpStatusId, cdpStatusName, cdpStatuses, isDisciplinaryActionCompleted, isJobTitleReviewCompleted]);

  return {
    cdpStatusName,
    cdpActionCommentLabel,
    cdpActionCommentVisibility,
    cdpActionCommentRequired,
    cdpActionAdminJobUpdateOptionVisible,
    cdpActionDisciplinaryOptionVisible,
    jobTitleDropDownVisible,
    completedAdditionalMessage,
  };
};

const getSelectedCdpStatusName = (id: string, cdpStatuses: CdpStatusResource[]) => {
  const selectedCDPStatus = cdpStatuses.filter((status) => status._id === id);

  return selectedCDPStatus[0]?.name || '';
};
