/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Column } from 'components/standard-table/types';
import React from 'react';
import { TimesheetResource } from 'services/timesheetService';
import styled from 'styled-components';

const fixedColumns: Column<TimesheetResource>[] = [
  {
    label: 'Project',
    sortKey: 'project.name',
    path: 'project.name',
    render: (item) => item.project?.name,
  },
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Time Spent',
    sortKey: 'timeSpent',
    path: 'timeSpent',
    render: (item) => `${item.timeSpent} Hrs`,
  },
  {
    label: 'Time Remaining',
    sortKey: 'timeRemaining',
    path: 'timeRemaining',
  },
];

const variableColumns = (checkTimeSheetEmployee: any, downloadTimeSheetEmployee: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        {item.hasEmptyDescriptions && <PendingIcon visibility={item.hasEmptyDescriptions} />}
        <IconButton onClick={() => checkTimeSheetEmployee(item._id, item.employee._id)} color="primary">
          <VisibilityIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            downloadTimeSheetEmployee(
              item._id,
              item.employee.name + ' ' + item.employee.lastName,
              item.period,
              item.project.name,
            )
          }
          color="primary"
        >
          <DownloadIcon />
        </IconButton>
      </Container>
    ),
  };
};

const columns = (checkTimeSheetEmployee: any, downloadTimeSheetEmployee: any) => {
  return [...fixedColumns, variableColumns(checkTimeSheetEmployee, downloadTimeSheetEmployee)];
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const PendingIcon = styled(ListAltIcon)`
  color: red;
  margin-top: 10px;
`;

export default columns;
