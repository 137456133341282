import { Checkbox, FormControlLabel, InputAdornment, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import { useOnboardingProcessEffect } from './hooks';

export type SearchFilter = {
  searchText: string;
  isActive: boolean;
};

clearLocalStorage(); //Clear local storage on refresh

const localStorageSufix = '_onboardingProcess';

const DEFAULT_QUERY: SearchFilter & Query = {
  pageSize: 20,
  page: 1,
  isSortAscending: 1,
  sortByColumn: 'title',
  searchText: '',
  isActive: true,
};

const DEFAULT_FILTER: SearchFilter = {
  searchText: localStorage.getItem(localStorageSufix) || '',
  isActive: true
}

const OnboardingProcessTemplateList: React.FC = () => {
  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_FILTER);
  const {
    onboardingProcessTemplates,
    loading,
    setLoading,
    fetchOnboardingProcessTemplates,
  } = useOnboardingProcessEffect(query);
  const initialLoading = useInitialLoading(loading);

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setQuery({ ...query, ...defaultFilter }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter]);

  const handleOnChange = useCallback(
    (event: any) => {
      const { name, value } = event?.target;

      saveInfoInLocalStorage(name, value as string, localStorageSufix);
      if(name === "isActive") {        
        setDefaultFilter({ ...defaultFilter, [name!]: value === "true" ? false : true});
      } else {
        setDefaultFilter({ ...defaultFilter, [name!]: value});
      }
    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <Card>
        <CardHeader
          title="Templates"
          subTitle=""
          loading={loading}
          newPageRedirectionLink={'/onboarding-process-templates/new'}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <SearchBar>
                  <TextField
                    id="standard-search"
                    label="Search"
                    type="search"
                    name='searchText'
                    value={defaultFilter.searchText}
                    onChange={handleOnChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </SearchBar>
              </Grid>
              <LabelContainer>
                <FormControlLabel
                  id={'isActiveCheckbox'}
                  control={<Checkbox value={defaultFilter.isActive} checked={defaultFilter.isActive} onChange={handleOnChange} name="isActive" />}
                  label="Active"
                />
              </LabelContainer>
            </Grid>
            {onboardingProcessTemplates?.items.length ? (
              <StandardTable
                keyColumn="_id"
                data={onboardingProcessTemplates.items}
                totalItems={onboardingProcessTemplates.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchOnboardingProcessTemplates, setLoading)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    text-align: right;
  }
`;
const SearchBar = styled.div`
  margin: 10px 0px 20px 15px;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;
const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;
const LabelContainer = styled.div`
  display: inline-flex;
`;
const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default OnboardingProcessTemplateList;
