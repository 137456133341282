import { CircularProgress, IconButton, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HistoryIcon from '@material-ui/icons/History';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import RichTextEditor from 'components/rich-text-editor';
import SingleSelect from 'components/single-select';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { getEmployeeName } from 'utils/general/generalMethods';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import HistoryModal from '../one-on-one-list/modal';
import { useFetchOneOnOneEffect, useReadOnlyEffect } from './hooks';
import OneOnOneActionItem from './one-on-one-action-item';

saveInfoInLocalStorage('inputValue1on1', '', '_form');

const OneOnOneForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    oneOnOne,
    loading,
    onHistoryPressed,
    showModal,
    setShowModal,
    handleOnBlur,
    leaders,
    statuses,
    feelings,
    values,
    useInput,
    useRadioButton,
    catalogLoading,
    isSubmiting,
    handleSaveOneOnOne,
    isValid,
  } = useFetchOneOnOneEffect(id);
  const [statusOneOnOne, setStatusOneOnOne] = useState<Item>();
  const selectedStatus = statuses.find((status) => status.id === values.status);

  useEffect(() => {
    setStatusOneOnOne(selectedStatus);
    if (statusOneOnOne?.label === 'Not Done') {
      values.feelings = [];
    }
  }, [selectedStatus, values.feelings, statusOneOnOne]);

  const feelingInput = useRadioButton('feelings', 'inputValue1on1');
  const localReadOnly = localStorage.getItem('oneOnOneMode');
  const { isReadOnly } = useReadOnlyEffect(localReadOnly, id, oneOnOne);

  return (
    <Container>
      <Card>
        <CardHeader
          loading={loading}
          title={`One on One: ${!loading ? getEmployeeName(oneOnOne?.employee) : 'Loading'}`}
          subTitle={`Leader: ${!loading ? getEmployeeName(oneOnOne?.leaderAssigned, 'No Leader') : 'Loading...'}`}
        />
        <CardBody>
          <StatusContainer>
            <BodyTitle>Status and assignement</BodyTitle>
            <DetailsLine>
              <SelectContainer>
                <SingleSelect
                  id={'assignedToSelect'}
                  label={'Assigned To:'}
                  options={leaders}
                  {...useInput('leaderAssigned', 'inputValue1on1')}
                  disabled={isReadOnly}
                  onBlur={handleOnBlur}
                />
              </SelectContainer>
              <SelectContainer>
                <SingleSelect
                  id={'assignedToStatus'}
                  label={'Status'}
                  options={statuses}
                  {...useInput('status', 'inputValue1on1')}
                  disabled={isReadOnly}
                  onBlur={handleOnBlur}
                />
              </SelectContainer>

              <Tooltip title="One On Ones History">
                <IconButton onClick={onHistoryPressed} color="primary">
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </DetailsLine>
          </StatusContainer>
          <Feelings>
            <BodyTitle>
              How are you feeling today: {statusOneOnOne?.label !== 'Not Done' && <RequiredForm>*</RequiredForm>}{' '}
            </BodyTitle>
            {catalogLoading && (
              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            )}
            {!catalogLoading && (
              <RadioGroupContainer>
                {feelings.map((feeling) => (
                  <FormControlLabel
                    key={feeling.id}
                    value={feeling.id}
                    checked={statusOneOnOne?.label !== 'Not Done' ? values.feelings.includes(feeling.id) : false}
                    control={<Radio />}
                    label={feeling.label}
                    onChange={feelingInput.onChange}
                    disabled={
                      statusOneOnOne?.label !== 'Not Done' && statusOneOnOne?.label !== 'Complete' ? false : true
                    }
                    onBlur={handleOnBlur}
                  />
                ))}
              </RadioGroupContainer>
            )}
          </Feelings>
          <BodyTitle>Talking Points:</BodyTitle>
          <TextBody id={'talkingPointsInput'} onBlur={handleOnBlur}>
            <RichTextEditor height={'800px'} {...useInput('talkingPoint', 'inputValue1on1')} readOnly={isReadOnly} />
          </TextBody>
          <ButtonContainer>
            {isSubmiting && <Spinner size={30} />}
            <Submit
              id={'submitOneOnOnes'}
              disabled={
                isSubmiting ||
                loading ||
                catalogLoading ||
                (!isValid && statusOneOnOne?.label !== 'Not Done') ||
                isReadOnly
              }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleSaveOneOnOne(true)}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBody>
      </Card>
      {id && oneOnOne && leaders && (
        <OneOnOneActionItem oneOnOneId={id} oneOnOne={oneOnOne} isReadOnly={isReadOnly}></OneOnOneActionItem>
      )}

      <HistoryModal
        showModal={showModal}
        setShowModal={setShowModal}
        seletedEmployeeId={oneOnOne?.employee._id || ''}
        displayInNewTab
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;
const RequiredForm = styled.span`
  color: red;
  font-size: 15px;
  margin-left: 5px;
`;

const RadioGroupContainer = styled(RadioGroup)`
  padding: 10px;
  display: block;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 0 20px 20px 30px;
  align-items: center;
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  height: 110px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const DetailsLine = styled.div`
  display: inline-block;
  flex: 1;
  padding: 20px;
  text-align: left;
`;

const TextBody = styled.div`
  margin: 0 30px 30px 30px;
`;
const SelectContainer = styled.div`
  min-width: 160px;
  padding: 0 5px;
  float: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const StatusContainer = styled.div``;
const Feelings = styled.div``;

export default OneOnOneForm;
