import { CircularProgress, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import SingleSelect from 'components/single-select';
import { useForm } from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createActionItem, updateActionItem } from 'services/actionItemService';
import styled from 'styled-components';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import { getLeaderIdByEmployeeId } from 'utils/leaderFinder/findLeader';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import Comments from './comments';
import { useActionItemEffect, useCatalogs, useEmployeeAndLeaderEffect } from './hooks';

const schema = {
  employee: yup.string().required().label('Employee'),
  leaderAssigned: yup.string().required().label('Leader'),
  status: yup.string().required().label('Status'),
  priority: yup.string().required().label('Priority'),
  title: yup.string().required().max(100).label('Title'),
  description: yup.string().required().label('Description'),
  createdBy: yup.string().label('createdBy'),
  dueDate: yup.string().required().label('Date').typeError('Invalid date'),
  type: yup.string().required().min(0),
  typeId: yup.string().required().min(0),
};

type Props = {};

const ActionItemForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    actionItem,
    actionItemCreationDate,
    loading,
    actionItemCreatedBy,
    actionItemCompletionDate,
    setActionItemCompletionDate,
  } = useActionItemEffect(id);
  const history = useHistory();
  const { employees, leaders, statuses, priorities, loading: catalogLoading, employeesWithLeaders } = useCatalogs();
  const [savingActionItem, setSavingActionItem] = useState(false);
  const { useInput, useDateInput, values, isValid, setIsValid } = useForm(actionItem, schema, loading);
  const subTitle = id ? 'Update the action item' : 'Create a new action item';
  const initialLoading = loading || catalogLoading;
  const display = initialLoading ? 'none' : 'block';
  const newStatuses = id ? statuses : statuses.filter((x) => x.label === 'Open');
  const { employeeSelected, setEmployeeSelected, leaderSelected, setLeaderSelected } = useEmployeeAndLeaderEffect(
    values.employee,
    values.leaderAssigned!,
  );

  useEffect(() => {
    if (initialLoading) return;
    const statusCompleted = statuses.find((item) => item.id === values.status)?.label === 'Completed';

    statusCompleted ? setActionItemCompletionDate(new Date().toString()) : setActionItemCompletionDate(null);
  }, [values.status, statuses, initialLoading, setActionItemCompletionDate]);

  actionItem.typeId = actionItem.typeId || 'none';

  if (!initialLoading) {
    actionItem.status = actionItem.status || statuses[statuses.map((stat) => stat.label).indexOf('Open')].id;
  }

  const handleSaveActionItem = async () => {
    if (!isValid) {
      notifier.error(`Please, fix the errors on the form before submitting `);
      return;
    }

    setSavingActionItem(true);

    if (id) {
      values.completionDate = actionItemCompletionDate;
      const response = await updateActionItem(values, id);

      if (response.data) notifier.success('Action Item updated successfully.');
      setSavingActionItem(false);
      return;
    }

    const newActionItem = await createActionItem(values);

    setSavingActionItem(false);
    if (newActionItem) {
      notifier.success('Action Item created successfully.');
      history.push(`/action-items/${newActionItem._id}`);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'employee') {
      values.employee = (event.target.value as string) || '';
      values.leaderAssigned = getLeaderIdByEmployeeId(employeesWithLeaders, values.employee);
      setEmployeeSelected(event.target.value as string);
      setLeaderSelected(values.leaderAssigned);
    } else if (event.target.name === 'leaderAssigned') {
      values.leaderAssigned = (event.target.value as string) || '';
      setLeaderSelected(event.target.value as string);
    }

    if (values.employee && values.leaderAssigned && values.priority && values.title && values.description)
      setIsValid(true);
    else setIsValid(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Action Item: ${!initialLoading ? values?.title : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <SectionWrapper>
            <BodyTitle>Assignment</BodyTitle>
            <DetailsLine>
              <SelectContainer>
                <SingleSelect
                  id={'employeeSelect'}
                  name={'employee'}
                  label={'Employee'}
                  required
                  options={employees}
                  onChange={handleChange}
                  value={employeeSelected}
                />
              </SelectContainer>
              <SelectContainer>
                <SingleSelect
                  label={'Assigned To:'}
                  id={'leaderSelect'}
                  name={'leaderAssigned'}
                  options={leaders}
                  required
                  onChange={handleChange}
                  value={leaderSelected}
                />
              </SelectContainer>
            </DetailsLine>
          </SectionWrapper>
          <SectionWrapper>
            <BodyTitle>Status and Priority</BodyTitle>
            <DetailsLine>
              <SelectContainer>
                <SingleSelect
                  id={'statusSelect'}
                  label={'Status'}
                  empty={false}
                  options={newStatuses}
                  {...useInput('status')}
                />
              </SelectContainer>
              <SelectContainer>
                <SingleSelect
                  id={'prioritySelect'}
                  required
                  label={'Priority'}
                  options={priorities}
                  {...useInput('priority')}
                />
              </SelectContainer>
            </DetailsLine>
          </SectionWrapper>
          <SectionWrapper>
            <BodyTitle>Dates</BodyTitle>
            <DetailsLine>
              {id && (
                <TextField
                  id="createdBy"
                  label="Created By"
                  variant="outlined"
                  disabled={true}
                  value={actionItemCreatedBy}
                />
              )}
              <SelectContainer>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <KeyboardDatePicker
                    id={'creationDateSelect'}
                    label="Creation Date"
                    disabled={true}
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    value={actionItemCreationDate}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={() => {}}
                  />
                </Tooltip>
              </SelectContainer>

              <SelectContainer>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <KeyboardDatePicker
                    id={'dueDateSelect'}
                    required
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    label="Due Date"
                    minDate={new Date()}
                    {...useDateInput('dueDate')}
                  />
                </Tooltip>
              </SelectContainer>

              <SelectContainer>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <KeyboardDatePicker
                    id={'completionDateSelect'}
                    label="Completion Date"
                    disabled={true}
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    value={actionItemCompletionDate}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={() => {}}
                  />
                </Tooltip>
              </SelectContainer>
            </DetailsLine>
          </SectionWrapper>
          <Clear />
          <BodyTitle>Title and Description:</BodyTitle>
          <TextBody>
            <TextField
              required
              fullWidth
              inputProps={{
                maxLength: 280,
              }}
              id="titleActionItem"
              label="Title"
              variant="outlined"
              {...useInput('title')}
            />
          </TextBody>
          <TextBody>
            <ExpandTextField
              required
              multiline
              fullWidth
              minRows={4}
              maxRows={Infinity}
              inputProps={{ maxLength: 5000 }}
              id="descriptionActionItem"
              label="Description"
              variant="outlined"
              {...useInput('description')}
            />
          </TextBody>

          <ButtonContainer>
            {savingActionItem && <Spinner size={30} />}
            <Submit
              id={'submitActionItem'}
              disabled={savingActionItem || loading || catalogLoading || !isValid}
              onClick={handleSaveActionItem}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
      {id && <Comments actionItemId={id} />}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const SectionWrapper = styled.div`
  float: left;
`;
const Clear = styled.div`
  clear: both;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
`;

const TextBody = styled.div`
  margin: 30px;
`;

const SelectContainer = styled.div`
  min-width: 160px;
  padding: 0 5px;
  display: block;
  float: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const ExpandTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: auto !important;
  }
`;

export default ActionItemForm;
