/* eslint-disable react-hooks/rules-of-hooks */
import { CircularProgress } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import { BLUE } from 'shared/style/utilities/colors';
import styled from 'styled-components';

import { useCDPTemplateEffect } from './hooks';

type Props = {
  showModal: boolean;
  setShowModal: any;
  templateId: string;
};

const TemplateModal: React.FC<Props> = (props) => {
  const { showModal, setShowModal, templateId } = props;

  if (!templateId || !showModal) return <div></div>;

  const handleClose = () => {
    setShowModal(false);
  };

  const { template, loading } = useCDPTemplateEffect(templateId);

  return (
    <div>
      <ModalContainer
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <ContentContainer>
            <Title>{template ? template.title : ''}</Title>

            {loading && (
              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            )}
            {!loading && (
              <Wrapper>
                <TableDiv>
                  <h3>Ratings:</h3>
                  <div>
                    {template?.ratings.map((rating) => (
                      <RatingDescription>
                        <StyledChip size="small" label={`${rating.order}. ${rating.description}`} owncolor={BLUE} />
                      </RatingDescription>
                    ))}
                  </div>
                  <h3>Categories:</h3>
                  <CategoryContainer>
                    <thead>
                      <CategoryHeader>Name</CategoryHeader>
                      <CategoryHeader>Description</CategoryHeader>
                    </thead>
                    <tbody>
                      {template?.categories.map((category) => (
                        <tr>
                          <CategoryCell>{category.title}</CategoryCell>
                          <CategoryCell>{category.description}</CategoryCell>
                        </tr>
                      ))}
                    </tbody>
                  </CategoryContainer>
                </TableDiv>
              </Wrapper>
            )}
          </ContentContainer>
        </Fade>
      </ModalContainer>
    </div>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  background-color: ${(p) => p.theme.palette.background.paper};
  box-shadow: ${(p) => p.theme.shadows[5]};
  align-items: center;
  justify-content: center;
  min-width: 450px;
  min-height: 540px;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const RatingDescription = styled.span`
  padding: 5px;
`;

const CategoryContainer = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
`;
const CategoryHeader = styled.th`
  border-bottom: 1px solid #e0e0e0;
`;
const CategoryCell = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  max-width: 500px;
  min-width: 150px;
`;

const Wrapper = styled.div`
  overflow: auto;
  padding: 10px;
  max-height: 800px;
`;
const TableDiv = styled.div`
  padding-bottom: 10px;
`;

const Title = styled.div`
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 20px;
`;

const StyledChip = styled(Chip)<{ owncolor: string }>`
  background-color: ${(p) => p.owncolor} !important;
  color: white !important;
  font-weight: bold;
`;

export default TemplateModal;
