import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { CareerDevelopmentPlanResource, getOldCdpsById } from 'services/careerDevelopmentPlanService';

export const useDisplayModalEffect = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return { showModal, setShowModal };
};

export const useSeletedEmployeeOneOnOneEffect = (seletedEmployee: string) => {
  const [selectedEmployee, setSelectedEmployee] = useState('');

  useEffect(() => {
    setSelectedEmployee(seletedEmployee);
  }, [seletedEmployee]);

  return { selectedEmployee, setSelectedEmployee };
};

export const useOldCdpEffect = (query: Query, selectedEmployee: string) => {
  const [careerDevelopmentPlans, setCareerDevelopmentPlans] = useState<PageResource<CareerDevelopmentPlanResource>>({
    totalItems: 0,
    items: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchOneOnOnes = useCallback(async () => {
    setLoading(true);
    const data = await getOldCdpsById(query, selectedEmployee);

    setLoading(false);
    setCareerDevelopmentPlans(data);
  }, [query, selectedEmployee]);

  useEffect(() => {
    fetchOneOnOnes();
  }, [fetchOneOnOnes, query]);

  return { oneOnOnes: careerDevelopmentPlans, loading };
};
