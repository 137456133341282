import { InputAdornment, OutlinedTextFieldProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

import { TextFieldWrapper } from './SearchBar.styles';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {}

const SearchBar = (props: Props) => {
  const { ...other } = props;

  return (
    <TextFieldWrapper
      {...other}
      variant="outlined"
      inputProps={{
        maxLength: 100,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
