import { useCallback, useState } from 'react';

export type Query = {
  page: number;
  pageSize: number;
  isSortAscending: 1 | 0;
  sortByColumn: string;
};

export type PageResource<T extends {}> = {
  items: T[];
  totalItems: number;
};

enum sort {
  ASC = 1,
  DESC = 0,
}

const usePaging = <T extends Query>(defaultQuery: T) => {
  const [query, setQuery] = useState(defaultQuery);
  const handleColumnSorting = useCallback(
    (sortByColumn: string) => {
      if (query.sortByColumn === sortByColumn)
        return setQuery({ ...query, isSortAscending: query.isSortAscending === sort.DESC ? sort.ASC : sort.DESC });
      setQuery({ ...query, sortByColumn, isSortAscending: sort.ASC });
    },
    [query],
  );

  const handlePageChange = useCallback((page: number) => setQuery({ ...query, page }), [query]);

  return { handleColumnSorting, handlePageChange, query, setQuery };
};

export default usePaging;
