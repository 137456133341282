import { Query } from 'hooks/usePaging';
import { EmployeeResource } from 'services/employeeService';
import { Description } from 'services/screenOnboardingProcessService';
import constants from 'utils/constants';
import notifier from 'utils/notifiers/notifier';
import { SearchFilter } from 'utils/objectTypes/types';

export const getEmployeeName = (employee?: EmployeeResource, defaultText: string = 'No name') => {
  if (!employee) return defaultText;
  return `${employee.name} ${employee.lastName}`;
};

export const getCountryAbbreviation = (countryName: string) => {
  const separatedName = countryName.split(' ');
  let abbreviation = '';

  if (separatedName.length > 1) {
    abbreviation = separatedName[0].charAt(0) + separatedName[1].charAt(0);
  } else {
    abbreviation = separatedName[0].substr(0, 2);
  }

  return abbreviation.toUpperCase();
};

export const generateTempKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};

export const verifyAllCompleted = (descriptions: Description[]) => {
  let countCompleted = 0;
  let verify = 0;

  for (let i = 0; i < descriptions.length; i++) {
    if (descriptions[i].opStatus === 'Completed') {
      countCompleted++;
    }
  }
  if (countCompleted === descriptions.length) {
    verify = 1;
  }
  return verify;
};

export const DEFAULT_QUERY_WITH_SEARCH: Query & SearchFilter = {
  pageSize: 20,
  page: 1,
  isSortAscending: 1,
  sortByColumn: 'name',
  searchText: '',
};

export const isFileSizeValid = (selectedFile: any) => {
  const MAX_FILE_SIZE = 1024; // 1MB

  if (!selectedFile) {
    return false;
  }

  const fileSizeKiloBytes = selectedFile.size / 1024;

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    notifier.error(constants.ERROR_FILE_SIZE_EXCEDED);
    return false;
  }

  return true;
};

export const sortDateAsc = (a, b) => {
  var dateA = new Date(a.date).getTime();
  var dateB = new Date(b.date).getTime();

  return dateA > dateB ? 1 : -1;
};
