import { useCallback, useEffect, useState } from 'react';
import { getEpStatus } from 'services/epStatusService';
import {
  Description,
  ExitProcedureProcessResource,
  getEpById,
  SaveExitProcedureProcessResource,
} from 'services/exitProcedureProcessService';
import { ExitItem, Item } from 'shared/types';
import { collectionToItemExitProcedure } from 'utils/mappers/item';

const defaultExitProcedureProcessResource = {
  descriptions: [],
};

export const useExitProcedureProcessEffect = (id: string) => {
  const [saveExitProcedureProcess, setSaveExitProcedureProcess] = useState<SaveExitProcedureProcessResource>(
    defaultExitProcedureProcessResource,
  );
  const [exitProcedureProcess, setExitProcedureProcess] = useState<ExitProcedureProcessResource>();
  const [loading, setLoading] = useState(false);

  const fetchExitProcedureProcess = useCallback(async () => {
    setLoading(true);
    const exitProcedureProcessResult = await getEpById(id);

    if (exitProcedureProcessResult) {
      setSaveExitProcedureProcess(mapExitProcedureProcessResponse(exitProcedureProcessResult));
      setExitProcedureProcess(exitProcedureProcessResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchExitProcedureProcess();
  }, [id, fetchExitProcedureProcess]);

  return {
    exitProcedureProcess,
    setExitProcedureProcess,
    loading,
    saveExitProcedureProcess,
    setSaveExitProcedureProcess,
    setLoading,
    fetchExitProcedureProcess,
  };
};

const mapExitProcedureProcessResponse = (ep: ExitProcedureProcessResource) => {
  return {
    descriptions: mapDescriptions(ep.descriptions),
  } as SaveExitProcedureProcessResource;
};

const mapDescriptions = (descriptions: Description[]) => {
  return descriptions.map((description) => ({
    description: description.description,
    position: description.position,
    epStatus: description.epStatus,
  }));
};

export const useCatalogs = (id: string) => {
  const [loading, setLoading] = useState(true);

  const [descriptions, setDescriptions] = useState<ExitItem[]>([]);
  const [epStatuses, setEpStatuses] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [descriptionsResult, epStatusResult] = await Promise.all([getEpById(id), getEpStatus()]);

      const descriptionItems: ExitItem[] = descriptionsResult.descriptions.map((actualExitProcedure) => ({
        id: actualExitProcedure._id,
        description: actualExitProcedure.description,
        epStatus: actualExitProcedure.epStatus,
      }));

      const epStatusItems = collectionToItemExitProcedure(epStatusResult);

      setDescriptions(descriptionItems);
      setEpStatuses(epStatusItems);

      setLoading(false);
    }

    fetchCatalogs();
  }, [id]);

  return { descriptions, epStatuses, loading };
};

export const useReadOnlyEffect = (localStorageValue: string | null, epFinished: boolean, adminPermission: boolean) => {
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (adminPermission) {
      setReadOnly(false);
      return;
    }

    if (localStorageValue && localStorageValue === 'readOnly') {
      setReadOnly(true);
    } else if (epFinished) {
      setReadOnly(true);
    } else setReadOnly(false);
  }, [adminPermission, epFinished, localStorageValue]);

  return { isReadOnly, setReadOnly };
};
