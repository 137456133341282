export const CINNABAR = '#DC3534';
export const APPLE_BLOSSOM = '#C3011A';
export const FRUIT_SALAD = '#4CAF50';
export const BLACK = '#131111';
export const WHITE = '#ffffff';
export const SOLITUDE = '#e9ecef';
export const NOBEL = '#B4B4B4';

export const CUTTY_SARK = '#557177';
export const POSEIDON = '#134E5C';
export const POSEIDON_REGRESSION = '#036981';
export const DEEP_SEA_GREEN = '#0A4957';
export const TEAL_BLUE = '#034352';
export const TEAL_BLUE_DARK = '#023D4A';
export const TIBER = '#0A2F37';
export const BLACK_PEARL = '#03151A';
export const DIM_GRAY = '#646464';

export const ROPE = '#95581B';
export const TENN = '#D06700';
export const KORMA = '#8D4C0D';
export const PERU_TAN = '#854201';
export const CINNAMON = '#783C00';
export const PIZAZZ = '#ff9500';

export const YELLOW = '#ffff00';
export const RED = '#ff0000';
export const HOTPINK = '#ff66cc';
export const LIGHTBLUE = '#addfff';
export const PURPLE = '#BA37B8';
export const BLUE = '#5bcaff';
export const SIDE_BAR_BACKGROUND = '#262325';
