import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import {
  getAllScreenOnboardingProcess,
  ScreenOnboardingProcessFilter,
  ScreenOnboardingProcessResource,
} from 'services/screenOnboardingProcessService';
import { getStatusesFilter } from 'services/statusFilterService';
import { Item } from 'shared/types';
import { collectionToItem } from 'utils/mappers/item';

export const useCatalogFilter = () => {
  const [loading, setLoading] = useState(true);
  const [statusesFilter, setStatusesFilter] = useState<Item[]>([]);

  useEffect(() => {
    async function loadCatalog() {
      const newStatuses = await getStatusesFilter();

      setStatusesFilter(collectionToItem(newStatuses));
      setLoading(false);
    }
    loadCatalog();
  }, []);

  return { loading, statusesFilter };
};

export const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

export const useScreenOnboardingProcessEffect = (filter: Query & ScreenOnboardingProcessFilter) => {
  const [screenOnboardingProcess, setScreenOnboardingProcess] = useState<PageResource<ScreenOnboardingProcessResource>>(
    {
      totalItems: 0,
      items: [],
    },
  );
  const [loading, setLoading] = useState(true);

  const fetchScreenOnboardingProcess = useCallback(async () => {
    setLoading(true);

    const data = await getAllScreenOnboardingProcess(filter);

    setLoading(false);
    setScreenOnboardingProcess(data);
  }, [filter]);

  useEffect(() => {
    fetchScreenOnboardingProcess();
  }, [fetchScreenOnboardingProcess, filter]);

  return { screenOnboardingProcess, loading, setLoading, fetchScreenOnboardingProcess };
};
