import { useCallback, useEffect, useState } from 'react';
import { getKeyResultTypeById, KeyResultTypeResource, SaveKeyResultTypeResource } from 'services/hitgoalsService';

const defaultTypeResource = {
  name: '',
};

export const useKeyResultTypeEffect = (id?: string) => {
  const [type, setType] = useState<SaveKeyResultTypeResource>(defaultTypeResource);
  const [loading, setLoading] = useState(false);

  const fetchProject = useCallback(async () => {
    setLoading(true);
    const typeResult = await getKeyResultTypeById(id!);

    if (typeResult) {
      setType(mapTypeResponse(typeResult));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchProject();
  }, [id, fetchProject]);

  return { type, loading };
};

const mapTypeResponse = (type: KeyResultTypeResource) => {
  return {
    name: type.name,
  } as SaveKeyResultTypeResource;
};
