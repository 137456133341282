import { useCallback, useEffect, useState } from 'react';
import { AnniversaryByMonthResource, getAnniversaries } from 'services/humanResourcesService';

export const useAnniversaresEffect = () => {
  const [anniversariesByMonth, setAnniversariesByMonth] = useState<AnniversaryByMonthResource[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchAnniversaries = useCallback(async () => {
    setLoading(true);

    const anniversariesObject = await getAnniversaries();

    setLoading(false);

    setAnniversariesByMonth(anniversariesObject.anniversaries);
  }, []);

  useEffect(() => {
    fetchAnniversaries();
  }, [fetchAnniversaries]);

  return { anniversariesByMonth, loading, setLoading, fetchBirthdays: fetchAnniversaries };
};
