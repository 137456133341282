import { useCallback, useEffect, useState } from 'react';
import { ActionItemCommentResource, getAllComments } from 'services/actionItemService';

export const useCommentsEffect = (id?: string) => {
  const [comments, setComments] = useState<ActionItemCommentResource[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchComments = useCallback(async () => {
    setLoading(true);
    const commentsResult = await getAllComments(id!);

    if (commentsResult) {
      setComments(commentsResult);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchComments();
  }, [id, fetchComments]);

  return { comments, setComments, loading };
};
