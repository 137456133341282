import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import SingleSelectBase from 'components/single-select';
import { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useRef } from 'react';
import { ScreenOnboardingProcessFilter } from 'services/screenOnboardingProcessService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  search: string;
  statusesFilter: Item[];
  filter: ScreenOnboardingProcessFilter & Query;
  defaultFilter: ScreenOnboardingProcessFilter;
  setDefaultFilter: any;
  setFilter(value: ScreenOnboardingProcessFilter & Query): void;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_op';

const Filter: React.FC<Props> = (props) => {
  const { filter, statusesFilter, setFilter, defaultFilter, setDefaultFilter } = props;

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setFilter({ ...filter, ...defaultFilter, page: 1 }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter]);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event?.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: value, page: 1 });
    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByNameInput'}
        inputProps={{
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={'Search by Name'}
        variant="outlined"
        name="search"
        value={defaultFilter.search}
        onChange={handleOnChange}
      />
      <SingleSelect
        id={'statusSelect'}
        name="status"
        options={statusesFilter}
        label={'Status'}
        onChange={handleOnChange}
        value={defaultFilter.status || ''}
      />
    </Container>
  );
};

const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: inline-block;
  flex: 1;
  padding: 10px 0;
`;

const TextFieldWrapper = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export default Filter;
