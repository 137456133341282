import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import { monthName } from 'pages/hr/commonValues';
import React from 'react';
import { deleteEmployee, EmployeeResource } from 'services/employeeService';
import { stringDateToHumanFormat } from 'utils/formatters/date';
import { getCountryAbbreviation } from 'utils/general/generalMethods';

const fixedColumns: Column<EmployeeResource>[] = [
  {
    label: 'Hire Date',
    sortKey: 'hireDate',
    path: 'hireDate',
    render: (item) =>
      `${
        item.hireDate && stringDateToHumanFormat(item.hireDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.hireDate)
          : '-'
      }`,
  },
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
    render: (item) => `${item.name} ${item.lastName}`,
  },
  {
    label: 'Email',
    sortKey: 'email',
    path: 'email',
  },
  {
    label: 'Leader',
    sortKey: 'leader.name',
    path: 'leader.name',
    render: (item) => `${item.leader ? item.leader.name + ' ' + item.leader.lastName : '-'}`,
  },
  {
    label: 'Project',
    sortKey: 'project.name',
    path: 'project.name',
    render: (item) => `${item.project ? item.project.name : '-'}`,
  },
  {
    label: 'Job Title',
    sortKey: 'jobTitle.order',
    path: 'jobTitle.order',
    render: (item) => `${item.jobTitle ? item.jobTitle.name : '-'}`,
  },
  {
    label: 'Active',
    sortKey: 'isActive',
    path: 'isActive',
    render: (item) => {
      return item.isActive ? 'Active' : 'Inactive';
    },
  },
  {
    label: 'Client Period',
    sortKey: 'clientSalaryPeriodMonth',
    path: 'clientSalaryPeriodMonth',
    render: (item) => `${item.clientSalaryPeriodMonth ? monthName(parseInt(item.clientSalaryPeriodMonth)) : '-'}`,
  },
  {
    label: 'Employee Period',
    sortKey: 'salaryPeriodMonth',
    path: 'salaryPeriodMonth',
    render: (item) => `${item.salaryPeriodMonth ? monthName(parseInt(item.salaryPeriodMonth)) : '-'}`,
  },
  {
    label: 'Contract type',
    sortKey: 'employeeType',
    path: 'employeeType',
    render: (item) => `${item.employeeType ? item.employeeType.name : '-'}`,
  },
  {
    label: 'Country',
    sortKey: 'homeAddress.country',
    path: 'homeAddress.country',
    render: (item) => {
      return item.homeAddress && item.homeAddress.country ? getCountryAbbreviation(item.homeAddress.country) : '-';
    },
  },
];

const variableColumns = (fetchEmployees: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <TableRowAction
        id={item._id}
        editEnable
        deleteEnable
        fetchItems={fetchEmployees}
        deleteItem={deleteEmployee}
        setLoading={setLoading}
        url={'employees'}
        name={'Employee'}
        deleteTitle={'Delete Employee'}
        deleteMessage={'Do you want to delete this employee?'}
      />
    ),
  };
};

const columns = (fetchEmployees: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchEmployees, setLoading)];
};

export default columns;
