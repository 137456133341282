import { PageResource, Query } from 'hooks/usePaging';

import { UserResource } from './authService';
import http from './httpService';

const DEVELOPMENT_PLAN_TEMPLATES_URL = '/api/developmentPlanTemplates';

export type CategoryResource = {
  _id: string;
  title: string;
  description: string;
  order: number;
};

export type RatingResource = {
  _id: string;
  order: number;
  description: string;
};

export type SaveCategoryResource = {
  title: string;
  description: string;
};

export type SaveRatingResource = {
  order: number;
  description: string;
};

export type DevelopmentPlanResource = {
  _id: string;
  title: string;
  createdBy: UserResource;
  creationDate: Date;
  categories: CategoryResource[];
  ratings: RatingResource[];
};

export type SaveDevelopmentPlanResource = {
  title: string;
  categories: CategoryResource[];
  ratings: RatingResource[];
};

export async function getDevelopmentPlanTemplates() {
  const { data } = await http.get<DevelopmentPlanResource[]>(DEVELOPMENT_PLAN_TEMPLATES_URL + '/');

  return data;
}

export async function getDevelopmentPlanById(id: string) {
  const { data } = await http.get<DevelopmentPlanResource>(`${DEVELOPMENT_PLAN_TEMPLATES_URL}/single/${id}`);

  return data;
}

export async function getDevelopmentPlanTemplatesQuery(query: Query) {
  const { data } = await http.get<PageResource<DevelopmentPlanResource>>(
    `${DEVELOPMENT_PLAN_TEMPLATES_URL}/query` + http.toQueryString(query),
  );

  return data;
}

export async function deleteDevelopmentPlanTemplate(id: string) {
  const { data } = await http.delete<DevelopmentPlanResource[]>(`${DEVELOPMENT_PLAN_TEMPLATES_URL}/${id}`);

  return data;
}
export async function updateDevelopmentPlanTemplate(developmentPlanTemplate: SaveDevelopmentPlanResource, id: string) {
  const { data } = await http.put<DevelopmentPlanResource>(
    `${DEVELOPMENT_PLAN_TEMPLATES_URL}/${id}`,
    developmentPlanTemplate,
  );

  return data;
}
export async function copyDevelopmentPlanTemplate(id: string) {
  const { data } = await http.get<DevelopmentPlanResource[]>(`${DEVELOPMENT_PLAN_TEMPLATES_URL}/copy/${id}`);

  return data;
}

export async function createDevelopmentPlanTemplate(developmentPlanTemplate: SaveDevelopmentPlanResource) {
  const { data } = await http.post<DevelopmentPlanResource>(
    `${DEVELOPMENT_PLAN_TEMPLATES_URL}`,
    developmentPlanTemplate,
  );

  return data;
}
