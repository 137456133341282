import { CatalogResource } from 'shared/types';

import http from './httpService';

const STATUS_FILTER_URL = '/api/statusFilter';

export async function getStatusesFilter() {
  const { data } = await http.get<CatalogResource[]>(STATUS_FILTER_URL);

  return data;
}
