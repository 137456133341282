import { KeyResultForHitGoalResource } from 'services/hitgoalProcessService';
export const statusObjectToString = (keyResults: KeyResultForHitGoalResource[], statusName: string) => {
  let countCompleted = 0;
  let countInCompleted = 0;
  let message = '';

  for (let i = 0; i < keyResults.length; i++) {
    if (keyResults[i].status === 'Complete') {
      countCompleted++;
    } else {
      countInCompleted++;
    }
  }
  if (statusName === 'Completed') {
    if (countCompleted !== 0) {
      message = `Completed (${countCompleted}/${keyResults.length})`;
    }
  }
  if (statusName === 'Incomplete') {
    if (countInCompleted !== 0) {
      message = `Incomplete (${countCompleted}/${keyResults.length})`;
    }
  }
  if (statusName === 'Uncompleted') {
    if (countInCompleted !== 0) {
      message = `Uncompleted (${countCompleted}/${keyResults.length})`;
    }
  }
  if (statusName === 'Unsuccessful') {
    message = 'Unsuccessful';
  }

  return message;
};
