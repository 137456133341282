import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import DateInputPicker from 'components/date-input-picker';
import SingleSelect from 'components/single-select';
import { useForm } from 'hooks/useForm';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createOneOnOne } from 'services/oneOnOneService';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCatalogs, useFetchOneOnOneEffect } from './hooks';

const schema = {
  employee: yup.string().required().label('Employee'),
  leaderAssigned: yup.string().label('Leader assigned'),
  status: yup.string().required().label('Status'),
  creationDate: yup.string().required().label('Creation Date'),
};

const OneOnOneCreationForm: React.FC = () => {
  const { oneOnOne } = useFetchOneOnOneEffect();
  const { leaders, statuses, employees, employeesWithLeaders, loading } = useCatalogs();
  const { useInput, values } = useForm(oneOnOne, schema, loading);
  const { isSubmiting, handleSaveOneOnOne: handleCreateOneOnOne } = useSubmitForm(values, true);

  const [employeeSelected, setEmployeeSelected] = useState('');
  const [leaderSelected, setLeaderSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  let [isValid, setIsValid] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    if (event.target.name === 'employee') {
      values.employee = (event.target.value as string) || '';
      values.leaderAssigned = getLeaderIdByEmployeeId();
      setEmployeeSelected(event.target.value as string);
      setLeaderSelected(values.leaderAssigned);
    } else if (event.target.name === 'leaderAssigned') {
      values.leaderAssigned = (event.target.value as string) || '';
      setLeaderSelected(event.target.value as string);
    }

    if (values.employee) setIsValid(true);
    else setIsValid(false);
  };

  const getLeaderIdByEmployeeId = () => {
    const leaderToSet = employeesWithLeaders.find((employee) => {
      return employee.employeeId === values.employee;
    }) || { leaderId: '' };

    return leaderToSet.leaderId || '';
  };

  const setDefaultStatus = () => {
    const statusToSet = statuses.find((status) => {
      return status.label === 'Open';
    }) || { id: '' };

    console.log('Status found: ' + statusToSet.id);
    values.status = statusToSet.id;
    setStatusSelected(statusToSet.id);
    return statusToSet.id || '';
  };

  if (!loading && !statusSelected) setDefaultStatus();

  return (
    <Container>
      <Card>
        <CardHeader loading={loading} title={'New one on One'} />
        <CardBody>
          <StatusContainer>
            <BodyTitle>One on one info</BodyTitle>
            <DetailsLine>
              <OptionsContainer>
                <SelectContainer>
                  <SingleSelectContainer
                    id={'employeeSelect'}
                    name={'employee'}
                    label={'Employee'}
                    options={employees}
                    onChange={handleChange}
                    value={employeeSelected}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SingleSelectContainer
                    id={'leaderSelect'}
                    name={'leaderAssigned'}
                    label={'Assigned To'}
                    options={leaders}
                    onChange={handleChange}
                    value={leaderSelected}
                  ></SingleSelectContainer>
                </SelectContainer>
                <SelectContainer>
                  <SingleSelectContainer
                    id={'statusSelect'}
                    label={'Status'}
                    name={'status'}
                    disabled={true}
                    options={statuses}
                    value={statusSelected}
                  ></SingleSelectContainer>
                </SelectContainer>
              </OptionsContainer>
            </DetailsLine>

            <DetailsLine>
              <SelectContainer>
                <DateInputPickerContainer
                  id={'periodSelect'}
                  views={['year', 'month']}
                  label="Start Date"
                  {...(useInput('creationDate') as any)}
                />
              </SelectContainer>
            </DetailsLine>
          </StatusContainer>

          <ButtonContainer>
            {isSubmiting && <Spinner size={30} />}
            <Submit id={'submitOneOnOne'} disabled={isSubmiting || loading || !isValid} onClick={handleCreateOneOnOne}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

const useSubmitForm = (values: any, isValid: boolean) => {
  const history = useHistory();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const handleCreateOneOnOne = useCallback(async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    setIsSubmitting(true);

    try {
      const newOneOnOne = await createOneOnOne(values);

      if (newOneOnOne) {
        notifier.success('One on one created successfully.');
        history.push(`/one-on-ones/${newOneOnOne._id}`);
      }
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    }

    setIsSubmitting(false);
  }, [isValid, values, history]);

  return { isSubmiting, handleSaveOneOnOne: handleCreateOneOnOne };
};

const Container = styled.div`
  margin: 0 15px;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
`;

const SelectContainer = styled.div`
  min-width: 160px;
  padding: 0 5px;
  width: 300px;
  float: left;
`;

const OptionsContainer = styled.div`
  float: left;
  display: block;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const SingleSelectContainer = styled(SingleSelect)`
  width: 290px;
  margin-bottom: 10px;
`;

const DateInputPickerContainer = styled(DateInputPicker)`
  width: 290px;
  margin-top: -20px;
`;

const StatusContainer = styled.div``;

export default OneOnOneCreationForm;
