import { CatalogResource } from 'shared/types';

import http from './httpService';

const ACTION_ITEM_STATUS_URL = '/api/actionItemStatus';

export async function getAllActionItemStatus() {
  const { data } = await http.get<CatalogResource[]>(ACTION_ITEM_STATUS_URL);

  return data;
}
