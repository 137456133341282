import { CatalogResource } from 'shared/types';

import http from './httpService';

const STATUS_REFERRAL_URL = '/api/statusReferral';

export async function getStatusesReferral() {
  const { data } = await http.get<CatalogResource[]>(STATUS_REFERRAL_URL);

  return data;
}
