import { CircularProgress, FormControlLabel, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  createOnboardingProcessTemplate,
  DescriptionResource,
  updateOnboardingProcessTemplate,
} from 'services/onboardingProcessTemplate';
import styled from 'styled-components';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import OnboardingProcessDescriptionForm from './description';
import { useOnboardingProcessTemplateEffect } from './hooks';
import { useForm } from './useForm';

const schema = {
  title: yup.string().required().label('Title'),
  isActive: yup.string().optional().label('IsActive'),
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

type Props = {};

const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};

const OnboardingProcessTemplateForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    saveOnboardingProcessTemplate,
    onboardingProcessTemplate,
    creationdate,
    loading,
    setSaveOnboardingProcessTemplate,
    isActive,
    setIsActive,
  } = useOnboardingProcessTemplateEffect(id);

  const history = useHistory();
  const [savingOnboardingProcess, setSavingOnboardingProcess] = useState(false);
  const subTitle = id ? 'Update the onboarding process template' : 'Create a new onboarding process template';
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';
  const [enabledAddDescription, setEnableAddDescription] = useState(true);

  const { useInput, isValid } = useForm(
    schema,
    setSaveOnboardingProcessTemplate,
    saveOnboardingProcessTemplate,
    saveOnboardingProcessTemplate,
  );

  const [newDescription, setNewDescription] = useState<DescriptionResource>({
    _id: generateKey('id'),
    description: '',
    order: 1,
  });

  const handleOnNewDescription = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      setNewDescription({ ...newDescription, [name!]: value });
    },
    [newDescription],
  );

  const handleAddNewDescription = () => {
    if (newDescription.description.trim() !== '') {
      const newDescriptions = [...saveOnboardingProcessTemplate.descriptions, newDescription];

      setSaveOnboardingProcessTemplate({ ...saveOnboardingProcessTemplate, descriptions: newDescriptions });
      setNewDescription({
        _id: generateKey('id'),
        description: '',
        order: 1,
      });
    }
  };

  const handleSaveOnboardingProcessItem = async () => {
    setSavingOnboardingProcess(true);

    orderDescriptions();

    if (id) {
      const response = await updateOnboardingProcessTemplate(saveOnboardingProcessTemplate, id);

      if (response) notifier.success('Onboarding process template updated successfully.');
      setSavingOnboardingProcess(false);
      return;
    }
    try {
      const newOnboardingProcessTemplate = await createOnboardingProcessTemplate(saveOnboardingProcessTemplate);

      if (newOnboardingProcessTemplate) {
        notifier.success('Onboarding process template created successfully.');
        history.push(`/onboarding-process-templates/${newOnboardingProcessTemplate._id}`);
      }
    } finally {
      setSavingOnboardingProcess(false);
    }
  };

  const orderDescriptions = () => {
    let order = 1;

    saveOnboardingProcessTemplate.descriptions.forEach((description) => {
      description.order = order++;
    });
  };

  useEffect(() => {
    setEnableAddDescription(newDescription.description.trim() === '');
  }, [newDescription]);

  const handleChange = (event: React.ChangeEvent<{ name: unknown; checked: unknown }>) => {
    const { name, checked } = event.target;

    setIsActive(Boolean(checked));

    setSaveOnboardingProcessTemplate({ ...saveOnboardingProcessTemplate, isActive: !isActive });
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Onboarding Process Template: ${!initialLoading ? saveOnboardingProcessTemplate?.title : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Information</BodyTitle>
          <br />
          <DetailsLine>
            <TextField
              required
              fullWidth
              label="Title"
              variant="outlined"
              inputProps={{ maxLength: 280 }}
              name={'title'}
              value={saveOnboardingProcessTemplate.title}
              {...useInput()}
            />
          </DetailsLine>

          <BodyTitle>Onboarding checklist</BodyTitle>

          <DetailsLineList>
            {saveOnboardingProcessTemplate.descriptions.length ? (
              <>
                <TableTitleContainer>
                  <TableFirstTitleContainer>Title</TableFirstTitleContainer>
                  <TableLastTitleContainer>Action</TableLastTitleContainer>
                </TableTitleContainer>
                {saveOnboardingProcessTemplate.descriptions.map((description, index) => (
                  <OnboardingProcessDescriptionForm
                    key={description._id}
                    description={description}
                    index={index}
                    itemsCount={saveOnboardingProcessTemplate.descriptions.length}
                    saveOnboardingProcessTemplate={saveOnboardingProcessTemplate}
                    setSaveOnboardingProcessTemplate={setSaveOnboardingProcessTemplate}
                  />
                ))}
              </>
            ) : (
              <NoRecords>There are no descriptions to display, try adding a new description. </NoRecords>
            )}
          </DetailsLineList>
          <DetailsLine>
            <TextField
              fullWidth
              rows={4}
              label="Description"
              variant="outlined"
              inputProps={{ maxLength: 5000 }}
              name={'description'}
              value={newDescription.description}
              onChange={handleOnNewDescription}
            />

            <IconContainer>
              <IconButton disabled={enabledAddDescription} color="primary" onClick={handleAddNewDescription}>
                <AddCircleIcon fontSize={'large'} />
              </IconButton>
            </IconContainer>
          </DetailsLine>

          <FormControlLabelOptionContainer
            control={<Switch id={'isActiveSwitch'} name="isActive" checked={isActive} onChange={handleChange} />}
            label="Active"
          />

          {id && (
            <>
              <BodyTitle>Creation information</BodyTitle>
              <br />
              <DetailsLine>
                <TextFieldContainer
                  disabled={true}
                  label="Created by"
                  variant="outlined"
                  value={
                    onboardingProcessTemplate?.createdBy
                      ? onboardingProcessTemplate.createdBy.name + ' ' + onboardingProcessTemplate.createdBy.lastName
                      : ''
                  }
                />
                <TextFieldContainer disabled={true} label="Creation date" variant="outlined" value={creationdate} />
              </DetailsLine>
            </>
          )}

          <ButtonContainer>
            {savingOnboardingProcess && <Spinner size={30} />}
            <Submit disabled={savingOnboardingProcess || loading || !isValid} onClick={handleSaveOnboardingProcessItem}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const TextFieldContainer = styled(TextField)`
  width: 300px;
  padding-right: 20px;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const DetailsLineList = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: -15px;
`;

const TableFirstTitleContainer = styled.h4`
  text-align: left;
  font-size: 20;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  flex: 1;
  padding-left: 85px;
  width: 300px;
  color: ${(p) => p.theme.palette.primary.light};
`;

const TableLastTitleContainer = styled.h4`
  text-align: right;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${(p) => p.theme.palette.primary.light};
  flex: 1;
  padding-right: 25px;
`;
const FormControlLabelOptionContainer = styled(FormControlLabel)`
  margin-left: 10px;
`;

export default OnboardingProcessTemplateForm;
