import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource } from 'shared/types';

import { EmployeeResource, EmployeeResourceCompact } from './employeeService';
import http from './httpService';

const CDP_URL = '/api/careerDevelopmentPlans';

export type CareerDevelopmentPlanFilter = {
  leaders: string[];
  leaderAssigned: string[];
  cdpStatus: string[];
  date: string | null;
  search: string;
};

export type CareerDevelopmentPlanResource = {
  _id: string;
  employee: EmployeeResource;
  leader?: EmployeeResourceCompact;
  leaderAssigned?: EmployeeResourceCompact;
  jobTitle: CatalogResource;
  cdpStatus: CatalogResource;
  period: string;
  creationDate: string;
  categories: Category[];
  ratings: Rating[];
  newJobTitle: string;
  additionalComments: string;
  actionComments: string;
  template: string;
  jobTitleUpdateApproval: string;
  disciplinaryAction: string;
  adminComments: string;
  emailSent: boolean;
};

export type CreateCareerDevelopmentPlanResource = {
  employee: string;
  leaderAssigned?: string;
  cdpStatus: string;
  template: string;
  period: string;
};

export type SaveCareerDevelopmentPlanResource = {
  leaderAssigned: string;
  cdpStatus: string;
  categories: SaveCategory[];
  newJobTitle: string | null | undefined;
  additionalComments: string | null | undefined;
  actionComments: string | null | undefined;
  jobTitleUpdateApproval: string | null | undefined;
  disciplinaryAction: string | null | undefined;
  adminComments: string | null | undefined;
};

export type TemporalSaveCareerDevelopmentPlanResource = {
  leaderAssigned: string;
  cdpStatus: string;
  newJobTitle: string | null | undefined;
  additionalComments: string | null | undefined;
  actionComments: string | null | undefined;
  jobTitleUpdateApproval: string | null | undefined;
  disciplinaryAction: string | null | undefined;
  adminComments: string | null | undefined;
};

export type Category = {
  _id: string;
  title: string;
  description: string;
  position: number;
  rating: string;
  comments: string;
};

export type SaveCategory = {
  title: string;
  description: string;
  position: number;
  rating: string;
  comments: string;
};

export type Rating = {
  order: number;
  description: string;
};

export async function getAllCareerDevelopmentPlans(filter: CareerDevelopmentPlanFilter & Query) {
  const { data } = await http.get<PageResource<CareerDevelopmentPlanResource>>(
    CDP_URL + '/' + http.toQueryString(filter),
  );

  return data;
}

export async function getOldCdpsById(query: Query, id: string) {
  const { data } = await http.get<PageResource<CareerDevelopmentPlanResource>>(
    `${CDP_URL}/${id}/cdps` + http.toQueryString(query),
  );

  return data;
}

export async function getCdpById(id: string) {
  const { data } = await http.get<CareerDevelopmentPlanResource>(`${CDP_URL}/${id}`);

  return data;
}

export async function updateCdp(cdp: SaveCareerDevelopmentPlanResource, id: string) {
  const { data } = await http.put<SaveCareerDevelopmentPlanResource>(`${CDP_URL}/${id}`, cdp);

  return data;
}

export async function createCdp(cdp: CreateCareerDevelopmentPlanResource) {
  const { data } = await http.post<CareerDevelopmentPlanResource>(`${CDP_URL}`, cdp);

  return data;
}

export async function deleteCareerDevelopmentPlan(id: string) {
  const { data } = await http.delete<CareerDevelopmentPlanResource>(`${CDP_URL}/${id}`);

  return data;
}

export async function getPreviousCdpById(id: string | undefined) {
  if (!id) return;
  const { data } = await http.get<CareerDevelopmentPlanResource>(`${CDP_URL}/${id}/previousCdp`);

  return data;
}

export async function sendCareerDevelopmentPlanEmail(id: string) {
  const { data } = await http.get<CareerDevelopmentPlanResource>(`${CDP_URL}/${id}/sendMail`);

  return data;
}

export async function getCDPPDF(id: string) {
  const { data } = await http.get(`${CDP_URL}/${id}/pdf`, { responseType: 'blob' });

  return data;
}

export async function loadAllCdpsFile(query: Query) {
  const { data } = await http.get<any[]>(CDP_URL + '/queryAllCdps.csv' + http.toQueryString(query));

  return data;
}
