import { CircularProgress, TextField, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import SingleSelect from 'components/single-select';
import { useForm } from 'hooks/useForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { updateRewardPayment } from 'services/rewardsPaymentService';
import getBoxBackground from 'shared/style/utilities/getBoxBackground';
import getBoxShadow from 'shared/style/utilities/getBoxShadow';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import Constants from 'utils/constants';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import useRewardPaymentRequest from './hook';

saveInfoInLocalStorage('inputValue', '', '_form');
type Props = {
  rewardId: string;
  statusInTheMainScreen: string;
};

const schemaPaymentRequest = {
  paymentStatusID: yup.string().required().label('Payment Status'),
  paymentDate: yup.string().default('').optional().label('paymentDate').typeError('Invalid date'),
  requestComment: yup.string().default('').optional().label('requestComment'),
};

const PaymentRequest: React.FC<Props> = (props) => {
  const { rewardId, statusInTheMainScreen } = props;
  const [disablePaymentDateInput, setDisablePaymentDateInput] = useState(true);
  const [selectedStatusLabel, setSelectedStatusLabel] = useState<Item>();
  const [disabledSaveButon, setDisabledSaveButon] = useState(true);

  const {
    saveRewardPaymentRequest,
    reward,
    paymentStatus,
    loading,
    selectedStatus,
    hasPermission,
  } = useRewardPaymentRequest(rewardId, statusInTheMainScreen);

  const { useInput, useDateInput, values, isValid, setIsValid } = useForm(
    saveRewardPaymentRequest,
    schemaPaymentRequest,
    loading,
  );
  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />;
  };

  useEffect(() => {
    const statusSelected = paymentStatus.find((item) => {
      return item.id === values.paymentStatusID;
    });

    setSelectedStatusLabel(statusSelected);

    if (selectedStatus !== Constants.REWARD_PENDING_REFUND_STATUS_NAME) {
      setDisabledSaveButon(true);
    } else {
      if (statusSelected?.label === Constants.REWARD_REJECTED_STATUS_NAME) {
        setDisablePaymentDateInput(true);
        values.paymentDate = null;
      } else {
        setDisablePaymentDateInput(false);
      }

      if (statusSelected?.label === Constants.REWARD_REFUNDED_STATUS_NAME && values.paymentDate === null) {
        setDisabledSaveButon(true);
      }

      if (
        statusSelected?.label === Constants.REWARD_REFUNDED_STATUS_NAME &&
        values.paymentDate !== null &&
        values.paymentDate !== ''
      ) {
        setDisabledSaveButon(false);
      }

      if (statusSelected?.label === Constants.REWARD_REJECTED_STATUS_NAME) {
        setDisabledSaveButon(false);
      }
    }
  }, [
    paymentStatus,
    saveRewardPaymentRequest.rewStatusID,
    selectedStatus,
    selectedStatusLabel,
    setIsValid,
    values.requestComment,
    values.paymentDate,
    values.paymentStatusID,
  ]);

  const { isSubmiting, handleUpdateReward: handleUpdateRewardPayment } = useSubmitForm(values, true, rewardId);

  return (
    <Container>
      <Card>
        <FormHeader loading={loading} title={`Payment Request`} />
        <CardBody>
          <FormContainer>
            <RowContainer>
              <SingleSelect
                disabled={!hasPermission || selectedStatus !== Constants.REWARD_PENDING_REFUND_STATUS_NAME ? true : false}
                id={'statusID'}
                required
                label={'Status'}
                options={paymentStatus}
                {...useInput('paymentStatusID')}
              />

              <DateContainer>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <KeyboardDatePicker
                    id={'purchasedDate'}
                    label="Payment Date"
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    disabled={!hasPermission || disablePaymentDateInput}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    defaultValue={null}
                    InputLabelProps={{
                      shrink: values.paymentDate ? true : false,
                    }}
                    TextFieldComponent={TextFieldComponent}
                    required={!disablePaymentDateInput}
                    {...useDateInput('paymentDate')}
                  />
                </Tooltip>
              </DateContainer>
            </RowContainer>
            <TextBody>
              <TextField
                multiline
                fullWidth
                disabled={!hasPermission || selectedStatus !== Constants.REWARD_PENDING_REFUND_STATUS_NAME ? true : false}
                rows={4}
                inputProps={{ maxLength: 5000 }}
                id="requestComment"
                label="Comment"
                variant="outlined"
                {...useInput('requestComment')}
              />
            </TextBody>
            <ButtonContainer>
              {isSubmiting && <Spinner size={30} />}
              {statusInTheMainScreen === Constants.REWARD_PENDING_REFUND_STATUS_NAME ? (
                <Submit disabled={isSubmiting || loading || disabledSaveButon} onClick={handleUpdateRewardPayment}>
                  Save
                </Submit>
              ) : (
                ''
              )}
            </ButtonContainer>
          </FormContainer>
        </CardBody>
      </Card>
    </Container>
  );
};
const useSubmitForm = (values: any, isValid: boolean, rewardId: string) => {
  const history = useHistory();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const handleUpdateRewardPayment = useCallback(async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    setIsSubmitting(true);
    if (rewardId) {
      try {
        if (values.paymentDate === null) values.paymentDate = '';

        const newReward = await updateRewardPayment(values, rewardId);

        if (newReward) {
          notifier.success('Reward updated successfully.');
          history.push(`/rewards/`);
        }
      } catch (error) {
        if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
      }
    }

    setIsSubmitting(false);
  }, [isValid, values, rewardId, history]);

  return { isSubmiting, handleUpdateReward: handleUpdateRewardPayment };
};
const FormContainer = styled.div``;

const TextBody = styled.div`
  margin: 20px;
`;
const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
  float: right;
`;
const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const Container = styled.div``;

const DateContainer = styled.div`
  width: 30%;
  float: left;
  padding-bottom: 7px;
`;

const RowContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: 20px;
`;

const FormHeader = styled(CardHeader)`
  background: ${(p) => getBoxBackground(p.theme.palette.secondary.main, p.theme.palette.secondary.dark)};
  box-shadow: ${(p) => getBoxShadow(p.theme.palette.secondary.main)};
`;

export default PaymentRequest;
