import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource } from 'shared/types';

import http from './httpService';
import { JobTitleResource } from './jobTitleService';
import { ProjectResource } from './projectService';

const EMPLOYEE_URL = '/api/employees';

export type EmployeeFilter = {
  employees: string[];
  leaders: string[];
  projects: string[];
  jobTitles: string;
  onlyLeaders: boolean;
  isActive: boolean;
  search: string;
  salaryPeriodMonth?: string;
  clientSalaryPeriodMonth?: string;
  country?: string;
};
export type EmployeeWithReferFilter = {
  search: string;
  status: string;
};

export type EmployeeResource = {
  _id: string;
  email: string;
  clientEmail: string;
  name: string;
  lastName: string;
  leader?: EmployeeResource;
  isLeader: boolean;
  isActive: boolean;
  project: ProjectResource;
  jobTitle: JobTitleResource;
  birthdayDate?: string | null;
  hireDate?: string | null;
  effectiveHireDate?: string | null;
  authorizedEmployees: string[];

  employeeType: TypeEmployee;
  homeAddress: HomeAddress;
  mobilePhoneNumber: number;
  homePhoneNumber: number;
  legadmiId: number | null;
  plate1: string;
  plate2: string;
  idNumber: string;
  maritalStatus: MaritalStatus;
  children: number;
  insuranceInfo: InsuranceInfo;
  emergencyContacts: EmergencyContacts;
  resignationInfo: ResignationInfo;
  notes: string;
  salaryPeriodMonth: string;
  clientSalaryPeriodMonth: string;
  isTimesheetEnabled: boolean;
  authorizedTimesheets: string[];
  referBy?: EmployeeResource;
  referralAmount: number;
  yearlyBudget: string;
  startBonusAmount: string;
  startBonus: boolean;
  activateRewards: boolean;
  primaryBackendLanguage: string;
  alternativeBackendLanguage: string;
  primaryFrontendLanguage: string;
  alternativeFrontendLanguage: string;
  otherSkills: string;
};

export type EmployeeWithReferResource = {
  _id: string;
  name: string;
  lastName: string;
  hireDate?: string | null;
  referBy?: EmployeeResourceCompact;
  referralAmount: number;
  depositDate: string;
  statusReferral: string;
};

export type EmployeeResourceCompact = {
  _id: string;
  name: string;
  lastName: string;
};

export type SaveEmployeeResource = {
  name: string;
  lastName: string;
  email: string;
  clientEmail: string;
  leader: string;
  isLeader: boolean;
  project: string;
  jobTitle: string;
  isActive?: boolean;
  birthdayDate?: string | null;
  hireDate?: string | null;
  authorizedEmployees: string[];
  homeAddress: HomeAddress;
  employeeType: string;
  mobilePhoneNumber: string;
  homePhoneNumber: string;
  legadmiId: string;
  plate1: string;
  plate2: string;
  idNumber: string;
  maritalStatus: string;
  children: number;
  insuranceInfo: InsuranceInfoFlat;
  emergencyContacts: EmergencyContacts;
  resignationInfo: ResignationInfoFlat;
  isTimesheetEnabled: boolean;
  authorizedTimesheets: string[];
  referBy: string;
  activateRewards: boolean;
  primaryBackendLanguage: string;
  alternativeBackendLanguage: string;
  primaryFrontendLanguage: string;
  alternativeFrontendLanguage: string;
  otherSkills: string;
};

export type HomeAddress = {
  country: string;
  state: string;
  city: string;
  zipCode: string;
  addressLine1: string;
  addressLine2: string;
};

export type MaritalStatus = {
  _id: string;
  name: string;
};

export type TypeEmployee = {
  _id: string;
  name: string;
};

export type InsuranceInfoFlat = {
  status: string;
  inclusionDate: any;
  comments: string;
};

export type InsuranceInfo = {
  status: CatalogResource;
  inclusionDate: any;
  comments: string;
};

export type EmergencyContacts = {
  name1: string;
  phoneNumber1: number;
  relationship1: string;
  name2: string;
  phoneNumber2: number;
  relationship2: string;
};

export type ResignationInfoFlat = {
  resignationReason: string;
  resignationDate: any;
  payrollEndDate: any;
  lastDay: any;
  resignationComments: string;
};

export type ResignationInfo = {
  resignationReason: CatalogResource;
  resignationDate: any;
  payrollEndDate: any;
  lastDay: any;
  resignationComments: string;
};

export type ProjectHistoryResource = {
  employee: string;
  projectFrom: string;
  projectTo: string;
  comments: string;
  effectiveDate: string;
};

export type ProjectHistoryListResource = {
  employee: string;
  projectFrom: ProjectResource;
  projectTo: ProjectResource;
  comments: string;
  effectiveDate: string;
};

export async function getAllEmployees() {
  const { data } = await http.get<EmployeeResource[]>(EMPLOYEE_URL);

  return data;
}
export async function getAllEmployeesWithNoRol() {
  const { data } = await http.get<EmployeeResource[]>(EMPLOYEE_URL + '/noRol');

  return data;
}
export async function getAllEmployeesWithReferBy(filter: EmployeeWithReferFilter & Query) {
  const { data } = await http.get<PageResource<EmployeeWithReferResource>>(
    EMPLOYEE_URL + '/referBy' + http.toQueryString(filter),
  );

  return data;
}
export async function getAllIncludingEmployees() {
  const { data } = await http.get<EmployeeResource[]>(`${EMPLOYEE_URL}/all`);

  return data;
}

export async function getAllLeaders() {
  const { data } = await http.get<EmployeeResource[]>(`${EMPLOYEE_URL}/leaders`);

  return data;
}

export async function getEmployeeById(id: string) {
  const { data } = await http.get<EmployeeResource>(`${EMPLOYEE_URL}/single/${id}`);

  return data;
}

export async function getAllEmployeesQuery(query: Query) {
  const { data } = await http.get<PageResource<EmployeeResource>>(EMPLOYEE_URL + '/query' + http.toQueryString(query));

  return data;
}

export async function updateEmployee(employee: SaveEmployeeResource, id: string) {
  const { data } = await http.put<EmployeeResource>(`${EMPLOYEE_URL}/${id}`, employee);

  return data;
}

export async function createEmployee(employee: SaveEmployeeResource, salary: string, rate: string) {
  const saveEmployeeObject = { ...employee, salary: salary, rate: rate };

  const { data } = await http.post<EmployeeResource>(`${EMPLOYEE_URL}`, saveEmployeeObject);

  return data;
}

export async function deleteEmployee(id: string) {
  const { data } = await http.delete<EmployeeResource>(`${EMPLOYEE_URL}/${id}`);

  return data;
}
export async function deleteReferral(id: string) {
  const { data } = await http.delete<EmployeeResource>(`${EMPLOYEE_URL}/${id}/delete-referral`);

  return data;
}

export async function loadCsvFile(query: Query) {
  const { data } = await http.get<any[]>(EMPLOYEE_URL + '/query.csv' + http.toQueryString(query));

  return data;
}

export async function loadCsvFileAllInfo(query: Query) {
  const { data } = await http.get<any[]>(EMPLOYEE_URL + '/queryAllInfo.csv' + http.toQueryString(query));

  return data;
}

export async function addProjectHistory(projectHistory: ProjectHistoryResource) {
  const { data } = await http.post<ProjectHistoryResource>(`${EMPLOYEE_URL}/projectHistoryChange`, projectHistory);

  return data;
}

export async function getProjectHistoryByEmployeeId(id: string, query: Query) {
  const { data } = await http.get<PageResource<ProjectHistoryResource>>(
    `${EMPLOYEE_URL}/projectHistoryChange/` + http.toQueryString({ ...query, employeeId: id }),
  );

  return data;
}

export async function deleteEmployeeProjectHistory(id: string) {
  const { data } = await http.delete<ProjectHistoryResource>(`${EMPLOYEE_URL}/${id}/delete-project-history`);

  return data;
}
