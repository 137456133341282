import { Column } from 'components/detailed-table/types';
import StatusHighlighter from 'components/status-highlighter';
import React from 'react';
import { rewardHistorical } from 'services/rewardsHistoricalService';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<rewardHistorical>[] = [
  {
    label: 'User',
    sortKey: '',
    path: 'rewardHistorical.name',
    render: (item) => item.user,
  },
  {
    label: 'Status',
    sortKey: '',
    path: 'rewardHistorical.name',
    render: (item) => <StatusHighlighter name={item.status} />,
  },
  {
    label: 'Date',
    sortKey: '',
    path: 'rewardHistorical.date',
    render: (item) => stringDateToHumanFormat(item.date),
  },
];

const columns = () => {
  return [...fixedColumns];
};

export default columns;
