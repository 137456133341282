import { useCallback, useEffect, useState } from 'react';
import {
  DescriptionResource,
  getOnboardingProcessById,
  OnboardingProcessResource,
  SaveOnboardingProcessResource,
} from 'services/onboardingProcessTemplate';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const defaultOnboardingProcessTemplateResource = {
  title: '',
  descriptions: new Array<DescriptionResource>(),
  isActive: true,
};

export const useOnboardingProcessTemplateEffect = (id?: string) => {
  const [saveOnboardingProcessTemplate, setSaveOnboardingProcessTemplate] = useState<SaveOnboardingProcessResource>(
    defaultOnboardingProcessTemplateResource,
  );
  const [onboardingProcessTemplate, setOnboardingProcessTemplate] = useState<OnboardingProcessResource>();
  const [creationdate, setCreationDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const fetchOnboardingProcessTemplate = useCallback(async () => {
    setLoading(true);
    const onboardingProcessTemplateResult:OnboardingProcessResource = await getOnboardingProcessById(id!);

    if (onboardingProcessTemplateResult) {
      setSaveOnboardingProcessTemplate(mapOnboardingProcessTemplateResponse(onboardingProcessTemplateResult));
      setOnboardingProcessTemplate(onboardingProcessTemplateResult);
      setCreationDate(stringDateToHumanFormat(onboardingProcessTemplateResult.creationDate.toString()));
      setIsActive(onboardingProcessTemplateResult.isActive);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchOnboardingProcessTemplate();
  }, [id, fetchOnboardingProcessTemplate]);

  return {
    saveOnboardingProcessTemplate,
    onboardingProcessTemplate,
    creationdate,
    loading,
    setSaveOnboardingProcessTemplate,
    isActive,
    setIsActive,
  };
};

const mapOnboardingProcessTemplateResponse = (onboardingProcessTemplate: OnboardingProcessResource) => {
  return {
    title: onboardingProcessTemplate.title,
    descriptions: onboardingProcessTemplate.descriptions,
    isActive: onboardingProcessTemplate.isActive,
  } as OnboardingProcessResource;
};