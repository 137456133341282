import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDialog from 'components/alert-dialog';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useState } from 'react';
import { ScreenOnboardingProcessFilter } from 'services/screenOnboardingProcessService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';

import columns from './columns';
import Filter from './filter';
import { useCatalogFilter, useScreenOnboardingProcessEffect } from './hooks';

export type SearchFilter = {
  searchText: string;
};

clearLocalStorage(); //Clear local storage on refresh

const ScreenOnboardingProcessList: React.FC = () => {
  const localSearchFilter = localStorage.getItem('search_op');
  const localStatusFilter = localStorage.getItem('status_op');

  const DEFAULT_QUERY: Query & ScreenOnboardingProcessFilter = {
    search: localSearchFilter || '',
    status: localStatusFilter || '',
    pageSize: 20,
    page: 1,
    isSortAscending: 0,
    sortByColumn: 'creationDate',
  };

  const DEFAULT_FILTER: ScreenOnboardingProcessFilter = {
    search: localSearchFilter || '',
    status: localStatusFilter || '',
  };

  const { query, setQuery, handlePageChange, handleColumnSorting } = usePaging(DEFAULT_QUERY);
  const {
    screenOnboardingProcess,
    loading: oneOnOneLoading,
    setLoading,
    fetchScreenOnboardingProcess,
  } = useScreenOnboardingProcessEffect(query);
  const { statusesFilter, loading: catalogLoading } = useCatalogFilter();
  const initialLoading = useInitialLoading(oneOnOneLoading || catalogLoading);
  const isLoading = oneOnOneLoading || catalogLoading;
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_FILTER);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const showConfirmationModal = useCallback(async (opId: string, emailSent: boolean) => {
    setOpenConfirmationDialog(true);
  }, []);

  return (
    <Container>
      <Card>
        <CardHeader
          title="Onboarding Process"
          subTitle="All the onboarding process information"
          newPageRedirectionLink="/onboarding-process-screen/new"
          loading={isLoading}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter
              defaultFilter={defaultFilter}
              setDefaultFilter={setDefaultFilter}
              search={''}
              statusesFilter={statusesFilter}
              setFilter={setQuery}
              filter={query}
            />
            {screenOnboardingProcess?.items?.length ? (
              <StandardTable
                keyColumn="_id"
                data={screenOnboardingProcess.items}
                totalItems={screenOnboardingProcess.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchScreenOnboardingProcess, setLoading, showConfirmationModal)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    text-align: right;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default ScreenOnboardingProcessList;
