/* eslint-disable react/jsx-no-bind */
import { TextField } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SingleSelect from 'components/single-select';
import { monthValues } from 'pages/hr/commonValues';
import React, { useState } from 'react';
import { listItems } from 'shared/types';

import { Column } from '../types';

type Props = {
  data: any[];
  columns: Column[];
  keyColumn?: string;
  editId?: string;
  dropDownOptions: listItems[];
};

const TableContent: React.FC<Props> = (props) => {
  const { data, columns, keyColumn = '_id', editId = '', dropDownOptions } = props;
  const [displayData, setDisplayData] = useState(data);

  function onChange(e: any, item: any, column: Column) {
    const value = e.target.value;
    const attributte = column.path;

    let newRows: any[] = [];

    if (attributte) {
      if (column.path === 'month') {
        const selectedMonth = monthValues.find((month) => month.label === value);

        newRows = displayData.map((row) => {
          if (row._id === item._id) {
            return { ...row, [attributte]: selectedMonth?.id };
          }
          return row;
        });
      } else {
        newRows = displayData.map((row) => {
          if (row._id === item._id) {
            return { ...row, [attributte]: value };
          }
          return row;
        });
      }

      if (newRows.length > 0) setDisplayData(newRows);
    }
  }

  return (
    <TableBody>
      {displayData.map((item) => (
        <TableRow id={`${item[keyColumn]}`} key={`${item[keyColumn]}`} data-testid="table-row-test-id">
          {columns.map((column) =>
            editId !== item._id || column.path === 'edit-delete' ? (
              <TableCell
                data-testid="table-cell-test-id"
                key={`${item[keyColumn]}_${column.label}_cell`}
                style={{ width: '15%' }}
              >
                {renderCell(item, column)}
              </TableCell>
            ) : dropDownOptions.find((option) => option.propertyId === column.path) ? (
              <td key={`${item[keyColumn]}_${column.label}_${column.path}_single`}>
                <SingleSelect
                  id={`${item[keyColumn]}_${column.label}_${column.path}`}
                  label={''}
                  options={dropDownOptions.find((option) => option.propertyId === column.path)?.options || []}
                  value={renderCell(item, column)}
                  required
                  name={`${column.path}`}
                  fullWidth
                  onChange={(e) => onChange(e, item, column)}
                  style={{ marginTop: 4 }}
                />
              </td>
            ) : (
              <td key={`${item[keyColumn]}_${column.label}_edit`}>
                <TextField
                  data-testid="textfield-cell-test-id"
                  variant="outlined"
                  value={renderCell(item, column)}
                  onChange={(e) => onChange(e, item, column)}
                ></TextField>
              </td>
            ),
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

function renderCell(item: any, column: Column) {
  if (column.render) return column.render(item);

  return getValue(item, column.path!);
}

function getValue(value: any, path: string) {
  return path.split('.').reduce((prev, current) => prev[current], value);
}

export default TableContent;
