export const isEmail = (emailValue: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(String(emailValue).toLowerCase())) {
    return true;
  }
  return false;
};

export const phoneRegExp = /^\s*\+?(?:\+?(\d{1,3}))?[-. (]*(\+?\d{1,3})?[-. )]*[-. (]*(\+?\d{1,3})?[-. )]*(\+?\d{0,4})[-. ]*(\d{0,5})(?: *x(\d+))?\s*$/
