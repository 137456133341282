import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { GetApp } from '@material-ui/icons';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SearchIcon from '@material-ui/icons/Search';
import WorkOffIcon from '@material-ui/icons/WorkOffOutlined';
import MultiSelectBase from 'components/multi-select';
import SingleSelectBase from 'components/single-select';
import { Query } from 'hooks/usePaging';
import React, { useCallback, useEffect, useRef } from 'react';
import { TimeSheetFilter } from 'services/timesheetService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

type Props = {
  leaders: Item[];
  projects: Item[];
  months: Item[];
  years: Item[];
  filter: TimeSheetFilter & Query;
  defaultFilter: TimeSheetFilter;
  setDefaultFilter: any;
  setFilter(value: TimeSheetFilter & Query): void;
  handleOnReport: any;
  handleOnDownloadAll: any;
  handleOnPtoDownload: any;
};

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const localStoragePrefix = '_timesheets';

const Filter: React.FC<Props> = (props) => {
  const {
    filter,
    leaders,
    projects,
    months,
    years,
    setFilter,
    defaultFilter,
    setDefaultFilter,
    handleOnReport,
    handleOnDownloadAll,
    handleOnPtoDownload,
  } = props;

  const timeToClearRef = useRef(0);

  useEffect(() => {
    timeToClearRef.current = setTimeout(() => setFilter({ ...filter, ...defaultFilter, page: 1 }), 2000);

    return () => clearTimeout(timeToClearRef.current);
  }, [defaultFilter.search]);

  useEffect(() => {
    setFilter({ ...filter, ...defaultFilter, page: 1 });
  }, [defaultFilter.leaders, defaultFilter.projects, defaultFilter.month, defaultFilter.year]);

  const handleOnChange = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      saveInfoInLocalStorage(name, value as string, localStoragePrefix);
      setDefaultFilter({ ...defaultFilter, [name!]: value, page: 1 });
    },
    [setDefaultFilter, defaultFilter],
  );

  return (
    <Container>
      <TextFieldWrapper
        id={'searchByNameTimehseetInput'}
        inputProps={{
          maxLength: 100,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label={'Search by Name'}
        variant="outlined"
        name="search"
        value={defaultFilter.search}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'leaderSelect'}
        name="leaders"
        options={leaders}
        currentValues={filter.leaders || []}
        label={'Leaders'}
        onChange={handleOnChange}
      />
      <MultiSelect
        id={'projectSelectTimsheet'}
        name="projects"
        options={projects}
        currentValues={filter.projects || []}
        label={'Projects'}
        onChange={handleOnChange}
      />
      <SingleSelect
        id={'monthSelectTimesheet'}
        name="month"
        options={months}
        label={'Month'}
        onChange={handleOnChange}
        value={filter.month}
      />
      <SingleSelect
        id={'yearSelesctTimesheet'}
        name="year"
        options={years}
        label={'Year'}
        onChange={handleOnChange}
        value={filter.year}
      />
      <Tooltip title="Monthly Report">
        <IconButton color="default" onClick={handleOnReport}>
          <ReceiptIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="PTO Monthly Report">
        <IconButton color="default" onClick={handleOnPtoDownload}>
          <WorkOffIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Download All">
        <IconButton color="default" onClick={handleOnDownloadAll}>
          <GetApp />
        </IconButton>
      </Tooltip>
    </Container>
  );
};

const MultiSelect = styled(MultiSelectBase)`
  margin-right: 10px;
`;

const SingleSelect = styled(SingleSelectBase)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 0;
`;

const TextFieldWrapper = styled(TextField)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export default Filter;
