/* eslint-disable react/jsx-no-bind */
import { Divider, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteIcon from '@material-ui/icons/Delete';
import SingleSelect from 'components/single-select';
import React from 'react';
import { KeyResultForHitGoalResource } from 'services/hitgoalProcessService';
import { Item } from 'shared/types';
import styled from 'styled-components';
import * as yup from 'yup';

type Props = {
  keyResultItem: KeyResultForHitGoalResource;
  index: number;
  itemsCount: number;
  keyResultStatus: Item[];
  onMovingTemplateUp: Function;
  onMovingTemplateDown: Function;
  onDeleteKeyResult: Function;
  onValueChange: Function;
};
export const schemaKeyResultItem = yup.object().shape({
  _id: yup.string().required().label('id'),
  keyResult: yup.string().required().label('Key result'),
  source: yup.string().required().label('Source'),
  status: yup.string().required().label('Status'),
});

const KeyResultItemForm: React.FC<Props> = (props) => {
  const {
    keyResultItem,
    index,
    itemsCount,
    onMovingTemplateUp,
    onMovingTemplateDown,
    onDeleteKeyResult,
    keyResultStatus,
    onValueChange,
  } = props;

  return (
    <ParentContainer>
      <Container>
        <div>
          <IconContainerArrows>
            <IconButton color="primary" onClick={() => onMovingTemplateUp(keyResultItem._id)} disabled={index === 0}>
              <UpIcon fontSize={'default'} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => onMovingTemplateDown(keyResultItem._id)}
              disabled={index === itemsCount - 1}
            >
              <DownIcon fontSize={'default'} />
            </IconButton>
          </IconContainerArrows>
        </div>

        <TextFieldsContainer>
          <TextFieldDescriptionContainer
            fullWidth
            label="Key Result"
            variant="outlined"
            name={'keyResult'}
            value={keyResultItem.keyResult}
            error={!keyResultItem.keyResult}
            onChange={(event) => onValueChange(event, keyResultItem._id)}
            required
          />

          <TextFieldDescriptionContainer
            fullWidth
            label="Source"
            variant="outlined"
            name={'source'}
            value={keyResultItem.source}
            error={!keyResultItem.source}
            onChange={(event) => onValueChange(event, keyResultItem._id)}
            required
          />

          <SingleSelectDescriptionContainer>
            <SingleSelect
              options={keyResultStatus}
              label="Status"
              name={'status'}
              value={keyResultItem.status}
              onChange={(event) => onValueChange(event, keyResultItem._id)}
              empty={false}
            />
          </SingleSelectDescriptionContainer>
          <IconContainer>
            <IconButton color="primary" onClick={() => onDeleteKeyResult(keyResultItem._id)}>
              <DeleteIcon fontSize={'large'} />
            </IconButton>
          </IconContainer>
        </TextFieldsContainer>
      </Container>

      <DividerContainer />
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Container = styled.div`
  width: 100%;
  min-width: 800px;
  display: flex;
  flex: 1;
  text-align: left;
  align-items: center;
  margin-bottom: 5px;
`;

const TextFieldsContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 5px;
`;

const TextFieldDescriptionContainer = styled(TextField)`
  width: 40%;
  display: block;
  margin-left: 10px;
`;

export const SingleSelectDescriptionContainer = styled.div`
  display: flex;
  margin-left: 10px;
  width: 14%;
`;

const IconContainer = styled.div`
  margin-left: 5px;
  margin-right: 0px;
  margin-top: -10px;
  width: 2%;
`;

const IconContainerArrows = styled.div`
  margin-left: -30px;
  orientation: vertical;
  display: block;
  width: 30px;
`;

const DividerContainer = styled(Divider)`
  margin-bottom: 5px;
`;

export default KeyResultItemForm;
