import { useCallback, useEffect, useState } from 'react';
import { getJobTitleById, JobTitleResource, SaveJobTitleResource } from 'services/jobTitleService';

const defaultJobTitleResource = {
  name: '',
  order: '1',
  jobFamily: '',
};

export const useJobTitleEffect = (id?: string) => {
  const [jobTitle, setJobTitle] = useState<SaveJobTitleResource>(defaultJobTitleResource);
  const [loading, setLoading] = useState(false);

  const fetchJobTitle = useCallback(async () => {
    setLoading(true);
    const jobTitleResult = await getJobTitleById(id!);

    if (jobTitleResult) {
      setJobTitle(mapJobTitleResponse(jobTitleResult));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchJobTitle();
  }, [id, fetchJobTitle]);

  return { jobTitle, loading };
};

const mapJobTitleResponse = (jobTitle: JobTitleResource) => {
  return {
    name: jobTitle.name,
    order: jobTitle.order.toString(),
    jobFamily: jobTitle.jobFamily,
  } as SaveJobTitleResource;
};
