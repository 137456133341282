import { CatalogResource } from 'shared/types';

import http from './httpService';

const PROCESS_TYPES_URL = '/api/processTypes';

export async function getAllProcessTypes() {
  const { data } = await http.get<CatalogResource[]>(PROCESS_TYPES_URL);

  return data;
}
