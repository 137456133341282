import jwtDecode from 'jwt-decode';
import { ToastOptions } from 'react-toastify';
import { RoleResource } from 'shared/types';
import { isExpected, isForbidden, isUnauthorized } from 'utils/checkStatusCode/checkStatusCode';
import notifier from 'utils/notifiers/notifier';

import httpService from './httpService';

export type UserResource = {
  _id: string;
  name: string;
  lastName: string;
  email: string;
  roles: RoleResource[];
  picture: string;
};

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

export const handleLogin = async (accessToken: string) => {
  const response = await httpService.post<UserResource>('/api/login', { accessToken });
  const token = response.headers['x-auth-token'];

  if (!token) notifier.error('Something went wrong with the login, please try again');
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  window.location.href = '/';
};

export const handleMicrosoftLogin = async (err, data) => {
  if (err) {
    handleLoginFailure(err);
    return;
  }

  const accessToken = data.accessToken;

  const loginData = {
    accountData: data.account,
    accessToken: accessToken,
  };

  try {
    const response = await httpService.post<UserResource>('/api/login/microsoft', loginData);
    const token = response.headers['x-auth-token'];

    if (!token) {
      notifier.error('Something went wrong with the login, please try again');
      return;
    }

    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    window.location.href = '/';
  } catch (err) {
    console.log(err);
  }
};

const handleLoginFailure = (error) => {
  notifier.error('Something went wrong with the login');
};

export const handleLogout = () => {
  sessionStorage.clear();
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.location.href = '/login';
};

export const handleTokenExpired = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.location.href = '/token-expired';
};

export const getJwt = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export function getCurrentUser() {
  try {
    const jwt = getJwt();

    if (!jwt) return null;
    return jwtDecode<UserResource>(jwt);
  } catch (ex) {
    return null;
  }
}

httpService.interceptors.request.use(
  async (req) => {
    const token = getJwt();

    if (token && req.headers) req.headers['x-auth-token'] = token;
    return req;
  },
  async (error) => {
    throw error;
  },
);

httpService.interceptors.response.use(
  (res) => {
    const newToken = res.headers['x-auth-token'];

    if (newToken) localStorage.setItem('ACCESS_TOKEN', res.headers['x-auth-token']);
    return res;
  },
  async (error) => {
    if (!isExpected(error)) {
      notifier.error('Something went wrong, please try again');
    } else if (isForbidden(error)) {
      handleLogout();
      notifier.error('The session has expired, please login again');
    } else if (isUnauthorized(error)) {
      if (error.response && error.response.data && error.response.data === 'Invalid token') {
        handleTokenExpired();
      }
      notifier.error('You are not authorized');
    } else if (isExpected(error)) {
      const toastOptions: ToastOptions = {
        bodyStyle: { whiteSpace: 'pre' },
      };

      notifier.error(error.response.data.friendlyMessage, toastOptions);
    }

    return { data: null };
  },
);

export const handleLoginManual = async (email: string) => {
  const response = await httpService.post<UserResource>('/api/loginManual', { email });
  const token = response.headers['x-auth-token'];

  if (!token) notifier.error('Something went wrong with the login, please try again');
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  window.location.href = '/';
};
