import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import RichTextEditor from 'components/rich-text-editor';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import { useOneOnOneTemplateEffect } from './hooks';
import { BodyTitle, ButtonContainer, Container, Spinner, Submit } from './oneOnOneTemplate.styles';

saveInfoInLocalStorage('inputValue', '', '_form');

const OneOnOneTemplateForm: React.FC = () => {
  const { loading, isSubmiting, handleSaveOneOnOneTemplate, useInput } = useOneOnOneTemplateEffect();

  return (
    <Container>
      <Card>
        <Prompt
          when={
            localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') === 'changed'
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <CardHeader loading={loading} title={'One on One Template'} />
        <CardBody>
          <BodyTitle>Template</BodyTitle>
          <br />
          <RichTextEditor height={'500px'} {...useInput('template')} />
          <ButtonContainer>
            {isSubmiting && <Spinner size={30} />}
            <Submit id={'submitOneOnOnes'} disabled={isSubmiting || loading} onClick={handleSaveOneOnOneTemplate}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

export default OneOnOneTemplateForm;
