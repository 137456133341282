import { createStyles, CssBaseline, makeStyles, Theme } from '@material-ui/core';
import Navbar from 'components/nav-bar';
import Sidebar from 'components/side-bar';
import {
  ROLE_ADMIN,
  ROLE_DEVMANAGER,
  ROLE_FINANCE,
  ROLE_HR,
  ROLE_IT,
  ROLE_LEADER,
  ROLE_REWARDS,
} from 'helpers/roleHelper';
import ActionItemForm from 'pages/action-item-form';
import ActionItemsList from 'pages/action-items-list';
import AuthorizedPeopleList from 'pages/authorized-people';
import CareerDevelopmentPlanCreationForm from 'pages/career-development-plan/career-development-plan-creation-form';
import CareerDevelopmentPlanForm from 'pages/career-development-plan/career-development-plan-form';
import CareerDevelopmentPlanList from 'pages/career-development-plan/career-development-plan-list';
import DashBoardJobTitles from 'pages/dashboard-jobtitles/dashboard-jobtitles-list';
import DevelopmentPlanTemplateForm from 'pages/development-plan-templates/development-plan-template-form';
import DevelopmentPlanTemplateList from 'pages/development-plan-templates/development-plan-templates-list';
import EmployeesForm from 'pages/employees/employees-form';
import EmployeesList from 'pages/employees/employees-list';
import ProjectHistoryList from 'pages/employees/project-history-list';
import ExitProcedureTemplateForm from 'pages/exit-procedure-templates/exit-procedure-template-form';
import ExitProcedureTemplateList from 'pages/exit-procedure-templates/exit-procedure-template-list';
import HitgoalProcessList from 'pages/hitgoal-process/hitgoal-process-list';
import HitgoalsCategoryForm from 'pages/hitgoals/categories/categories-form';
import HitgoalsCategoriesList from 'pages/hitgoals/categories/categories-list';
import HitgoalTemplatesForm from 'pages/hitgoals/hitgoal-templates/template-form';
import HitgoalTemplatesList from 'pages/hitgoals/hitgoal-templates/template-list';
import HitgoalsReasonForm from 'pages/hitgoals/reason/reason-form';
import HitgoalsReasonsList from 'pages/hitgoals/reason/reason-list';
import HitgoalsTypeForm from 'pages/hitgoals/types/types-form';
import HitgoalsTypesList from 'pages/hitgoals/types/types-list';
import Anniversaries from 'pages/hr/anniversaries';
import Birthdays from 'pages/hr/birthdays';
import InsuranceList from 'pages/hr/insurance';
import JobTitleForm from 'pages/jobtitles/jobtitles-form';
import JobTitleList from 'pages/jobtitles/jobtitles-list';
import Login from 'pages/login';
import LoginManual from 'pages/login-manual';
import OnboardingProcessTemplateForm from 'pages/onboarding-process-templates/onboarding-process-template-form';
import OnboardingProcessTemplateList from 'pages/onboarding-process-templates/onboarding-process-template-list';
import OneOnOneReportList from 'pages/one-on-one-reports';
import OneOnOneCreationForm from 'pages/one-on-ones/one-on-one-creation-form';
import OneOnOneForm from 'pages/one-on-ones/one-on-one-form';
import OneOnOne from 'pages/one-on-ones/one-on-one-list';
import ProcessForm from 'pages/process-form';
import ProcessesList from 'pages/processes-list';
import ProjectForm from 'pages/projects/projects-form';
import ProjectList from 'pages/projects/projects-list';
import ReferralsForm from 'pages/referrals/referrals-form';
import ReferralsList from 'pages/referrals/referrals-list';
import RewardsCreationForm from 'pages/rewards/rewards-creation-form';
import RewardsList from 'pages/rewards/rewards-list';
import RateForm from 'pages/salaries/rates-form';
import RateList from 'pages/salaries/rates-list';
import SalaryForm from 'pages/salaries/salaries-form';
import SalaryList from 'pages/salaries/salaries-list';
import ScreenExitProcedureCreationForm from 'pages/screen-exit-procedure/screen-exit-procedure-creation-form';
import ScreenExitProcedureForm from 'pages/screen-exit-procedure/screen-exit-procedure-form';
import ScreenExitProcedureList from 'pages/screen-exit-procedure/screen-exit-procedure-list';
import ScreenOnboardingProcessCreationForm from 'pages/screen-onboarding-process/screen-onboarding-process-creation-form';
import ScreenOnboardingProcessForm from 'pages/screen-onboarding-process/screen-onboarding-process-form';
import ScreenOnboardingProcessList from 'pages/screen-onboarding-process/screen-onboarding-process-list';
import TimesheetEmployee from 'pages/timesheets/employee';
import TimeSheetManagementForm from 'pages/timesheets/timesheet-management';
import TimeSheetSummaryForm from 'pages/timesheets/timesheet-summary';
import TokenExpired from 'pages/token-expired';
import UsersForm from 'pages/users/users-form';
import UsersList from 'pages/users/users-list';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch, spring } from 'react-router-transition';
import styled, { createGlobalStyle } from 'styled-components';

import HitgoalCreationForm from '../../pages/hitgoal-process/hitgoal/hitgoal-form';
import OneOnOneTemplateForm from '../../pages/one-on-one-template-form/index';
import { privateRoutesNavBar } from '../side-bar/routes';
import { useWindowSize } from './hooks';
import PrivateRoute from './privateRoute';

const getDrawerWidth = (currentWidth: number) => {
  let panelWidth = currentWidth * 0.2;

  //Set mobile size.
  if (currentWidth < 600) panelWidth = 200;
  //Max bar size
  else if (panelWidth > 310) panelWidth = 310;

  return panelWidth as number;
};

const Routing: React.FC = () => {
  const user = useCurrentUser();
  const navBarRoutes = Boolean(user) ? privateRoutesNavBar : [];
  const [mobileOpen, setMobileOpen] = useState(false);
  const [windowWidth] = useWindowSize();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${getDrawerWidth(windowWidth)}px)`,
          marginLeft: getDrawerWidth(windowWidth),
        },
      },
    }),
  );

  const classes = useStyles();

  return (
    <RouterPanel>
      <GlobalStyles />
      <CssBaseline />
      <div>
        <Sidebar
          logoText="DevSimple"
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          drawerWidth={getDrawerWidth(windowWidth)}
        />
      </div>
      <MainPanel className={classes.content}>
        <Navbar userInfo={user} routes={navBarRoutes} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <AnimatedSwitch {...animation} runOnMount>
          <Route path="/login" component={Login} />
          <Route path="/login-manual" component={LoginManual} />
          <PrivateRoute
            exact
            path="/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE, ROLE_LEADER, ROLE_DEVMANAGER, ROLE_IT]}
            component={DashBoardJobTitles}
          />
          <PrivateRoute
            exact
            path="/employee-jobtitles"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_FINANCE, ROLE_DEVMANAGER, ROLE_IT]}
            component={DashBoardJobTitles}
          />
          <PrivateRoute
            exact
            path="/one-on-ones"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={OneOnOne}
          />
          <PrivateRoute
            exact
            path="/one-on-ones/new/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={OneOnOneCreationForm}
          />
          <PrivateRoute
            exact
            path="/one-on-ones/:id"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={OneOnOneForm}
          />
          <PrivateRoute exact path="/one-on-one-template/" roles={[ROLE_ADMIN]} component={OneOnOneTemplateForm} />
          <PrivateRoute
            exact
            path="/action-items"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={ActionItemsList}
          />
          <PrivateRoute
            exact
            path="/one-on-one-reports"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_DEVMANAGER]}
            component={OneOnOneReportList}
          />
          <PrivateRoute
            exact
            path="/action-items/new/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={ActionItemForm}
          />
          <PrivateRoute
            exact
            path="/action-items/:id"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={ActionItemForm}
          />
          <PrivateRoute
            exact
            path="/process-assignment"
            roles={[ROLE_ADMIN, ROLE_DEVMANAGER]}
            component={ProcessesList}
          />
          <PrivateRoute
            exact
            path="/process-assignment/new/"
            roles={[ROLE_ADMIN, ROLE_DEVMANAGER]}
            component={ProcessForm}
          />
          <PrivateRoute
            exact
            path="/process-assignment/new/:employeeId/:processTypeId"
            roles={[ROLE_ADMIN, ROLE_DEVMANAGER]}
            component={ProcessForm}
          />
          <PrivateRoute
            exact
            path="/process-assignment/:id"
            roles={[ROLE_ADMIN, ROLE_DEVMANAGER]}
            component={ProcessForm}
          />
          <PrivateRoute
            exact
            path="/authorized-people"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE, ROLE_DEVMANAGER]}
            component={AuthorizedPeopleList}
          />
          <PrivateRoute exact path="/employees" roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]} component={EmployeesList} />
          <PrivateRoute
            exact
            path="/employees/new/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={EmployeesForm}
          />
          <PrivateRoute
            exact
            path="/employees/:id"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={EmployeesForm}
          />
          <PrivateRoute exact path="/projects" roles={[ROLE_ADMIN, ROLE_FINANCE]} component={ProjectList} />
          <PrivateRoute exact path="/projects/new/" roles={[ROLE_ADMIN, ROLE_FINANCE]} component={ProjectForm} />
          <PrivateRoute exact path="/projects/:id" roles={[ROLE_ADMIN, ROLE_FINANCE]} component={ProjectForm} />
          <PrivateRoute exact path="/jobtitles" roles={[ROLE_ADMIN]} component={JobTitleList} />
          <PrivateRoute exact path="/jobtitles/new/" roles={[ROLE_ADMIN]} component={JobTitleForm} />
          <PrivateRoute exact path="/jobtitles/:id" roles={[ROLE_ADMIN]} component={JobTitleForm} />
          <PrivateRoute exact path="/birthdays/" roles={[ROLE_ADMIN, ROLE_HR]} component={Birthdays} />
          <PrivateRoute exact path="/anniversaries/" roles={[ROLE_ADMIN, ROLE_HR]} component={Anniversaries} />
          <PrivateRoute exact path="/insurances/" roles={[ROLE_ADMIN, ROLE_HR]} component={InsuranceList} />
          <PrivateRoute
            exact
            path="/employees/:employeeId/salaries/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={SalaryList}
          />
          <PrivateRoute
            exact
            path="/employees/:id/salaries/:salaryId/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={SalaryForm}
          />
          <PrivateRoute
            exact
            path="/employees/:id/salaries/new/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={SalaryForm}
          />
          <PrivateRoute
            exact
            path="/employees/:employeeId/rates/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={RateList}
          />
          <PrivateRoute
            exact
            path="/employees/:id/rates/:rateId/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={RateForm}
          />
          <PrivateRoute
            exact
            path="/employees/:id/rates/new/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={RateForm}
          />
          <PrivateRoute
            exact
            path="/employees/:employeeId/project-history/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={ProjectHistoryList}
          />
          <PrivateRoute
            exact
            path="/development-plan-templates/"
            roles={[ROLE_ADMIN]}
            component={DevelopmentPlanTemplateList}
          />
          <PrivateRoute
            exact
            path="/development-plan-templates/new"
            roles={[ROLE_ADMIN]}
            component={DevelopmentPlanTemplateForm}
          />
          <PrivateRoute
            exact
            path="/development-plan-templates/:id"
            roles={[ROLE_ADMIN]}
            component={DevelopmentPlanTemplateForm}
          />
          <PrivateRoute
            exact
            path="/onboarding-process-templates/"
            roles={[ROLE_ADMIN, ROLE_HR]}
            component={OnboardingProcessTemplateList}
          />
          <PrivateRoute
            exact
            path="/onboarding-process-templates/new"
            roles={[ROLE_ADMIN, ROLE_HR]}
            component={OnboardingProcessTemplateForm}
          />
          <PrivateRoute
            exact
            path="/onboarding-process-templates/:id"
            roles={[ROLE_ADMIN, ROLE_HR]}
            component={OnboardingProcessTemplateForm}
          />
          <PrivateRoute
            exact
            path="/career-development-plan/"
            roles={[ROLE_ADMIN, ROLE_LEADER, ROLE_HR, ROLE_FINANCE, ROLE_DEVMANAGER]}
            component={CareerDevelopmentPlanList}
          />
          <PrivateRoute
            exact
            path="/career-development-plan/new"
            roles={[ROLE_ADMIN, ROLE_LEADER, ROLE_HR, ROLE_FINANCE, ROLE_DEVMANAGER]}
            component={CareerDevelopmentPlanCreationForm}
          />
          <PrivateRoute
            exact
            path="/career-development-plan/:id"
            roles={[ROLE_ADMIN, ROLE_LEADER, ROLE_HR, ROLE_FINANCE, ROLE_DEVMANAGER]}
            component={CareerDevelopmentPlanForm}
          />
          <PrivateRoute
            exact
            path="/hitGoals-process/"
            roles={[ROLE_ADMIN, ROLE_DEVMANAGER, ROLE_LEADER]}
            component={HitgoalProcessList}
          />
          <PrivateRoute
            exact
            path="/hitGoals-process/new"
            roles={[ROLE_ADMIN, ROLE_DEVMANAGER, ROLE_LEADER]}
            component={HitgoalCreationForm}
          />
          <PrivateRoute
            exact
            path="/hitGoals-process/:id"
            roles={[ROLE_ADMIN, ROLE_DEVMANAGER, ROLE_LEADER]}
            component={HitgoalCreationForm}
          />
          <PrivateRoute
            exact
            path="/rewards/"
            roles={[ROLE_ADMIN, ROLE_FINANCE, ROLE_REWARDS]}
            component={RewardsList}
          />
          <PrivateRoute
            exact
            path="/rewards/new"
            roles={[ROLE_ADMIN, ROLE_FINANCE, ROLE_REWARDS]}
            component={RewardsCreationForm}
          />
          <PrivateRoute
            exact
            path="/rewards/:id"
            roles={[ROLE_ADMIN, ROLE_FINANCE, ROLE_REWARDS]}
            component={RewardsCreationForm}
          />
          <PrivateRoute
            exact
            path="/onboarding-process-screen/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_IT]}
            component={ScreenOnboardingProcessList}
          />
          <PrivateRoute
            exact
            path="/onboarding-process-screen/new"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_IT]}
            component={ScreenOnboardingProcessCreationForm}
          />
          <PrivateRoute
            exact
            path="/onboarding-process-screen/:id"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_IT]}
            component={ScreenOnboardingProcessForm}
          />
          <PrivateRoute
            exact
            path="/referrals/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={ReferralsList}
          />
          <PrivateRoute
            exact
            path="/referrals/:id"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_FINANCE]}
            component={ReferralsForm}
          />
          <PrivateRoute
            exact
            path="/exit-procedure-process/"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_IT]}
            component={ScreenExitProcedureList}
          />
          <PrivateRoute
            exact
            path="/exit-procedure-process/new"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_IT]}
            component={ScreenExitProcedureCreationForm}
          />
          <PrivateRoute
            exact
            path="/exit-procedure-process/:id"
            roles={[ROLE_ADMIN, ROLE_HR, ROLE_IT]}
            component={ScreenExitProcedureForm}
          />

          <PrivateRoute exact path="/users/" roles={[ROLE_ADMIN]} component={UsersList} />
          <PrivateRoute exact path="/users/new" roles={[ROLE_ADMIN]} component={UsersForm} />
          <PrivateRoute exact path="/users/:id" roles={[ROLE_ADMIN]} component={UsersForm} />

          <PrivateRoute
            exact
            path="/timesheet-management"
            roles={[ROLE_ADMIN, ROLE_FINANCE, ROLE_HR]}
            component={TimeSheetManagementForm}
          />
          <PrivateRoute
            exact
            path="/timesheets-summary"
            roles={[ROLE_ADMIN, ROLE_FINANCE, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={TimeSheetSummaryForm}
          />
          <PrivateRoute
            exact
            path="/timesheets-summary/:id"
            roles={[ROLE_ADMIN, ROLE_FINANCE, ROLE_HR, ROLE_LEADER, ROLE_DEVMANAGER]}
            component={TimesheetEmployee}
          />

          <PrivateRoute exact path="/reason/" roles={[ROLE_ADMIN]} component={HitgoalsReasonsList} />
          <PrivateRoute exact path="/reason/new" roles={[ROLE_ADMIN]} component={HitgoalsReasonForm} />
          <PrivateRoute exact path="/reason/:id" roles={[ROLE_ADMIN]} component={HitgoalsReasonForm} />

          <PrivateRoute exact path="/categories/" roles={[ROLE_ADMIN]} component={HitgoalsCategoriesList} />
          <PrivateRoute exact path="/categories/new" roles={[ROLE_ADMIN]} component={HitgoalsCategoryForm} />
          <PrivateRoute exact path="/categories/:id" roles={[ROLE_ADMIN]} component={HitgoalsCategoryForm} />

          <PrivateRoute exact path="/key-result-types/" roles={[ROLE_ADMIN]} component={HitgoalsTypesList} />
          <PrivateRoute exact path="/key-result-types/new" roles={[ROLE_ADMIN]} component={HitgoalsTypeForm} />
          <PrivateRoute exact path="/key-result-types/:id" roles={[ROLE_ADMIN]} component={HitgoalsTypeForm} />

          <PrivateRoute
            exact
            path="/hitGoal-templates/"
            roles={[ROLE_ADMIN, ROLE_LEADER]}
            component={HitgoalTemplatesList}
          />
          <PrivateRoute
            exact
            path="/hitGoal-templates/new"
            roles={[ROLE_ADMIN, ROLE_LEADER]}
            component={HitgoalTemplatesForm}
          />
          <PrivateRoute
            exact
            path="/hitGoal-templates/:id"
            roles={[ROLE_ADMIN, ROLE_LEADER]}
            component={HitgoalTemplatesForm}
          />
          <PrivateRoute
            exact
            path="/exit-procedure-templates/"
            roles={[ROLE_ADMIN, ROLE_HR]}
            component={ExitProcedureTemplateList}
          />
          <PrivateRoute
            exact
            path="/exit-procedure-templates/new"
            roles={[ROLE_ADMIN, ROLE_HR]}
            component={ExitProcedureTemplateForm}
          />
          <PrivateRoute
            exact
            path="/exit-procedure-templates/:id"
            roles={[ROLE_ADMIN, ROLE_HR]}
            component={ExitProcedureTemplateForm}
          />

          <Route path="/token-expired" component={TokenExpired} />
          <Route component={NotFound} />
        </AnimatedSwitch>
      </MainPanel>
    </RouterPanel>
  );
};

const popConfig = { stiffness: 360, damping: 25 };

const animation = {
  atEnter: {
    transitionIndex: 0,
    scale: 0.8,
    opacity: 0,
  },
  atLeave: {
    transitionIndex: 2,
    scale: spring(0.8, popConfig),
    opacity: spring(0, popConfig),
  },
  atActive: {
    scale: spring(1, popConfig),
    opacity: 1,
    transitionIndex: 1,
  },
  mapStyles: (styles) => ({
    position: styles.transitionIndex <= 1 ? 'relative' : 'absolute',
    width: '100%',
    height: '100%',
    transform: `scale(${styles.scale})`,
    opacity: styles.opacity,
  }),
};

const RouterPanel = styled(Router)`
  display: 'flex';
`;

//TODO: Move later and create
const NotFound: React.FC = () => {
  return <p>Not Found</p>;
};

const MainPanel = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  max-height: 100%;
`;

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${(p) => p.theme.palette.background.paper};
  }

  body, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 300;
    line-height: 1.5em;

  }

  .Toastify__toast-container {
    width: 320px;
    }

  .Toastify__toast--default {
      background: #fff;
      color: #aaa;
  }

  .Toastify__toast--success {
    background: ${(p) => p.theme.palette.success.main};
  };
`;

export default Routing;
