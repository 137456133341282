/* eslint-disable react/jsx-no-bind */
import { Divider, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useState } from 'react';
import { KeyResultForTemplateResource } from 'services/hitgoalsService';
import styled from 'styled-components';
import * as yup from 'yup';

import { useForm } from '../useForm';

type Props = {
  keyResultItem: KeyResultForTemplateResource;
  keyResults: KeyResultForTemplateResource[];
  setKeyResults: Function;
  index: number;
  itemsCount: number;
  onKeyResultsChange: Function;
};

export const schemaKeyResultItem = yup.object().shape({
  _id: yup.string().required().label('id'),
  keyResult: yup.string().required().label('Key result'),
  source: yup.string().required().label('Source'),
});

const schemaKeyResult = {
  keyResult: yup.string().required().label('Key result'),
  source: yup.string().required().label('Source'),
};

const KeyResultItemForm: React.FC<Props> = (props) => {
  const { keyResultItem, keyResults, setKeyResults, index, itemsCount, onKeyResultsChange } = props;
  const [ownKeyResult, setOwnKeyResult] = useState(keyResultItem);
  const { useInput } = useForm(schemaKeyResult, setOwnKeyResult, ownKeyResult, keyResultItem);

  const handleDelete = useCallback(() => {
    const newKeyResults = keyResults.filter((_, i) => i !== index);

    setKeyResults(newKeyResults);
    onKeyResultsChange();
  }, [keyResults, setKeyResults, onKeyResultsChange, index]);

  const handleMovingUp = useCallback(() => {
    const tmp = keyResults[index - 1];

    keyResults[index - 1] = keyResults[index];
    keyResults[index] = tmp;

    setKeyResults([...keyResults]);
    onKeyResultsChange();
  }, [keyResults, index, setKeyResults, onKeyResultsChange]);

  const handleMovingDown = useCallback(() => {
    const tmp = keyResults[index + 1];

    keyResults[index + 1] = keyResults[index];
    keyResults[index] = tmp;
    setKeyResults([...keyResults]);
    onKeyResultsChange();
  }, [keyResults, index, setKeyResults, onKeyResultsChange]);

  useEffect(() => {
    if (ownKeyResult.source === '') return;
    if (ownKeyResult !== keyResultItem) onKeyResultsChange();
  }, [ownKeyResult]);

  return (
    <ParentContainer>
      <Container>
        <div>
          <IconContainerArrows>
            <IconButton color="primary" onClick={handleMovingUp} disabled={index === 0}>
              <UpIcon fontSize={'default'} />
            </IconButton>
            <IconButton color="primary" onClick={handleMovingDown} disabled={index === itemsCount - 1}>
              <DownIcon fontSize={'default'} />
            </IconButton>
          </IconContainerArrows>
        </div>

        <TextFieldsContainer>
          <TextFieldDescriptionContainer
            fullWidth
            label="Key Result"
            variant="outlined"
            name={'keyResult'}
            value={ownKeyResult.keyResult}
            {...useInput()}
          />

          <TextFieldDescriptionContainer
            fullWidth
            label="source"
            variant="outlined"
            name={'source'}
            value={ownKeyResult.source}
            {...useInput()}
          />
          <IconContainer>
            <IconButton color="primary" onClick={handleDelete}>
              <DeleteIcon fontSize={'large'} />
            </IconButton>
          </IconContainer>
        </TextFieldsContainer>
      </Container>

      <DividerContainer />
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  text-align: left;
  align-items: center;
  margin-bottom: 5px;
`;

const TextFieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 5px;
`;

const TextFieldDescriptionContainer = styled(TextField)`
  width: 47%;
  display: block;
  margin-left: 10px;
`;

const IconContainer = styled.div`
  margin-left: 5px;
  margin-right: 0px;
  margin-top: -10px;
  width: 2%;
`;

const IconContainerArrows = styled.div`
  margin-left: -30px;
  orientation: vertical;
  display: block;
  width: 30px;
`;

const DividerContainer = styled(Divider)`
  margin-bottom: 5px;
`;

export default KeyResultItemForm;
