/* eslint-disable react-hooks/exhaustive-deps */
import { setLoading } from 'components/table-row-action/tests/testMethods';
import { saveAs } from 'file-saver';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { UserResource } from 'services/authService';
import { getEmployeeById } from 'services/employeeService';
import { getProjects } from 'services/projectService';
import {
  deleteFile,
  getTimesheetById,
  getTimesheetsByEmployeePeriodProject,
  getTimesheetsDaySubTypes,
  getTimesheetsDayTypes,
  loadTimeSheetFile,
  TimesheetMonth,
  updateTimesheet,
  uploadFile,
} from 'services/timesheetService';
import { Item } from 'shared/types';
import config from 'utils/config';
import constants from 'utils/constants';
import {
  calculateDaysLeft,
  checkAndTransformDate,
  getLastBussinessDay,
  stringDateToHumanFormatMonthName,
} from 'utils/formatters/date';
import { isFileSizeValid, sortDateAsc } from 'utils/general/generalMethods';
import { collectionToItem } from 'utils/mappers/item';
import notifier from 'utils/notifiers/notifier';
import * as XLSX from 'xlsx';

const defaultTimesheetMonth: TimesheetMonth = {
  _id: '',
  period: new Date(),
  employee: '',
  project: undefined,
  timeSpent: 0,
  timeRemaining: 0,
  overtime: 0,
  otherTime: 0,
  holidayTime: 0,
  days: [],
};

export const useEmployeeCatalogFilter = (id: string) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<Item[]>([]);
  const [dayTypes, setDayTypes] = useState<Item[]>([]);
  const [daySubTypes, setDaySubTypes] = useState<Item[]>([]);
  const [availablePeriods, setAvailablePeriods] = useState<Item[]>([]);
  const [employeeName, setEmployeeName] = useState('');

  useEffect(() => {
    async function loadCatalog() {
      const newProjects = await getProjects();
      const dayTypes = await getTimesheetsDayTypes();
      const daySubTypes = await getTimesheetsDaySubTypes();
      const employeeInfo = await getEmployeeById(id);

      setAvailablePeriods(getAvailablePeriods());
      setProjects(
        collectionToItem(newProjects.filter((project) => employeeInfo.authorizedTimesheets.includes(project._id))),
      );
      // eslint-disable-next-line array-callback-return
      dayTypes.find((type) => {
        if (type.name === constants.VACATION_DAY_TYPE) type.name = 'Vacation/PTO';
        else if (type.name === constants.SICK_DAY_TYPE) type.name = 'Sick Day';
      });
      const dayTypesList = collectionToItem(dayTypes);
      const daySubTypesList = collectionToItem(daySubTypes);

      setDayTypes(dayTypesList);
      setDaySubTypes(daySubTypesList);
      setEmployeeName(employeeInfo.name + ' ' + employeeInfo.lastName);
      setLoading(false);
    }
    loadCatalog();
  }, [id]);

  return { loading, projects, dayTypes, daySubTypes, availablePeriods, employeeName };
};

export const useTimesheetEmployeeEffect = (employee: string, user: UserResource, timesheetId?: string) => {
  const [timesheetMonth, setTimesheetMonth] = useState<TimesheetMonth>(defaultTimesheetMonth);
  const [loadingTimesheet, setLoadingTimesheet] = useState(true);
  const [loadingFile, setLoadingFile] = useState(false);
  const [dateSelected, setDateSelected] = useState(moment.utc().date(1).startOf('day').toString());
  const [employeeId, setEmployeeId] = useState(employee);
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [registeredHours, setRegisteredHours] = useState('');
  const [remainingHours, setRemainingHours] = useState('');
  const [overtimeHours, setOvertimeHours] = useState('');
  const [otherTimeHours, setOtherTimeHours] = useState('');
  const [deadLineDate, setDeadLineDate] = useState('');
  const [daysLeft, setDaysLeft] = useState('- days left');
  const [holidayHours, setHolidayHours] = useState('');
  const [pubicURL, setPublicURL] = useState('');
  const [projectAllowsHolidays, setProjectAllowsHolidays] = useState(false);
  const [projectAllowsVacations, setProjectAllowsVacations] = useState(false);
  const [file, setFile] = useState<ArrayBuffer | undefined>();
  const [fileName, setFileName] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openUploadFileConfirmationDialog, setOpenUploadFileConfirmationDialog] = useState(false);
  const [openDeleteFileConfirmationDialog, setOpenDeleteFileConfirmationDialog] = useState(false);
  const [timesheetClosed, setTimesheetClosed] = useState(false);
  const [jsonDataFromFile, setJsonDataFromFile] = useState('');
  const [displayUploadFileOption, setDisplayUploadFileOption] = useState(true);

  const handleUploadFile = async (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      setFile(selectedFile);
      setFileName(selectedFile.name);
      setOpenUploadFileConfirmationDialog(true);
    }
  };

  const handleDeleteFile = () => {
    setOpenDeleteFileConfirmationDialog(true);
  };

  const handleOnCancelUpload = () => {
    setFileName('');
  };

  const handleDownloadFile = async () => {
    setLoadingFile(true);
    const data = await loadTimeSheetFile(timesheetMonth._id);

    if (data) {
      const pdfBlob = new Blob([data]);
      const pdfName = fileName;

      saveAs(pdfBlob, pdfName);
    } else {
      notifier.error(constants.ERROR_FILE_DOWNLOAD);
    }

    setLoadingFile(false);
  };

  const uploadFileConfirmation = async () => {
    const isValid = isFileSizeValid(file);

    setLoadingFile(true);
    setOpenUploadFileConfirmationDialog(false);

    if (!isValid) {
      setFileName('');
      setLoadingFile(false);
      return;
    }

    const response = await uploadFile(file, timesheetMonth._id);

    if (response) {
      setFileName(response.newFileName);
      notifier.success(response.message);
    } else {
      notifier.error(response.message || constants.ERROR_GENERIC);
    }

    setLoadingFile(false);
  };

  const deleteFileConfirmation = async () => {
    setOpenDeleteFileConfirmationDialog(false);
    setLoadingFile(true);
    const result = await deleteFile(timesheetMonth._id);

    setLoadingFile(false);

    if (result && result.uploadedFileName === '') {
      notifier.success(constants.MSG_FILE_DELETED_SUCCESSFULLY);
      setFileName('');
    } else {
      notifier.error(constants.ERROR_GENERIC);
    }
  };

  const fetchTimesheet = useCallback(async () => {
    setLoadingTimesheet(true);

    if (!dateSelected) {
      setTimesheetMonth(defaultTimesheetMonth);
      return;
    }

    let timeSheetResponse: any;

    if (!timesheetId && !employee) {
      if (!employeeId) {
        const employeeResponse = await getEmployeeById(user._id);

        setEmployeeId(employeeResponse._id);
        setProjectId(employeeResponse.project._id);
        setProjectAllowsHolidays(employeeResponse.project.holidays);
        setProjectAllowsVacations(employeeResponse.project.vacations);
        setProjectName(employeeResponse.project.name);
        setDisplayUploadFileOption(employeeResponse.project.allowTimsheetImport || false);

        timeSheetResponse = await getTimesheetsByEmployeePeriodProject(
          employeeResponse._id,
          dateSelected,
          employeeResponse.project._id,
        );
      } else {
        timeSheetResponse = await getTimesheetsByEmployeePeriodProject(employeeId, dateSelected, projectId);
      }
    } else if (timesheetId) {
      timeSheetResponse = await getTimesheetById(timesheetId);
      const timsheetDate = moment.utc(timeSheetResponse.period).set('date', 1).startOf('day');

      setDateSelected(timsheetDate.toString());
    }

    if (timeSheetResponse) {
      setRegisteredHours(timeSheetResponse.timeSpent.toString() + 'Hrs');
      setRemainingHours(timeSheetResponse.timeRemaining.toString() + 'Hrs');
      setOvertimeHours(timeSheetResponse.overtime.toString() + 'Hrs');
      setOtherTimeHours(timeSheetResponse.otherTime.toString() + 'Hrs');
      setHolidayHours(timeSheetResponse.holidayTime.toString() + 'Hrs');

      const deadLine = getLastBussinessDay(moment.utc(timeSheetResponse.period).endOf('month'));
      const daysLeft = calculateDaysLeft(deadLine, moment.utc());

      if (!employee) {
        const shouldCloseTimesheet = calculateDeadLineTime(deadLine);

        if (shouldCloseTimesheet) {
          setTimesheetClosed(true);
        } else {
          setTimesheetClosed(false);
        }
      }

      setDeadLineDate(stringDateToHumanFormatMonthName(deadLine));
      setDaysLeft(daysLeft > 0 ? '' + daysLeft + ' days left' : '0 days left');

      const currentMonthinfo =
        deadLine.utc().month() === moment().utc().month() ? '' : '&month=' + (deadLine.month() + 1);

      setPublicURL(
        `${config.baseUrl}/api/timesheets/pdf-download?client=${timeSheetResponse.project.name}&id=${timeSheetResponse.employee._id}${currentMonthinfo}`,
      );
    } else {
      setRegisteredHours('-');
      setRemainingHours('-');
      setOvertimeHours('-');
      setOtherTimeHours('-');
    }
    timeSheetResponse.days?.sort(sortDateAsc);

    setTimesheetMonth(timeSheetResponse);
    setFileName(timeSheetResponse.uploadedFileName || '');

    if (timeSheetResponse.project) {
      setProjectId(timeSheetResponse.project._id);
      setDisplayUploadFileOption(timeSheetResponse.project.allowTimsheetImport || false);
      setProjectName(timeSheetResponse.project.name);
      setProjectAllowsHolidays(timeSheetResponse.project.holidays);
      setProjectAllowsVacations(timeSheetResponse.project.vacations);
    }

    setLoadingTimesheet(false);
  }, [dateSelected, employee, employeeId, projectId, timesheetId, user._id]);

  useEffect(() => {
    fetchTimesheet();
  }, [fetchTimesheet]);

  const handleConvertUploadedFile = (uploadedFile: any, setUploadedFile?: Function) => {
    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result || null;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        setJsonDataFromFile(JSON.stringify(json, null, 2));
        if (setUploadedFile) {
          setUploadedFile(null);
        }
      };
      reader.readAsBinaryString(uploadedFile);
    }
  };

  const handleOnUploadFileClose = () => {
    setDisplayUploadFileOption(false);
  };

  useEffect(() => {
    if (!jsonDataFromFile) return;
    const data = JSON.parse(jsonDataFromFile);

    if (!data[0].hasOwnProperty('Issue') || !data[0].hasOwnProperty('Key') || !data[0].hasOwnProperty('Logged')) {
      notifier.error('File not supported');
      return;
    }

    let keyAttributtes: any = null;
    let temporalDate: String | null = null;

    timesheetMonth.days.forEach((day) => {
      if (day.type.name === 'Normal') {
        day.timeSpent = 0;
        day.taskDescription = '';
      }
    });

    const timesheetMonthNumber = moment.utc(timesheetMonth.period).month();
    let continueFor = true;

    data.forEach((row) => {
      if (!continueFor) return;
      keyAttributtes = Object.keys(row);

      keyAttributtes.forEach((key) => {
        if (!continueFor) return;
        temporalDate = checkAndTransformDate(key);

        if (temporalDate) {
          var fileMonth = moment.utc(temporalDate.toString()).month();

          if (fileMonth !== timesheetMonthNumber) {
            continueFor = false;
            notifier.error("The file dates don't match the timesheet month");
          }
        }

        const selectedDay = timesheetMonth.days.find((day) => day.date.toString() === temporalDate);

        if (selectedDay) {
          if (!!row.Key || !!row.Issue) {
            selectedDay.taskDescription = `${!!selectedDay.taskDescription ? selectedDay.taskDescription + '\n' : ''}(${
              row.Key
            }) ${row.Issue}`;
            selectedDay.timeSpent = selectedDay.timeSpent + row[key];
          }
        }
      });
    });

    if (continueFor) {
      timesheetMonth.days.forEach((day) => {
        if (day.timeSpent < 8 && day.type.name === 'Normal') day.timeSpent = 8;
      });
      refreshTimesheet();
    }
  }, [jsonDataFromFile]);

  const refreshTimesheet = async () => {
    const result = await updateTimesheet(timesheetMonth);

    setLoading(true);

    if (result) {
      notifier.success('Timesheet saved successfully.');
      refreshTimesheetCalculations(result, true);
      setLoading(true);
    }
  };

  const refreshTimesheetCalculations = (result: TimesheetMonth, shouldSetTimesheetMonth = true) => {
    result.days?.sort(sortDateAsc);
    if (shouldSetTimesheetMonth) setTimesheetMonth(result);
    setRegisteredHours(result.timeSpent.toString() + 'Hrs');
    setRemainingHours(result.timeRemaining.toString() + 'Hrs');
    setOvertimeHours(result.overtime.toString() + 'Hrs');
    setOtherTimeHours(result.otherTime.toString() + 'Hrs');
    setHolidayHours(result.holidayTime.toString() + 'Hrs');
  };

  return {
    timesheetMonth,
    setTimesheetMonth,
    dateSelected,
    registeredHours,
    setRegisteredHours,
    remainingHours,
    setRemainingHours,
    overtimeHours,
    setOvertimeHours,
    otherTimeHours,
    setOtherTimeHours,
    holidayHours,
    setHolidayHours,
    setDateSelected,
    loadingTimesheet,
    fetchTimesheet,
    deadLineDate,
    daysLeft,
    setDeadLineDate,
    setDaysLeft,
    projectId,
    employeeId,
    projectName,
    setProjectName,
    projectAllowsHolidays,
    projectAllowsVacations,
    setProjectId,
    pubicURL,
    setPublicURL,
    handleUploadFile,
    fileName,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    openUploadFileConfirmationDialog,
    setOpenUploadFileConfirmationDialog,
    uploadFileConfirmation,
    handleDeleteFile,
    handleDownloadFile,
    openDeleteFileConfirmationDialog,
    setOpenDeleteFileConfirmationDialog,
    deleteFileConfirmation,
    loadingFile,
    handleOnCancelUpload,
    timesheetClosed,
    handleConvertUploadedFile,
    handleOnUploadFileClose,
    displayUploadFileOption,
  };
};

export const calculateDeadLineTime = (deadLine: moment.Moment) => {
  const deadLineTimeInUTC = moment(deadLine).utcOffset(0);
  const currentTime = moment.utc().valueOf();

  deadLineTimeInUTC.set({ hour: 23, minute: 0, second: 0, millisecond: 0 }); //11:00 pm UTC (5:00 pm CR)
  return currentTime >= deadLineTimeInUTC.valueOf();
};

const getAvailablePeriods = () => {
  const date = new Date();
  const currentDate = moment.utc().year(date.getFullYear()).month(date.getMonth()).date(1).startOf('day');
  const previousMonth = moment
    .utc()
    .year(date.getFullYear())
    .month(date.getMonth())
    .date(1)
    .subtract(1, 'M')
    .startOf('day');
  const nextMonth = moment.utc().year(date.getFullYear()).month(date.getMonth()).date(1).add(1, 'M').startOf('day');

  const availablePeriods: Item[] = [
    {
      id: previousMonth.toString(),
      label: previousMonth.format('MMMM YYYY'),
    },
    {
      id: currentDate.toString(),
      label: currentDate.format('MMMM YYYY'),
    },
    {
      id: nextMonth.toString(),
      label: nextMonth.format('MMMM YYYY'),
    },
  ];

  return availablePeriods;
};
