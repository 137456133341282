import { useCallback, useEffect, useState } from 'react';
import { getRewardHistoricalsById, rewardHistorical } from 'services/rewardsHistoricalService';

export const useDisplayModalEffect = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return { showModal, setShowModal };
};

export const useHistoricalRewardsEffect = (seletedRewardId: string) => {
  const [historialRewards, setHistorialRewards] = useState<rewardHistorical[]>([]);

  const [loading, setLoading] = useState(true);

  const fetchHistoricals = useCallback(async () => {
    setLoading(true);
    const data = await getRewardHistoricalsById(seletedRewardId);

    setLoading(false);
    setHistorialRewards(data);
  }, [seletedRewardId]);

  useEffect(() => {
    fetchHistoricals();
  }, [fetchHistoricals]);

  return { historicals: historialRewards, loading };
};
