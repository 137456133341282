import { CircularProgress, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import CurrencyInput from 'components/currencyInput';
import SingleSelect from 'components/single-select';
import { id } from 'date-fns/locale';
import { useForm } from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { saveSalary, updateSalary } from 'services/salaryService';
import styled from 'styled-components';
import { encryptText } from 'utils/encryptionMethods/encryption';
import { dateFormatUsed, dateFormatUsedDisplay, stringDateToHumanFormat } from 'utils/formatters/date';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCatalogs, useSalariesEffect } from './hooks';

const schemaSalary = {
  employee: yup.string().optional().label('Employee'),
  salary: yup.string().required().label('Salary'),
  increase: yup.string().optional().label('Increase'),
  jobTitle: yup.string().required().label('Job Title'),
  increaseType: yup.string().optional().label('Job Title'),
  comments: yup.string().required().label('Comments'),
  date: yup.string().required().label('Date'),
};

const SalaryForm: React.FC = () => {
  const { id: employeeId, salaryId } = useParams<{ id: string; salaryId: string }>();

  const { employee, jobTitles, loadingCatalogs, dateFirst } = useCatalogs(employeeId!);
  const {
    salary,
    lastIncrease,
    loadingSalary,
    salaryResultObject,
    isLastSalary,
    lastSalary,
    handleOnSalaryResult,
    increaseTypes,
    employeeJobTitle,
    jobTitleChangeId,
  } = useSalariesEffect(salaryId, employeeId);

  const [savingSalary, setSavingSalary] = useState(false);
  const { useInput, useDateInput, useCurrencyInput, values, setValues, isValid } = useForm(salary, schemaSalary);
  const display = loadingCatalogs && loadingSalary ? 'none' : 'block';
  const subTitle = salaryId && salaryId !== 'new' ? 'Update salary' : 'Add a new salary record';
  const history = useHistory();

  const handleSaveSalary = async () => {
    if (!isValid) {
      notifier.error('Please, fix the errors on the form before submitting');
      return;
    }
    setSavingSalary(true);
    values.employee = employeeId;

    const valuesToSend = { ...values };

    valuesToSend.salary = encryptText(valuesToSend.salary.replace(',', ''));
    valuesToSend.increase = valuesToSend.increase ? encryptText(valuesToSend.increase.replace(',', '')) : '';

    if (salaryId && salaryId !== 'new') {
      const response = await updateSalary(valuesToSend, salaryId);

      if (response) {
        notifier.success('Salary updated successfully.');
      }

      setSavingSalary(false);
      return;
    }

    const newSalary = await saveSalary(valuesToSend);

    if (newSalary) {
      handleOnSalaryResult(newSalary);
      notifier.success('Salary added successfully.');
      history.push(`/employees/${employeeId}/salaries/${newSalary._id}`);
    }
    setSavingSalary(false);
  };

  const calculateNewSalary = () => {
    if (!values.increase)
      return `New salary: \u000A $${parseFloat(lastSalary.replaceAll(',', '').replaceAll(/\s/g, '')).toLocaleString()}`;

    const currentSalary = lastSalary.replace('$', '').replaceAll(',', '');
    const increase = values.increase.replaceAll(',', '').replace('$', '');
    let newSalary = parseFloat(currentSalary) + parseFloat(increase);

    if (salaryId && salaryId !== 'new') {
      newSalary = newSalary - parseFloat(lastIncrease.replace('$', '').replaceAll(',', ''));
    }

    if (isNaN(newSalary)) newSalary = parseFloat(currentSalary);

    values.salary = newSalary.toLocaleString();

    return `New salary: \u000A $${newSalary.toLocaleString()}`;
  };

  useEffect(() => {
    if (!loadingCatalogs && !loadingSalary && id) {
      const selectedIncreaseValue =
        increaseTypes.find((increaseValue) => increaseValue.id.toString() === values.increaseType)?.label || '';
      let currentJobTitleId = '';

      if (selectedIncreaseValue !== 'Promotion' && selectedIncreaseValue !== 'Job Title Change') {
        currentJobTitleId = employeeJobTitle;
      }
      setValues((oldValues) => ({ ...oldValues, comments: selectedIncreaseValue, jobTitle: currentJobTitleId }));

      if (selectedIncreaseValue === 'Job Title Change') {
        setValues((oldValues) => ({ ...oldValues, increase: '0' }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.increaseType]);

  return (
    <Container>
      <Card>
        <CardHeader
          loading={loadingCatalogs}
          title={`Employee: ${!loadingCatalogs ? employee?.name + ' ' + employee?.lastName : 'Loading'}`}
          subTitle={subTitle}
        />
        {loadingCatalogs && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>New salary Information</BodyTitle>

          <DetailsLine>
            <OptionsContainer>
              <SelectContainerWide>
                <SingleSelectContainer
                  id={'increaseTypeSelect'}
                  label={'Increase Type'}
                  options={increaseTypes}
                  {...useInput('increaseType')}
                />
              </SelectContainerWide>
              <TextBody hidden={!!lastSalary && dateFirst !== salary.date}>
                <TextField
                  required
                  label="New Salary"
                  variant="outlined"
                  name="newSalary"
                  id="newSalary"
                  {...useCurrencyInput('salary')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputComponent: CurrencyInputContainer as any,
                  }}
                  disabled={!isLastSalary}
                />
              </TextBody>

              <TextBodyFlexParent hidden={!lastIncrease || !lastSalary || dateFirst === salary.date}>
                <TextBodyFlex>
                  <TextFieldIncrease
                    required
                    label="Increase"
                    variant="outlined"
                    name="increase"
                    id="increase"
                    {...useCurrencyInput('increase')}
                    InputProps={{
                      inputComponent: CurrencyInputContainer as any,
                    }}
                    disabled={!isLastSalary || dateFirst === salary.date || values.increaseType === jobTitleChangeId}
                  />
                  <div className={'visualization'}>
                    <NewSalaryDiv style={{ whiteSpace: 'pre-wrap' }}>{calculateNewSalary()}</NewSalaryDiv>
                  </div>
                </TextBodyFlex>
              </TextBodyFlexParent>

              <SelectContainerWide>
                <SingleSelectContainer
                  id={'jobTitleSelect'}
                  required={true}
                  label={'Job Title'}
                  options={jobTitles}
                  {...useInput('jobTitle')}
                />
              </SelectContainerWide>

              <SelectContainerWide>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <DateInputPickerContainer
                    id={'dateSelect'}
                    required
                    label="Date"
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    disabled={!isLastSalary && dateFirst === salary.date}
                    {...useDateInput('date')}
                    InputLabelProps={{
                      shrink: values.date ? true : false,
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Tooltip>
              </SelectContainerWide>
            </OptionsContainer>
          </DetailsLine>
          <DetailsLine>
            <TextWideBody>
              <MultiLineTetFieldContainer
                id={'salaryCommentsInput'}
                multiline
                fullWidth
                required
                rows={2}
                label="Comments"
                variant="outlined"
                {...useInput('comments')}
              />
            </TextWideBody>
          </DetailsLine>

          {salaryId && salaryId !== 'new' && (
            <div>
              <DetailsLine>
                <TextBody>
                  <TextField
                    label="Created By"
                    variant="outlined"
                    disabled={true}
                    fullWidth
                    value={salaryResultObject?.createdBy?.name + ' ' + salaryResultObject?.createdBy?.lastName}
                  />
                </TextBody>

                <TextBody>
                  <TextField
                    label="Creation date"
                    variant="outlined"
                    disabled={true}
                    fullWidth
                    value={stringDateToHumanFormat(salaryResultObject?.createdAt ? salaryResultObject.createdAt : '')}
                  />
                </TextBody>
              </DetailsLine>

              {salaryResultObject && salaryResultObject.updatedBy && (
                <DetailsLine>
                  <TextBody>
                    <TextField
                      label="Updated By"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                      value={
                        salaryResultObject?.updatedBy
                          ? salaryResultObject.updatedBy.name + ' ' + salaryResultObject.updatedBy.lastName
                          : ''
                      }
                    />
                  </TextBody>

                  <TextBody>
                    <TextField
                      label="Update date"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                      value={stringDateToHumanFormat(salaryResultObject?.updatedAt ? salaryResultObject.updatedAt : '')}
                    />
                  </TextBody>
                </DetailsLine>
              )}
            </div>
          )}

          <ButtonContainer>
            {savingSalary && <Spinner size={30} />}
            <Submit disabled={savingSalary || loadingCatalogs || !isValid} onClick={handleSaveSalary}>
              Save salary
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const ButtonContainer = styled.div`
  display: block;
  padding: 20px;
  text-align: right;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
`;

const TextBody = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 280px;
  float: left;
`;

const TextBodyFlexParent = styled.div`
  float: left;
`;

const TextBodyFlex = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 280px;
  float: left;
  display: flex;
`;

const CurrencyInputContainer = styled(CurrencyInput)`
  margin-right: 20px;
  width: 233px;
  float: left;
`;

const OptionsContainer = styled.div`
  display: block;
  float: left;
`;

const SingleSelectContainer = styled(SingleSelect)`
  width: 280px;
`;

const SelectContainerWide = styled.div`
  min-width: 160px;
  width: 280px;
  display: block;
  float: left;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: -5px;
`;

const DateInputPickerContainer = styled(KeyboardDatePicker)`
  width: 280px;
`;

const TextWideBody = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 100%;
  margin-bottom: 30px;
`;

const TextFieldIncrease = styled(TextField)`
  width: 200px;
`;

const MultiLineTetFieldContainer = styled(TextField)`
  height: 50px !important;
`;

const NewSalaryDiv = styled.span`
  padding-left: 8px;
  align-self: center;
`;

export default SalaryForm;
