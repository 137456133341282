import { useCallback, useState } from 'react';
import * as yup from 'yup';

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

export const useForm = <T extends object>(
  schema: yup.ObjectSchemaDefinition<T>,
  setOwnValue: any,
  ownValue: any,
  originalValue: any,
) => {
  const useInput = () => {
    const [error, setError] = useState<string | null>();

    const onChange = useCallback(
      (event: OnChangeEvent) => {
        const { name, value } = event.target;

        setOwnValue({ ...ownValue, [name!]: value });

        if (originalValue) originalValue[name!] = value;

        const schemaField = schema[name!] as any;

        try {
          schemaField.validateSync(value);
          setError(null);
        } catch (ex) {
          setError(ex.errors[0] as string);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ownValue, originalValue],
    );

    return { onChange, error: Boolean(error), helperText: error };
  };

  return { useInput };
};
