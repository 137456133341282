import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { UserResource } from 'services/authService';

import { loginRoutes, logoutRoutes, privateRoutes, RouteItem } from './routes';

const useSideBar = () => {
  const [routes, setRoutes] = useState<RouteItem[]>([]);
  const user = useCurrentUser();

  const fetchUserAuthorization = useCallback(async (user: UserResource) => {
    const enabledRoutes = Boolean(user) ? [...privateRoutes(user), ...logoutRoutes] : logoutRoutes;

    const display = user ? enabledRoutes : loginRoutes;
    const availableDisplay = display.filter((route) => route.visibility === true);

    setRoutes(availableDisplay);
  }, []);

  useEffect(() => {
    if (!user) return;
    fetchUserAuthorization(user);
  }, [fetchUserAuthorization, user]);

  return { routes, setRoutes };
};

export default useSideBar;
