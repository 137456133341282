import usePaging, { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeResource, getEmployeeById } from 'services/employeeService';
import {
  getLastRateByEmployeeId,
  getRateById,
  getRatesByEmployeeId,
  RateResource,
  SaveRateResource,
} from 'services/salaryService';
import { decryptText } from 'utils/encryptionMethods/encryption';

export const defaultRateResource = {
  employee: '',
  rate: '',
  increase: '',
  comments: '',
  effectiveDate: '',
};
const DEFAULT_QUERY: Query = {
  pageSize: 20,
  page: 1,
  isSortAscending: 0,
  sortByColumn: 'effectiveDate',
};

export const useRatesEffect = (rateId: string, employeeId: string) => {
  const [rate, setRate] = useState<SaveRateResource>(defaultRateResource);
  const [lastIncrease, setLastIncrease] = useState('0');
  const [loadingRate, setLoadingRate] = useState(true);
  const [rateResultObject, setRateResultObject] = useState<RateResource>();
  const [lastRate, setLastRate] = useState('');
  const [isLastRate, setIsLastRate] = useState(true);

  const fetchRate = useCallback(async () => {
    setLoadingRate(true);
    const lastRateResult = await getLastRateByEmployeeId(employeeId!);

    setLastRate(decryptText(lastRateResult.rate).replaceAll(/\s/g, '').replace('$', ''));

    if (rateId !== 'new') {
      const rateResult = await getRateById(rateId!);

      if (rateResult) {
        const checkLastRate = decryptText(lastRateResult.rate) === decryptText(rateResult.rate);

        setIsLastRate(checkLastRate);

        if (!checkLastRate) {
          setLastRate(decryptText(rateResult.rate).replaceAll(/\s/g, '').replace('$', ''));
        }

        setRate(mapRateResponse(rateResult));

        setRateResultObject(rateResult);
        setLastIncrease(decryptText(rateResult.increase).replaceAll(/\s/g, '').replace('$', ''));
      }
    }
    setLoadingRate(false);
  }, [employeeId, rateId]);

  useEffect(() => {
    fetchRate();
  }, 
  []);

  const handleOnRateResult = (rateResult: RateResource) => {
    setRate(mapRateResponse(rateResult));

    setRateResultObject(rateResult);
  };

  return {
    rate,
    lastIncrease,
    setRate,
    isLastRate,
    lastRate,
    loadingRate,
    setLoadingRate,
    rateResultObject,
    fetchRate,
    handleOnRateResult,
  };
};

export const useCatalogs = (id: string) => {
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [employee, setEmployee] = useState<EmployeeResource>();

  const defaultResponse: PageResource<RateResource> = { totalItems: 0, items: [] };
  const [rates, setRates] = useState(defaultResponse);
  const { query } = usePaging(DEFAULT_QUERY);

  const [dateFirst, setDateFirst] = useState('');
  let firstDate = '';

  useEffect(() => {
    async function fetchCatalogs() {
      setLoadingCatalogs(true);

      const employeeResult = await getEmployeeById(id!);

      if (employeeResult) {
        setEmployee(employeeResult);
      }

      const ratesResult = await getRatesByEmployeeId(id, query);

      setRates(ratesResult);

      if (ratesResult.items[0] !== undefined) {
        firstDate = ratesResult.items[0].effectiveDate;

        for (let i = 0; i < ratesResult.items.length; i++) {
          if (ratesResult.items[i].effectiveDate < firstDate) {
            firstDate = ratesResult.items[i].effectiveDate;
          }
        }
      }

      setDateFirst(firstDate);
      setLoadingCatalogs(false);
    }

    fetchCatalogs();
  }, [id, query, firstDate]);

  return {
    employee,
    loadingCatalogs,
    dateFirst,
  };
};

const mapRateResponse = (rate: RateResource) => {
  return {
    employee: rate.employee,
    rate: decryptText(rate.rate).replace('$', ''),
    increase: decryptText(rate.increase).replace('$', ''),
    comments: rate.comments,
    effectiveDate: rate.effectiveDate,
  } as SaveRateResource;
};
