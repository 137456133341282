import { useForm } from 'hooks/useForm';
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  getLastOneOnOneTemplate,
  OneOnOneTemplateResource,
  updateOneOnOneTemplateById,
} from 'services/oneOnOneTemplateService';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

const defaultOneOnOneTemplate: OneOnOneTemplateResource = {
  _id: '',
  template: '',
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
};

const schema = {
  template: yup.string().optional().label('Template'),
  createdBy: yup.string().optional().nullable().label('Created By'),
  createdAt: yup.string().optional().nullable().label('Created At'),
  updatedBy: yup.string().optional().nullable().label('Updated By'),
  updatedAt: yup.string().optional().nullable().label('Updated At'),
};

export const useOneOnOneTemplateEffect = () => {
  const [oneOnOneTemplate, setOneOnOneTemplate] = useState<OneOnOneTemplateResource>(defaultOneOnOneTemplate);
  const [loading, setLoading] = useState(true);
  const [isSubmiting, setIsSubmitting] = useState(false);
  let { values, useInput, isValid } = useForm(oneOnOneTemplate, schema as any, loading);

  useEffect(() => {
    async function fetchOneOnOneTemplate() {
      setLoading(true);
      const oneOnOneTemplateResult = await getLastOneOnOneTemplate();

      setLoading(false);
      setOneOnOneTemplate(oneOnOneTemplateResult);
    }

    fetchOneOnOneTemplate();
  }, []);

  useLayoutEffect(() => {
    saveInfoInLocalStorage('inputValue', '', '_form');
  });

  const handleSaveOneOnOneTemplate = async () => {
    if (!isValid) {
      notifier.error('Invalid values');
      return;
    }
    setIsSubmitting(true);

    try {
      const response = await updateOneOnOneTemplateById(values, oneOnOneTemplate._id);

      if (response) {
        notifier.success('One on One template updated successfully.');
      }
      saveInfoInLocalStorage('inputValue', '', '_form');
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    }

    setIsSubmitting(false);
  };

  return { loading, isSubmiting, handleSaveOneOnOneTemplate, useInput };
};
