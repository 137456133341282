import { Column } from 'components/standard-table/types';
import { EmployeeResource } from 'services/employeeService';
import { stringDateToHumanFormat } from 'utils/formatters/date';

export const columns: Column<EmployeeResource>[] = [
  {
    label: 'Name',
    sortKey: 'name',
    path: 'name',
    render: (item) => `${item.name} ${item.lastName}`,
  },
  {
    label: 'Status',
    sortKey: 'insuranceInfo.status',
    path: 'insuranceInfo.status',
    render: (item) => `${item.insuranceInfo ? item.insuranceInfo.status.name : ''}`,
  },
  {
    label: 'Country',
    sortKey: 'homeAddress.country',
    path: 'homeAddress.country',
    render: (item) => `${item.homeAddress?.country || ''}`,
  },
  {
    label: 'Inclusion Date',
    sortKey: 'insuranceInfo.inclusionDate',
    path: 'insuranceInfo.inclusionDate',
    render: (item) =>
      `${
        item.insuranceInfo && item.insuranceInfo.inclusionDate
          ? stringDateToHumanFormat(item.insuranceInfo.inclusionDate)
          : 'Not Assigned'
      }`,
  },
  {
    label: 'Comments',
    sortKey: 'insuranceInfo.comments',
    path: 'insuranceInfo.comments',
    render: (item) => `${item.insuranceInfo ? item.insuranceInfo.comments : ''}`,
  },
];
