import { Box } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Rating, SaveCareerDevelopmentPlanResource, SaveCategory } from 'services/careerDevelopmentPlanService';
import styled from 'styled-components';
import * as yup from 'yup';

import { useForm } from '../useForm';

type Props = {
  category: SaveCategory;
  ratings: Rating[];
  setSaveCareerDevelopmentPlan: any;
  saveCareerDevelopmentPlan: SaveCareerDevelopmentPlanResource;
  index: number;
  itemsCount: number;
  isReadOnly: boolean;
};

const schema = {
  rating: yup.string().required().label('Title'),
  comments: yup.string().default('').optional().label('Description'),
};

const CareerDevelopmentPlanCategoryForm: React.FC<Props> = (props) => {
  const { category, ratings, isReadOnly } = props;
  const [ownCategory, setOwnCategory] = useState(category);
  const { useInput } = useForm(schema, setOwnCategory, ownCategory, category);

  return (
    <Box>
      <Prompt
        when={localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') === 'changed'}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <Container>
        <CategoryTitle>
          <BodyTitle>
            {ownCategory.title}
            <Tooltip title={ownCategory.description}>
              <IconButton aria-label="hrOneOnOnes">
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </BodyTitle>
          <RadioGroupContainer aria-label="ratings" name="rating" value={ownCategory.rating} {...useInput()}>
            {ratings.map((rating, index) => (
              <FormControlLabel
                key={index}
                value={rating.description}
                control={<Radio />}
                label={rating.description}
                disabled={isReadOnly}
              />
            ))}
          </RadioGroupContainer>
        </CategoryTitle>
        <Box>
          <ExpandTextField
            multiline
            fullWidth
            minRows={3}
            maxRows={Infinity}
            label="Comments"
            variant="outlined"
            name={'comments'}
            value={ownCategory.comments}
            {...useInput()}
            disabled={isReadOnly}
          />
        </Box>
      </Container>
    </Box>
  );
};

const Container = styled.div`
  width: 100%;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
`;

const CategoryTitle = styled.div`
  text-align: center;
  display: block;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

const RadioGroupContainer = styled(RadioGroup)`
  display: inline;
`;

const ExpandTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: auto !important;
  }
`;

export default CareerDevelopmentPlanCategoryForm;
