import { useEffect, useState } from 'react';
import { getAllEmployees, getAllLeaders } from 'services/employeeService';
import { CreateOneOnOneResource } from 'services/oneOnOneService';
import { getAllOneOnOneStatus } from 'services/statusService';
import { EmployeeAndLeader, Item } from 'shared/types';
import { collectionEmployeeToItem, collectionEmployeeWithLeader, collectionToItem } from 'utils/mappers/item';

const defaultCreateOneOnOneResource = {
  employee: '',
  leaderAssigned: '',
  status: '',
  creationDate: new Date().toString(),
};

export const useFetchOneOnOneEffect = () => {
  const [oneOnOne] = useState<CreateOneOnOneResource>(defaultCreateOneOnOneResource);

  return { oneOnOne };
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [employeesWithLeaders, setEmployeesWithLeaders] = useState<EmployeeAndLeader[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [employeesResult, statusResult, leadersResult] = await Promise.all([
        getAllEmployees(),
        getAllOneOnOneStatus(),
        getAllLeaders(),
      ]);

      const statusItems = collectionToItem(statusResult);
      const leaderItems = collectionEmployeeToItem(leadersResult);
      const employeesItems = collectionEmployeeToItem(employeesResult);
      const employeesWithLeaders = collectionEmployeeWithLeader(employeesResult);

      setStatuses(statusItems);
      setEmployees(employeesItems);
      setLeaders(leaderItems);
      setLoading(false);
      setEmployeesWithLeaders(employeesWithLeaders);
    }

    fetchCatalogs();
  }, []);

  return { leaders, statuses, employees, employeesWithLeaders, loading };
};
