import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import { saveAs } from 'file-saver';
import React from 'react';
import { getRewardRefundById } from 'services/rewardsRefundService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import HistoryRewardsModal from '../modal';
import columns from './columns';
import Filter from './filter';
import { useDisplayModalEffect, useRewardsEffect, useSeletedEmployeeEffect, useSeletedRewardEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

const RewardsList: React.FC = () => {
  const { showModal, setShowModal } = useDisplayModalEffect(false);
  const { seletedRewardId, setSeletedRewardId } = useSeletedRewardEffect('');
  const { seletedEmployee, setSeletedEmployee } = useSeletedEmployeeEffect('');

  const onHistoryPressed = (reward: string) => {
    let rewardObj = JSON.parse(JSON.stringify(reward));
    const name = rewardObj.employee.name + ' ' + rewardObj.employee.lastName;

    setSeletedRewardId(rewardObj._id);
    setSeletedEmployee(name);

    setShowModal(true);
  };

  const getPDF = async (cdpId: string) => {
    setLoading(true);
    const rewardResult = await getRewardRefundById(cdpId);

    if (rewardResult.invoice?.indexOf('base64') !== -1) {
      const fileData = rewardResult.invoice?.split(',')!;
      const fileContent = fileData[2];
      const fileName = fileData[0]?.substring(fileData[0]?.indexOf(':') + 1, fileData[0]?.length) || '';

      const byteChar = atob(fileContent);
      let byteArray = new Array(byteChar.length);

      for (let i = 0; i < byteChar.length; i++) {
        byteArray[i] = byteChar.charCodeAt(i);
      }

      let uIntArray = new Uint8Array(byteArray);
      let blob = new Blob([uIntArray], { type: 'application/pdf' });

      saveAs(blob, fileName);
    }
    setLoading(false);
  };

  const {
    reasons,
    statuses,
    loadingCatalogs,
    handlePageChange,
    handleColumnSorting,
    query,
    setQuery,
    rewards,
    loading: rewardLoading,
    setLoading,
    fetchRewards,
    defaultFilter,
    setDefaultFilter,
  } = useRewardsEffect();
  const isLoading = rewardLoading || loadingCatalogs;

  return (
    <Container>
      <Card>
        <CardHeader
          title="Rewards"
          subTitle="Flow to request payment of rewards "
          newPageRedirectionLink={'/rewards/new'}
          loading={isLoading}
        />
        {loadingCatalogs && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}

        <CardBody>
          <Filter 
            defaultFilter={defaultFilter} 
            setDefaultFilter={setDefaultFilter}
            search={''} 
            reasons={reasons} 
            statuses={statuses} 
            setFilter={setQuery} 
            filter={query} 
          />
          {!isLoading && (
            <div>
              {rewards?.items?.length ? (
                <StandardTable
                  keyColumn="_id"
                  data={rewards.items}
                  totalItems={rewards.totalItems}
                  page={query.page}
                  pageSize={query.pageSize}
                  isSortAscending={Boolean(query.isSortAscending)}
                  sortByColumn={query.sortByColumn}
                  onChangePage={handlePageChange}
                  columns={columns(fetchRewards, setLoading, getPDF, onHistoryPressed)}
                  onSort={handleColumnSorting}
                />
              ) : (
                <NoRecords>There are no records to display, please check your filters. </NoRecords>
              )}
            </div>
          )}
        </CardBody>
      </Card>

      <HistoryRewardsModal
        showModal={showModal}
        setShowModal={setShowModal}
        seletedRewardId={seletedRewardId}
        selectedEmployee={seletedEmployee}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default RewardsList;
