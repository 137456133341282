import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import SingleSelect from 'components/single-select';
import TransferList from 'components/transferList';
import { useForm } from 'hooks/useForm';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createUser, updateUser } from 'services/usersService';
import { CatalogResource } from 'shared/types';
import styled from 'styled-components';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCatalogFilter, useCatalogs, useUserEffect } from './hooks';
const schemaUser = {
  name: yup.string().required().label('Name'),
  lastName: yup.string().label('Last Name'),
  email: yup.string().required().label('Email'),
  roles: yup.array(yup.string().optional()),
};

type Props = {};

const roleIntersection = (roles: CatalogResource[], userRoles: CatalogResource[]) => {
  const roleList = roles.filter((ar) => !userRoles.find((rm) => rm.name === ar.name));

  return roleList;
};

const UserForm: React.FC<Props> = () => {
  let { employees, loading: employeeLoading, employeesList } = useCatalogs();
  const { id } = useParams<{ id?: string }>();
  const subTitle = id ? 'Update the user' : 'Create a new user';
  const history = useHistory();
  const [savingUser, setSavingUser] = useState(false);

  const { user, loading, userRoles, setUserRoles, employeeSelected, setEmployeeSelected } = useUserEffect(
    id,
    employeesList,
  );
  const { roles, loadingCatalogs } = useCatalogFilter();
  const { values, isValid, setIsValid } = useForm(user, schemaUser as any, loading);
  const initialLoading = loading || loadingCatalogs;
  const display = initialLoading ? 'none' : 'block';

  const handleSaveUserRoles = async () => {
    if (!isValid) {
      notifier.error('Please, fix the errors on the form before submitting ');
      return;
    }
    setSavingUser(true);

    values.roles = userRoles;
    values.name = employeeSelected?.name || '';

    values.lastName = '' + employeeSelected?.lastName || '';

    values.email = '' + employeeSelected?.email || '';

    if (id) {
      const response = await updateUser(values, id);

      if (response) notifier.success('User updated successfully.');
      setSavingUser(false);
      return;
    }

    const newUser = await createUser(values);

    if (newUser) {
      notifier.success('User created successfully.');
      history.push(`/users/${newUser._id}`);
    }
    setSavingUser(false);
  };

  const handleChange = (event: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
    let employeeFound;

    if (event.target.name === 'employee') {
      employeeFound = employeesList.find((employee) => employee._id === event.target.value);
    }

    if (employeeFound) {
      setEmployeeSelected(employeeFound);
      setIsValid(true);
    } else setIsValid(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`User: ${!initialLoading ? values?.name + ' ' + values?.lastName : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>User information</BodyTitle>

          <DetailsLine>
            {id && (
              <Label>
                <b>Email:</b> {values.email}
              </Label>
            )}

            {!id && (
              <SingleSelectContainer
                id={'employeeSelect'}
                name={'employee'}
                label={'Employee'}
                options={employees}
                onChange={handleChange}
                value={employeeSelected}
              />
            )}
          </DetailsLine>

          <br />
          <BodyTitle>Roles</BodyTitle>

          {!initialLoading && (
            <TransferList
              roleOptions={roleIntersection(roles, values.roles)}
              userRoles={values.roles}
              setUserRoles={setUserRoles}
            />
          )}

          <ButtonContainer>
            {savingUser && <Spinner size={30} />}
            <Submit id={'saveButton'} disabled={savingUser || loading || !isValid} onClick={handleSaveUserRoles}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const SingleSelectContainer = styled(SingleSelect)`
  margin-right: 10px;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
`;
const Label = styled.div`
  margin-top: 10px;
  margin-left: 5px;
  font-size: 16px;
`;

export default UserForm;
