import { CircularProgress, FormControlLabel, IconButton, Radio, RadioGroup } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import SingleSelect from 'components/single-select';
import { isPermissionGranted, ROLE_ADMIN } from 'helpers/roleHelper';
import { useForm } from 'hooks/useForm';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { getPreviousCdpById, updateCdp } from 'services/careerDevelopmentPlanService';
import styled from 'styled-components';
import { stringDateToMonthYearFormat } from 'utils/formatters/date';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import DevelopmentPlanCategoryForm from './category';
import { useCareerDevelopmentPlanEffect, useCatalogs, useCdpStatusInformationEffect, useReadOnlyEffect } from './hooks';
import TemplateModal from './modal';
import { useCDPTemplateEffect, useDisplayModalEffect } from './modal/hooks';

const schema = {
  leaderAssigned: yup.string().required().label('Assigned To'),
  cdpStatus: yup.string().required().label('CdpStatus'),
  newJobTitle: yup.string().optional().label('New job title'),
  additionalComments: yup.string().default('').optional().label('Additional comments'),
  actionComments: yup.string().default('').optional().label('Action comments'),
  jobTitleUpdateApproval: yup.string().default('').optional().label('Job title update approval'),
  disciplinaryAction: yup.string().default('').optional().label('Disciplinary action'),
  adminComments: yup.string().optional().label('Job title update approval reason'),
};

saveInfoInLocalStorage('inputValue', '', '_form');
type Props = {};

const CareerDevelopmentPlanForm: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const variable = id.split(',');
  const idDevelopmentPlan = variable[0];

  const { leaders, cdpStatuses, jobTitles, loading: catalogLoading } = useCatalogs();

  const {
    temporalSaveCareerDevelopmentPlan,
    careerDevelopmentPlan,
    setCareerDevelopmentPlan,
    setTemporalSaveCareerDevelopmentPlan,
    loading,
    setLoading,
    saveCareerDevelopmentPlan,
    setSaveCareerDevelopmentPlan,
    fetchCareerDevelopmentPlan,
  } = useCareerDevelopmentPlanEffect(idDevelopmentPlan);
  const [savingDevelopmentPlan, setSavingDevelopmentPlan] = useState(false);
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';

  const { showModal, setShowModal } = useDisplayModalEffect(false);
  const { template } = useCDPTemplateEffect(careerDevelopmentPlan?.template!);

  const { useInput, values, isValid } = useForm(temporalSaveCareerDevelopmentPlan, schema, loading || catalogLoading);

  const {
    cdpStatusName,
    cdpActionCommentVisibility,
    cdpActionCommentLabel,
    cdpActionCommentRequired,
    cdpActionAdminJobUpdateOptionVisible,
    cdpActionDisciplinaryOptionVisible,
    jobTitleDropDownVisible,
    completedAdditionalMessage,
  } = useCdpStatusInformationEffect(
    values.cdpStatus,
    cdpStatuses,
    values.jobTitleUpdateApproval ? true : false,
    values.disciplinaryAction ? true : false,
  );

  const handleSaveCareerDevelopmentPlan = async () => {
    setSavingDevelopmentPlan(true);

    if (
      !isValid ||
      (cdpActionCommentRequired && !values.actionComments) ||
      (cdpActionAdminJobUpdateOptionVisible && values.jobTitleUpdateApproval === 'no' && !values.adminComments) ||
      (cdpActionAdminJobUpdateOptionVisible && !values.newJobTitle) ||
      (cdpActionDisciplinaryOptionVisible && values.disciplinaryAction && !values.adminComments)
    ) {
      notifier.error(`Please, fix the errors on the form before submitting `);
      setSavingDevelopmentPlan(false);
      return;
    }

    const saveCDPObject = {
      leaderAssigned: values.leaderAssigned,
      cdpStatus: values.cdpStatus,
      newJobTitle: values.newJobTitle,
      additionalComments: values.additionalComments,
      categories: careerDevelopmentPlan!.categories,
      actionComments: values.actionComments,
      jobTitleUpdateApproval: cdpStatusName === 'Job Title Review' ? values.jobTitleUpdateApproval : '',
      disciplinaryAction: cdpStatusName === 'Disciplinary Action' ? values.disciplinaryAction : '',
      adminComments: values.adminComments,
    };

    if (variable[1] === 'create') {
      const response = await updateCdp(saveCDPObject, idDevelopmentPlan);

      if (response) {
        notifier.success('Career Development Plan created successfully.');
        saveInfoInLocalStorage('inputValue', '', '_form');
        if (cdpStatusName === 'Completed') fetchCareerDevelopmentPlan();
      }
    } else {
      const response = await updateCdp(saveCDPObject, idDevelopmentPlan);

      if (response) {
        notifier.success('Career Development Plan updated successfully.');
        saveInfoInLocalStorage('inputValue', '', '_form');
        if (cdpStatusName === 'Completed') fetchCareerDevelopmentPlan();
      }
    }
    setSavingDevelopmentPlan(false);
    return;
  };

  const handleLoadPreviousCdpData = async () => {
    setLoading(true);
    const response = await getPreviousCdpById(careerDevelopmentPlan?.employee._id);

    if (response && careerDevelopmentPlan) {
      saveCareerDevelopmentPlan.categories = response.categories;
      careerDevelopmentPlan.categories = response.categories;
      saveCareerDevelopmentPlan.additionalComments = response.additionalComments;

      setSaveCareerDevelopmentPlan(saveCareerDevelopmentPlan);
      setTemporalSaveCareerDevelopmentPlan(saveCareerDevelopmentPlan);
      setCareerDevelopmentPlan(careerDevelopmentPlan);

      notifier.success('Previous CDP info loaded');
    } else if (!response) {
      notifier.warning('There are not other similar CDPs for this employee');
    }
    setLoading(false);
  };

  const handleWatchCdpTemplate = async () => {
    if (careerDevelopmentPlan) {
      setShowModal(true);
    } else {
      notifier.warning('CDP Template was not found');
    }
  };

  const user = useCurrentUser();
  const userHasAdminPermission = () => {
    return isPermissionGranted([ROLE_ADMIN], user);
  };
  const localReadOnly = localStorage.getItem('cdpMode');
  const { isReadOnly } = useReadOnlyEffect(
    localReadOnly,
    (!localStorage.getItem('inputValue_form') && cdpStatusName === 'Completed') ||
      (localStorage.getItem('inputValue_form') !== null &&
        localStorage.getItem('inputValue_form') !== 'changed' &&
        cdpStatusName === 'Completed') ||
      false,
    userHasAdminPermission(),
  );

  console.log(localStorage.getItem('inputValue_form'));

  const handleRadioButtonOnChange = () => {
    if (values.cdpStatus !== (careerDevelopmentPlan?.cdpStatus || '')) {
      values.actionComments = '';
      values.adminComments = '';
      values.disciplinaryAction = '';
      values.jobTitleUpdateApproval = '';
    } else {
      values.actionComments = careerDevelopmentPlan?.actionComments;
      values.adminComments = careerDevelopmentPlan?.adminComments;
    }
  };

  const [isAdmnReasonFiledRequired, setIsAdminReasonFieldRequired] = useState(false);

  const handleAdmnRadioButtonApprove = () => {
    setIsAdminReasonFieldRequired(false);
  };
  const handleAdminRadioButtonDeny = () => {
    setIsAdminReasonFieldRequired(true);
  };

  return (
    <Container>
      <Card>
        <Prompt
          when={
            localStorage.getItem('inputValue_form') !== null && localStorage.getItem('inputValue_form') === 'changed'
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <CardHeader
          loading={initialLoading}
          title={`Career Development Plan: ${
            !initialLoading
              ? `${careerDevelopmentPlan?.employee.name} ${careerDevelopmentPlan?.employee.lastName}`
              : 'Loading'
          }`}
          subTitle={
            !initialLoading
              ? `Current Job Title: ${careerDevelopmentPlan?.jobTitle?.name} | Period: ${stringDateToMonthYearFormat(
                  careerDevelopmentPlan?.period,
                )}`
              : ''
          }
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Information</BodyTitle>
          <br />
          <DetailsLine>
            <SelectContainer>
              <SingleSelectContainer
                label={'Assigned To:'}
                options={leaders}
                fullWidth
                {...useInput('leaderAssigned')}
                disabled={isReadOnly}
              />
            </SelectContainer>
            <TopButtonsContainer>
              <BodyTitle>Template: {template?.title}</BodyTitle>
              <Submit onClick={handleWatchCdpTemplate}>
                <VisibilityContainer aria-label="viewCDP">
                  <VisibilityIconEye />
                </VisibilityContainer>
              </Submit>
            </TopButtonsContainer>
          </DetailsLine>
          <ClearDiv />

          <DetailsLineList>
            {careerDevelopmentPlan &&
            careerDevelopmentPlan.categories &&
            !loading &&
            careerDevelopmentPlan.categories.length ? (
              <>
                {careerDevelopmentPlan.categories.map((category, index) => (
                  <DevelopmentPlanCategoryForm
                    key={category._id}
                    category={category}
                    ratings={careerDevelopmentPlan.ratings}
                    index={index}
                    itemsCount={careerDevelopmentPlan.categories.length}
                    saveCareerDevelopmentPlan={saveCareerDevelopmentPlan}
                    setSaveCareerDevelopmentPlan={setSaveCareerDevelopmentPlan}
                    isReadOnly={isReadOnly}
                  />
                ))}
              </>
            ) : (
              <NoRecords>There are no categories to display </NoRecords>
            )}
          </DetailsLineList>

          <DetailsLine>
            <ExpandTextField
              multiline
              fullWidth
              minRows={3}
              maxRows={Infinity}
              label="Additional Comments"
              variant="outlined"
              {...useInput('additionalComments')}
              disabled={isReadOnly}
            />
          </DetailsLine>

          <BodyTitle>Select one of the following actions once you have completed all the fields above</BodyTitle>

          <RadioGroupContainer {...useInput('cdpStatus')}>
            {cdpStatuses.length ? (
              <>
                {cdpStatuses.map((cdpStatus) => (
                  <FormControlLabel
                    key={cdpStatus._id}
                    value={cdpStatus._id}
                    control={<Radio />}
                    label={cdpStatus.description}
                    onChange={handleRadioButtonOnChange}
                    disabled={isReadOnly}
                  />
                ))}
              </>
            ) : (
              <NoRecords>There are no actions to display, plase try reloading this page </NoRecords>
            )}
          </RadioGroupContainer>

          <DetailsLine style={{ display: completedAdditionalMessage ? '' : 'none' }}>
            <Text>{completedAdditionalMessage}</Text>
          </DetailsLine>

          <DetailsLine style={{ display: jobTitleDropDownVisible ? '' : 'none' }}>
            <SelectContainer style={{ paddingBottom: '10px' }}>
              <SingleSelect
                label={'New Job Title'}
                options={jobTitles}
                {...useInput('newJobTitle')}
                disabled={isReadOnly}
                required={cdpActionAdminJobUpdateOptionVisible}
              />
            </SelectContainer>
          </DetailsLine>
          <DetailsLine>
            <TextField
              style={{ display: cdpActionCommentVisibility }}
              multiline
              fullWidth
              rows={3}
              required={cdpActionCommentRequired}
              label={cdpActionCommentLabel}
              variant="outlined"
              {...useInput('actionComments')}
              disabled={isReadOnly}
            />
          </DetailsLine>

          <BodyTitle
            style={{ display: cdpActionAdminJobUpdateOptionVisible && userHasAdminPermission() ? '' : 'none' }}
          >
            Admin options
          </BodyTitle>

          <DetailsLine
            style={{ display: cdpActionAdminJobUpdateOptionVisible && userHasAdminPermission() ? '' : 'none' }}
          >
            <RadioGroupAdminContainer row {...useInput('jobTitleUpdateApproval')}>
              <FormControlLabel
                value={'yes'}
                control={<Radio />}
                label={'Approve'}
                onChange={handleAdmnRadioButtonApprove}
                disabled={isReadOnly}
              />
              <FormControlLabel
                value={'no'}
                control={<Radio />}
                label={'Deny'}
                onChange={handleAdminRadioButtonDeny}
                disabled={isReadOnly}
              />
            </RadioGroupAdminContainer>
          </DetailsLine>

          <DetailsLineWithRadioButtons
            style={{ display: cdpActionDisciplinaryOptionVisible && userHasAdminPermission() ? '' : 'none' }}
          >
            <SubTilte>Employee has signed the disciplinary letter?</SubTilte>

            <RadioGroupAdminContainer row {...useInput('disciplinaryAction')}>
              <FormControlLabel
                value={'yes'}
                control={<Radio />}
                label={'Yes'}
                onChange={handleAdminRadioButtonDeny}
                disabled={isReadOnly}
              />
              <FormControlLabel
                value={'no'}
                control={<Radio />}
                label={'No'}
                onChange={handleAdminRadioButtonDeny}
                disabled={isReadOnly}
              />
            </RadioGroupAdminContainer>
          </DetailsLineWithRadioButtons>

          <DetailsLine
            style={{
              display:
                (cdpActionAdminJobUpdateOptionVisible || cdpActionDisciplinaryOptionVisible) &&
                userHasAdminPermission() &&
                (values.jobTitleUpdateApproval || values.disciplinaryAction)
                  ? ''
                  : 'none',
            }}
          >
            <TextField
              multiline
              fullWidth
              rows={3}
              required={isAdmnReasonFiledRequired}
              label={cdpActionCommentLabel}
              variant="outlined"
              {...useInput('adminComments')}
              disabled={isReadOnly}
            />
          </DetailsLine>

          <ButtonContainer>
            <Submit onClick={handleLoadPreviousCdpData}>Load previous CDP data</Submit>
            {savingDevelopmentPlan && <Spinner size={30} />}
            <Submit
              disabled={savingDevelopmentPlan || loading || isReadOnly || !isValid}
              onClick={handleSaveCareerDevelopmentPlan}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
      {careerDevelopmentPlan && (
        <TemplateModal showModal={showModal} setShowModal={setShowModal} templateId={careerDevelopmentPlan.template} />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
`;

const DetailsLine = styled.div`
  display: block;
  padding-left: 30px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const DetailsLineWithRadioButtons = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
`;

const SubTilte = styled.div`
  font-size: 16px;
  margin-right: 10px;
`;

const DetailsLineList = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: -3px;
`;

const SelectContainer = styled.div`
  min-width: 160px;
  display: block;
  float: left;
`;

const TopButtonsContainer = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;
const VisibilityContainer = styled(IconButton)`
  padding: 0 5px;
  height: 30px;
`;

const VisibilityIconEye = styled(VisibilityIcon)`
  color: white;
`;

const ClearDiv = styled.div`
  clear: both;
`;

const RadioGroupContainer = styled(RadioGroup)`
  padding: 10px;
`;

const RadioGroupAdminContainer = styled(RadioGroup)`
  text-align: center;
  display: block;
`;

const Text = styled.div`
  font-weight: bold;
`;

const SingleSelectContainer = styled(SingleSelect)`
  width: 280px;
`;

const ExpandTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: auto !important;
  }
`;

export default CareerDevelopmentPlanForm;
