import { isPermissionGranted, ROLE_ADMIN, ROLE_FINANCE } from 'helpers/roleHelper';
import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { UserResource } from 'services/authService';
import { getRewardHistoricalById } from 'services/rewardsHistoricalService';
import { getRewardRefundById, RewardRefund, SaveRewardRefund } from 'services/rewardsRefundService';
import Constants from 'utils/constants';

const defaultRewardRefund = {
  realAmount: '',
  requestComment: '',
  purchasedDate: '', // new Date().toString(),
  purchaseCompany: '',
  purchased: '',
  electronicInvoice: '',
  invoice: '',
  rewStatusID: '',
};

export const useRewardRefund = (id: string) => {
  const [saveRewardRefund, setSaveRewardRefund] = useState<SaveRewardRefund>(defaultRewardRefund);
  const [fileNameLabel, setFileNameLabel] = useState('');
  const [loading, setLoading] = useState(false);
  const [realAmount, setRealAmount] = useState('');
  const [purchasedDate, setPurchasedDate] = useState(new Date().toISOString());
  const [invoiceFile, setInvoiceFile] = useState<FormData>();
  const [isAttachButtonEnable, setIsAttachButtonEnable] = useState('');
  let [rewardsRefundCommnet, setRewardsRefundCommnet] = useState('');
  const [hasPermission, setHasPermision] = useState(false);
  const user = useCurrentUser();

  const fetchRewardRefund = useCallback(async () => {
    const rewardResult = await getRewardRefundById(id);

    if (rewardResult) {
      if (rewardResult.rewStatusID.statusName !== Constants.REWARD_APPROVED_STATUS_NAME) {
        const rewardHistorical = await getRewardHistoricalById(
          id,
          rewardResult.realAmount !== '' ? Constants.REWARD_PENDING_REFUND_STATUS_NAME : '',
        );

        setRewardsRefundCommnet(rewardHistorical?.requestComment ?? '');
      }

      setRealAmount(rewardResult.realAmount || '');
      if (
        rewardResult.purchaseCompany === '' &&
        (rewardResult.realAmount === '' || rewardResult.realAmount === undefined) &&
        rewardResult.purchased === ''
      ) {
        rewardResult.purchasedDate = '';
        setPurchasedDate(rewardResult.purchasedDate);
      }

      if (rewardResult.realAmount === null || rewardResult.realAmount === undefined) rewardResult.realAmount = '';

      setIsAttachButtonEnable(rewardResult.electronicInvoice);

      if (rewardResult.invoice?.indexOf('base64') !== -1) {
        let formData = new FormData();
        const fileNameProperty = rewardResult.invoice?.split(',')[0];
        const fileContent = rewardResult.invoice?.split(',')[2] || '';
        const fileName =
          fileNameProperty?.substring(fileNameProperty?.indexOf(':') + 1, fileNameProperty?.length) || '';

        const base64toblob = dataURItoBlob(`${rewardResult.invoice?.split(',')[1]},${fileContent}`);
        const blobtofile = blobToFile(base64toblob, 'fff');
        var file = new File([blobtofile], fileName, {
          lastModified: 1534584790000,
          type: blobtofile.type,
        });

        setFileNameLabel(fileName);
        formData.append('file', file);
        formData.append('filename', fileName);
        setInvoiceFile(formData);
      }

      setSaveRewardRefund(mapRewardResponse(rewardResult, rewardsRefundCommnet));
    }
  }, [id, rewardsRefundCommnet]);

  useEffect(() => {
    if (id) fetchRewardRefund();
    setHasPermision(isPermissionGranted([ROLE_ADMIN, ROLE_FINANCE], user));
  }, [fetchRewardRefund, id, user]);

  return {
    saveRewardRefund,
    setSaveRewardRefund,
    fetchRewardRefund,
    loading,
    setLoading,
    setRealAmount,
    realAmount,
    purchasedDate,
    invoiceFile,
    setInvoiceFile,
    isAttachButtonEnable,
    setIsAttachButtonEnable,
    fileNameLabel,
    setFileNameLabel,
    hasPermission,
  };
};

const mapRewardResponse = (rewardRefund: RewardRefund, rewardsRefundCommnet: string) => {
  return {
    realAmount: rewardRefund.realAmount,
    requestComment: rewardsRefundCommnet,
    purchasedDate: rewardRefund.purchasedDate,
    purchaseCompany: rewardRefund.purchaseCompany,
    purchased: rewardRefund.purchased,
    invoice: rewardRefund.invoice,
    electronicInvoice: rewardRefund.electronicInvoice || '',
    rewStatusID: rewardRefund.rewStatusID,
  } as SaveRewardRefund;
};

function dataURItoBlob(dataURI: any) {
  var binary = atob(dataURI.split(',')[1]);
  var array: any[] = [];

  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

const blobToFile = (blob: any, fileName: string) => {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
};
