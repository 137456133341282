import usePaging, { PageResource, Query } from 'hooks/usePaging';
import { useEffect, useState } from 'react';
import { CategoryResource, getCategoriesQuery } from 'services/categoriesService';
import { DEFAULT_QUERY_WITH_SEARCH } from 'utils/general/generalMethods';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';

const localStoragePrefix = '_categories';

export const useCategoriesEffect = () => {
  const defaultResponse: PageResource<CategoryResource> = { totalItems: 0, items: [] };
  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY_WITH_SEARCH);
  const [categories, setCategories] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>(localStorage.getItem('searchText_categories') || '');
  const fetchCategories = async () => {
    if (searchText === '') {
      setLoading(true);
    }
    query.searchText = searchText;
    const newCategories = await getCategoriesQuery(query);

    setLoading(false);
    setCategories(newCategories);
  };

  const onSearchTextChange = (event: any) => {
    const currentValue = event.target.value;

    saveInfoInLocalStorage('searchText', currentValue, localStoragePrefix);

    setQuery({ ...query, page: 1 });
    setSearchText(currentValue);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchCategories();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText, query]);

  return {
    categories,
    loading,
    searchText,
    setLoading,
    fetchCategories,
    onSearchTextChange,
    handlePageChange,
    handleColumnSorting,
    query,
  };
};
