import { Checkbox, FormControlLabel } from '@material-ui/core';
import SingleSelectNoValue from 'components/single-select-no-value';
import React, { useState } from 'react';
import { ScreenOnboardingProcessResource } from 'services/screenOnboardingProcessService';
import { Item, OnboardingItem } from 'shared/types';
import styled from 'styled-components';

type Props = {
  description: OnboardingItem;
  opStatuses: Item[];
  setSaveOnboardingProcess: any;
  saveOnboardingProcess: ScreenOnboardingProcessResource | undefined;
};

const ItemList: React.FC<Props> = (props) => {
  const { description, opStatuses, setSaveOnboardingProcess, saveOnboardingProcess } = props;

  const [valueSelect, setValueSelect] = useState<string>(description.opStatus);
  const [valueCheck, setValueCheck] = useState<boolean>(
    description.opStatus === 'Completed' || description.opStatus === 'N/A',
  );

  const onChange = (event: any) => {
    if (!saveOnboardingProcess) return;

    const { value } = event.target;

    setValueSelect(value);
    if (value === 'Completed') {
      setValueCheck(value === 'Completed');
    } else if (value === 'N/A') {
      setValueCheck(value === 'N/A');
    } else {
      setValueCheck(value === '');
    }

    findDescription(saveOnboardingProcess, value);
  };

  const onCheck = (event: any) => {
    const { checked } = event.target;

    if (!saveOnboardingProcess) return;

    if (!checked) {
      setValueSelect('Pending');
      setValueCheck(checked);

      findDescription(saveOnboardingProcess, 'Pending');
    } else {
      setValueSelect('Completed');
      setValueCheck(checked);

      findDescription(saveOnboardingProcess, 'Completed');
    }
  };

  const findDescription = (saveOnboardingProcess: ScreenOnboardingProcessResource, value: string) => {
    const childDescription = saveOnboardingProcess.descriptions.find(
      (itemDescription) => itemDescription._id === description.id,
    );

    if (!childDescription) return;

    childDescription.opStatus = value;

    setSaveOnboardingProcess({ ...saveOnboardingProcess, childDescription });
  };

  return (
    <OptionsContainer>
      <CheckboxLabel
        key={description.id}
        control={<Checkbox key={`check-${description.id}`} checked={valueCheck} onChange={onCheck} color="secondary" />}
        label={description.description}
      />
      <SelectContainer>
        <SingleSelectNoValue
          label={'Status'}
          options={opStatuses}
          value={valueSelect}
          onChange={onChange}
          name={'description'}
        />
      </SelectContainer>
    </OptionsContainer>
  );
};

const SelectContainer = styled.div`
  display: flex;
  min-width: 25%;
  margin-right: 3%;

  margin-top: 1%;
`;
const CheckboxLabel = styled(FormControlLabel)`
  display: flex;
  min-width: 75%;
  margin-right: 3%;
  overflow: hidden;
`;
const OptionsContainer = styled.div`
  display: flex;

  border: 1px solid #dddddd;
  border-radius: 3px;
`;

export default ItemList;
