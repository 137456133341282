/* eslint-disable react/jsx-no-bind */

import CopyButton from 'components/copy-button-exit-template';
import { Column } from 'components/standard-table/types';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import {
  copyExitProcedureTemplate,
  deleteExitProcedureTemplate,
  ExitProcedureResource,
} from 'services/exitProcedureTemplate';
import styled from 'styled-components';

const fixedColumns: Column<ExitProcedureResource>[] = [
  {
    label: 'Title',
    sortKey: 'title',
    path: 'title',
  },
];

const variableColumns = (fetchExitProcedureTemplates: any, setLoading: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <CopyButtonContainer>
          <CopyButton id={item._id} fetchItems={fetchExitProcedureTemplates} copyItem={copyExitProcedureTemplate} />
        </CopyButtonContainer>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchExitProcedureTemplates}
          deleteItem={deleteExitProcedureTemplate}
          setLoading={setLoading}
          url={'exit-procedure-templates'}
          name={'Exit procedure template'}
          deleteTitle={'Delete Exit Procedure Template'}
          deleteMessage={'Do you want to delete this exit procedure template?'}
        />
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const CopyButtonContainer = styled.div`
  float: left;
`;

const columns = (fetchExitProcedureTemplates: any, setLoading: any) => {
  return [...fixedColumns, variableColumns(fetchExitProcedureTemplates, setLoading)];
};

export default columns;
