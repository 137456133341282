import { CircularProgress, TextField, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import CurrencyInput from 'components/currencyInput';
import SingleSelect from 'components/single-select';
import { useForm } from 'hooks/useForm';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createReward } from 'services/rewardsService';
import styled from 'styled-components';
import { isBadRequest } from 'utils/checkStatusCode/checkStatusCode';
import Constants from 'utils/constants';
import { dateFormatUsed, dateFormatUsedDisplay } from 'utils/formatters/date';
import { saveInfoInLocalStorage } from 'utils/localStorageManagment/localStorageManager';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import PaymentRequest from '../rewards-payment-request';
import { RewardRefund } from '../rewards-refund';
import RewardValidation from '../rewards-validation';
import { useCatalogs, useRewardEffect } from './hooks';

saveInfoInLocalStorage('inputValue', '', '_form');

type Props = {};

const schemaReward = {
  reasonID: yup.string().required().label('Reward Reason'),
  requestDescription: yup.string().required().label('Description'),
  estimatedAmount: yup.string().required().label('Estimated Amount').typeError('Invalid Number'),
};

const RewardsCreationForm: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const { reasonsReward, loading: catalogLoading } = useCatalogs(id);

  const { saveReward, rewardCreationDate, employeeSelected, rewStatusNameSelected, reward, loading } = useRewardEffect(
    id,
  );

  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';

  let { useInput, useCurrencyInput, values, isValid } = useForm(saveReward, schemaReward as any, catalogLoading);
  const { isSubmiting, handleSaveReward: handleSaveScreenReward } = useSubmitForm(values, true);

  const user = useCurrentUser();

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Employee: ${!initialLoading ? `${user?.name} ${user?.lastName}` : 'Loading'}`}
          subTitle="Request a new reward "
        />

        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Employee Information:</BodyTitle>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainer>
                <TextField
                  label="Email"
                  variant="outlined"
                  name="employeeEmail"
                  id="employeeEmail"
                  disabled={true}
                  value={id ? employeeSelected : user!.email}
                />
              </SelectContainer>

              <DateContainer>
                <Tooltip title={`Format ${dateFormatUsedDisplay}`} placement="top-start">
                  <KeyboardDatePicker
                    id={'creationDateSelect'}
                    label="Creation Date"
                    disabled={true}
                    format={dateFormatUsed}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    value={rewardCreationDate}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={() => {}}
                  />
                </Tooltip>
              </DateContainer>
            </OptionsContainer>
          </DetailsLine>
          <br></br>
          <DetailsLine>
            <OptionsContainer>
              <SelectContainer>
                <TextField
                  label="Estimated Amount"
                  variant="outlined"
                  name="estimatedAmount"
                  id="estimatedAmount"
                  required
                  disabled={id ? true : false}
                  {...useCurrencyInput('estimatedAmount')}
                  InputProps={{
                    inputComponent: CurrencyInputContainer as any,
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <SingleSelect
                  id={'reasonID'}
                  required
                  label={'Reason'}
                  options={reasonsReward}
                  disabled={id ? true : false}
                  {...useInput('reasonID')}
                />
              </SelectContainer>
            </OptionsContainer>
          </DetailsLine>

          <TextBody>
            <TextField
              required
              multiline
              fullWidth
              rows={4}
              id="descriptionInput"
              label="Describe how the yearly budget will be used"
              variant="outlined"
              inputProps={{ maxLength: 5000 }}
              disabled={id ? true : false}
              {...useInput('requestDescription')}
            />
          </TextBody>

          <ButtonContainer style={{ display: !id ? '' : 'none' }}>
            {isSubmiting && <Spinner size={30} />}
            <Submit disabled={isSubmiting || loading || catalogLoading || !isValid} onClick={handleSaveScreenReward}>
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
      {rewStatusNameSelected !== '' ? (
        <RewardValidation statusInTheMainScreen={rewStatusNameSelected} rewardId={id} />
      ) : (
        ''
      )}
      {rewStatusNameSelected === Constants.REWARD_APPROVED_STATUS_NAME ||
      rewStatusNameSelected === Constants.REWARD_PENDING_REFUND_STATUS_NAME ||
      rewStatusNameSelected === Constants.REWARD_REFUNDED_STATUS_NAME ||
      (rewStatusNameSelected === Constants.REWARD_REJECTED_STATUS_NAME && reward?.realAmount !== '') ? (
        <RewardRefund statusInTheMainScreen={rewStatusNameSelected} rewardId={id} />
      ) : (
        ''
      )}
      {((rewStatusNameSelected === Constants.REWARD_PENDING_REFUND_STATUS_NAME ||
        rewStatusNameSelected === Constants.REWARD_REFUNDED_STATUS_NAME) &&
        reward?.realAmount !== '') ||
      (rewStatusNameSelected === Constants.REWARD_REJECTED_STATUS_NAME && reward?.realAmount !== '') ? (
        <PaymentRequest statusInTheMainScreen={rewStatusNameSelected} rewardId={id} />
      ) : (
        ''
      )}
    </Container>
  );
};

const useSubmitForm = (values: any, isValid: boolean) => {
  const history = useHistory();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const handleSaveScreenReward = useCallback(async () => {
    if (!isValid) return notifier.error('Please, fix the errors on the form before submitting');
    setIsSubmitting(true);

    try {
      const newReward = await createReward(values);

      if (newReward) {
        notifier.success('Reward created successfully.');
        history.push(`/rewards/`);
      }
    } catch (error) {
      if (isBadRequest(error)) notifier.error(error.response.data.friendlyMessage);
    }

    setIsSubmitting(false);
  }, [isValid, values, history]);

  return { isSubmiting, handleSaveReward: handleSaveScreenReward };
};

const TextBody = styled.div`
  margin: 20px;
`;

const SelectContainer = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 280px;
  float: left;
  display: flex;
  align-items: center;
`;

const DateContainer = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  width: 185px;
  float: left;
  display: flex;
  align-items: center;
`;

const DetailsLine = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
`;

const OptionsContainer = styled.div`
  display: block;
  float: left;
`;
const CurrencyInputContainer = styled(CurrencyInput)`
  margin-right: 20px;
  width: 233px;
  float: left;
`;

const Container = styled.div`
  margin: 0 15px;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 0px;
  float: right;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300px;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 1.3em;
  margin-bottom: 15px;
`;

export default RewardsCreationForm;
