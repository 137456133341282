import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import { useForm } from 'hooks/useForm';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createCategory, updateCategory } from 'services/categoriesService';
import styled from 'styled-components';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { useCategoryEffect } from './hooks';

const schemaCategory = {
  name: yup.string().required().label('Name'),
};

type Props = {};

const HitgoalsCategoryForm: React.FC<Props> = () => {
  const { id } = useParams<{ id?: string }>();
  const { category, loading } = useCategoryEffect(id);
  const history = useHistory();
  const [savingCategory, setSavingCategory] = useState(false);
  const { useInput, values, isValid } = useForm(category, schemaCategory);
  const subTitle = id ? 'Update the category' : 'Create a new category';
  const initialLoading = loading;
  const display = initialLoading ? 'none' : 'block';

  const handleSaveCategoryItem = async () => {
    if (!isValid) {
      notifier.error('Please, fix the errors on the form before submitting');
      return;
    }

    setSavingCategory(true);

    if (id) {
      const response = await updateCategory(values, id);

      if (response) notifier.success('Category updated successfully.');
      setSavingCategory(false);
      return;
    }

    const newCategory = await createCategory(values);

    if (newCategory) {
      notifier.success('Category created successfully.');
      history.push(`/categories/${newCategory._id}`);
    }
    setSavingCategory(false);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          loading={initialLoading}
          title={`Category: ${!initialLoading ? values?.name : 'Loading'}`}
          subTitle={subTitle}
        />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <CardBodyStyled display={display}>
          <BodyTitle>Name</BodyTitle>
          <TextBody>
            <TextField
              required
              fullWidth
              inputProps={{
                maxLength: 32,
              }}
              id={'categoryName'}
              label="Name"
              variant="outlined"
              {...useInput('name')}
            />
          </TextBody>

          <ButtonContainer>
            {savingCategory && <Spinner size={30} />}
            <Submit
              id={'saveCategoryButton'}
              disabled={savingCategory || loading || !isValid}
              onClick={handleSaveCategoryItem}
            >
              Save
            </Submit>
          </ButtonContainer>
        </CardBodyStyled>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 15px;
  text-align: center;
`;

const CardBodyStyled = styled(CardBody)<{ display: string }>`
  text-align: left;
  display: ${(props) => props.display};
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.light};
`;

const Submit = styled(Button)`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  :hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }
  :disabled {
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.common.white};
  }
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
`;

const BodyTitle = styled.h4`
  color: ${(p) => p.theme.palette.text.primary};
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  margin-bottom: 3px;
  text-decoration: none;
  font-size: 1.3em;
`;

const TextBody = styled.div`
  margin: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  float: right;
  padding: 20px;
`;

export default HitgoalsCategoryForm;
