import { useCallback, useEffect, useState } from 'react';
import {
  DescriptionResource,
  ExitProcedureResource,
  getExitProcedureById,
  SaveExitProcedureResource,
} from 'services/exitProcedureTemplate';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const defaultExitProcedureTemplateResource = {
  title: '',
  descriptions: new Array<DescriptionResource>(),
  isActive: true,
};

export const useExitProcedureTemplateEffect = (id?: string) => {
  const [saveExitProcedureTemplate, setSaveExitProcedureTemplate] = useState<SaveExitProcedureResource>(
    defaultExitProcedureTemplateResource,
  );
  const [exitProcedureTemplate, setExitProcedureTemplate] = useState<ExitProcedureResource>();
  const [creationdate, setCreationDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const fetchExitProcedureTemplate = useCallback(async () => {
    setLoading(true);
    const exitProcedureTemplateResult:ExitProcedureResource = await getExitProcedureById(id!);

    if (exitProcedureTemplateResult) {
      setSaveExitProcedureTemplate(mapExitProcedureTemplateResponse(exitProcedureTemplateResult));
      setExitProcedureTemplate(exitProcedureTemplateResult);
      setCreationDate(stringDateToHumanFormat(exitProcedureTemplateResult.creationDate.toString()));
      setIsActive(exitProcedureTemplateResult.isActive);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) fetchExitProcedureTemplate();
  }, [id, fetchExitProcedureTemplate]);

  return {
    saveExitProcedureTemplate,
    exitProcedureTemplate,
    creationdate,
    loading,
    setSaveExitProcedureTemplate,
    isActive,
    setIsActive,
  };
};

const mapExitProcedureTemplateResponse = (exitProcedureTemplate: ExitProcedureResource) => {
  return {
    title: exitProcedureTemplate.title,
    descriptions: exitProcedureTemplate.descriptions,
    isActive: exitProcedureTemplate.isActive,
  } as ExitProcedureResource;
};