/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import { deleteOneOnOne, OneOnOneResource } from 'services/oneOnOneService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';

const fixedColumns: Column<OneOnOneResource>[] = [
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Leader Name',
    sortKey: 'employee.leader.name',
    path: 'employee.leader.name',
    render: (item) =>
      `${item.employee.leader ? item.employee.leader!.name + ' ' + item.employee.leader!.lastName : '-'}`,
  },
  {
    label: 'Assigned to',
    sortKey: 'leaderAssigned.name',
    path: 'leaderAssigned.name',
    render: (item) => `${item.leaderAssigned ? item.leaderAssigned.name + ' ' + item.leaderAssigned.lastName : '-'}`,
  },
  {
    label: 'Creation Date',
    sortKey: 'creationDate',
    path: 'creationDate',
    render: (item) =>
      `${
        item.creationDate && stringDateToHumanFormat(item.creationDate) !== '01/Jan/1900'
          ? stringDateToHumanFormat(item.creationDate)
          : '-'
      }`,
  },
  {
    label: 'Status',
    sortKey: 'status.name',
    path: 'status.name',
    render: (item) => <StatusHighlighter name={item.status.name} />,
  },
  {
    label: 'Feeling',
    sortKey: 'feelings',
    path: 'feelings',
    render: (item) => `${item.feelings[0] ? item.feelings[0].name : '-'}`,
  },
  {
    label: 'Action Items',
    sortKey: 'numberActionItems',
    path: 'numberActionItems',
    render: (item) => `${item.numberActionItems ? item.numberActionItems : '-'}`,
  },
];

const variableColumns = (
  fetchOneOnOnes: any,
  setLoading: any,
  canDelete: boolean,
  onHistoryPressed: any,
  onReadOnlyPressed: any,
) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <Container>
          <IconButton onClick={() => onHistoryPressed(item.employee._id)} color="primary">
            <HistoryIcon />
          </IconButton>

          {(item.status.name === 'Complete' || item.status.name === 'Not Done') &&
            new Date(item.creationDate).getMonth() < new Date().getMonth() && (
              <IconButton onClick={() => onReadOnlyPressed(item._id)} color="primary">
                <VisibilityIcon />
              </IconButton>
            )}
        </Container>

        {(!(item.status.name === 'Complete' || item.status.name === 'Not Done') ||
          new Date(item.creationDate).getMonth() >= new Date().getMonth()) && (
          <TableRowAction
            id={item._id}
            editEnable
            deleteEnable={canDelete}
            fetchItems={fetchOneOnOnes}
            deleteItem={deleteOneOnOne}
            setLoading={setLoading}
            url={'one-on-ones'}
            name={'One On One'}
            deleteTitle={'Delete One On One'}
            deleteMessage={'Do you want to delete this one on one?'}
          />
        )}
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const columns = (
  fetchOneOnOnes: any,
  setLoading: any,
  canDelete: boolean,
  onHistoryPressed: any,
  onReadOnlyPressed: any,
) => {
  return [...fixedColumns, variableColumns(fetchOneOnOnes, setLoading, canDelete, onHistoryPressed, onReadOnlyPressed)];
};

export default columns;
