import { PageResource, Query } from 'hooks/usePaging';
import { CatalogResource } from 'shared/types';

import { UserResource } from './authService';
import { EmployeeResourceCompact } from './employeeService';
import http from './httpService';
import { ProjectResource } from './projectService';

const TIMESHEET_URL = '/api/timesheets';

export type TimeSheetFilter = {
  leaders: string[];
  projects: string[];
  month: string;
  year: string;
  search: string;
};

export type TimesheetResource = {
  _id: string;
  period: Date;
  employee: EmployeeResourceCompact;
  project: ProjectResource;
  timeSpent: number;
  timeRemaining: number;
  overtime: number;
  holidayTime: number;
  hasEmptyDescriptions: boolean;
};

export type TimesheetDayType = {
  _id: string;
  name: string;
};

export type TimesheetDay = {
  _id: string;
  date: Date;
  type: TimesheetDayType;
  subType: TimesheetDayType;
  taskDescription: string;
  timeSpent: number;
  overtime: number;
};

export type TimesheetMonth = {
  _id: string;
  period: Date;
  employee: string;
  project: ProjectResource | undefined;
  timeSpent: number;
  timeRemaining: number;
  overtime: number;
  otherTime: number;
  holidayTime: number;
  days: TimesheetDay[];
  uploadedFileName?: string;
};

export type SaveTimesheetMonth = {
  period: Date;
  user: UserResource;
  project: ProjectResource;
  timeSpent: number;
  timeRemaining: number;
  overtime: number;
  holidayTime: number;
  days: TimesheetDay[];
};

export type HoursPerMonth = {
  _id: string;
  year: number;
  month: number;
  totalHours: number;
  country: string;
};

export type SaveHoursPerMonth = {
  year: string;
  month: string;
  totalHours: string;
};

export type HolidaysPerYear = {
  _id: string;
  year: number;
  month: number;
  day: number | null;
  description: string;
  country: string;
};

export type SaveHolidaysPerYear = {
  year: string;
  month: string | null;
  day: string | null;
  description: string;
  country: string;
};

export type UpdateHours = {
  timesheetId: string;
  dayId: string;
  hours: string;
  date: Date;
};

export type HolidayFilter = {
  year: string;
  country: string;
};

export type UpdateDescription = {
  timesheetId: string;
  dayId: string;
  description: string;
};

export type UpdateDayType = {
  timesheetId: string;
  dayId: string;
  value: string;
};

export async function getTimesheetsByPeriod(period: string) {
  const { data } = await http.get<TimesheetMonth[]>(TIMESHEET_URL + http.toQueryString({ period: period }));

  return data;
}

export async function getTimesheetsByEmployeePeriodProject(employeeId: string, period: string, projectId: string) {
  const { data } = await http.get<TimesheetMonth>(
    TIMESHEET_URL + http.toQueryString({ employeeId: employeeId, period: period, projectId: projectId }),
  );

  return data;
}

export async function getTimesheetById(id: string) {
  const { data } = await http.get<TimesheetMonth>(`${TIMESHEET_URL}/${id}`);

  return data;
}

export async function deleteTimesheet(id: string) {
  const { data } = await http.delete<TimesheetMonth[]>(`${TIMESHEET_URL}/${id}`);

  return data;
}
export async function updateTimesheet(timesheet: TimesheetMonth) {
  const { data } = await http.put<TimesheetMonth>(`${TIMESHEET_URL}/`, timesheet);

  return data;
}

export async function createTimesheet(employee: string, period: string, project: string) {
  const { data } = await http.post<TimesheetMonth>(`${TIMESHEET_URL}`, {
    employee: employee,
    period: period,
    project: project,
  });

  return data;
}

export async function updateDayHours(hoursInfo: UpdateHours) {
  const { data } = await http.put<TimesheetMonth>(`${TIMESHEET_URL}/dayhours`, hoursInfo);

  return data;
}

export async function updateDayDescription(descriptionInfo: UpdateDescription) {
  const { data } = await http.put<TimesheetMonth>(`${TIMESHEET_URL}/daydescription`, descriptionInfo);

  return data;
}

export async function updateDayType(dayTypeInfo: UpdateDayType) {
  const { data } = await http.put<TimesheetMonth>(`${TIMESHEET_URL}/daytype`, dayTypeInfo);

  return data;
}

export async function updateDaySubType(daySubTypeInfo: UpdateDayType) {
  const { data } = await http.put<TimesheetMonth>(`${TIMESHEET_URL}/daysubtype`, daySubTypeInfo);

  return data;
}

export async function deleteDay(timesheetId, dayId: string) {
  const { data } = await http.delete<TimesheetMonth>(`${TIMESHEET_URL}/day/${timesheetId}/${dayId}`);

  return data;
}

export async function uploadFile(file: any, timesheetId: string) {
  const FormData = require('form-data');
  const formData = new FormData();

  formData.append('file', file);
  formData.append('fileName', file.name);
  formData.append('timesheetId', timesheetId);

  const { data } = await http.post<any>(`${TIMESHEET_URL}/fileUpload`, formData);

  console.log(data);
  return data;
}

export async function deleteFile(id: string) {
  const { data } = await http.delete<TimesheetMonth>(`${TIMESHEET_URL}/fileUpload/${id}`);

  return data;
}

export async function loadTimeSheetFile(timesheetId: string) {
  const { data } = await http.get(`${TIMESHEET_URL}/timesheet-file-download/${timesheetId}`, { responseType: 'blob' });

  return data;
}

//MANAGEMENT
export async function getMaxHoursByYear(year: string) {
  const { data } = await http.get<HoursPerMonth[]>(`${TIMESHEET_URL}/hourspermonth/${year}`);

  return data;
}

export async function createMaxHoursPerMonth(hoursPerMonth: SaveHoursPerMonth) {
  const { data } = await http.post<HoursPerMonth[]>(`${TIMESHEET_URL}/hourspermonth`, hoursPerMonth);

  return data;
}

export async function deleteMaxHoursPerMonth(id: string) {
  const { data } = await http.delete<HoursPerMonth[]>(`${TIMESHEET_URL}/hourspermonth/${id}`);

  return data;
}

export async function getCurrentMonthInfo() {
  const { data } = await http.get<HoursPerMonth>(`${TIMESHEET_URL}/hourspermonth/current`);

  return data;
}

export async function getHolidaysByFilter(filter: HolidayFilter) {
  const { data } = await http.get<HolidaysPerYear[]>(TIMESHEET_URL + '/holidaysbyfilter' + http.toQueryString(filter));

  return data;
}

export async function saveHolidayPerYear(holiday: SaveHolidaysPerYear) {
  const { data } = await http.post<HolidaysPerYear[]>(`${TIMESHEET_URL}/holidaysperyear`, holiday);

  return data;
}

export async function updateHolidayPerYear(id: String, holiday: SaveHolidaysPerYear) {
  const { data } = await http.put<HolidaysPerYear[]>(`${TIMESHEET_URL}/holidaysperyear/${id}`, holiday);

  return data;
}

export async function deleteTimesheetHolidayPerYear(id: string) {
  const { data } = await http.delete<HolidaysPerYear[]>(`${TIMESHEET_URL}/holidaysperyear/${id}`);

  return data;
}

export async function copyLastYearHolidays(filter: HolidayFilter) {
  const { data } = await http.get<HolidaysPerYear[]>(
    TIMESHEET_URL + '/holidaysperyear/copy/' + http.toQueryString(filter),
  );

  return data;
}

export async function getTimesheetsDayTypes() {
  const { data } = await http.get<CatalogResource[]>(TIMESHEET_URL + '/daytypes');

  return data;
}

export async function getTimesheetsDaySubTypes() {
  const { data } = await http.get<CatalogResource[]>(TIMESHEET_URL + '/daysubtypes');

  return data;
}

export async function loadPdfTimeSheetFileInfo(timesheetId: string) {
  const { data } = await http.get(`${TIMESHEET_URL}/${timesheetId}/pdf`, { responseType: 'blob' });

  return data;
}

export async function getAllTimesheetsQuery(query: Query) {
  const { data } = await http.get<PageResource<TimesheetResource>>(
    TIMESHEET_URL + '/query' + http.toQueryString(query),
  );

  return data;
}

export async function loadXlsReportTimeSheetFile(month: string, year: string) {
  const { data } = await http.get(`${TIMESHEET_URL}/xlsReport/${month}/${year}`, { responseType: 'blob' });

  return data;
}

export async function loadAllReportsTimeSheetZip(month: string, year: string, projects: string[]) {
  const { data } = await http.get(
    `${TIMESHEET_URL}/allReports/${month}/${year}/${projects.length > 0 ? projects : 'no_projects'}`,
    {
      responseType: 'blob',
    },
  );

  return data;
}

export async function generateHoursPerMonth(year: string) {
  const { data } = await http.get<HoursPerMonth[]>(`${TIMESHEET_URL}/hourspermonth/generate/${year}`);

  return data;
}

export async function loadPtoReport(month: string, year: string) {
  const { data } = await http.get(`${TIMESHEET_URL}/xlsReportPto/${month}/${year}`, { responseType: 'blob' });

  return data;
}
