import { CatalogResource } from 'shared/types';

import http from './httpService';

const RESIGNATION_REASONS_URL = '/api/resignationReasons';

export async function getResignationReasons() {
  const { data } = await http.get<CatalogResource[]>(RESIGNATION_REASONS_URL);

  return data;
}
