import { useForm } from 'hooks/useForm';
import { useCurrentUser } from 'providers/UserProvider';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCategories } from 'services/categoriesService';
import { getAllEmployees } from 'services/employeeService';
import {
  createHitgoal,
  getHitgoalById,
  HitgoalResource,
  KeyResultForHitGoalResource,
  updateHitgoal,
} from 'services/hitgoalProcessService';
import { getKeyResultAreas, getKeyResultTypes } from 'services/hitgoalsService';
import { getAllUsers } from 'services/usersService';
import { HITGOAL_STATUS, Item } from 'shared/types';
import constants from 'utils/constants';
import { generateTempKey } from 'utils/general/generalMethods';
import { collectionEmployeeToItem, collectionToItem } from 'utils/mappers/item';
import notifier from 'utils/notifiers/notifier';
import * as yup from 'yup';

import { schemaKeyResultItem } from './key-result-item';

export const defaultHitGoalResource = {
  _id: '',
  objective: '',
  employee: '',
  reasonID: '',
  category: '',
  type: '',
  comment: '',
  createdBy: '',
  deadLine: new Date().toString(),
  createdAt: new Date().toISOString(),
  keyResults: new Array<KeyResultForHitGoalResource>(),
  isUnsuccessful: false,
  unsuccessfulReason: '',
};

export const schemaHitgoal = {
  _id: yup.string().optional().label('Id'),
  objective: yup.string().required().label('Objective'),
  employee: yup.string().required().label('Employee'),
  reasonID: yup.string().required().label('Reason'),
  category: yup.string().required().label('Category'),
  type: yup.string().required().label('Type'),
  comment: yup.string().optional().label('Comment'),
  createdBy: yup.string().required().label('CreatedBy'),
  deadLine: yup.string().required().label('DeadLine'),
  createdAt: yup.string().optional().label('Created At'),
  keyResults: yup.array().of(schemaKeyResultItem.required()).optional().label('Key Results'),
  isUnsuccessful: yup.boolean().optional().label('Is Unsuccessful'),
  unsuccessfulReason: yup.string().optional().label('Unsuccessful Reason'),
};

const keyResultSelectionOptions = [
  { id: HITGOAL_STATUS.INCOMPLETE, label: HITGOAL_STATUS.INCOMPLETE },
  { id: HITGOAL_STATUS.COMPLETE, label: HITGOAL_STATUS.COMPLETE },
];

export type OnChangeEvent = React.ChangeEvent<{
  name: string;
  value: string;
}>;

export const useHitGoal = (id: string) => {
  // STATES

  const history = useHistory();
  const location = useLocation();
  const user = useCurrentUser();
  const [hitgoal, setHitgoal] = useState<HitgoalResource>(defaultHitGoalResource);
  const { useInput, useDateInput, values, isValid, useSwitch } = useForm(hitgoal, schemaHitgoal);
  const [keyResults, setKeyResults] = useState<KeyResultForHitGoalResource[]>([]);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [reasons, setReasons] = useState<Item[]>([]);
  const [categories, setCategories] = useState<Item[]>([]);
  const [users, setUsers] = useState<Item[]>([]);
  const [types, setTypes] = useState<Item[]>([]);
  const [keyResultStatus] = useState<Item[]>(keyResultSelectionOptions);
  const [loading, setLoading] = useState(false);
  const [enabledAddKeyResult, setEnableAddKeyResult] = useState(true);
  const [savingType, setSavingType] = useState(false);
  const [isKeyResultListValid, setKeyResultValid] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [newKeyResult, setNewKeyResult] = useState<KeyResultForHitGoalResource>({
    _id: generateTempKey('id'),
    keyResult: '',
    source: '',
    status: HITGOAL_STATUS.INCOMPLETE,
  });
  const [unsuccessfulReasonText, setUnsuccessfulReasonText] = useState('');
  const [unsuccessfulErrorText, setUnsuccessfulErrorText] = useState('');

  // HANDLERS

  const handleAddNewKeyResult = () => {
    if (newKeyResult.keyResult !== '' && newKeyResult.source.trim() !== '') {
      setKeyResults((previousData) => [...previousData, newKeyResult]);
      setNewKeyResult({ _id: generateTempKey('id'), keyResult: '', source: '', status: HITGOAL_STATUS.INCOMPLETE });
    }
  };

  const handleOnNewKeyResult = useCallback(
    (event: OnChangeEvent) => {
      const { name, value } = event.target;

      setNewKeyResult({ ...newKeyResult, [name!]: value });
    },
    [newKeyResult],
  );

  const handleOnKeyResultChange = (event: OnChangeEvent, id: string) => {
    const { name, value } = event.target;

    setKeyResults(
      keyResults.map((keyResult) => {
        if (keyResult._id === id) return { ...keyResult, [name]: value };
        return keyResult;
      }),
    );
  };

  const handleOnMoveTemplateUp = (id: string) => {
    const index = keyResults.findIndex((keyResult) => keyResult._id === id);

    if (index > 0) {
      const selectedTemplate = keyResults[index];

      keyResults[index] = keyResults[index - 1];
      keyResults[index - 1] = selectedTemplate;
    }

    setKeyResults((keyResultItems) => [...keyResultItems]);
  };

  const handleOnMoveTemplateDown = (id: string) => {
    const index = keyResults.findIndex((keyResult) => keyResult._id === id);

    if (index !== -1 && index < keyResults.length - 1) {
      const selectedTemplate = keyResults[index];

      keyResults[index] = keyResults[index + 1];
      keyResults[index + 1] = selectedTemplate;
    }

    setKeyResults((keyResultItems) => [...keyResultItems]);
  };

  const handleOnDeteleTemplate = (id: string) => {
    setKeyResults((keyResultItems) => keyResultItems.filter((keyResult) => keyResult._id !== id));
  };

  const handleSaveHitgoalItem = async () => {
    values.keyResults = keyResults;
    values.unsuccessfulReason = unsuccessfulReasonText;

    if (!isValid || (values.isUnsuccessful && !values.unsuccessfulReason)) {
      notifier.error('Please, fix the errors on the form before submitting');
      if (values.isUnsuccessful && !values.unsuccessfulReason) setUnsuccessfulErrorText(constants.REQUIRED_FIELD_TEXT);
      return;
    }

    setSavingType(true);
    delete values._id;

    if (id) {
      const response = await updateHitgoal(values, id);

      if (response) notifier.success('HitGoal updated successfully.');
      setSavingType(false);
      return;
    }

    const newHitgoal = await createHitgoal(values);

    if (newHitgoal) {
      notifier.success('HitGoal created successfully.');
      history.push(`/hitGoals-process/${newHitgoal._id}`);
    }
    setSavingType(false);
  };

  const handleOnReasonChange = useCallback((event: OnChangeEvent) => {
    const { value } = event.target;

    setUnsuccessfulErrorText('');
    setUnsuccessfulReasonText(value);
  }, []);

  //DATA LOADING

  const fetchKeyResults = useCallback(async () => {
    setLoading(true);
    const [employeesResult, reasonsResult, typesResult, categoriesResult, usersResult] = await Promise.all([
      getAllEmployees(),
      getKeyResultAreas(),
      getKeyResultTypes(),
      getCategories(),
      getAllUsers(),
    ]);

    // const employeesItems = collectionEmployeeToItem(employeesResult);
    const employeesItems = collectionEmployeeToItem(employeesResult);
    const reasonsItems = collectionToItem(reasonsResult);
    const typesItems = collectionToItem(typesResult);
    const categoriesItems = collectionToItem(categoriesResult);
    const usersItems = collectionToItem(usersResult);

    setEmployees(employeesItems);
    setReasons(reasonsItems);
    setTypes(typesItems);
    setCategories(categoriesItems);
    setUsers(usersItems);

    if (!location.state)
      setHitgoal((currentValue) => ({
        ...currentValue,
        createdBy: user?._id || '',
      }));

    if (location.state) {
      const copiedHitGoal = location.state as HitgoalResource;

      copiedHitGoal.createdBy = user?._id || '';

      setHitgoal(copiedHitGoal);
      setKeyResults(copiedHitGoal.keyResults!);
      setIsCopy(true);
    }

    if (id) {
      const hitgoalResponse = await getHitgoalById(id!);

      setHitgoal(hitgoalResponse);
      setKeyResults(hitgoalResponse.keyResults!);
      setUnsuccessfulReasonText(hitgoalResponse.unsuccessfulReason || '');
    }

    setLoading(false);
  }, [id, location.state, user]);

  useEffect(() => {
    fetchKeyResults();
  }, [id, fetchKeyResults]);

  useEffect(() => {
    setEnableAddKeyResult(newKeyResult.keyResult.trim() === '' || newKeyResult.source.trim() === '');
  }, [newKeyResult]);

  useEffect(() => {
    const incompleteKeyResult = keyResults.find((keyResult) => !keyResult.keyResult || !keyResult.source);

    if (incompleteKeyResult) {
      setKeyResultValid(false);
    } else {
      setKeyResultValid(true);
    }
  }, [keyResults]);

  return {
    loading: loading,
    employees,
    reasons,
    types,
    categories,
    users,
    handleAddNewKeyResult,
    newKeyResult,
    handleOnNewKeyResult,
    enabledAddKeyResult,
    keyResults,
    handleOnMoveTemplateUp,
    handleOnMoveTemplateDown,
    handleOnDeteleTemplate,
    handleSaveHitgoalItem,
    handleOnKeyResultChange,
    keyResultStatus,
    useInput,
    useDateInput,
    values,
    isValid: isValid && keyResults.length > 0 && isKeyResultListValid,
    savingType,
    useSwitch,
    handleOnReasonChange,
    unsuccessfulReasonText,
    unsuccessfulErrorText,
    isCopy,
  };
};
