import {
  EmergencyContacts,
  HomeAddress,
  InsuranceInfoFlat,
  ResignationInfoFlat,
  SaveEmployeeResource,
} from 'services/employeeService';

import { TemporalSaveEmployeeResource } from './hooks';

export const transformToSaveObject = (values: TemporalSaveEmployeeResource) => {
  const homeAddress: HomeAddress = {
    country: values.homeAddressCountry,
    state: values.homeAddressState,
    city: values.homeAddressCity,
    zipCode: values.homeAddressZipCode,
    addressLine1: values.homeAddressAddressLine1,
    addressLine2: values.homeAddressAddressLine2,
  };

  const insuranceInfo: InsuranceInfoFlat = {
    status: values.insuranceStatus,
    inclusionDate: values.insuranceInclusionDate,
    comments: values.insuranceComments,
  };

  const emergencyContacts: EmergencyContacts = {
    name1: values.emergencyContactsName1,
    phoneNumber1: values.emergencyContactsPhoneNumber1,
    relationship1: values.emergencyContactsRelationship1,
    name2: values.emergencyContactsName2,
    phoneNumber2: values.emergencyContactsPhoneNumber2,
    relationship2: values.emergencyContactsRelationship2,
  };

  const resignationInfo: ResignationInfoFlat = {
    resignationReason: values.resignationReason,
    resignationDate: values.resignationDate,
    payrollEndDate: values.payrollEndDate,
    lastDay: values.lastDay,
    resignationComments: values.resignationComments,
  };

  return {
    email: values.email,
    clientEmail: values.clientEmail,
    name: values.name,
    lastName: values.lastName,
    leader: values.leader,
    isLeader: values.isLeader,
    isActive: values.isActive,
    project: values.project,
    birthdayDate: values.birthdayDate,
    hireDate: values.hireDate,
    effectiveHireDate: values.effectiveHireDate,
    authorizedEmployees: values.authorizedEmployees,
    homeAddress: homeAddress,
    employeeType: values.employeeType,
    mobilePhoneNumber: values.mobilePhoneNumber?.toString(),
    homePhoneNumber: values.homePhoneNumber ? values.homePhoneNumber.toString() : '0',
    legadmiId: values.legadmiId ? values.legadmiId.toString() : null,
    plate1: values.plate1,
    plate2: values.plate2,
    idNumber: values.idNumber,
    maritalStatus: values.maritalStatus,
    children: values.children,
    insuranceInfo: insuranceInfo,
    emergencyContacts: emergencyContacts,
    resignationInfo: resignationInfo,
    notes: values.notes,
    salaryPeriodMonth: values.salaryPeriodMonth,
    clientSalaryPeriodMonth: values.clientSalaryPeriodMonth,
    jobTitle: values.jobTitle || '',
    isTimesheetEnabled: values.isTimesheetEnabled,
    authorizedTimesheets: values.authorizedTimesheets,
    referBy: values.referBy,
    referralAmount: values.referralAmount?.toString(),
    yearlyBudget: values.yearlyBudget,
    activateRewards: values.activateRewards,
    primaryBackendLanguage: values.primaryBackendLanguage,
    alternativeBackendLanguage: values.alternativeBackendLanguage,
    primaryFrontendLanguage: values.primaryFrontendLanguage,
    alternativeFrontendLanguage: values.alternativeFrontendLanguage,
    otherSkills: values.otherSkills,
  } as SaveEmployeeResource;
};
