import { isPermissionGranted, ROLE_ADMIN, ROLE_FINANCE, ROLE_HR } from 'helpers/roleHelper';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserResource } from 'services/authService';
import { getCountries } from 'services/countriesService';
import {
  EmployeeResource,
  getAllEmployees,
  getAllIncludingEmployees,
  getAllLeaders,
  getEmployeeById,
} from 'services/employeeService';
import { getTypes } from 'services/employeeTypeServices';
import { getInsuranceStatuses } from 'services/insuranceStatusesService';
import { getJobTitles } from 'services/jobTitleService';
import { getMaritalStatuses } from 'services/maritalStatusesService';
import { getProjects } from 'services/projectService';
import { getResignationReasons } from 'services/resignationService';
import { getLastRateByEmployeeId, getLastSalaryByEmployeeId } from 'services/salaryService';
import { CountryResource, Item, StateResource } from 'shared/types';
import { decryptText } from 'utils/encryptionMethods/encryption';
import { convertDateToUTC } from 'utils/formatters/date';
import {
  citiesToItem,
  collectionEmployeeToItem,
  collectionToItem,
  countriesToItem,
  statesToItem,
} from 'utils/mappers/item';
import notifier from 'utils/notifiers/notifier';
import { OnChangeEvent } from 'utils/objectTypes/types';
import * as XLSX from 'xlsx';

export type TemporalSaveEmployeeResource = {
  name: string;
  lastName: string;
  email: string;
  clientEmail: string;
  leader: string;
  isLeader: boolean;
  project: string;
  jobTitle: string;
  isActive?: boolean;
  birthdayDate?: string | null;
  hireDate?: string | null;
  effectiveHireDate?: string | null;
  authorizedEmployees: string[];

  homeAddressCountry: string;
  homeAddressState: string;
  homeAddressCity: string;
  homeAddressZipCode: string;
  homeAddressAddressLine1: string;
  homeAddressAddressLine2: string;

  employeeType: string;
  mobilePhoneNumber: number;
  homePhoneNumber: number;
  legadmiId: number | '';
  plate1: string;
  plate2: string;
  idNumber: string;
  maritalStatus: string;
  children: number;

  insuranceStatus: string;
  insuranceInclusionDate: any;
  insuranceComments: string;

  emergencyContactsName1: string;
  emergencyContactsPhoneNumber1: number;
  emergencyContactsRelationship1: string;
  emergencyContactsName2: string;
  emergencyContactsPhoneNumber2: number;
  emergencyContactsRelationship2: string;

  resignationReason: string;
  resignationDate?: string | null;
  lastDay?: string | null;
  payrollEndDate?: string | null;
  resignationComments: string;

  primaryBackendLanguage: string;
  alternativeBackendLanguage: string;
  primaryFrontendLanguage: string;
  alternativeFrontendLanguage: string;
  otherSkills: string;

  notes: string;
  salaryPeriodMonth: string;
  clientSalaryPeriodMonth: string;
  isTimesheetEnabled: boolean;
  authorizedTimesheets: string[];
  referBy: string;
  referralAmount: number;
  yearlyBudget: string;
  activateRewards: boolean;
};

const defaultEmployeeResource: TemporalSaveEmployeeResource = {
  name: '',
  lastName: '',
  email: '',
  clientEmail: '',
  leader: '',
  project: '',
  jobTitle: '',
  isLeader: false,
  isActive: true,
  birthdayDate: null,
  hireDate: null,
  effectiveHireDate: null,
  authorizedEmployees: [],

  homeAddressCountry: 'Costa Rica',
  homeAddressState: '',
  homeAddressCity: '',
  homeAddressZipCode: '',
  homeAddressAddressLine1: '',
  homeAddressAddressLine2: '',

  employeeType: '',
  mobilePhoneNumber: 0,
  homePhoneNumber: 0,
  legadmiId: '',
  plate1: '',
  plate2: '',
  idNumber: '',
  maritalStatus: '',
  children: 0,

  insuranceStatus: '',
  insuranceInclusionDate: null,
  insuranceComments: '',

  emergencyContactsName1: '',
  emergencyContactsPhoneNumber1: 0,
  emergencyContactsRelationship1: '',
  emergencyContactsName2: '',
  emergencyContactsPhoneNumber2: 0,
  emergencyContactsRelationship2: '',

  resignationReason: '',
  resignationDate: null,
  payrollEndDate: null,
  lastDay: null,
  resignationComments: '',

  primaryBackendLanguage: '',
  alternativeBackendLanguage: '',
  primaryFrontendLanguage: '',
  alternativeFrontendLanguage: '',
  otherSkills: '',

  notes: '',
  salaryPeriodMonth: '',
  clientSalaryPeriodMonth: '',
  isTimesheetEnabled: true,
  authorizedTimesheets: [],
  referBy: '',
  referralAmount: 0,
  yearlyBudget: '',
  activateRewards: false,
};

export type ProjectChangeRecord = {
  effectiveDate: string | null;
  comments: string;
};

const defaultProjectChangeRecord: ProjectChangeRecord = {
  effectiveDate: null,
  comments: '',
};

export const useEmployeeEffect = (user: UserResource, id?: string) => {
  const history = useHistory();
  const [employee, setEmployee] = useState<TemporalSaveEmployeeResource>(defaultEmployeeResource);
  const [lastSalary, setLastSalary] = useState('');
  const [lastRate, setLastRate] = useState('');
  const [yearlyBudget, setYearlyBudget] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedReferBy, setSelectedReferBy] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasPermission, setHasPermision] = useState(false);
  const [selectedtimesheets, setSelectedtimesheets] = useState([] as string[]);
  const [projectChanged, setProjectChanged] = useState(false);
  const [projectChangeRecord, setProjectChangeRecord] = useState<ProjectChangeRecord>(defaultProjectChangeRecord);
  const [oldProjectId, setOldProjectId] = useState<string>('');
  const [jsonDataFromFile, setJsonDataFromFile] = useState('');
  const [displayUploadFileOption, setDisplayUploadFileOption] = useState(false);

  //Catalogs
  const [authorizedEmployees, setAuthorizedEmployees] = useState<Item[]>([]);
  const [leaders, setLeaders] = useState<Item[]>([]);
  const [projects, setProjects] = useState<Item[]>([]);
  const [maritalStatuses, setMaritalStatuses] = useState<Item[]>([]);
  const [employeeTypes, setEmployeeTypes] = useState<Item[]>([]);
  const [insuranceStatuses, setInsuranceStatuses] = useState<Item[]>([]);
  const [countries, setCountries] = useState<Item[]>([]);
  const [countriesInfo, setCountriesInfo] = useState<CountryResource[]>([]);
  const [resignationReasons, setResignationReasons] = useState<Item[]>([]);
  const [jobTitles, setJobTitles] = useState<Item[]>([]);
  const [employees, setEmployees] = useState<Item[]>([]);

  const fetchEmployee = useCallback(async () => {
    setLoading(true);
    setHasPermision(isPermissionGranted([ROLE_ADMIN, ROLE_HR, ROLE_FINANCE], user));

    if (!id) {
      setLoading(false);
      setDisplayUploadFileOption(true);
      return;
    }

    const employeeResult = await getEmployeeById(id!);

    if (employeeResult) {
      setEmployee(mapEmployeeResponse(employeeResult));
      setSelectedProject(employeeResult.project?._id || '');
      setSelectedReferBy(employeeResult.referBy?._id || '');
      setSelectedtimesheets(employeeResult.authorizedTimesheets || []);
      setYearlyBudget(employeeResult.yearlyBudget || '');

      if (hasPermission) {
        const lastSalaryResult = await getLastSalaryByEmployeeId(id!);
        const lastRateResult = await getLastRateByEmployeeId(id!);

        setLastSalary(decryptText(lastSalaryResult.salary).replaceAll(/\s/g, '').replace('$', ''));
        setLastRate(decryptText(lastRateResult.rate).replaceAll(/\s/g, '').replace('$', ''));
      } else {
        setLastSalary('');
        setLastRate('');
      }
    }
    setLoading(false);
  }, [id, user, hasPermission]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [
        employeesResult,
        leadersResult,
        projectsResult,
        maritalStatusesResult,
        insuranceStatusesResult,
        countriesResult,
        typesResult,
        resignationReasonsResult,
        jobTitlesResult,
        employeesAllIncludingResult,
      ] = await Promise.all([
        getAllEmployees(),
        getAllLeaders(),
        getProjects(),
        getMaritalStatuses(),
        getInsuranceStatuses(),
        getCountries(),
        getTypes(),
        getResignationReasons(),
        getJobTitles(),
        getAllIncludingEmployees(),
      ]);

      const employeeItems = collectionEmployeeToItem(employeesResult);
      const leaderItems = collectionEmployeeToItem(leadersResult);
      const projectsItems = collectionToItem(projectsResult);
      const maritalStatusesItems = collectionToItem(maritalStatusesResult);
      const typesItems = collectionToItem(typesResult);
      const insuranceStatusesItems = collectionToItem(insuranceStatusesResult);
      const resignationReasonsItems = collectionToItem(resignationReasonsResult);
      const countriesItems = countriesToItem(countriesResult);
      const jobTitlesItems = collectionToItem(jobTitlesResult);
      const employeeAllItems = collectionEmployeeToItem(employeesAllIncludingResult);

      setAuthorizedEmployees(employeeItems.filter((item: Item) => item.id !== id));
      setLeaders(leaderItems.filter((item: Item) => item.id !== id));
      setProjects(projectsItems);
      setMaritalStatuses(maritalStatusesItems);
      setInsuranceStatuses(insuranceStatusesItems);
      setLoading(false);
      setCountries(countriesItems);
      setCountriesInfo(countriesResult);
      setEmployeeTypes(typesItems);
      setResignationReasons(resignationReasonsItems);
      setJobTitles(jobTitlesItems);
      setEmployees(employeeAllItems);
    }

    fetchCatalogs();
  }, [id]);

  const getProjectName = (projectId: string) => {
    const currentProject = projects.find((project) => project.id === projectId);

    return currentProject?.label || '';
  };

  useEffect(() => {
    fetchEmployee();
  }, [id, fetchEmployee]);

  const handleProjectOnChange = (event: OnChangeEvent) => {
    const { value } = event.target;

    setSelectedProject(value);

    if (!id || !employee.project) {
      onProjectChangeConfirmation(value);
      return;
    }

    setOldProjectId(selectedProject);
    setProjectChanged(true);
  };

  const onProjectChangeConfirmation = (project?: string) => {
    if (!selectedtimesheets.find((projectId) => projectId === (project || selectedProject))) {
      const newList = ([] as string[]).concat(selectedtimesheets);

      newList.push(project || selectedProject);
      setSelectedtimesheets(newList);
    }

    setProjectChanged(false);
  };

  const onCancelEvent = () => {
    setProjectChangeRecord(defaultProjectChangeRecord);
    setSelectedProject(oldProjectId);
  };

  const handleOnProjectHistory = () => {
    history.push(`/employees/${id}/project-history`);
  };

  const resetHistoryDialog = () => {
    setProjectChangeRecord(defaultProjectChangeRecord);
  };

  const refreshSavedEmployee = (newEmployee: EmployeeResource) => {
    if (newEmployee.project) setEmployee({ ...employee, project: newEmployee.project._id });
  };

  const calculateSalaryPeriod = (selectedMonth: number) => {
    if (selectedMonth >= 1 && selectedMonth <= 4) {
      return 4;
    } else if (selectedMonth >= 5 && selectedMonth <= 8) {
      return 8;
    } else {
      return 0;
    }
  };

  const calculateInsuranceDate = (selectedDate: moment.Moment) => {
    return moment(selectedDate).add(3, 'months');
  };

  const handleConvertUploadedFile = (uploadedFile: any, setUploadedFile?: any) => {
    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result || null;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        setJsonDataFromFile(JSON.stringify(json, null, 2));
      };
      reader.readAsBinaryString(uploadedFile);
    }
  };

  const handleOnUploadFileClose = () => {
    setDisplayUploadFileOption(false);
  };

  useEffect(() => {
    if (!jsonDataFromFile) return;
    const data = JSON.parse(jsonDataFromFile);
    let changes = {};
    let country = '';
    let state = '';

    if (!data[1].hasOwnProperty('Attribute')) {
      notifier.error('File not supported');
      return;
    }

    data.map((row) => {
      if (row.Attribute === 'Country') country = row.Value;
      if (row.Attribute === 'State') state = row.Value;

      if (row.Value)
        changes = {
          ...changes,
          ...transformExcelValue(
            row.Attribute,
            row.Value,
            employeeTypes,
            jobTitles,
            maritalStatuses,
            countries,
            countriesInfo,
            projects,
            setSelectedProject,
            leaders,
            setLastSalary,
            setLastRate,
            country,
            state,
          ),
        };
    });

    const activeInsuranceStatus = insuranceStatuses.find((insuranceStatus) => insuranceStatus.label === 'Active');

    if (activeInsuranceStatus) {
      changes = { ...changes, insuranceStatus: activeInsuranceStatus.id };
    }

    setEmployee((oldValue) => ({ ...oldValue, ...changes }));
    setDisplayUploadFileOption(false);
  }, [
    countries,
    countriesInfo,
    employeeTypes,
    insuranceStatuses,
    jobTitles,
    jsonDataFromFile,
    leaders,
    maritalStatuses,
    projects,
  ]);

  return {
    employee,
    yearlyBudget,
    setYearlyBudget,
    lastSalary,
    setLastSalary,
    loading,
    hasPermission,
    selectedProject,
    setSelectedProject,
    selectedtimesheets,
    setSelectedtimesheets,
    selectedReferBy,
    setSelectedReferBy,
    lastRate,
    setLastRate,
    projectChanged,
    setProjectChanged,
    handleProjectOnChange,
    onProjectChangeConfirmation,
    projectChangeRecord,
    setProjectChangeRecord,
    oldProjectId,
    onCancelEvent,
    handleOnProjectHistory,
    resetHistoryDialog,
    getProjectName,
    refreshSavedEmployee,
    calculateSalaryPeriod,
    calculateInsuranceDate,
    jsonDataFromFile,
    setJsonDataFromFile,
    handleConvertUploadedFile,
    displayUploadFileOption,
    handleOnUploadFileClose,

    //Catalogs
    authorizedEmployees,
    leaders,
    projects,
    maritalStatuses,
    insuranceStatuses,
    countries,
    countriesInfo,
    employeeTypes,
    resignationReasons,
    jobTitles,
    employees,
  };
};

const transformExcelValue = (
  propertyName: string,
  value: string,
  employeeTypes: Item[],
  jobTitles: Item[],
  maritalStatuses: Item[],
  countries: Item[],
  countriesInfo: CountryResource[],
  projects: Item[],
  setSelectedProject: Function,
  leaders: Item[],
  setLastSalary: Function,
  setLastRate: Function,
  staticCountryName: string,
  staticStateName: string,
) => {
  let transformedValue;

  propertyName = propertyName.charAt(0).toLowerCase() + propertyName.slice(1);

  switch (propertyName) {
    case 'project':
      const project = projects.find((project) => project.label.toLocaleLowerCase() === value.toLocaleLowerCase());

      if (project) {
        setSelectedProject(project.id);
      }
      break;
    case 'contractModificationDate':
      transformedValue = { effectiveHireDate: changeTemplateDateFormat(value) };
      break;
    case 'hireDate':
      transformedValue = { hireDate: changeTemplateDateFormat(value) };
      break;
    case 'birthday':
      transformedValue = { birthdayDate: changeTemplateDateFormat(value) };
      break;
    case 'numberOfChildren':
      transformedValue = { children: value };
      break;
    case 'legadmiId':
      transformedValue = { legadmiId: value };
      break;
    case 'plateNumber1':
      transformedValue = { plate1: value };
      break;
    case 'plateNumber2':
      transformedValue = { plate2: value };
      break;
    case 'zipCode':
      transformedValue = { homeAddressZipCode: value };
      break;
    case 'type':
      const type = findValue(employeeTypes, value);

      if (type) {
        transformedValue = { employeeType: type.id };
      }
      break;
    case 'jobTitle':
      const jobTitle = findValue(jobTitles, value);

      if (jobTitle) {
        transformedValue = { jobTitle: jobTitle.id };
      }
      break;
    case 'maritalStatus':
      const maritalStatus = findValue(maritalStatuses, value);

      if (maritalStatus) {
        transformedValue = { maritalStatus: maritalStatus.id };
      }
      break;
    case 'country':
      const country = findValue(countries, value);

      if (country) {
        transformedValue = { homeAddressCountry: country.id };
      }
      break;
    case 'state':
      const stateList: StateResource[] | undefined = countriesInfo.find(
        (country) => country.CountryName.toLocaleLowerCase() === staticCountryName.toLocaleLowerCase(),
      )?.States;

      if (stateList) {
        const state = stateList.find((item) => item.StateName.toLocaleLowerCase() === value.toLocaleLowerCase());

        if (state) {
          transformedValue = { homeAddressState: state.StateName };
        }
      }
      break;
    case 'city':
      const stateList2: StateResource[] | undefined = countriesInfo.find(
        (country) => country.CountryName.toLocaleLowerCase() === staticCountryName.toLocaleLowerCase(),
      )?.States;

      if (stateList2) {
        const cityList: string[] | undefined = stateList2.find(
          (state) => state.StateName.toLocaleLowerCase() === staticStateName.toLocaleLowerCase(),
        )?.Cities;

        if (cityList) {
          const city = cityList.find((item) => item.toLocaleLowerCase() === value.toLocaleLowerCase());

          if (city) {
            transformedValue = { homeAddressCity: city };
          }
        }
      }
      break;
    case 'leader':
      const leader = findValue(leaders, value);

      if (leader) {
        transformedValue = { leader: leader.id };
      }
      break;
    case 'salary':
      setLastSalary(`$${value}`);
      break;
    case 'rate':
      setLastRate(`$${value}`);
      break;
    default:
      transformedValue = { [propertyName]: value.toString() };
  }

  return transformedValue;
};

const findValue = (itemList: Item[], propertyName: string) => {
  return itemList.find((item) => normalizeText(item.label) === normalizeText(propertyName));
};

const changeTemplateDateFormat = (stringDate: string) => {
  const dateData = stringDate.split('/');

  return `${dateData[1]}/${dateData[0]}/${dateData[2]}`;
};

const normalizeText = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLocaleLowerCase();
};

const mapEmployeeResponse = (employee: EmployeeResource) => {
  return {
    name: employee.name,
    lastName: employee.lastName,
    email: employee.email,
    clientEmail: employee.clientEmail,
    leader: employee.leader?._id,
    project: employee.project?._id,
    jobTitle: employee.jobTitle?.name || '',
    isLeader: employee.isLeader,
    isActive: employee.isActive,
    birthdayDate: employee.birthdayDate ? convertDateToUTC(new Date(employee.birthdayDate)) : null,
    hireDate: employee.hireDate ? convertDateToUTC(new Date(employee.hireDate)) : null,
    effectiveHireDate:
      employee.effectiveHireDate && !employee.effectiveHireDate.includes('1900')
        ? convertDateToUTC(new Date(employee.effectiveHireDate))
        : null,
    authorizedEmployees: employee.authorizedEmployees || [],

    homeAddressCountry: employee.homeAddress?.country || '',
    homeAddressState: employee.homeAddress?.state || '',
    homeAddressCity: employee.homeAddress?.city || '',
    homeAddressZipCode: employee.homeAddress?.zipCode || '',
    homeAddressAddressLine1: employee.homeAddress?.addressLine1 || '',
    homeAddressAddressLine2: employee.homeAddress?.addressLine2 || '',

    employeeType: employee.employeeType?._id || '',
    mobilePhoneNumber: employee.mobilePhoneNumber || 0,
    homePhoneNumber: employee.homePhoneNumber || 0,
    legadmiId: employee.legadmiId || null,
    plate1: employee.plate1,
    plate2: employee.plate2,
    idNumber: employee.idNumber,
    maritalStatus: employee.maritalStatus?._id || '',
    children: employee.children || 0,

    insuranceStatus: employee.insuranceInfo?.status._id || '',
    insuranceInclusionDate: employee.insuranceInfo?.inclusionDate || null,
    insuranceComments: employee.insuranceInfo?.comments || '',

    emergencyContactsName1: employee.emergencyContacts?.name1 || '',
    emergencyContactsPhoneNumber1: employee.emergencyContacts?.phoneNumber1 || 0,
    emergencyContactsRelationship1: employee.emergencyContacts?.relationship1 || '',
    emergencyContactsName2: employee.emergencyContacts?.name2 || '',
    emergencyContactsPhoneNumber2: employee.emergencyContacts?.phoneNumber2 || 0,
    emergencyContactsRelationship2: employee.emergencyContacts?.relationship2 || '',

    resignationReason: employee.resignationInfo?.resignationReason?._id || '',
    resignationDate: employee.resignationInfo?.resignationDate || null,
    payrollEndDate: employee.resignationInfo?.payrollEndDate || null,
    lastDay: employee.resignationInfo?.lastDay || null,
    resignationComments: employee.resignationInfo?.resignationComments || '',

    primaryBackendLanguage: employee.primaryBackendLanguage || '',
    alternativeBackendLanguage: employee.alternativeBackendLanguage || '',
    primaryFrontendLanguage: employee.primaryFrontendLanguage || '',
    alternativeFrontendLanguage: employee.alternativeFrontendLanguage || '',
    otherSkills: employee.otherSkills,

    notes: employee.notes || '',
    salaryPeriodMonth: employee.salaryPeriodMonth || '',
    clientSalaryPeriodMonth: employee.clientSalaryPeriodMonth || '',
    isTimesheetEnabled: employee.isTimesheetEnabled || false,
    authorizedTimesheets: employee.authorizedTimesheets || [],
    referBy: employee.referBy?._id,
    referralAmount: employee.referralAmount || 0,
    yearlyBudget: employee.yearlyBudget || '',
    activateRewards: employee.activateRewards,
  } as TemporalSaveEmployeeResource;
};

export const useCountriesInformation = (selectedCountry: string, countriesInfo: CountryResource[]) => {
  const [states, setStates] = useState<Item[]>([]);

  const loadStates = useCallback(() => {
    if (selectedCountry) {
      const selectedStates: StateResource[] | undefined = countriesInfo.find(
        (country) => country.CountryName === selectedCountry,
      )?.States;

      if (selectedStates) setStates(statesToItem(selectedStates));
      else setStates([]);
    } else setStates([]);
  }, [countriesInfo, selectedCountry]);

  useEffect(() => {
    async function loadCountriesInformation() {
      loadStates();
    }
    loadCountriesInformation();
  }, [loadStates, selectedCountry]);

  return { states, setStates, loadStates };
};

export const useStatesInformation = (
  selectedCountry: string,
  selectedState: string,
  countriesInfo: CountryResource[],
) => {
  const [cities, setCities] = useState<Item[]>([]);

  const loadCities = useCallback(() => {
    if (selectedState && countriesInfo) {
      const selectedStates: StateResource[] | undefined = countriesInfo.find(
        (country) => country.CountryName === selectedCountry,
      )?.States;

      if (selectedStates) {
        const selectedCities: string[] | undefined = selectedStates.find((state) => state.StateName === selectedState)
          ?.Cities;

        if (selectedCities) setCities(citiesToItem(selectedCities));
        else setCities([]);
      }
    } else setCities([]);
  }, [countriesInfo, selectedCountry, selectedState]);

  useEffect(() => {
    async function loadCountriesInformation() {
      loadCities();
    }
    loadCountriesInformation();
  }, [loadCities, selectedState, selectedCountry]);

  return { cities, setCities, loadCities };
};
