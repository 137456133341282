import { AxiosError } from 'axios';

const NOT_FOUND = 404;
const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const SERVER_ERROR = 500;
const FORBIDDEN = 403;

const checkErrorCode = (error: AxiosError, status: number) => error.response?.status === status;
const isNotFound = (error: AxiosError) => checkErrorCode(error, NOT_FOUND);
const isBadRequest = (error: AxiosError) => checkErrorCode(error, BAD_REQUEST);
const isUnauthorized = (error: AxiosError) => checkErrorCode(error, UNAUTHORIZED);
const isForbidden = (error: AxiosError) => checkErrorCode(error, FORBIDDEN);
const isServerError = (error: AxiosError) => checkErrorCode(error, SERVER_ERROR);
const isExpected = (error: AxiosError) =>
  error.response && error.response.status >= BAD_REQUEST && error.response.status < SERVER_ERROR;

export { isNotFound, isBadRequest, isUnauthorized, isForbidden, isServerError, isExpected };
