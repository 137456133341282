import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/card';
import CardBody from 'components/card/card-body';
import CardHeader from 'components/card/card-header';
import StandardTable from 'components/standard-table';
import usePaging, { Query } from 'hooks/usePaging';
import React, { useEffect, useState } from 'react';
import { EmployeeFilter } from 'services/employeeService';
import styled from 'styled-components';
import { clearLocalStorage } from 'utils/localStorageManagment/localStorageManager';

import columns from './columns';
import Filter from './filter';
import { useEmployeeCatalogFilter, useEmployeesEffect } from './hooks';

clearLocalStorage(); //Clear local storage on refresh

const EmployeesList: React.FC = () => {
  const localEmployeesFilter = localStorage.getItem('employees_employees');
  const localLeadersFilter = localStorage.getItem('leaders_employees');
  const localProject = localStorage.getItem('projects_employees');
  const localJobTitle = localStorage.getItem('jobTitles_employees');
  const localSearchFilter = localStorage.getItem('search_employees');
  const countryFilter = localStorage.getItem('country_employees');
  const salaryPeriodFilter = localStorage.getItem('salaryPeriod_employees');
  const clientSalaryPeriodFilter = localStorage.getItem('clientSalaryPeriodMonth_employees');
  const activeFilter = localStorage.getItem('isActive_employees');

  const DEFAULT_QUERY: Query & EmployeeFilter = {
    employees: localEmployeesFilter?.split(',') || [],
    leaders: localLeadersFilter?.split(',') || [],
    projects: localProject?.split(',') || [],
    jobTitles: localJobTitle || '',
    search: localSearchFilter || '',
    country: countryFilter || '',
    salaryPeriodMonth: salaryPeriodFilter || '',
    clientSalaryPeriodMonth: clientSalaryPeriodFilter || '',
    onlyLeaders: false,
    isActive: activeFilter ? Boolean(activeFilter) : true,
    pageSize: 20,
    page: 1,
    isSortAscending: 1,
    sortByColumn: 'name',
  };

  const { handlePageChange, handleColumnSorting, query, setQuery } = usePaging(DEFAULT_QUERY);
  const { employees, loading: employeesLoading, setLoading, fetchEmployees } = useEmployeesEffect(query);
  const { employeesFilter, leaders, projects, jobTitles } = useEmployeeCatalogFilter();
  const initialLoading = useInitialLoading(employeesLoading);
  const isLoading = employeesLoading;
  const [defaultFilter, setDefaultFilter] = useState(DEFAULT_QUERY);

  return (
    <Container>
      <Card>
        <CardHeader title="Employees" subTitle="" loading={isLoading} newPageRedirectionLink={'/employees/new'} />
        {initialLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        {!initialLoading && (
          <CardBody>
            <Filter
              employees={employeesFilter}
              leaders={leaders}
              projects={projects}
              jobTitles={jobTitles}
              defaultFilter={defaultFilter}
              setDefaultFilter={setDefaultFilter}
              search={''}
              onlyLeaders={false}
              setFilter={setQuery}
              filter={query}
              totalItems={employees.totalItems}
            />
            {employees?.items.length ? (
              <StandardTable
                keyColumn="_id"
                data={employees.items}
                totalItems={employees.totalItems}
                page={query.page}
                pageSize={query.pageSize}
                isSortAscending={Boolean(query.isSortAscending)}
                sortByColumn={query.sortByColumn}
                onChangePage={handlePageChange}
                columns={columns(fetchEmployees, setLoading)}
                onSort={handleColumnSorting}
              />
            ) : (
              <NoRecords>There are no records to display, please check your filters. </NoRecords>
            )}
          </CardBody>
        )}
      </Card>
    </Container>
  );
};

const useInitialLoading = (loading: boolean) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  return initialLoading;
};

const Container = styled.div`
  padding: 0 15px;
  .MuiTableCell-body:last-child {
    padding: 0px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 40vh;
`;

const Spinner = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.primary.main};
`;

const NoRecords = styled.div`
  padding: 10vh;
  border-bottom: 1px solid #dfdfe0;
  text-align: center;
`;

export default EmployeesList;
