import http from './httpService';

const HR_URL = '/api/humanResources';

export type EmployeeBirthdayResource = { _id: string; fullName: string; birthDayNumber: number };
export type BirthdayByMonthResource = { _id: string; birthdayList: [EmployeeBirthdayResource] };
export type BirthdayResource = {
  birthdays: [BirthdayByMonthResource];
};

export type EmployeeAnniversaryResource = {
  _id: string;
  fullName: string;
  anniversarieDayNumber: number;
  yearsInCompany: number;
};
export type AnniversaryByMonthResource = { _id: string; anniversaryList: [EmployeeAnniversaryResource] };
export type AnniversaryResource = {
  anniversaries: [AnniversaryByMonthResource];
};

export async function getBirthdays() {
  const { data } = await http.get<BirthdayResource>(HR_URL + '/birthdays');

  return data;
}

export async function getAnniversaries() {
  const { data } = await http.get<AnniversaryResource>(HR_URL + '/anniversaries');

  return data;
}
