import http from './httpService';

const REWARDS_URL = '/api/rewardsRefund';

export type SaveRewardRefund = {
  realAmount: string;
  requestComment: string;
  purchasedDate: string;
  purchaseCompany: string;
  purchased: string;
  electronicInvoice: string;
  invoice?: string;
  rewStatusID?: any;
};

export type RewardRefund = {
  _id: string;
  realAmount: string;
  requestComment: string;
  purchasedDate: string;
  purchaseCompany: string;
  purchased: string;
  electronicInvoice: string;
  invoice?: string;
  rewStatusID?: any;
};

export async function getRewardRefundById(id: string) {
  const { data } = await http.get<RewardRefund>(`${REWARDS_URL}/${id}/refund`);

  return data;
}

export async function updateRewardRefund(refund: any, id: string) {
  const { data } = await http.put<RewardRefund>(`${REWARDS_URL}/${id}/refund`, refund);

  return data;
}
