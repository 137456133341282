import { isPermissionGranted } from 'helpers/roleHelper';
import { useCurrentUser } from 'providers/UserProvider';
import React, { useCallback } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  roles?: Array<String>;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, roles = null, ...rest }) => {
  const user = useCurrentUser();
  const handleRender = useCallback(
    (props) => (Boolean(user) ? <Component {...props} {...rest} /> : <Redirect to="/login" />),
    [user, rest],
  );

  // check if route is restricted by role
  if (Boolean(user) && roles && !isPermissionGranted(roles, user)) {
    // role not authorised so redirect to home page
    return <Redirect to={{ pathname: '/' }} />;
  }

  return <Route {...rest} render={handleRender} />;
};

export default PrivateRoute;
