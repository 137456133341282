import {
  AppBar,
  Avatar,
  ClickAwayListener,
  createStyles,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Theme,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useRef, useState } from 'react';
import { UserResource } from 'services/authService';
import styled from 'styled-components';

import NavBarBreadcrumbs from './nav-bar-breadcrumbs';
import NavBarItem from './nav-bar-item';

export type RouteItem = {
  to: string;
  icon: string;
  name: string;
  onClick?(): void;
  iconStyle?: any;
};

type Props = {
  routes: RouteItem[];
  userInfo: UserResource | null;
  mobileOpen: boolean;
  setMobileOpen(value: boolean): void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

const NavBar: React.FC<Props> = (props) => {
  const { userInfo, routes, mobileOpen, setMobileOpen } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClosePopper = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleShowPopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Container position="static">
      <ToolbarContainer>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <NavBarBreadcrumbs userInfo={userInfo} />

        <div>
          <IconButtonContainer
            id={'userProfileId'}
            aria-label="User profile"
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            ref={anchorRef}
            onClick={handleShowPopper}
          >
            <Avatar alt={userInfo ? userInfo.name : ''} src={userInfo ? userInfo.picture : ''} />
          </IconButtonContainer>

          <PopperContainer open={open} anchorEl={anchorRef.current} transition placement={'bottom-end'}>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'top' }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClosePopper}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      <div>
                        {routes.map((route) => (
                          <NavBarItem {...route} key={route.to} />
                        ))}
                      </div>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </PopperContainer>
        </div>
      </ToolbarContainer>
    </Container>
  );
};

const Container = styled(AppBar)`
  background-color: transparent;
  box-shadow: none;
`;

const ToolbarContainer = styled(Toolbar)`
  min-height: 50px;
`;

const PopperContainer = styled(Popper)`
  z-index: 10;
`;

const IconButtonContainer = styled(IconButton)`
  padding: 5px;
  padding-top: 10px;
`;

export default NavBar;
