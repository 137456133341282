import { DatePicker } from '@material-ui/pickers';
import React, { ComponentProps, useCallback } from 'react';

type Props = {
  value: string | null;
  onChange(value?: any): void;
} & Omit<ComponentProps<typeof DatePicker>, 'value' | 'onChange'>;

const DateInputPicker: React.FC<Props> = (props) => {
  const { value, onChange, name, ...rest } = props;

  const dateToDisplay = replaceDefaultDate(value);

  const handleOnChange = useCallback(
    (date?: any) => {
      const event = { target: { value: date?.toString(), name } };

      onChange(event);
    },
    [onChange, name],
  );

  return (
    <DatePicker
      clearable
      inputVariant="outlined"
      variant="dialog"
      value={dateToDisplay && new Date(dateToDisplay)}
      onChange={handleOnChange}
      name={name}
      {...rest}
    />
  );
};

const replaceDefaultDate = (dateValue: string | null) => {
  if (!dateValue) return null;
  const dateToDisplay = new Date(dateValue);

  if (dateToDisplay.getFullYear() <= 1900) {
    return null;
  }
  return dateToDisplay.toISOString();
};

export default DateInputPicker;
