/* eslint-disable react/jsx-no-bind */
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Column } from 'components/standard-table/types';
import StatusHighlighter from 'components/status-highlighter';
import TableRowAction from 'components/table-row-action';
import React from 'react';
import {
  deleteScreenOnboardingProcess,
  ScreenOnboardingProcessResource,
} from 'services/screenOnboardingProcessService';
import styled from 'styled-components';
import { stringDateToHumanFormat } from 'utils/formatters/date';
import { statusObjectToString } from 'utils/formatters/status';
import { verifyAllCompleted } from 'utils/general/generalMethods';

const fixedColumns: Column<ScreenOnboardingProcessResource>[] = [
  {
    label: 'Date Created',
    sortKey: 'creationDate',
    path: 'creationDate',
    render: (item) => `${item.creationDate ? stringDateToHumanFormat(item.creationDate) : 'Not Assigned'}`,
  },
  {
    label: 'Employee',
    sortKey: 'employee.name',
    path: 'employee.name',
    render: (item) => `${item.employee.name} ${item.employee.lastName}`,
  },
  {
    label: 'Template',
    sortKey: 'template.title',
    path: 'template.title',
  },
  {
    label: 'Status',
    sortKey: 'isCompleted',
    path: 'isCompleted',
    render: (item) => <div>{<StatusHighlighter name={statusObjectToString(item.descriptions)} />}</div>,
  },
];

const variableColumns = (fetchScreenOnboardingProcess: any, setLoading: any, showConfirmationModal: any) => {
  return {
    path: 'edit-delete',
    render: (item) => (
      <Container>
        <TableRowAction
          id={item._id}
          editEnable
          deleteEnable
          fetchItems={fetchScreenOnboardingProcess}
          deleteItem={deleteScreenOnboardingProcess}
          setLoading={setLoading}
          url={'onboarding-process-screen'}
          name={'Onboarding process'}
          deleteTitle={'Delete Onboarding Process'}
          deleteMessage={'Do you want to delete this onboarding process?'}
        />
      </Container>
    ),
  };
};

const Container = styled.div`
  display: flex;
  float: right;
`;

const columns = (fetchOnboardingProcessTemplates: any, setLoading: any, showConfirmationModal: any) => {
  return [...fixedColumns, variableColumns(fetchOnboardingProcessTemplates, setLoading, showConfirmationModal)];
};

export default columns;
