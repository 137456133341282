/* eslint-disable react/jsx-no-bind */
import { Box, IconButton, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  handleConvertUploadedFile: Function;
  handleOnUploadFileClose: Function;
  xlsxTemplate: any;
}

const UploadXlsxFile: React.FC<Props> = (props) => {
  const [uploadedFile, setUploadedFile] = useState<any | null>(null);
  const { handleConvertUploadedFile, handleOnUploadFileClose, xlsxTemplate } = props;

  return (
    <Box style={{ marginBottom: '10px' }}>
      <Box>
        <RegularText>Upload from xlsx file:</RegularText>
        <Box style={{ display: 'flex', paddingTop: '4px' }}>
          <label htmlFor="getFile">
            <Input
              type="file"
              id="getFile"
              accept=".xls,.xlsx"
              onChange={(e) => setUploadedFile(e?.target?.files ? e.target.files[0] : null)}
            />
            <Button
              style={{ color: 'white', backgroundColor: '#5BCAFF', borderRadius: '5px 0px 0px 5px' }}
              component="span"
              color="primary"
            >
              Select file
            </Button>
          </label>
          <InputFileNameBox>{uploadedFile?.name || 'No file chosen'}</InputFileNameBox>
          <Box style={{ marginTop: '-5px' }}>
            <Tooltip title="Upload file">
              <IconButton color="primary">
                <PublishOutlinedIcon onClick={() => handleConvertUploadedFile(uploadedFile, setUploadedFile)} />
              </IconButton>
            </Tooltip>
            {xlsxTemplate && (
              <a href={xlsxTemplate} download="DevSimple_Employee_Template.xlsx">
                <Tooltip title="Donwload template file">
                  <IconButton color="primary">
                    <DescriptionOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </a>
            )}

            <Tooltip title="Close this option">
              <IconButton style={{ color: '#ef5350' }}>
                <CloseIcon onClick={() => handleOnUploadFileClose()} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const RegularText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  width: fit-content;
`;

const Input = styled('input')({
  display: 'none',
});

const InputFileNameBox = styled(Box)`
  width: 200px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 6px;
  height: 36.5px;
  border: 1px #5BCAFF solid;
  border-radius:0px 5px 5px 0px;
}
`;

export default UploadXlsxFile;
