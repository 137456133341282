import { PageResource, Query } from 'hooks/usePaging';
import { useCallback, useEffect, useState } from 'react';
import { getOnboardingProcessTemplatesQuery, OnboardingProcessResource } from 'services/onboardingProcessTemplate';

import { SearchFilter } from '.';



export const useOnboardingProcessEffect = (query: Query & SearchFilter) => {
  const defaultResponse: PageResource<OnboardingProcessResource> = { totalItems: 0, items: [] };
  const [onboardingProcessTemplates, setOnboardingProcessTemplates] = useState(defaultResponse);
  const [loading, setLoading] = useState(true);

  const fetchOnboardingProcessTemplates = useCallback(async () => {

    setLoading(true);
    const onboardingProcess = await getOnboardingProcessTemplatesQuery(query);

    setLoading(false);
    setOnboardingProcessTemplates(onboardingProcess);
  }, [query]);


  useEffect(() => {
    fetchOnboardingProcessTemplates();
  }, [fetchOnboardingProcessTemplates, query]);

  return {
    onboardingProcessTemplates,
    loading,
    setLoading,
    fetchOnboardingProcessTemplates,
  };
};
