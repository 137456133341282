import { useEffect, useState } from 'react';
import { getAllEmployees } from 'services/employeeService';
import { getOnboardingProcessTemplates } from 'services/onboardingProcessTemplate';
import { CreateScreenOnboardingProcessResource } from 'services/screenOnboardingProcessService';
import { Item } from 'shared/types';
import { collectionEmployeeToItem } from 'utils/mappers/item';

const defaultCreateOpResource = {
  employee: '',
  template: '',
  descriptions: [],
};

export const useFetchOpEffect = () => {
  const [op] = useState<CreateScreenOnboardingProcessResource>(defaultCreateOpResource);

  return { op };
};

export const useCatalogs = () => {
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Item[]>([]);
  const [templates, setTemplates] = useState<Item[]>([]);

  useEffect(() => {
    async function fetchCatalogs() {
      const [employeesResult, templatesResult] = await Promise.all([
        getAllEmployees(),
        getOnboardingProcessTemplates(),
      ]);

      const employeesItems = collectionEmployeeToItem(employeesResult);
      const templateItems: Item[] = templatesResult.map((template) => ({ id: template._id, label: template.title }));

      setEmployees(employeesItems);
      setLoading(false);

      setTemplates(templateItems);
    }

    fetchCatalogs();
  }, []);

  return { templates, employees, loading };
};
